import IconPropsInterface from '@icons/IconPropsInterface'

export const SocialViber = ({
  size = 20,
  color,
  style,
}: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style, verticalAlign: 'middle' }}
    >
      <g clipPath="url(#clip0_926_114736)">
        <path
          d="M9.99979 7.08333C10.3313 7.08333 10.6493 7.21503 10.8837 7.44945C11.1181 7.68387 11.2498 8.00181 11.2498 8.33333M9.99979 3.75C11.2154 3.75 12.3812 4.23289 13.2407 5.09243C14.1002 5.95197 14.5831 7.11776 14.5831 8.33333M9.99979 5.41667C10.7733 5.41667 11.5152 5.72396 12.0622 6.27094C12.6092 6.81792 12.9165 7.55979 12.9165 8.33333M7.08312 11.25C8.16645 12.3333 10.5581 13.75 11.6665 13.75C12.1526 13.755 12.6256 13.5918 13.0053 13.2881C13.3849 12.9844 13.648 12.5588 13.7498 12.0833V11.25C13.7498 11.25 12.7248 10.75 12.0831 10.4167C11.4415 10.0833 10.4165 11.25 10.4165 11.25C9.63656 10.9959 8.92431 10.5686 8.33312 10C7.76454 9.40881 7.33719 8.69656 7.08312 7.91667C7.08312 7.91667 8.24979 6.89167 7.91645 6.25C7.58312 5.60833 7.08312 4.58333 7.08312 4.58333H6.24979C5.77436 4.68516 5.34874 4.94818 5.04501 5.32784C4.74128 5.70751 4.5781 6.18048 4.58312 6.66667C4.58312 7.775 5.99979 10.1667 7.08312 11.25Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 1.25C5.45833 1.25 1.25 1.98333 1.25 8.75C1.25 13.0833 2.98333 14.9417 5.41667 15.7167V17.8917C5.39816 18.0746 5.44069 18.2586 5.53765 18.4149C5.63461 18.5711 5.78053 18.691 5.95269 18.7556C6.12485 18.8203 6.31357 18.8262 6.48944 18.7725C6.66531 18.7187 6.81845 18.6082 6.925 18.4583L8.875 16.2333H10C14.5417 16.2333 18.75 15.5083 18.75 8.73333C18.75 1.95833 14.5417 1.25 10 1.25Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_926_114736">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
