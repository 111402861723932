import { ReactNode } from 'react'
import styled, { CSSProperties } from 'styled-components'

import { Photo } from '@icons/Photo'
import { useIntersectionObserver } from '@src/hooks/useIntersectionObserver'
import { useToggle } from '@src/hooks/useToggle'
import { noop } from '@src/utils/helpers/noop'
import { useGetTheme } from '@theme/hooks'
import { BaseSkeleton } from '@ui/BaseSkeleton'
import { Box } from '@ui/Box'

export interface IImageBox {
  src?: string
  alt?: string
  boxHeight?: string | number
  boxWidth?: string | number
  size?: string | number
  fit?: string
  rounded?: boolean
  borderRadius?: number
  style?: CSSProperties
  placeholderColor?: string
  placeholderIcon?: ReactNode
  onClick?: () => void
  className?: string
}

export const StyledBox = styled.div<Omit<IImageBox, 'src'>>`
  position: relative;
  flex-shrink: 0;
  height: ${({ boxHeight }) => boxHeight};
  width: ${({ boxWidth }) => boxWidth};
  border-radius: ${({ rounded, borderRadius }) =>
    rounded ? '50%' : `${borderRadius}px`};
  overflow: hidden;
`
const Img = styled.img<IImageBox>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: ${({ fit }) => fit ?? 'cover'};
`
const SkeletonWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
`
export const getParam = (param?: string | number, size?: string | number) => {
  if (size) {
    return typeof size === 'string' ? size : `${size}px`
  }
  if (param) {
    return typeof param === 'string' ? param : `${param}px`
  }
  return '100%'
}

export const ImageBox = ({
  src,
  alt = '',
  boxHeight,
  boxWidth,
  fit,
  size,
  rounded = false,
  borderRadius = 0,
  style,
  placeholderColor,
  placeholderIcon = null,
  onClick = noop,
  className,
}: IImageBox) => {
  const theme = useGetTheme()
  const { isOn: isLoading, off: loaded } = useToggle(true)
  const { isOn: isTimeToLoad, on: startLoading } = useToggle(false)
  const { isOn: isFailed, on: failed } = useToggle(false)
  const { ref } = useIntersectionObserver({
    callback: startLoading,
    once: true,
  })

  const readyPlaceholderColor = placeholderColor
    ? placeholderColor
    : theme.colors.primaryLight

  const Placeholder = (
    <Box
      flex
      justifyContent="center"
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: readyPlaceholderColor,
      }}
    >
      {placeholderIcon ? (
        placeholderIcon
      ) : (
        <Photo color={theme.colors.primary} />
      )}
    </Box>
  )

  return (
    <StyledBox
      ref={ref}
      boxHeight={getParam(boxHeight, size)}
      boxWidth={getParam(boxWidth, size)}
      rounded={rounded}
      borderRadius={borderRadius}
      style={style}
      onClick={onClick}
      className={className}
    >
      {src ? (
        <>
          {isTimeToLoad && !isFailed && (
            <Img
              onLoad={loaded}
              onError={() => {
                failed()
                loaded()
              }}
              src={src}
              alt={alt}
              fit={fit}
            />
          )}
          {isLoading && (
            <SkeletonWrapper>
              <BaseSkeleton />
            </SkeletonWrapper>
          )}
          {isFailed && Placeholder}
        </>
      ) : (
        Placeholder
      )}
    </StyledBox>
  )
}
