import { clientApiSlice } from '@src/store/slices/clientApiSlice'
import { formatUrlGetParamsFromObject } from '@src/utils/helpers/formatUrlGetParamsFromObject'

export const executorApi = clientApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEmployeesExecutorOffer: builder.query<any, any>({
      query: ({ spaceId, page }) => ({
        url: `/spaces/${spaceId}/employees/drop-down${formatUrlGetParamsFromObject(
          {
            page,
            limit: 50,
          }
        )}`,
        method: 'GET',
      }),
    }),
    getVehicleExecutorOffer: builder.query<any, any>({
      query: ({ page, spaceId }) => ({
        url: `/vehicles${formatUrlGetParamsFromObject({
          space_id: spaceId,
          page,
          limit: 50,
        })}`,
        method: 'GET',
      }),
    }),
    createExecutorOffer: builder.mutation<any, any>({
      query: ({
        spaceId,
        projectId,
        bankAccountId,
        ownershipTypeId,
        validUntil,
        tasks,
      }) => ({
        url: `/spaces/${spaceId}/executor/offers`,
        method: 'POST',
        body: {
          project_id: projectId,
          ownership_type_id: ownershipTypeId,
          bank_account_id: bankAccountId,
          valid_until: validUntil,
          tasks,
        },
      }),
    }),
  }),
})

export const {
  useGetEmployeesExecutorOfferQuery,
  useLazyGetEmployeesExecutorOfferQuery,
  useGetVehicleExecutorOfferQuery,
  useLazyGetVehicleExecutorOfferQuery,
  useCreateExecutorOfferMutation,
} = executorApi
