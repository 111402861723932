import { createSlice } from '@reduxjs/toolkit'

export interface IRentState {
  workType: { label: string; value: number } | null
  vehicleTypes: { label: string; value: number }[]
  radius: number
  coordinates: [number, number] | null
}

const initialState: IRentState = {
  workType: null,
  radius: 1,
  coordinates: null,
  vehicleTypes: [],
}

const filterSliceRent = createSlice({
  name: 'filterRent',
  initialState,
  reducers: {
    setValueInStateRent: (state, action) => {
      const field = action.payload.field as keyof IRentState
      state[field] = action.payload.data as never
    },
    resetRentFilter: () => initialState,
  },
})

export const { setValueInStateRent, resetRentFilter } = filterSliceRent.actions

export const filterSliceRentReducer = filterSliceRent.reducer
