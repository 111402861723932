import IconPropsInterface from './IconPropsInterface'

export const Play = ({ size = 20, color, style }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 5 8"
      fill={color}
      stroke="transparent"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms', ...style }}
    >
      <path
        style={{ transform: 'translateX(17%)' }}
        d="M4.51196 3.60957L0.812348 0.649878C0.484966 0.387973 0 0.621059 0 1.04031V6.95969C0 7.37894 0.484966 7.61203 0.812348 7.35012L4.51196 4.39043C4.76216 4.19027 4.76216 3.80973 4.51196 3.60957Z"
      />
    </svg>
  )
}
