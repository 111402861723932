import styled from 'styled-components'

import { Box } from '@ui/Box'

export const ItemsListWrapper = styled(Box)<{ maxHeight?: number }>`
  width: calc(100% + 2px);
  display: flex;
  overflow: hidden;
  z-index: 3;
  position: absolute;
  left: -1px;
  top: 24px;
  margin-top: 10px;
  background: white;
  max-height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}px` : '100px')};
  background: ${(props) => props.theme.colors.light};
`
