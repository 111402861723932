import styled from 'styled-components'

import { getColor } from '@theme/helpers'
import {
  BorderVariant,
  FillVariant,
  IBaseButtonProps,
  SizeVariant,
} from '@ui/Buttons/Base/interfaces'
import {
  borderVariants,
  fillVariants,
  sizeVariants,
} from '@ui/Buttons/Base/variants'

export const BaseButton = styled.button.attrs(({ ref, disabled }) => ({
  ref,
  disabled,
}))<Omit<IBaseButtonProps, 'children' | 'handleClick'>>`
  display: block;
  flex-shrink: 0;
  ${getColor('primary')}
  width: 100%;
  max-width: ${({ fluid }) => (fluid ? '100%' : 'fit-content')};
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: background-color 300ms;
  ${({ borderVariant }) => borderVariants[borderVariant as BorderVariant]}
  ${({ fillVariant }) => fillVariants[fillVariant as FillVariant]}
  ${({ sizeVariant }) => sizeVariants[sizeVariant as SizeVariant]}
  &:disabled {
    pointer-events: none;
  }
  &:active {
    opacity: 0.75;
  }
`
