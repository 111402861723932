import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface IDetailedApplicationState {
  deletedTaskId: number | null
  editedTaskId: number | null
  isClosePopupOpened: boolean
  isEditPopupOpened: boolean
}

const initialState: IDetailedApplicationState = {
  deletedTaskId: null,
  editedTaskId: null,
  isClosePopupOpened: false,
  isEditPopupOpened: false,
}

const detailedApplicationSlice = createSlice({
  name: 'detailedApplication',
  initialState,
  reducers: {
    deletedTaskIdChanged: (
      state,
      { payload }: PayloadAction<number | null>
    ) => {
      state.deletedTaskId = payload
    },
    editedTaskIdChanged: (state, { payload }: PayloadAction<number | null>) => {
      state.editedTaskId = payload
    },
    closePopupToggled: (state, { payload }: PayloadAction<boolean>) => {
      state.isClosePopupOpened = payload
    },
    editPopupToggled: (state, { payload }: PayloadAction<boolean>) => {
      state.isEditPopupOpened = payload
    },
  },
})

export default detailedApplicationSlice.reducer

export const {
  deletedTaskIdChanged,
  closePopupToggled,
  editedTaskIdChanged,
  editPopupToggled,
} = detailedApplicationSlice.actions
