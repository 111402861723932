import IconPropsInterface from './IconPropsInterface'

export const Question = ({
  size = 20,
  color = '#D9D9D9',
}: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 12.75C9.8995 12.75 12.25 10.3995 12.25 7.5C12.25 4.6005 9.8995 2.25 7 2.25C4.1005 2.25 1.75 4.6005 1.75 7.5C1.75 10.3995 4.1005 12.75 7 12.75Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 10.4165V10.4235"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.9987 8.37469C6.98796 8.18532 7.03904 7.99759 7.14425 7.83978C7.24946 7.68196 7.4031 7.56261 7.58203 7.49969C7.80129 7.41584 7.99809 7.28224 8.15695 7.10941C8.3158 6.93658 8.43237 6.72924 8.49747 6.5037C8.56257 6.27816 8.57444 6.04059 8.53212 5.80969C8.48981 5.57879 8.39448 5.36087 8.25363 5.17307C8.11278 4.98528 7.93027 4.83274 7.72045 4.72747C7.51064 4.62219 7.27925 4.56706 7.0445 4.56641C6.80976 4.56576 6.57807 4.61961 6.36767 4.72371C6.15728 4.82782 5.97392 4.97934 5.83203 5.16636"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
