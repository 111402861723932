import Cookies from 'js-cookie'

import { createSlice } from '@reduxjs/toolkit'

type RegState = {
  access_token?: string | null
  token_type?: string | null
  expire_in?: string | null
  remember?: boolean
  regPhone?: string
}

const initialState: RegState = {
  access_token: null,
  token_type: null,
  expire_in: null,
  remember: false,
  regPhone: '',
}

const regSlice = createSlice({
  name: 'reg',
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const { access_token, token_type, expire_in } = action.payload.data
      state.access_token = access_token
      state.token_type = token_type
      state.expire_in = expire_in
      state.remember = action.payload.remember
      if (state.remember) {
        localStorage.setItem('TOKEN_TYPE', token_type)
        localStorage.setItem('ACCESS_TOKEN', access_token)
        localStorage.setItem('EXPIRES', expire_in)
      } else {
        Cookies.set('ACCESS_TOKEN', access_token)
        Cookies.set('TOKEN_TYPE', token_type)
      }
    },
    logoutState: (state) => {
      state.access_token = null
      state.token_type = null
      state.expire_in = null

      localStorage.removeItem('spaceId')
      localStorage.removeItem('ownSpaceId')
      localStorage.removeItem('TOKEN_TYPE')
      localStorage.removeItem('ACCESS_TOKEN')
      localStorage.removeItem('EXPIRES')
      Cookies.remove('ACCESS_TOKEN')
      Cookies.remove('TOKEN_TYPE')
    },
    setRegPhone: (state, action) => {
      state.regPhone = action.payload
    },
  },
})

export const { setCredentials, logoutState, setRegPhone } = regSlice.actions

export default regSlice.reducer
