export const FilePdf = () => {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3">
        <path
          opacity="0.3"
          d="M13.967 4.92487C13.4203 4.92434 12.8962 4.70692 12.5096 4.32035C12.123 3.93377 11.9056 3.40961 11.905 2.86291V0H2.69101C1.97731 0 1.29284 0.283516 0.78818 0.788163C0.283518 1.29281 0 1.97724 0 2.69092V18.9125C0.00105984 19.6255 0.285043 20.3089 0.78959 20.8127C1.29414 21.3165 1.978 21.5994 2.69101 21.5994H13.752C14.4651 21.5994 15.1489 21.3165 15.6535 20.8127C16.158 20.3089 16.442 19.6255 16.4431 18.9125V4.92487H13.967Z"
          fill="#FF2A2A"
        />
      </g>
      <path
        d="M16.4443 4.92487H13.9683C13.4215 4.92434 12.8974 4.70692 12.5108 4.32034C12.1242 3.93377 11.9068 3.40961 11.9062 2.86291V0L16.4443 4.92487Z"
        fill="#FF2A2A"
      />
      <path
        d="M13.0584 15.0849H2.73438C2.63492 15.0849 2.53953 15.0454 2.46921 14.9751C2.39888 14.9048 2.35938 14.8094 2.35938 14.7099C2.35964 14.6107 2.39926 14.5155 2.46956 14.4454C2.53986 14.3753 2.63509 14.3359 2.73438 14.3359H13.0584C13.1576 14.3359 13.2527 14.3753 13.3229 14.4455C13.393 14.5156 13.4324 14.6107 13.4324 14.7099C13.4325 14.7591 13.423 14.8079 13.4042 14.8534C13.3855 14.8989 13.358 14.9402 13.3232 14.975C13.2885 15.0099 13.2472 15.0375 13.2018 15.0564C13.1563 15.0752 13.1076 15.0849 13.0584 15.0849Z"
        fill="#FF2A2A"
      />
      <path
        d="M13.0584 10.8623H2.73438C2.63509 10.8623 2.53986 10.8229 2.46956 10.7528C2.39926 10.6827 2.35964 10.5876 2.35938 10.4883C2.35938 10.3888 2.39888 10.2934 2.46921 10.2231C2.53953 10.1528 2.63492 10.1133 2.73438 10.1133H13.0584C13.1076 10.1133 13.1563 10.123 13.2018 10.1418C13.2472 10.1607 13.2885 10.1883 13.3232 10.2232C13.358 10.258 13.3855 10.2994 13.4042 10.3449C13.423 10.3903 13.4325 10.4391 13.4324 10.4883C13.4324 10.5875 13.393 10.6826 13.3229 10.7527C13.2527 10.8229 13.1576 10.8623 13.0584 10.8623Z"
        fill="#FF2A2A"
      />
      <path
        d="M13.0584 12.9717H2.73438C2.63492 12.9717 2.53953 12.9322 2.46921 12.8618C2.39888 12.7915 2.35938 12.6961 2.35938 12.5967C2.35964 12.4974 2.39926 12.4022 2.46956 12.3321C2.53986 12.262 2.63509 12.2227 2.73438 12.2227H13.0584C13.1576 12.2227 13.2527 12.2621 13.3229 12.3322C13.393 12.4023 13.4324 12.4975 13.4324 12.5967C13.4325 12.6459 13.423 12.6946 13.4042 12.7401C13.3855 12.7856 13.358 12.8269 13.3232 12.8618C13.2885 12.8966 13.2472 12.9242 13.2018 12.9431C13.1563 12.962 13.1076 12.9717 13.0584 12.9717Z"
        fill="#FF2A2A"
      />
      <path
        d="M8.9864 8.74901H2.73438C2.63509 8.74901 2.53986 8.70965 2.46956 8.63954C2.39926 8.56942 2.35964 8.47429 2.35938 8.37501C2.35938 8.27555 2.39888 8.18017 2.46921 8.10984C2.53953 8.03951 2.63492 8 2.73438 8H8.9864C9.08568 8.00026 9.18081 8.03988 9.25092 8.11018C9.32103 8.18048 9.3604 8.27572 9.3604 8.37501C9.36013 8.47412 9.32065 8.56909 9.25057 8.63917C9.18048 8.70925 9.08551 8.74875 8.9864 8.74901Z"
        fill="#FF2A2A"
      />
      <path
        d="M8.9864 6.63964H2.73438C2.63509 6.63964 2.53986 6.60025 2.46956 6.53014C2.39926 6.46003 2.35964 6.36491 2.35938 6.26563C2.35938 6.16617 2.39888 6.07077 2.46921 6.00044C2.53953 5.93011 2.63492 5.89062 2.73438 5.89062H8.9864C9.08568 5.89089 9.18081 5.93051 9.25092 6.00081C9.32103 6.07111 9.3604 6.16635 9.3604 6.26563C9.36013 6.36474 9.32065 6.45971 9.25057 6.52979C9.18048 6.59988 9.08551 6.63937 8.9864 6.63964Z"
        fill="#FF2A2A"
      />
      <path
        d="M18.3913 17.0586H5.88725C5.00028 17.0586 4.28125 17.7776 4.28125 18.6646V22.3925C4.28125 23.2794 5.00028 23.9984 5.88725 23.9984H18.3913C19.2783 23.9984 19.9973 23.2794 19.9973 22.3925V18.6646C19.9973 17.7776 19.2783 17.0586 18.3913 17.0586Z"
        fill="#FF2A2A"
      />
      <path d="M8.38482 21.3359V22.063H7.63281V21.3359H8.38482Z" fill="white" />
      <path
        d="M9.49263 21.0105V22.0655H8.76562V19.0586H9.94363C10.3006 19.0586 10.5736 19.1476 10.7606 19.3246C10.8553 19.4176 10.9291 19.5295 10.9774 19.6531C11.0256 19.7767 11.0472 19.9091 11.0406 20.0416C11.0443 20.2164 11.0011 20.389 10.9156 20.5415C10.8283 20.6907 10.6989 20.8107 10.5436 20.8865C10.3563 20.9751 10.1507 21.0179 9.94363 21.0115L9.49263 21.0105ZM10.3006 20.0416C10.3006 19.7749 10.1546 19.6416 9.86263 19.6416H9.49263V20.4245H9.86263C10.1546 20.4272 10.3006 20.2995 10.3006 20.0416Z"
        fill="white"
      />
      <path
        d="M13.9134 21.3411C13.7882 21.5684 13.5991 21.7541 13.3694 21.8751C13.111 22.0074 12.8237 22.073 12.5334 22.0661H11.3984V19.0592H12.5334C12.8239 19.0518 13.1117 19.1161 13.3714 19.2461C13.6005 19.3645 13.7891 19.5483 13.9134 19.7741C14.0436 20.0139 14.109 20.2834 14.1034 20.5561C14.1089 20.8298 14.0434 21.1002 13.9134 21.3411ZM13.1314 21.1901C13.2127 21.1066 13.2757 21.0072 13.3164 20.898C13.357 20.7888 13.3744 20.6724 13.3674 20.5561C13.3744 20.44 13.3571 20.3237 13.3164 20.2146C13.2758 20.1056 13.2128 20.0063 13.1314 19.9231C12.9496 19.7635 12.712 19.6823 12.4704 19.6971H12.1254V21.4151H12.4704C12.7118 21.4297 12.9492 21.3489 13.1314 21.1901Z"
        fill="white"
      />
      <path
        d="M16.4766 19.0586V19.6376H15.2436V20.2965H16.1956V20.8495H15.2436V22.0655H14.5156V19.0586H16.4766Z"
        fill="white"
      />
      <path
        d="M12.8807 5.89062H11.1127C10.8045 5.89062 10.5547 6.14044 10.5547 6.44861V8.21656C10.5547 8.52473 10.8045 8.77455 11.1127 8.77455H12.8807C13.1889 8.77455 13.4387 8.52473 13.4387 8.21656V6.44861C13.4387 6.14044 13.1889 5.89062 12.8807 5.89062Z"
        fill="#FF2A2A"
      />
    </svg>
  )
}
