import { createSlice } from '@reduxjs/toolkit'
import { IOption } from '@src/shared/interfaces/partials/IOption'
import { TaskType } from '@src/shared/interfaces/task/TaskType'

import { LIMIT_TASKS } from './constants'
import { ITaskListItemViewData } from './interfaces'

export interface ICommonTaskState {
  tasksTabsAreDisabled: boolean
  list: {
    items: ITaskListItemViewData[]
    lastItemsCount: number
    cursor: string | null
  }
  filter: {
    filterIsOn: boolean
    filterIsOpened: boolean
    type: IOption<TaskType> | null
    global: 'all' | 'favorite' | 'hidden'
    enable_rostechnadsor: boolean
    payment_methods: string[]
  }
  ownewshipId: number
  bankAccount: number
  phonesCache: Record<number, Record<number, string>>
}

const initialState: ICommonTaskState = {
  tasksTabsAreDisabled: false,
  list: {
    items: [],
    lastItemsCount: LIMIT_TASKS,
    cursor: null,
  },
  filter: {
    filterIsOn: false,
    filterIsOpened: false,
    type: null,
    global: 'all',
    enable_rostechnadsor: false,
    payment_methods: [],
  },
  ownewshipId: -1,
  bankAccount: -1,
  phonesCache: {},
}

const taskSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    setTasksTabsAreDisabled: (state, action) => {
      state.tasksTabsAreDisabled = action.payload
    },
    setFilterIsOn: (state, action) => {
      state.filter.filterIsOn = action.payload
    },
    setFilterIsOpened: (state, action) => {
      state.filter.filterIsOpened = action.payload
    },
    addPageDataTasks: (state, action) => {
      state.list.items = [...state.list.items, ...action.payload]
    },
    setCursorTasks: (state, action) => {
      state.list.cursor = action.payload
    },
    setLastItemsCountTasks: (state, action) => {
      state.list.lastItemsCount = action.payload
    },
    clearTasksList: (state) => {
      state.list.items = []
      state.list.cursor = null
      state.list.lastItemsCount = LIMIT_TASKS
    },
    setFilterType: (state, action) => {
      state.filter.type = action.payload
    },
    setGlobalFilter: (state, action) => {
      state.filter.global = action.payload
    },
    setRostechnadsor: (state, action) => {
      state.filter.enable_rostechnadsor = action.payload
    },
    addPaymentMethod: (state, action) => {
      state.filter.payment_methods = [
        ...state.filter.payment_methods,
        action.payload,
      ]
    },
    deletePaymentMethod: (state, action) => {
      state.filter.payment_methods = state.filter.payment_methods.filter(
        (item) => item !== action.payload
      )
    },
    hideTask: (state, action) => {
      state.list.items = state.list.items.filter(
        ({ id }) => +id !== +action.payload
      )
    },
    setOwnershipId: (state, action) => {
      state.ownewshipId = action.payload
    },
    setBankAccount: (state, action) => {
      state.bankAccount = action.payload
    },
    putPhoneIntoCache: (state, action) => {
      if (state.phonesCache[action.payload.taskSpaceId]) {
        state.phonesCache[action.payload.taskSpaceId][
          action.payload.profilePhoneId
        ] = action.payload.value
      } else {
        state.phonesCache[action.payload.taskSpaceId] = {
          [action.payload.profilePhoneId]: action.payload.value,
        }
      }
    },
  },
})

export const {
  setTasksTabsAreDisabled,
  setFilterIsOn,
  setFilterIsOpened,
  addPageDataTasks,
  setCursorTasks,
  setLastItemsCountTasks,
  clearTasksList,
  setFilterType,
  setGlobalFilter,
  addPaymentMethod,
  deletePaymentMethod,
  setRostechnadsor,
  hideTask,
  setOwnershipId,
  setBankAccount,
  putPhoneIntoCache,
} = taskSlice.actions

export const taskSliceReducer = taskSlice.reducer
