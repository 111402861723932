import IconPropsInterface from './IconPropsInterface'

export const Description = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 15 12"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path d="M4.5 1H13.6667" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.5 6H13.6667" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.5 11H13.6667" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M1.16797 1V1.00833"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.16797 6V6.00833"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.16797 11V11.0083"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
