import IconPropsInterface from '@icons/IconPropsInterface';


export const Inbox = ({ size = 20, color, style }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style, flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M18.8346 10H13.8346L12.168 12.5H8.83464L7.16797 10H2.16797"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.04297 4.25898L2.16797 10.0007V15.0007C2.16797 15.4427 2.34356 15.8666 2.65612 16.1792C2.96868 16.4917 3.39261 16.6673 3.83464 16.6673H17.168C17.61 16.6673 18.0339 16.4917 18.3465 16.1792C18.659 15.8666 18.8346 15.4427 18.8346 15.0007V10.0007L15.9596 4.25898C15.8217 3.98131 15.6089 3.74763 15.3454 3.58422C15.0819 3.42081 14.778 3.33415 14.468 3.33398H6.53464C6.22457 3.33415 5.92069 3.42081 5.65717 3.58422C5.39366 3.74763 5.18095 3.98131 5.04297 4.25898Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}