import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface InvoiceItem {
  task_id?: number
  title: string
  quantity: number
  price_per_unit: number
  tax: boolean
  comment: string
}
interface initialStateType {
  tasks: {
    task_id: number
    cost: number
    cost_delivery?: number
    number_of_passengers?: number
    title?: string
    description?: string
  }[]
  selectedBankId: number
  invoiceItems: InvoiceItem[]
  dealInfo: Record<any, any> | null
}

const initialState: initialStateType = {
  tasks: [],
  selectedBankId: -1,
  invoiceItems: [
    {
      title: '',
      quantity: 0,
      price_per_unit: 0,
      tax: false,
      comment: '',
    },
  ],
  dealInfo: null,
}

const DealSlice = createSlice({
  name: 'deal',
  initialState: initialState,
  reducers: {
    setTasksData: (state, action) => {
      state.tasks = [...state.tasks, action.payload]
    },
    resetTasks: (state) => {
      state.tasks = []
    },
    setTasksInvoice: (state, action) => {
      state.invoiceItems = action.payload.map((item: Record<any, any>) => {
        return {
          task_id: item?.task?.id,
          title: item.title,
          quantity: item.quantity,
          price_per_unit: item.price_per_unit.amount,
          tax: item.tax,
          comment: item.comment,
        }
      })
    },
    changeCost: (state, action) => {
      const { id, text } = action.payload
      console.log(text, 'text')
      const foundTask = state.tasks.find((task) => task.task_id === id)
      if (foundTask) {
        foundTask.cost = text
      }
    },
    changeCostDelivery: (state, action) => {
      const { id, text } = action.payload
      const foundTask = state.tasks.find((task) => task.task_id === id)
      if (foundTask) {
        foundTask.cost_delivery = text
      }
    },
    changeTitle: (state, action) => {
      const { id, text } = action.payload
      const foundTask = state.tasks.find((task) => task.task_id === id)
      if (foundTask) {
        foundTask.title = text
      }
    },
    changeDescription: (state, action) => {
      const { id, text } = action.payload
      const foundTask = state.tasks.find((task) => task.task_id === id)
      if (foundTask) {
        foundTask.description = text
      }
    },
    changePassenger: (state, action) => {
      const { id, text } = action.payload
      const foundTask = state.tasks.find((task) => task.task_id === id)
      if (foundTask) {
        foundTask.number_of_passengers = Number(text)
      }
    },
    setSelectedBankId: (state, action) => {
      state.selectedBankId = action.payload
    },
    setAddNewItem: (state) => {
      state.invoiceItems = [
        ...state.invoiceItems,
        {
          title: '',
          quantity: 0,
          price_per_unit: 0,
          tax: false,
          comment: '',
        },
      ]
    },
    setInvoice: (
      state,
      action: PayloadAction<{
        index: number
        field: keyof (typeof state.invoiceItems)[0]
        value: string | boolean | number
      }>
    ) => {
      const { index, field, value } = action.payload

      const updatedItems = [...state.invoiceItems]

      updatedItems[index] = {
        ...updatedItems[index],
        [field]: value,
      }

      state.invoiceItems = updatedItems
    },
    filterInvoiceItems: (state, action) => {
      state.invoiceItems = state.invoiceItems.filter(
        (item, index) => !action.payload.includes(index)
      )
    },
    resetInvoiceItems: (state) => {
      state.invoiceItems = [
        {
          title: '',
          quantity: 0,
          price_per_unit: 0,
          tax: false,
          comment: '',
        },
      ]
    },
    setDealInfo: (state, action) => {
      state.dealInfo = action.payload
    },
  },
})

export const {
  setTasksData,
  changeCost,
  changeCostDelivery,
  changePassenger,
  setSelectedBankId,
  setAddNewItem,
  setInvoice,
  filterInvoiceItems,
  resetInvoiceItems,
  changeTitle,
  changeDescription,
  setTasksInvoice,
  setDealInfo,
  resetTasks,
} = DealSlice.actions

export const dealReducer = DealSlice.reducer
