import IconPropsInterface from './IconPropsInterface'

export const Edit3 = ({ size = 20, color, style }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style, flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M14.668 2.5009C14.8868 2.28203 15.1467 2.10842 15.4326 1.98996C15.7186 1.87151 16.0251 1.81055 16.3346 1.81055C16.6442 1.81055 16.9507 1.87151 17.2366 1.98996C17.5226 2.10842 17.7824 2.28203 18.0013 2.5009C18.2202 2.71977 18.3938 2.97961 18.5122 3.26558C18.6307 3.55154 18.6917 3.85804 18.6917 4.16757C18.6917 4.4771 18.6307 4.7836 18.5122 5.06956C18.3938 5.35553 18.2202 5.61537 18.0013 5.83424L6.7513 17.0842L2.16797 18.3342L3.41797 13.7509L14.668 2.5009Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
