import IconPropsInterface from './IconPropsInterface'

export const WorkType = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 16 16"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M13.8333 3.83203H2.16667C1.24619 3.83203 0.5 4.57822 0.5 5.4987V12.9987C0.5 13.9192 1.24619 14.6654 2.16667 14.6654H13.8333C14.7538 14.6654 15.5 13.9192 15.5 12.9987V5.4987C15.5 4.57822 14.7538 3.83203 13.8333 3.83203Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.66797 3.83333V2.16667C4.66797 1.72464 4.84356 1.30072 5.15612 0.988155C5.46869 0.675595 5.89261 0.5 6.33464 0.5H9.66797C10.11 0.5 10.5339 0.675595 10.8465 0.988155C11.159 1.30072 11.3346 1.72464 11.3346 2.16667V3.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8 8V8.00833" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M0.5 8.83203C2.82632 10.0043 5.39502 10.6149 8 10.6149C10.605 10.6149 13.1737 10.0043 15.5 8.83203"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
