import { useEffect } from 'react'

import {
  StyledTitleLevel1,
  StyledTitleLevel2,
} from '@pages/TasksBoard/ExecutorOffer/StyledTitles'
import { setOfferFieldValue } from '@pages/TasksBoard/ExecutorOffer/executorOfferSlice'
import { usePrepaymentAmount } from '@pages/TasksBoard/ExecutorOffer/hooks'
import {
  NumberInput,
  SelectEmployee,
  TextInput,
} from '@pages/TasksBoard/ExecutorOffer/inputs'
import { DadataCityInput } from '@pages/TasksBoard/ExecutorOffer/inputs/DadataCityInput'
import { DoubleDateInput } from '@pages/TasksBoard/ExecutorOffer/inputs/DoubleDateInput'
import { InfoField } from '@pages/TasksBoard/ExecutorOffer/inputs/InfoField'
import { PointsList } from '@pages/TasksBoard/components/PointsList'
import { ITaskListItemViewData } from '@pages/TasksBoard/interfaces'
import { useAppDispatch, useAppSelector } from '@src/hooks/store'
import {
  dimensionsToCubicMetersMultipliers,
  weightToKgMultipliers,
} from '@src/services/dirs/units'
import { RegularText } from '@ui/Typography'

interface IProps {
  taskId: number
  data: ITaskListItemViewData | undefined
}
export const EditTaskCargo = ({ taskId, data }: IProps) => {
  const dispatch = useAppDispatch()
  const { isFirstValidation, isPreviewMode } = useAppSelector(
    (state) => state.executorOffer
  )

  const length = data?.details?.length?.value
  const lengthUnit = data?.details?.length?.unit?.name
  const lengthUnitSlug = data?.details?.length?.unit?.value
  const width = data?.details?.width?.value
  const widthUnit = data?.details?.width?.unit?.name
  const height = data?.details?.height?.value
  const heightUnit = data?.details?.height?.unit?.name
  const weight = data?.details?.weight?.value
  const weightUnitSlug = data?.details?.weight?.unit?.value
  const points = data?.details?.points
  const taskType = data?.type
  const deferredPayment = data?.project.deferred_payment || ''
  const { handler, showPrepaymentDays } = usePrepaymentAmount(taskId)
  const volumeCubicMeters = +(
    Math.pow(
      dimensionsToCubicMetersMultipliers[
        lengthUnitSlug as keyof typeof dimensionsToCubicMetersMultipliers
      ],
      3
    ) *
    length! *
    height! *
    width!
  ).toFixed(3)

  const weightKilograms = +(
    weightToKgMultipliers[
      weightUnitSlug as keyof typeof weightToKgMultipliers
    ] * weight!
  ).toFixed(3)

  useEffect(() => {
    if (data) {
      dispatch(
        setOfferFieldValue({
          taskId,
          fieldName: 'deferred_payment',
          value: deferredPayment,
        })
      )
      dispatch(
        setOfferFieldValue({
          taskId,
          fieldName: 'volume',
          value: volumeCubicMeters,
        })
      )
      dispatch(
        setOfferFieldValue({
          taskId,
          fieldName: 'weight',
          value: weightKilograms,
        })
      )
    }
  }, [data, dispatch, taskId, volumeCubicMeters, weightKilograms])

  return (
    <div>
      <StyledTitleLevel1>Основная информация</StyledTitleLevel1>
      <InfoField title={'Габариты груза'}>
        <RegularText>{`${length}${lengthUnit} X ${width}${widthUnit} X ${height}${heightUnit}`}</RegularText>
      </InfoField>
      <InfoField title={'Объем груза'}>
        <RegularText>{`${volumeCubicMeters} куб.м`}</RegularText>
      </InfoField>
      {weight && (
        <InfoField title={'Вес груза'}>
          <RegularText>{`${weightKilograms} кг`}</RegularText>
        </InfoField>
      )}
      <NumberInput
        taskId={taskId}
        fieldName={'cost'}
        title={'Стоимость грузоперевозки, руб.'}
        description={''}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
      />
      <DoubleDateInput
        taskId={taskId}
        title={'Дата и время загрузки'}
        isFirstValidation={isFirstValidation}
        isPreviewMode={isPreviewMode}
      />
      {points && taskType && (
        <InfoField title={'Маршрут'}>
          <PointsList points={points} taskType={taskType} />
        </InfoField>
      )}

      <SelectEmployee
        taskId={taskId}
        fieldName={'employee_id'}
        additionalFieldName={'responsible_person'}
        title={'Ответственный сотрудник по задаче'}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
      />
      <NumberInput
        taskId={taskId}
        fieldName={'deferred_payment'}
        title={'Отсрочка оплаты, дней'}
        description={''}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
      />
      <NumberInput
        taskId={taskId}
        additionalHandler={handler}
        fieldName={'prepayment_amount'}
        title={'Сумма предоплаты, руб.'}
        description={''}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
      />
      <StyledTitleLevel1>Данные для договора</StyledTitleLevel1>
      <DadataCityInput
        taskId={taskId}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
      />
      <NumberInput
        taskId={taskId}
        fieldName={'contract_period'}
        title={'Срок действия договора, мес.'}
        description={''}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
      />
      {showPrepaymentDays && (
        <NumberInput
          taskId={taskId}
          fieldName={'prepayment_days'}
          title={'Срок внесения предоплаты, дней'}
          description={
            'Количество дней на внесение предоплаты с момента получения счета.'
          }
          isPreviewMode={isPreviewMode}
          isFirstValidation={isFirstValidation}
        />
      )}
      <NumberInput
        taskId={taskId}
        fieldName={'cost_stop'}
        title={'Дополнительная остановка, руб.'}
        description={''}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
      />
      <NumberInput
        taskId={taskId}
        fieldName={'cost_city'}
        title={'Дополнительный город, руб.'}
        description={''}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
      />
      <TextInput
        taskId={taskId}
        fieldName={'responsible_person'}
        title={'Ответственное лицо по договору'}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
      />

      <StyledTitleLevel2>Неустойки</StyledTitleLevel2>
      <NumberInput
        taskId={taskId}
        fieldName={'idle_cargo_hours'}
        title={'Ожидание предоставления груза, часов'}
        description={''}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
      />
      <NumberInput
        taskId={taskId}
        fieldName={'idle_cargo_cost'}
        title={'Сумма неустойки за ожидание, руб./час'}
        description={''}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
      />
    </div>
  )
}
