import IconPropsInterface from '@icons/IconPropsInterface'

export const TaskRaising = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M57.5757 13.5948L50.7825 6.80171C50.6086 6.62743 50.4019 6.48917 50.1745 6.39484C49.947 6.30051 49.7032 6.25195 49.4569 6.25195C49.2107 6.25195 48.9668 6.30051 48.7394 6.39484C48.5119 6.48917 48.3053 6.62743 48.1313 6.80171L41.3382 13.5948C41.1641 13.7689 41.026 13.9756 40.9318 14.203C40.8376 14.4305 40.7891 14.6743 40.7891 14.9205C40.7891 15.1666 40.8376 15.4104 40.9318 15.6379C41.026 15.8653 41.1641 16.072 41.3382 16.2461C41.5122 16.4202 41.7189 16.5583 41.9464 16.6525C42.1738 16.7467 42.4176 16.7952 42.6638 16.7952C42.91 16.7952 43.1538 16.7467 43.3812 16.6525C43.6087 16.5583 43.8153 16.4202 43.9894 16.2461L47.5819 12.6536V51.8711C47.5819 52.3684 47.7794 52.8453 48.1311 53.1969C48.4827 53.5485 48.9596 53.7461 49.4569 53.7461C49.9542 53.7461 50.4311 53.5485 50.7827 53.1969C51.1344 52.8453 51.3319 52.3684 51.3319 51.8711V12.6536L54.9244 16.2461C55.0981 16.4208 55.3047 16.5595 55.5322 16.6541C55.7597 16.7487 56.0036 16.7974 56.25 16.7974C56.4964 16.7974 56.7404 16.7487 56.9679 16.6541C57.1954 16.5595 57.4019 16.4208 57.5757 16.2461C57.7499 16.0721 57.8882 15.8655 57.9825 15.638C58.0769 15.4105 58.1254 15.1667 58.1254 14.9205C58.1254 14.6742 58.0769 14.4304 57.9825 14.2029C57.8882 13.9754 57.7499 13.7688 57.5757 13.5948Z"
        fill={color}
      />
      <path
        d="M35.3656 11.75H3.875C3.37772 11.75 2.90081 11.5525 2.54917 11.2008C2.19754 10.8492 2 10.3723 2 9.875C2 9.37772 2.19754 8.9008 2.54917 8.54917C2.90081 8.19754 3.37772 8 3.875 8H35.3656C35.8629 8 36.3398 8.19754 36.6915 8.54917C37.0431 8.9008 37.2406 9.37772 37.2406 9.875C37.2406 10.3723 37.0431 10.8492 36.6915 11.2008C36.3398 11.5525 35.8629 11.75 35.3656 11.75Z"
        fill="#34C759"
      />
      <path
        d="M35.5619 25.5H11.875C11.3777 25.5 10.9008 25.3025 10.5492 24.9508C10.1975 24.5992 10 24.1223 10 23.625C10 23.1277 10.1975 22.6508 10.5492 22.2992C10.9008 21.9475 11.3777 21.75 11.875 21.75H35.5619C36.0592 21.75 36.5361 21.9475 36.8877 22.2992C37.2393 22.6508 37.4369 23.1277 37.4369 23.625C37.4369 24.1223 37.2393 24.5992 36.8877 24.9508C36.5361 25.3025 36.0592 25.5 35.5619 25.5Z"
        fill={color}
      />
      <path
        d="M18.875 35.5H34.7581C35.2554 35.5 35.7323 35.6975 36.084 36.0492C36.4356 36.4008 36.6331 36.8777 36.6331 37.375C36.6331 37.8723 36.4356 38.3492 36.084 38.7008C35.7323 39.0525 35.2554 39.25 34.7581 39.25H18.875C18.3777 39.25 17.9008 39.0525 17.5492 38.7008C17.1975 38.3492 17 37.8723 17 37.375C17 36.8777 17.1975 36.4008 17.5492 36.0492C17.9008 35.6975 18.3777 35.5 18.875 35.5Z"
        fill={color}
      />
      <path
        d="M26.875 49.25H34.9544C35.4517 49.25 35.9286 49.4475 36.2802 49.7992C36.6318 50.1508 36.8294 50.6277 36.8294 51.125C36.8294 51.6223 36.6318 52.0992 36.2802 52.4508C35.9286 52.8025 35.4517 53 34.9544 53H26.875C26.3777 53 25.9008 52.8025 25.5492 52.4508C25.1975 52.0992 25 51.6223 25 51.125C25 50.6277 25.1975 50.1508 25.5492 49.7992C25.9008 49.4475 26.3777 49.25 26.875 49.25Z"
        fill={color}
      />
    </svg>
  )
}
