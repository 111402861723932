import IconPropsInterface from './IconPropsInterface'

export const Eye = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 20 20"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M9.9987 11.6673C10.9192 11.6673 11.6654 10.9211 11.6654 10.0007C11.6654 9.08018 10.9192 8.33398 9.9987 8.33398C9.07822 8.33398 8.33203 9.08018 8.33203 10.0007C8.33203 10.9211 9.07822 11.6673 9.9987 11.6673Z"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.833333"
      />
      <path
        d="M18.3346 9.99935C16.1121 13.8885 13.3346 15.8327 10.0013 15.8327C6.66797 15.8327 3.89047 13.8885 1.66797 9.99935C3.89047 6.11018 6.66797 4.16602 10.0013 4.16602C13.3346 4.16602 16.1121 6.11018 18.3346 9.99935Z"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.833333"
      />
    </svg>
  )
}
