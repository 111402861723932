import IconPropsInterface from './IconPropsInterface'

export const Profile = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      stroke={color}
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
      viewBox="0 0 12 14"
      fill="none"
    >
      <path
        d="M5.9987 6.33333C7.47146 6.33333 8.66536 5.13943 8.66536 3.66667C8.66536 2.19391 7.47146 1 5.9987 1C4.52594 1 3.33203 2.19391 3.33203 3.66667C3.33203 5.13943 4.52594 6.33333 5.9987 6.33333Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.25 13V11.6667C1.25 10.9594 1.58362 10.2811 2.17747 9.78105C2.77132 9.28095 3.57675 9 4.41659 9H7.58317C8.423 9 9.22843 9.28095 9.82228 9.78105C10.4161 10.2811 10.7498 10.9594 10.7498 11.6667V13"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
