import { ChangeEventHandler, memo } from 'react'

import {
  setOfferFieldErrorMessage,
  setOfferFieldValue,
} from '@pages/TasksBoard/ExecutorOffer/executorOfferSlice'
import { InputWrapper } from '@pages/TasksBoard/ExecutorOffer/inputs/InputWrapper'
import { useAppDispatch, useAppSelector } from '@src/hooks/store'
import { Box } from '@ui/Box'
import { BaseInput } from '@ui/Inputs/Base/BaseInput'
import { RegularText } from '@ui/Typography'

// eslint-disable-next-line react/display-name
export const MaterialQuantityField = memo(
  ({ taskId, isPreviewMode, isFirstValidation, title, units }: any) => {
    const unitFieldName = 'material_type_unit_id'
    const quantityFieldName = 'quantity'
    const fieldData = useAppSelector(
      (state) => state.executorOffer.selectedTasks[taskId][quantityFieldName]
    )
    const unitFieldData = useAppSelector(
      (state) => state.executorOffer.selectedTasks[taskId][unitFieldName]
    )
    const dispatch = useAppDispatch()
    const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
      const value = e.target.value.replace(/[^0-9.]/g, '')
      if (!isFirstValidation) {
        dispatch(
          setOfferFieldErrorMessage({
            taskId,
            fieldName: quantityFieldName,
            value: fieldData.getErrorMessage(value),
          })
        )
      }

      dispatch(
        setOfferFieldValue({
          taskId,
          fieldName: quantityFieldName,
          value: value,
        })
      )
    }

    return (
      <InputWrapper
        title={title}
        description={''}
        required={true}
        isDisabled={false}
        errorMessage={fieldData.errorMessage}
      >
        <Box flex cg={10}>
          {isPreviewMode ? (
            <RegularText>
              {fieldData.value === '' ? 'Не задано' : fieldData.value}
            </RegularText>
          ) : (
            <BaseInput
              type={'text'}
              style={{ width: '100%' }}
              hasError={!!fieldData.errorMessage}
              border
              value={fieldData.value}
              onChange={handleChange}
              disabled={fieldData.isDisabled}
            />
          )}
          <RegularText>
            {units.find((item: any) => item.value === unitFieldData.value)
              ?.name || ''}
          </RegularText>
        </Box>
      </InputWrapper>
    )
  }
)
