export const FileXla = () => {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3">
        <path
          opacity="0.3"
          d="M13.9671 4.92491C13.4204 4.92438 12.8962 4.70696 12.5096 4.32038C12.123 3.9338 11.9056 3.40964 11.905 2.86294V0H2.69103C1.97733 0 1.29285 0.283518 0.788187 0.788169C0.283524 1.29282 0 1.97726 0 2.69094V18.9126C0 19.6263 0.283524 20.3108 0.788187 20.8154C1.29285 21.3201 1.97733 21.6036 2.69103 21.6036H13.7511C14.4648 21.6036 15.1492 21.3201 15.6539 20.8154C16.1586 20.3108 16.4421 19.6263 16.4421 18.9126V4.92491H13.9671Z"
          fill="#00C650"
        />
      </g>
      <path
        d="M16.4433 4.92491H13.9683C13.4216 4.92438 12.8974 4.70696 12.5108 4.32038C12.1242 3.9338 11.9068 3.40964 11.9062 2.86294V0L16.4433 4.92491Z"
        fill="#00C650"
      />
      <path
        d="M18.3913 17.0586H5.88726C5.00029 17.0586 4.28125 17.7776 4.28125 18.6646V22.3925C4.28125 23.2795 5.00029 23.9985 5.88726 23.9985H18.3913C19.2783 23.9985 19.9973 23.2795 19.9973 22.3925V18.6646C19.9973 17.7776 19.2783 17.0586 18.3913 17.0586Z"
        fill="#00C650"
      />
      <path
        d="M8.26082 21.3008V22.0278H7.50781V21.3008H8.26082Z"
        fill="white"
      />
      <path
        d="M10.6035 22.0265L9.97051 21.0905L9.42552 22.0265H8.5925L9.55349 20.4785L8.5625 19.0195H9.42552L10.0425 19.9255L10.5745 19.0195H11.4035L10.4555 20.5335L11.4675 22.0265H10.6035Z"
        fill="white"
      />
      <path
        d="M12.4916 21.4695H13.4656V22.0265H11.7656V19.0195H12.4936L12.4916 21.4695Z"
        fill="white"
      />
      <path
        d="M15.8456 21.476H14.7186L14.5306 22.025H13.7656L14.8656 19.0391H15.7026L16.8026 22.025H16.0286L15.8456 21.476ZM15.6586 20.923L15.2836 19.817L14.9056 20.923H15.6586Z"
        fill="white"
      />
      <path
        d="M11.8903 15.0832H4.55826C3.92325 15.0832 3.40625 14.4512 3.40625 13.6732V8.3543C3.40625 7.57731 3.92325 6.94531 4.55826 6.94531H11.8933C12.5273 6.94531 13.0443 7.57731 13.0443 8.3543V13.6732C13.0413 14.4512 12.5243 15.0832 11.8903 15.0832ZM4.55826 7.66529C4.24726 7.66529 3.99527 7.9743 3.99527 8.3543V13.6732C3.99527 14.0532 4.24726 14.3622 4.55826 14.3622H11.8933C12.2033 14.3622 12.4553 14.0532 12.4553 13.6732V8.3543C12.4553 7.9743 12.2033 7.66529 11.8933 7.66529H4.55826Z"
        fill="#00C650"
      />
      <path
        d="M12.7443 11.8789H3.69531V12.5999H12.7443V11.8789Z"
        fill="#00C650"
      />
      <path
        d="M12.7443 9.40625H3.69531V10.1273H12.7443V9.40625Z"
        fill="#00C650"
      />
      <path
        d="M10.4241 7.30469H9.70312V14.7216H10.4241V7.30469Z"
        fill="#00C650"
      />
      <path
        d="M6.73662 7.30469H6.01562V14.7216H6.73662V7.30469Z"
        fill="#00C650"
      />
    </svg>
  )
}
