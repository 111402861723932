import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { IOffer } from './types/interfaces'

export const LIMIT_OFFERS = 20

interface initialStateType {
  items: IOffer[]
  page: number
  lastItemsCount: number
  ownershipId: number
  paymentMethod: string
  defferedPayment: number
  bank_account_id: number
  contract_template: 'has_contract' | 'not_contract' | ''
  isSendData: boolean
  ownershipName: string
  ascentOfferId: null | number
  // для отображения обновленных данных в модалке просмотра
  foundOwnership: Record<string, any> | null
  foundBankAccount: Record<string, any> | null
}

const initialState: initialStateType = {
  items: [],
  page: 1,
  lastItemsCount: LIMIT_OFFERS,
  ownershipId: -1,
  paymentMethod: 'cash',
  defferedPayment: 0,
  bank_account_id: -1,
  contract_template: '',
  isSendData: false,
  ownershipName: '',
  ascentOfferId: null,
  foundOwnership: null,
  foundBankAccount: null,
}

const offerSlice = createSlice({
  name: 'offer',
  initialState: initialState,
  reducers: {
    offerIncrementPage: (state) => {
      state.page += 1
    },
    offerSetLastItemsCount: (state, action) => {
      state.lastItemsCount = action.payload
    },
    offerAddPageData: (state, action) => {
      state.items = [...state.items, ...action.payload]
    },
    offerRemove: (state) => {
      state.items = []
      state.page = 1
      state.lastItemsCount = LIMIT_OFFERS
    },
    setOwnershipId: (state, action) => {
      state.ownershipId = action.payload
    },
    setPaymentMethod: (state, action) => {
      state.paymentMethod = action.payload
    },
    setDefferedPayment: (state, action) => {
      state.defferedPayment = action.payload
    },
    setBankAccount: (state, action) => {
      state.bank_account_id = action.payload
    },
    setContract: (state, action) => {
      state.contract_template = action.payload
    },
    setIsSendData: (state, action) => {
      state.isSendData = action.payload
    },
    setOwnershipName: (state, action) => {
      state.ownershipName = action.payload
    },
    ascentOfferIdChanged: (
      state,
      { payload }: PayloadAction<null | number>
    ) => {
      state.ascentOfferId = payload
    },
    setFoundOwnership: (state, action) => {
      state.foundOwnership = action.payload
    },
    setFoundBankAccount: (state, action) => {
      state.foundBankAccount = action.payload
    },
  },
})

export const {
  offerIncrementPage,
  offerSetLastItemsCount,
  offerAddPageData,
  offerRemove,
  setOwnershipId,
  setPaymentMethod,
  setDefferedPayment,
  setBankAccount,
  setContract,
  setIsSendData,
  setOwnershipName,
  ascentOfferIdChanged,
  setFoundOwnership,
  setFoundBankAccount,
} = offerSlice.actions

export const offerReducer = offerSlice.reducer
