import { createSlice } from '@reduxjs/toolkit'

import { IBreadcrumbItem } from './interfaces'

const initialState: {
  items: IBreadcrumbItem[]
} = {
  items: [],
}

const breadcrumbsSlice = createSlice({
  name: 'breadcrumbs',
  initialState,
  reducers: {
    setBreadcrumbs: (state, action) => {
      state.items = action.payload
    },
  },
})

export const { setBreadcrumbs } = breadcrumbsSlice.actions

export default breadcrumbsSlice.reducer
