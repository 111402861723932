import {
  AddGalleryItemRequest,
  DeleteAuthorAnswerRequest,
  DeleteGalleryItemRequest,
  GetReviewDealsRequest,
  GetReviewDealsResponse,
  PostAuthorsReviewRequest,
  UpdateProfileDescriptionRequest,
} from '@pages/Profile/types/ProfileTypes'
import { IPermissionsResponse } from '@src/shared/interfaces/IPermissionsData'
import { ISuccessResponse } from '@src/shared/interfaces/apiResponse/ISuccessResponse'
import { IProfileInfo } from '@src/shared/interfaces/profile/Profile'
import { clientApiSlice } from '@src/store/slices/clientApiSlice'
import { formatUrlGetParamsFromObject } from '@src/utils/helpers/formatUrlGetParamsFromObject'

import {
  IGalleryData,
  IGalleryResponse,
  IVehicleCard,
  IVehicleData,
  IVehiclesItem,
} from '../interfaces'

export const profileApiSlice = clientApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentSpacePermissions: builder.query<IPermissionsResponse, unknown>({
      query: (spaceId) => ({
        url: `/spaces/${spaceId}/permissions`,
        method: 'GET',
      }),
    }),
    getSpaceById: builder.query<
      ISuccessResponse<IProfileInfo>,
      string | undefined
    >({
      query: (id) => ({
        url: `/spaces/${id}/view`,
      }),
    }),
    postPublicOwnershipTypes: builder.query<
      ISuccessResponse<{ name: string; id: number }[]>,
      string | undefined | number
    >({
      query: (id) => ({
        url: `/spaces/${id}/public/ownership-types`,
      }),
    }),
    updateProfileDescription: builder.mutation<
      unknown,
      UpdateProfileDescriptionRequest
    >({
      query: ({ id, type, text }) => ({
        url: `/spaces/${id}/profile/update`,
        method: 'POST',
        body: {
          type: type,
          description: text,
        },
      }),
      //@ts-ignore
      invalidatesTags: [{ type: 'Profile', id: 'LIST' }],
    }),
    getReviewDeals: builder.query<
      ISuccessResponse<GetReviewDealsResponse>,
      GetReviewDealsRequest
    >({
      query: ({ id, limit, page, type }) => ({
        url: `/spaces/${id}/reviews${formatUrlGetParamsFromObject({
          limit,
          page,
          type,
        })}`,
      }),
    }),
    postAuthorsReviewProfile: builder.mutation<
      unknown,
      PostAuthorsReviewRequest
    >({
      query: ({ dealId, dealReviewId, body }) => ({
        url: `/deals/${dealId}/reviews/${dealReviewId}/feedback`,
        method: 'POST',
        body: body,
      }),
      //@ts-ignore
      invalidatesTags: [{ type: 'Profile', id: 'LIST' }],
    }),
    deleteAuthorAnswer: builder.mutation<DeleteAuthorAnswerRequest, unknown>({
      query: ({ dealId, dealReviewDelId, itemId }) => ({
        url: `/deals/${dealId}/reviews/${dealReviewDelId}/feedback`,
        method: 'DELETE',
        body: {
          ids: [itemId],
        },
      }),
      //@ts-ignore
      invalidatesTags: [{ type: 'Profile', id: 'LIST' }],
    }),
    getGallery: builder.query<IGalleryResponse, IGalleryData>({
      query: ({ spaceId }) => ({
        url: `/spaces/${spaceId}/profile/executor/gallery`,
        method: 'GET',
      }),
    }),
    deleteGalleryItem: builder.mutation<unknown, DeleteGalleryItemRequest>({
      query: ({ spaceId, itemId }) => ({
        url: `/spaces/${spaceId}/profile/executor/gallery`,
        method: 'DELETE',
        body: {
          ids: [itemId],
        },
      }),
    }),
    addGalleryItem: builder.mutation<unknown, AddGalleryItemRequest>({
      query: ({ spaceId, name, description, bind_key }) => ({
        url: `/spaces/${spaceId}/profile/executor/gallery/create`,
        method: 'POST',
        body: {
          items: [
            {
              name,
              description,
              bind_key,
            },
          ],
        },
      }),
    }),
    getVehiclesItems: builder.query<IVehicleCard[], IVehicleData>({
      query: ({
        spaceId,
        name,
        page,
        limit,
        vehicle_type_ids,
        task_type_ids,
      }) => ({
        url: `/vehicles?space_id=${spaceId}${
          name ? `&name=${name}` : ''
        }&page=${page}&limit=${limit}&${
          vehicle_type_ids.length
            ? `vehicle_type_ids[]=${vehicle_type_ids}`
            : ''
        }&${task_type_ids.map((item) => `task_type_ids[]=${item}&`).join('')}`,
        method: 'GET',
      }),
      transformResponse: (response: any) => {
        return response.data.items.map((item: IVehiclesItem) => {
          return {
            imageSrc: item.image_gallery.find((image) => image.is_primary)
              ?.media?.public_url,
            taskTypes: item.task_types.map((taskType) => taskType.slug),
            name: item.name,
            link: '',
            id: item.id,
            characteristics: item.characteristics,
          }
        })
      },
    }),
  }),
})

export const {
  useGetCurrentSpacePermissionsQuery,
  useGetSpaceByIdQuery,
  usePostPublicOwnershipTypesQuery,
  useUpdateProfileDescriptionMutation,
  useGetReviewDealsQuery,
  useLazyGetReviewDealsQuery,
  usePostAuthorsReviewProfileMutation,
  useDeleteAuthorAnswerMutation,
  useGetGalleryQuery,
  useDeleteGalleryItemMutation,
  useAddGalleryItemMutation,
  useLazyGetVehiclesItemsQuery,
} = profileApiSlice
