import { IWorkType } from '@pages/MyApplications/types/tasks/details/IRentDetails'

export const getWorkTypeName = (workType: IWorkType) => {
  let name = workType.name
  let innerWorkType = workType.children

  while (innerWorkType && innerWorkType.length > 0) {
    name += `/${innerWorkType[0]?.name}`

    innerWorkType = innerWorkType[0]?.children
  }

  return name
}

export const getWorkTypeValue = (workType?: IWorkType) => {
  if (!workType) return null

  let innerWorkType = workType

  while (innerWorkType?.children && innerWorkType.children.length > 0) {
    innerWorkType = innerWorkType.children[0]
  }

  return { value: innerWorkType.id, label: innerWorkType.name }
}
