import IconPropsInterface from './IconPropsInterface'

export const Notifications = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.68433 9.64713C3.56475 12.0188 0.993417 12.9364 0.993164 15.0998C0.993032 16.2269 1.69938 17.2553 2.91247 17.2553H17.0599C18.5301 17.2553 19.0052 16.2337 19.0052 15.0998C19.0052 12.9581 16.2099 12.0401 16.2099 9.64713C16.2099 8.37303 16.2099 7.62176 16.2099 6.40095C16.2099 3.2266 13.1627 1.1745 9.98833 1.16993C6.80854 1.16535 3.91803 3.22567 3.74857 6.40095C3.68433 7.60469 3.74857 8.37303 3.68433 9.64713Z"
        stroke={color}
        strokeWidth="1.4"
      />
      <path
        d="M13.4988 17.2553H6.49883C6.49883 17.2553 6.47784 20.8312 9.98833 20.8312C13.4988 20.8312 13.4988 17.2553 13.4988 17.2553Z"
        stroke={color}
        strokeWidth="1.4"
      />
    </svg>
  )
}
