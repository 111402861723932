import IconPropsInterface from './IconPropsInterface'

export const NotificationLight = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="#6B6B6B"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path d="M10.0044 2.5C13.1386 2.5 15.5368 5.12666 15.5368 8.4506L15.5335 9.75567C15.5338 10.0306 15.6924 10.3141 16.1033 10.7978C16.1521 10.8553 16.1016 10.7967 16.2976 11.0235C17.0345 11.876 17.3225 12.3613 17.3225 13.0987C17.3225 13.2327 17.3184 13.3239 17.3003 13.4477C17.2731 13.6336 17.2197 13.814 17.1316 13.9873C16.8032 14.6334 16.0941 15 15.0011 15H13.0075L12.9902 15.0651C12.5443 16.6499 11.5187 17.5 10.0011 17.5C8.46297 17.5 7.43033 16.6268 6.99428 15.0004L5.00112 15C3.87606 15 3.16058 14.6241 2.84699 13.9571C2.76912 13.7914 2.72244 13.62 2.69888 13.4422C2.68313 13.3234 2.67969 13.2362 2.67969 13.0987C2.67969 12.3616 2.96722 11.8763 3.70263 11.0239C3.89831 10.7971 3.84787 10.8556 3.89665 10.7981C4.30715 10.3141 4.4654 10.0304 4.4654 9.75509V8.44925C4.4654 5.1279 6.8692 2.5 10.0044 2.5ZM11.889 15.0003H8.11325C8.44992 15.9833 9.05581 16.4286 10.0011 16.4286C10.9464 16.4286 11.5523 15.9833 11.889 15.0003ZM10.0044 3.57143C7.4833 3.57143 5.53683 5.69937 5.53683 8.44925V9.75509C5.53683 10.3624 5.27888 10.8248 4.71379 11.4911C4.68338 11.527 4.65159 11.5641 4.6132 11.6087C4.56324 11.6666 4.56324 11.6666 4.51388 11.7238C3.94349 12.3849 3.75112 12.7096 3.75112 13.0987C3.75112 13.2846 3.76538 13.3923 3.8166 13.5012C3.93727 13.7579 4.26218 13.9286 5.00112 13.9286H15.0011C15.717 13.9286 16.0461 13.7584 16.1766 13.5018C16.2347 13.3874 16.2511 13.2752 16.2511 13.0987C16.2511 12.7098 16.0584 12.3852 15.487 11.7241C15.4376 11.667 15.4376 11.667 15.3875 11.6091C15.3491 11.5645 15.3172 11.5273 15.2867 11.4915C14.7206 10.8251 14.4621 10.3627 14.4621 9.75509L14.4654 8.44925C14.4654 5.69773 12.524 3.57143 10.0044 3.57143Z" />
    </svg>
  )
}
