import { forwardRef } from 'react'
import styled from 'styled-components'

import { getBgColor, getColor } from '@theme/helpers'
import { shadows } from '@theme/index'

import {
  IBaseInputProps,
  IconLayoutVariant,
  VertPaddingVariant,
} from './interfaces'
import { iconLayoutVariants, vertPaddingVariants } from './variants'

const StyledWrapper = styled.div`
  position: relative;
  border-radius: 7px;
`

export const StyledInput = styled.input<{
  vertPaddingVariant: VertPaddingVariant
  iconLayoutVariant: IconLayoutVariant
  hasError: boolean
  width?: number
  border?: boolean
  shadow?: boolean
}>`
  display: block;
  box-sizing: border-box;
  box-shadow: ${({ shadow }) => (shadow ? shadows.light : '')};
  width: 100%;
  height: 100%;
  outline: none;
  border-radius: 7px;
  ${getColor('text20')}
  ${getBgColor('light')}
  font-size: 14px;
  line-height: 24px;
  transition: 300ms;
  ${({ vertPaddingVariant }) => vertPaddingVariants[vertPaddingVariant]}
  ${({ iconLayoutVariant }) => iconLayoutVariants[iconLayoutVariant]}
  ${({ width }) => (width ? `max-width: ${width}px;` : '')}
  font-family: inherit;

  border: 1px solid
    ${({ theme, hasError, border }) =>
      border ? theme.colors[hasError ? 'accent' : 'gray77'] : 'transparent'};

  &:hover {
    border: 1px solid
      ${({ theme, hasError, border }) =>
        border ? theme.colors[hasError ? 'accent' : 'gray69'] : 'transparent'};
  }

  &:focus {
    border: 1px solid
      ${({ theme, hasError, border }) =>
        border ? theme.colors[hasError ? 'accent' : 'primary'] : 'transparent'};
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:read-only {
    border: 1px solid
      ${({ theme, hasError, border }) =>
        border ? theme.colors[hasError ? 'accent' : 'gray77'] : 'transparent'};
  }

  &::placeholder {
    color: ${(props) => props.theme.colors.gray50};
    font-weight: ${(props) => props.theme.fontWeights.semibold};
    font: ${(props) => props.theme.fonts.primary};
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;
`

const StyledContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledLeftContainer = styled(StyledContainer)`
  left: 15px;
`
const StyledRightContainer = styled(StyledContainer)`
  right: 15px;
`
export const BaseInput = forwardRef<HTMLInputElement, IBaseInputProps>(
  (
    {
      onChange,
      onBlur,
      value,
      type,
      hasError = false,
      width,
      placeholder = '',
      disabled = false,
      leftButton = null,
      rightButton = null,
      vertPaddingVariant = 'regular',
      iconLayoutVariant,
      autoFocus,
      autocomplete,
      tabIndex,
      style,
      name,
      onKeyDown,
      id,
      border,
      shadow,
      className,
      readonly,
      height,
      onClick,
      maxLength = 255,
      min,
      max,
      onPaste,
      onFocus,
    }: IBaseInputProps,
    ref
  ) => {
    const getIconLayoutVariant = () => {
      if (!!leftButton && !!rightButton) {
        return 'both'
      }
      if (rightButton) {
        return 'right'
      }
      if (leftButton) {
        return 'left'
      }
      return 'none'
    }

    return (
      <StyledWrapper style={style} className={className}>
        <StyledInput
          id={id}
          vertPaddingVariant={vertPaddingVariant}
          iconLayoutVariant={iconLayoutVariant ?? getIconLayoutVariant()}
          type={type}
          name={name}
          placeholder={placeholder}
          disabled={disabled}
          onChange={onChange}
          onWheel={(e) => (e.target as HTMLElement).blur()}
          hasError={hasError}
          width={width}
          ref={ref}
          autoFocus={autoFocus}
          tabIndex={tabIndex}
          value={value}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          border={border}
          shadow={shadow}
          readOnly={readonly}
          height={height}
          onClick={onClick}
          maxLength={maxLength}
          autoComplete={autocomplete}
          min={min}
          max={max}
          onPaste={onPaste}
          onFocus={onFocus}
        />
        {!!leftButton && (
          <StyledLeftContainer>{leftButton}</StyledLeftContainer>
        )}
        {!!rightButton && (
          <StyledRightContainer>{rightButton}</StyledRightContainer>
        )}
      </StyledWrapper>
    )
  }
)

BaseInput.displayName = 'BaseInput'
