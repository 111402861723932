import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from '@src/store'

interface IState {
  deletedItemId: number | null
  currentItemCoords: [number, number] | null
  expandedItemId: number | null
  isPaymentPopupOpened: boolean
  activatedAnswerId: number | null
}

const autoSearchInitialState: IState = {
  deletedItemId: null,
  currentItemCoords: null,
  expandedItemId: null,

  isPaymentPopupOpened: false,
  activatedAnswerId: null,
}

const autoSearchSlice = createSlice({
  name: 'autoSearch',
  initialState: autoSearchInitialState,
  reducers: {
    deletedItemIdChanged: (
      state,
      { payload }: PayloadAction<number | null>
    ) => {
      state.deletedItemId = payload
    },
    currentItemCoordsChanged: (
      state,
      { payload }: PayloadAction<[number, number] | null>
    ) => {
      state.currentItemCoords = payload
    },
    expandedItemIdChanged: (
      state,
      { payload }: PayloadAction<number | null>
    ) => {
      state.expandedItemId = payload
    },
    isPaymentPopupOpenedChanged: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isPaymentPopupOpened = payload
    },
    activatedAnswerIdChanged: (
      state,
      { payload }: PayloadAction<number | null>
    ) => {
      state.activatedAnswerId = payload
    },
  },
})

export const {
  deletedItemIdChanged,
  currentItemCoordsChanged,
  expandedItemIdChanged,
  isPaymentPopupOpenedChanged,
  activatedAnswerIdChanged,
} = autoSearchSlice.actions

export const selectIsAutoSearchPaymentPopupOpened = (state: RootState) =>
  state.autoSearch.isPaymentPopupOpened

export default autoSearchSlice.reducer
