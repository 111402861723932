import IconPropsInterface from './IconPropsInterface'

export const Property = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 20 20"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M15 4.16797H5C3.61929 4.16797 2.5 5.28726 2.5 6.66797V13.3346C2.5 14.7153 3.61929 15.8346 5 15.8346H15C16.3807 15.8346 17.5 14.7153 17.5 13.3346V6.66797C17.5 5.28726 16.3807 4.16797 15 4.16797Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 8.33203H17.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83203 12.5H5.84036"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.16797 12.5H10.8346"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
