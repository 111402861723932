import IconPropsInterface from './IconPropsInterface'

export const Pause = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 6 8"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path d="M1 1V7M5 1V7" strokeLinecap="round" />
    </svg>
  )
}
