import { useEffect } from 'react'
import styled from 'styled-components'

import { Logo } from '@icons/Logo'
import { useAppDispatch } from '@src/hooks/store'
import {
  closeNotification,
  shiftNotificationAsync,
} from '@src/shared/MutationResultNotifications/notificationsSlice'
import { NotificationContentType } from '@src/shared/MutationResultNotifications/types'
import { media } from '@theme/helpers'
import { useGetTheme } from '@theme/hooks'
import { CloseButton } from '@ui/Buttons/CloseButton'
import { Paper } from '@ui/Paper'
import { Signature } from '@ui/Typography'

const Wrapper = styled(Paper)`
  ${media(
    'xlarge',
    () => `
    min-width: 480px;
    max-width: 480px;
  `
  )}
  position: relative;
  min-width: 343px;
  max-width: 343px;
  margin-bottom: 14px;
  padding: 14px 16px;
  overflow: hidden;
`
const Text = styled.p`
  color: ${(props) => props.theme.colors.text20};
  font-weight: ${(props) => props.theme.fontWeights.thin};
  margin-top: 5px;
`
const ImageBox = styled.div`
  position: absolute;
  right: -15px;
  bottom: -15px;
  z-index: 2;
`

const TitleText = styled.p`
  font-size: ${(props) => props.theme.fontSizes.s};
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  margin-top: 5px;

  ${media(
    'xlarge',
    () => `
     font-size: ${(props: Record<string, any>) => props.theme.fontSizes.l};
  `
  )}
`

export const NotificationItem = ({
  type,
  text,
  descriptionText,
  index,
}: NotificationContentType) => {
  const theme = useGetTheme()
  const dispatch = useAppDispatch()

  const noticeText = {
    error: (
      <TitleText
        style={{
          color: `${theme.colors.error}`,
        }}
      >
        {text}
      </TitleText>
    ),
    success: (
      <TitleText
        style={{
          color: `${theme.colors.success}`,
        }}
      >
        {text}
      </TitleText>
    ),
  }

  useEffect(() => {
    dispatch(shiftNotificationAsync())
  }, [])

  return (
    <Wrapper>
      <CloseButton
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          zIndex: 1002,
        }}
        onClick={() => dispatch(closeNotification(index))}
        type={'small'}
      />
      <Signature>Уведомление от Veresking</Signature>
      {noticeText[type]}
      {descriptionText && <Text>{descriptionText}</Text>}
      <ImageBox>
        <Logo color={theme.colors.gray21} />
      </ImageBox>
    </Wrapper>
  )
}
