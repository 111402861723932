import IconPropsInterface from './IconPropsInterface'

export const CheckMessage = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 18 10"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M17.3321 0.832031L8.99876 9.16536L8.18736 8.34277"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.833333"
      />
      <path
        d="M0.667969 4.9987L4.83464 9.16536L9.0013 4.9987L13.168 0.832031"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.833333"
      />
    </svg>
  )
}
