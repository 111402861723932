import IconPropsInterface from '@icons/IconPropsInterface'

export const Registration = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5355 12.4645C22.4882 14.4171 22.4882 17.5829 20.5355 19.5355C18.5829 21.4882 15.4171 21.4882 13.4645 19.5355C11.5118 17.5829 11.5118 14.4171 13.4645 12.4645C15.4171 10.5118 18.5829 10.5118 20.5355 12.4645"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.86621 5.495C9.86321 5.495 9.86121 5.497 9.86121 5.5C9.86121 5.503 9.86321 5.505 9.86621 5.505C9.86921 5.505 9.87121 5.503 9.87121 5.5C9.87121 5.497 9.86921 5.495 9.86621 5.495"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.32715 5.495C7.32415 5.495 7.32215 5.497 7.32215 5.5C7.32215 5.503 7.32415 5.505 7.32715 5.505C7.33015 5.505 7.33215 5.503 7.33215 5.5C7.33215 5.497 7.33015 5.495 7.32715 5.495"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.7793 5.49012C4.7763 5.49012 4.7743 5.49212 4.7743 5.49512C4.7743 5.49812 4.7763 5.50012 4.7793 5.50012C4.7823 5.50012 4.7843 5.49812 4.7843 5.49512C4.7843 5.49212 4.7823 5.49012 4.7793 5.49012"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 8H19"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 11.417V5C19 3.895 18.105 3 17 3H4C2.895 3 2 3.895 2 5V16C2 17.105 2.895 18 4 18H12.418"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 14V18"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 16H15"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
