import IconPropsInterface from '@icons/IconPropsInterface'

export const Change = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 10V7.66667C4 7.04783 4.23705 6.45434 4.65901 6.01675C5.08097 5.57917 5.65326 5.33333 6.25 5.33333H16M16 5.33333L13.75 3M16 5.33333L13.75 7.66667"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 10V12.3333C16 12.9522 15.7629 13.5457 15.341 13.9832C14.919 14.4208 14.3467 14.6667 13.75 14.6667H4M4 14.6667L6.25 17M4 14.6667L6.25 12.3333"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
