import IconPropsInterface from './IconPropsInterface'

export const Logo = ({ size = 100, color }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 309 335"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.05">
        <path
          d="M263.703 289.998C270.746 282.983 277.113 275.294 282.685 267.029C264.089 260.206 247.4 250.668 233.676 236.421C217.903 220.059 207.243 199.925 202.154 178.374L182.618 212.731C189.805 228.852 199.79 243.82 212.356 256.867C219.278 264.05 226.851 270.513 235.027 276.159C188.696 315.177 120.344 315.177 74.0132 276.159C82.1652 270.513 89.7624 264.026 96.6844 256.867C108.84 244.253 118.584 229.837 125.699 214.293L106.404 180.32C101.146 201.151 90.6548 220.563 75.3638 236.445C61.6405 250.692 44.9748 260.206 26.3555 267.053C31.9268 275.318 38.294 283.007 45.3366 290.022C105.536 349.991 203.48 349.991 263.679 290.022"
          fill={color}
        />
        <path
          d="M60.6052 99.4698C41.4311 121.285 29.8061 149.852 29.8061 181.11C29.8061 200.138 34.0992 218.158 41.7929 234.279C39.6222 235.624 37.4034 236.898 35.1604 238.123C28.6967 241.607 21.9436 244.514 14.9492 246.844C5.44665 226.927 0.140625 204.631 0.140625 181.11C0.140625 138.728 17.3852 100.335 45.29 72.5608L60.6052 99.4698Z"
          fill={color}
        />
        <path
          d="M62.7734 57.9264C70.8531 51.9679 79.3909 46.8985 88.2423 42.718L153.892 154.895L217.926 44.8803C227.477 28.4706 251.378 12.109 277.667 0L153.699 218.035L62.7734 57.9264Z"
          fill={color}
        />
        <path
          d="M183.033 60.7221C163.835 56.2773 143.792 56.3734 124.642 61.0584L122.641 57.5506C118.251 49.8624 113.862 42.1981 109.496 34.5579C138.342 25.8605 169.237 25.6923 198.155 34.1254L183.033 60.7221Z"
          fill={color}
        />
        <path
          d="M263.709 72.877C291.59 100.651 308.858 139.044 308.858 181.426C308.858 204.947 303.552 227.243 294.05 247.161C287.055 244.83 280.302 241.899 273.839 238.439C271.596 237.238 269.377 235.941 267.206 234.595C274.9 218.474 279.193 200.454 279.193 181.426C279.193 149.64 267.182 120.664 247.453 98.7048L262.72 71.8679C263.058 72.2043 263.395 72.5166 263.733 72.853"
          fill={color}
        />
      </g>
    </svg>
  )
}
