import IconPropsInterface from './IconPropsInterface'

export const BarSupport = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 14 14"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M13.0013 8.33333L11.0013 6.33333H6.33464C6.15782 6.33333 5.98826 6.2631 5.86323 6.13807C5.73821 6.01305 5.66797 5.84348 5.66797 5.66667V1.66667C5.66797 1.48986 5.73821 1.32029 5.86323 1.19526C5.98826 1.07024 6.15782 1 6.33464 1H12.3346C12.5114 1 12.681 1.07024 12.806 1.19526C12.9311 1.32029 13.0013 1.48986 13.0013 1.66667V8.33333Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33333 9.0013V10.3346C8.33333 10.5114 8.2631 10.681 8.13807 10.806C8.01305 10.9311 7.84348 11.0013 7.66667 11.0013H3L1 13.0013V6.33464C1 6.15782 1.07024 5.98826 1.19526 5.86323C1.32029 5.73821 1.48986 5.66797 1.66667 5.66797H3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
