import { createSlice } from '@reduxjs/toolkit'

const initialState: {
  isOnline: boolean
} = {
  isOnline: true,
}

const checkOnlineSlice = createSlice({
  name: 'checkOnline',
  initialState,
  reducers: {
    setCheckIsOnline: (state, action) => {
      state.isOnline = action.payload
    },
  },
})

export const { setCheckIsOnline } = checkOnlineSlice.actions

export const checkOnlineReducer = checkOnlineSlice.reducer
