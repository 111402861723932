import IconPropsInterface from '@icons/IconPropsInterface'

export const DocumentHorizontalMark = ({
  size = 20,
  color,
  style,
}: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style, flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M18.7076 15.8333L9.70506 15.8333C10.1303 15.0977 10.3743 14.243 10.3743 13.3333C10.3743 10.5719 8.13577 8.33333 5.37435 8.33333C4.09376 8.33333 2.92561 8.81475 2.04102 9.60649V3.33333C2.04102 2.8731 2.41411 2.5 2.87435 2.5H12.1952C13.6743 2.5 14.83 3.77704 14.6828 5.24876L14.5613 6.46397C14.5508 6.56876 14.6389 6.65687 14.7437 6.6464L15.9589 6.52488C17.4306 6.3777 18.7077 7.53341 18.7077 9.01247L18.7076 15.8333Z"
        fill={color}
      />
      <path
        d="M2.04102 13.3333C2.04102 14.1787 2.35571 14.9506 2.87435 15.5382V19.1667L5.37435 17.5L7.87435 19.1667V15.5382C8.39299 14.9506 8.70768 14.1787 8.70768 13.3333C8.70768 11.4924 7.2153 10 5.37435 10C3.5334 10 2.04102 11.4924 2.04102 13.3333Z"
        fill={color}
      />
    </svg>
  )
}
