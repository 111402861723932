export const FileCsv = () => {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3">
        <path
          opacity="0.3"
          d="M13.967 4.92476C13.4203 4.92423 12.8962 4.70681 12.5096 4.32025C12.123 3.93368 11.9056 3.40954 11.905 2.86285V0H2.69101C1.97731 0 1.29284 0.283509 0.788179 0.788145C0.283516 1.29278 0 1.9772 0 2.69086V18.9121C0.00105984 19.625 0.285044 20.3084 0.789592 20.8122C1.29414 21.316 1.978 21.5989 2.69101 21.5989H13.751C14.1045 21.5991 14.4546 21.5296 14.7812 21.3944C15.1078 21.2592 15.4045 21.0611 15.6545 20.8112C15.9045 20.5613 16.1028 20.2646 16.2381 19.9381C16.3734 19.6115 16.4431 19.2615 16.4431 18.9081V4.92076L13.967 4.92476Z"
          fill="#00C650"
        />
      </g>
      <path
        d="M16.4443 4.92489H13.9683C13.4215 4.92436 12.8974 4.70694 12.5108 4.32036C12.1242 3.93379 11.9068 3.40963 11.9062 2.86293V0L16.4443 4.92489Z"
        fill="#00C650"
      />
      <path
        d="M18.3913 17.0586H5.88726C5.00028 17.0586 4.28125 17.7776 4.28125 18.6646V22.3925C4.28125 23.2794 5.00028 23.9984 5.88726 23.9984H18.3913C19.2783 23.9984 19.9973 23.2794 19.9973 22.3925V18.6646C19.9973 17.7776 19.2783 17.0586 18.3913 17.0586Z"
        fill="#00C650"
      />
      <path
        d="M8.04588 21.3125V22.0375H7.29688V21.3125H8.04588Z"
        fill="white"
      />
      <path
        d="M10.7372 19.3136C10.9903 19.5185 11.1622 19.8067 11.2222 20.1266H10.4562C10.4051 19.9939 10.3148 19.8798 10.1972 19.7996C10.0736 19.7185 9.9281 19.677 9.78024 19.6807C9.68275 19.6777 9.58589 19.6972 9.49712 19.7376C9.40836 19.778 9.33005 19.8382 9.26824 19.9136C9.13082 20.0913 9.06164 20.3123 9.07324 20.5366C9.06156 20.76 9.13078 20.9801 9.26824 21.1566C9.3303 21.2316 9.4087 21.2915 9.49745 21.3315C9.58619 21.3715 9.68293 21.3907 9.78024 21.3876C9.9281 21.3912 10.0736 21.3497 10.1972 21.2686C10.3148 21.1901 10.4057 21.0778 10.4582 20.9466H11.2242C11.163 21.2656 10.9912 21.5527 10.7392 21.7576C10.4689 21.9655 10.134 22.0716 9.79324 22.0576C9.52486 22.0635 9.25987 21.9968 9.02624 21.8646C8.80734 21.738 8.6301 21.5504 8.51623 21.3246C8.39298 21.0795 8.33121 20.808 8.33624 20.5336C8.33108 20.259 8.39286 19.9871 8.51623 19.7416C8.6301 19.5159 8.80734 19.3282 9.02624 19.2017C9.25987 19.0695 9.52486 19.0028 9.79324 19.0087C10.1339 18.997 10.4679 19.1048 10.7372 19.3136Z"
        fill="white"
      />
      <path
        d="M13.6599 21.6277C13.5737 21.7652 13.4502 21.8755 13.3039 21.9457C13.1257 22.029 12.9305 22.0695 12.7339 22.0636C12.4378 22.0756 12.146 21.9895 11.9039 21.8187C11.7969 21.7383 11.7094 21.6349 11.6478 21.5162C11.5862 21.3974 11.552 21.2664 11.5479 21.1327H12.3189C12.3242 21.2334 12.3674 21.3284 12.4399 21.3987C12.5115 21.4657 12.6068 21.5017 12.7049 21.4987C12.7853 21.5028 12.8641 21.4755 12.9249 21.4227C12.9512 21.3966 12.9719 21.3654 12.9857 21.331C12.9994 21.2966 13.006 21.2597 13.0049 21.2227C13.0058 21.1877 12.9997 21.1528 12.987 21.1202C12.9743 21.0876 12.9552 21.0578 12.9309 21.0327C12.8783 20.9793 12.8158 20.9369 12.7469 20.9077C12.6729 20.8747 12.5719 20.8357 12.4419 20.7907C12.2832 20.7385 12.1282 20.6757 11.9779 20.6027C11.8544 20.5388 11.7483 20.4458 11.6689 20.3317C11.5775 20.1933 11.5323 20.0294 11.5399 19.8637C11.5358 19.6994 11.583 19.538 11.6749 19.4017C11.7694 19.2686 11.8993 19.1647 12.0499 19.1017C12.2241 19.0312 12.411 18.9972 12.5989 19.0017C12.883 18.9867 13.1635 19.0704 13.3929 19.2387C13.4908 19.3181 13.5709 19.4171 13.6281 19.5294C13.6853 19.6418 13.7183 19.7648 13.7249 19.8907H12.9419C12.9322 19.8027 12.8943 19.7203 12.8339 19.6557C12.8026 19.6261 12.7657 19.6031 12.7253 19.5882C12.6849 19.5732 12.6419 19.5666 12.5989 19.5687C12.5241 19.5649 12.4507 19.5891 12.3929 19.6367C12.3658 19.6621 12.3448 19.6933 12.3313 19.7279C12.3178 19.7625 12.3122 19.7997 12.3149 19.8367C12.314 19.8698 12.3198 19.9028 12.3318 19.9337C12.3438 19.9646 12.3619 19.9928 12.3849 20.0167C12.4344 20.0689 12.4944 20.1101 12.5609 20.1377C12.6309 20.1687 12.7329 20.2087 12.8609 20.2567C13.0225 20.3087 13.18 20.3729 13.3319 20.4487C13.4565 20.5157 13.5637 20.6109 13.6449 20.7267C13.7376 20.8668 13.7835 21.0328 13.7759 21.2007C13.7766 21.3509 13.7365 21.4985 13.6599 21.6277Z"
        fill="white"
      />
      <path
        d="M14.7438 19.043L15.4768 21.2959L16.2098 19.043H16.9848L15.9428 22.0369H15.0068L13.9688 19.043H14.7438Z"
        fill="white"
      />
      <path
        d="M11.8923 15.0792H4.55725C3.92225 15.0792 3.40625 14.4472 3.40625 13.6693V8.35038C3.40625 7.5734 3.92225 6.94141 4.55725 6.94141H11.8923C12.5273 6.94141 13.0433 7.5734 13.0433 8.35038V13.6693C13.0433 14.4472 12.5273 15.0792 11.8923 15.0792ZM4.55725 7.66141C4.24725 7.66141 3.99425 7.97039 3.99425 8.35038V13.6693C3.99425 14.0493 4.24725 14.3583 4.55725 14.3583H11.8923C12.2023 14.3583 12.4543 14.0493 12.4543 13.6693V8.35038C12.4543 7.97039 12.2023 7.66141 11.8923 7.66141H4.55725Z"
        fill="#00C650"
      />
      <path d="M12.7443 11.875H3.69531V12.596H12.7443V11.875Z" fill="#00C650" />
      <path
        d="M12.7443 9.40625H3.69531V10.1272H12.7443V9.40625Z"
        fill="#00C650"
      />
      <path
        d="M10.4319 7.30078H9.71094V14.7176H10.4319V7.30078Z"
        fill="#00C650"
      />
      <path
        d="M6.73663 7.30078H6.01562V14.7176H6.73663V7.30078Z"
        fill="#00C650"
      />
    </svg>
  )
}
