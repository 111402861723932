import styled from 'styled-components'

import { useAppSelector } from '@src/hooks/store'
import { NotificationItem } from '@src/shared/MutationResultNotifications/NotificationItem'
import { media } from '@theme/helpers'

const StyledNotificationsContainer = styled.div`
  position: fixed;
  right: 40px;
  bottom: 0;
  width: auto;
  z-index: 1001;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  ${media(
    'small',
    () => `
    right: 16px;
  `
  )}
`

export const MutationResultNotifications = () => {
  const notifications = useAppSelector(
    (state) => state.notifications.notifications
  )

  return (
    <StyledNotificationsContainer>
      {notifications.map(({ type, text }, index) => {
        return (
          <NotificationItem key={index} type={type} text={text} index={index} />
        )
      })}
    </StyledNotificationsContainer>
  )
}
