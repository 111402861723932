import React, { useEffect, useState } from 'react'
import styled, { useTheme } from 'styled-components'

import { ArrowDown } from '@icons/ArrowDown'
import { Check } from '@icons/Check'
import { useClickOut } from '@src/hooks/useClickout'
import { useToggle } from '@src/hooks/useToggle'
import { noop } from '@src/utils/helpers/noop'
import { setParamByType } from '@src/utils/helpers/setParamByType'
import { DROPDOWN_HEIGHT, DROPDOWN_WIDTH } from '@ui/Dropdowns/constants'
import { paramToNumber } from '@ui/Dropdowns/paramToNumber'
import { ItemListWithScrollbar } from '@ui/Dropdowns/styled/ItemListWithScrollbar'
import { ItemWrapper } from '@ui/Dropdowns/styled/ItemsList'
import { ItemsListWrapper } from '@ui/Dropdowns/styled/ItemsListWrapper'
import { IDropdown } from '@ui/Dropdowns/types/IDropdown'

const DropDownWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px;
  background: ${(props) => props.theme.colors.light};
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: ${(props) => props.theme.fontSizes.s};

  p {
    white-space: nowrap;
  }
`

const SelectWrapper = styled.div<{ isSelected: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  width: 100%;
  position: relative;

  p {
    color: ${(props) =>
      props.isSelected ? props.theme.colors.text20 : props.theme.colors.gray50};
    width: 95%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: start;
    transition: ${({ theme }) => theme.transitions.base};

    &:hover {
      color: ${(props) => props.theme.colors.gray10};
    }
  }
`

const ArrowWrapper = styled.div`
  &.active {
    transform: rotate(-180deg);
  }
`

const DropDown: React.FC<IDropdown> = ({
  title,
  items,
  width,
  height,
  setEvent,
  disabled,
  handleChange,
  className,
  activeValue = null,
  handleStringChange,
  hasError,
  id,
}) => {
  // const [selectedItem, setSelectedItem] = useState(-1)
  const [selectedNumberEvent, setSelectedNumberEvent] = useState<
    number | string
  >(activeValue ? activeValue : -1)
  const { isOn, toggle, off } = useToggle(false)
  const theme = useTheme()
  const dropDownRef = useClickOut(off)

  const handleSelectItem = (index: number, event: number | string) => {
    if (selectedNumberEvent === event) {
      setSelectedNumberEvent(-1)
    } else {
      setSelectedNumberEvent(event)
    }

    // Для автоматического закрытия dropdown
    off()
  }

  useEffect(() => {
    setEvent && setEvent(selectedNumberEvent)
  }, [selectedNumberEvent, setEvent])

  useEffect(() => {
    if (handleChange && typeof selectedNumberEvent === 'number') {
      handleChange(selectedNumberEvent)
    }
  }, [selectedNumberEvent])

  useEffect(() => {
    if (handleStringChange && typeof selectedNumberEvent === 'string') {
      handleStringChange(selectedNumberEvent)
    }
  }, [selectedNumberEvent])

  useEffect(() => {
    if (activeValue !== selectedNumberEvent) {
      setSelectedNumberEvent(activeValue ? activeValue : -1)
    }
  }, [activeValue])

  const currentWidth = setParamByType(DROPDOWN_WIDTH, width)
  const currentHeight = setParamByType(DROPDOWN_HEIGHT, height)

  let styles
  let listStyles

  if (isOn && items) {
    styles = {
      width: currentWidth,
      height: currentHeight,
      borderLeft: `1px solid ${theme.colors.gray77}`,
      borderRight: ` 1px solid ${theme.colors.gray77}`,
      borderTop: `1px solid ${theme.colors.gray77}`,
      borderBottom: `1px solid transparent`,
      borderRadius: '7px 7px 0 0',
    }
  }
  if (!isOn || !items) {
    styles = {
      width: currentWidth,
      height: currentHeight,
      border: `1px solid ${theme.colors.gray77}`,
      borderRadius: '7px',
    }
  }
  if (isOn && items && hasError) {
    styles = {
      width: currentWidth,
      height: currentHeight,
      borderLeft: `1px solid ${theme.colors.accent}`,
      borderRight: ` 1px solid ${theme.colors.accent}`,
      borderTop: `1px solid ${theme.colors.accent}`,
      borderRadius: '7px 7px 0 0',
    }
  }
  if (!isOn && hasError) {
    styles = {
      width: currentWidth,
      height: currentHeight,
      border: `1px solid ${theme.colors.accent}`,
      borderRadius: '7px',
    }
  }

  if (isOn) {
    listStyles = {
      top: `${paramToNumber(currentHeight) - 11}px`,
      minWidth: currentWidth,
      borderLeft: `1px solid ${theme.colors.gray77}`,
      borderRight: `1px solid ${theme.colors.gray77}`,
      borderBottom: `1px solid ${theme.colors.gray77}`,
      borderRadius: '0 0 7px 7px',
    }
  }
  if (!isOn) {
    listStyles = { minWidth: currentWidth }
  }
  if (isOn && hasError) {
    listStyles = {
      top: `${paramToNumber(currentHeight) - 11}px`,
      minWidth: currentWidth,
      borderLeft: `1px solid ${theme.colors.accent}`,
      borderRight: ` 1px solid ${theme.colors.accent}`,
      borderBottom: `1px solid ${theme.colors.accent}`,
      borderRadius: '0 0 7px 7px',
    }
  }

  return (
    <DropDownWrapper
      ref={dropDownRef}
      style={styles}
      className={className}
      id={id}
      tabIndex={0}
    >
      <SelectWrapper
        onClick={disabled ? noop : toggle}
        style={{ height: currentHeight }}
        isSelected={selectedNumberEvent !== -1}
      >
        <p>
          {selectedNumberEvent != -1
            ? items.find((item) => item.value === selectedNumberEvent)?.name
            : title}
        </p>
        <ArrowWrapper className={isOn ? 'active' : ''}>
          <ArrowDown color={theme.colors.gray60} size={10} />
        </ArrowWrapper>
      </SelectWrapper>
      {isOn && (
        <ItemsListWrapper style={listStyles}>
          <ItemListWithScrollbar>
            {items.map((item, index) => {
              return (
                <ItemWrapper
                  isSelected={selectedNumberEvent === item.value}
                  key={item.value}
                >
                  <li onClick={() => handleSelectItem(index, item.value)}>
                    <p
                      style={{
                        color:
                          selectedNumberEvent === item.value
                            ? `${theme.colors.primary}`
                            : `${theme.colors.text20}`,
                      }}
                    >
                      {item.name}
                    </p>
                    {selectedNumberEvent === item.value && (
                      <Check
                        color={theme.colors.primary}
                        size={14}
                        style={{ marginRight: '14px' }}
                      />
                    )}
                  </li>
                </ItemWrapper>
              )
            })}
          </ItemListWithScrollbar>
        </ItemsListWrapper>
      )}
    </DropDownWrapper>
  )
}

export default DropDown
