import IconPropsInterface from './IconPropsInterface'

export const SortDown = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill={color}
      stroke="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M3.002 4.958c-.204.105-.252.207-.252.542 0 .335.048.437.252.542.107.054.331.058 3.248.058 2.917 0 3.141-.004 3.248-.058.204-.105.252-.207.252-.542 0-.335-.048-.437-.252-.542-.107-.054-.331-.058-3.248-.058-2.917 0-3.141.004-3.248.058m11.667-.001a.628.628 0 0 0-.183.154c-.069.094-.069.103-.078 4.475-.005 2.409-.02 4.381-.034 4.381-.013 0-.347-.324-.741-.719-.394-.396-.781-.758-.86-.805-.196-.116-.363-.112-.564.012-.301.186-.425.519-.29.784.077.153 2.67 2.764 2.814 2.835.141.07.364.076.51.014.125-.053 2.703-2.63 2.815-2.815.165-.271.043-.639-.274-.825-.2-.116-.367-.118-.557-.005-.079.047-.466.409-.86.805-.394.395-.728.719-.742.719-.014 0-.025-1.954-.025-4.342 0-4.068-.004-4.349-.058-4.456-.098-.191-.212-.251-.503-.262-.196-.008-.282.004-.37.05M3.002 9.958c-.204.105-.252.207-.252.542 0 .335.048.437.252.542.107.054.331.058 3.248.058 2.917 0 3.141-.004 3.248-.058.204-.105.252-.207.252-.542 0-.335-.048-.437-.252-.542-.107-.054-.331-.058-3.248-.058-2.917 0-3.141.004-3.248.058m0 5c-.204.105-.252.207-.252.542 0 .335.048.437.252.542.107.054.371.058 4.081.058 3.711 0 3.974-.004 4.081-.058.205-.105.253-.207.253-.542 0-.335-.048-.437-.253-.542-.107-.054-.37-.058-4.081-.058-3.71 0-3.974.004-4.081.058"
        fillRule="evenodd"
      />
    </svg>
  )
}
