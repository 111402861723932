import { createSlice } from '@reduxjs/toolkit'

interface initialStateType {
  customerReviews: {
    deal_review_rating: number
    deal_review_comment: string
    driver_reviews?: {
      employee_id: string
      rating: number
      comment: string
    }[]
  }
  executorComment: string
}

const initialState: initialStateType = {
  customerReviews: {
    deal_review_rating: 0,
    deal_review_comment: '',
    driver_reviews: [],
  },
  executorComment: '',
}

const reviewSlice = createSlice({
  name: 'review',
  initialState: initialState,
  reducers: {
    setReviewRating: (state, action) => {
      state.customerReviews.deal_review_rating = action.payload
    },
    setReviewComment: (state, action) => {
      state.customerReviews.deal_review_comment = action.payload
    },
    setDriverElement: (state, action) => {
      if (state.customerReviews.driver_reviews) {
        state.customerReviews.driver_reviews = [
          ...state.customerReviews.driver_reviews,
          action.payload,
        ]
      }
    },
    setDriverComment: (state, action) => {
      const { id, comment } = action.payload
      if (state.customerReviews.driver_reviews) {
        const driver = state.customerReviews?.driver_reviews.find(
          (item) => item.employee_id === id
        )
        if (driver) {
          driver.comment = comment
        }
      }
    },
    setDriverRating: (state, action) => {
      const { id, rating } = action.payload
      if (state.customerReviews.driver_reviews) {
        const driver = state.customerReviews.driver_reviews.find(
          (item) => item.employee_id === id
        )
        if (driver) {
          driver.rating = rating
        }
      }
    },
    setExecutorComment: (state, action) => {
      state.executorComment = action.payload
    },
    clearCustomerComments: (state) => {
      state.customerReviews = {
        deal_review_rating: 0,
        deal_review_comment: '',
        driver_reviews: [],
      }
    },
  },
})

export const {
  setReviewRating,
  setReviewComment,
  setDriverComment,
  setDriverRating,
  setExecutorComment,
  setDriverElement,
  clearCustomerComments,
} = reviewSlice.actions

export const reviewReducer = reviewSlice.reducer
