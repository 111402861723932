import moment from 'moment-timezone'
import 'moment/dist/locale/ru'
import { CSSProperties, ReactNode } from 'react'
import Calendar, { CalendarProps } from 'react-calendar'
import { Value } from 'react-calendar/dist/cjs/shared/types'
import { NavigationLabelArgs } from 'react-calendar/src/shared/types'
import styled from 'styled-components'

import { ArrowLeft } from '@icons/ArrowLeft'
import { ArrowRight } from '@icons/ArrowRight'
import { Date as DateIcon } from '@icons/Date'
import { useClickOut } from '@src/hooks/useClickout'
import { useTimezone } from '@src/hooks/useTimezone'
import { useToggle } from '@src/hooks/useToggle'
import { useGetTheme } from '@theme/hooks'
import { Box } from '@ui/Box'
import { BaseButton } from '@ui/Buttons/Base/BaseButton'
import { BaseInput } from '@ui/Inputs/Base/BaseInput'
import { FieldSkeleton } from '@ui/Skeletons/FieldSkeleton'

moment.locale('ru')

const INPUT_HEIGHT = 45
const CALENDAR_WIDTH = 290
export const DEFAULT_ACTIVE_START_DATE = new Date(1990, 0, 1)

type CalendarPosition = 'top' | 'bottom'

export type OmitCalendarProps = Omit<CalendarProps, 'value' | 'onChange'>

interface IProps {
  value: Value
  onChange: (value: Value) => void
  children?: (handleClose?: () => void) => ReactNode
}

export interface IBaseCalendarProps {
  type?: 'past' | 'future'
  onBlur?: () => void
  onToggle?: (isOn: boolean) => void

  disabled?: boolean
  hasError?: boolean
  placeholder?: string
  position?: CalendarPosition
  formatString?: string
  className?: string
  style?: CSSProperties
}

export const formatValue = ({
  value,
  formatString,
  allowPartialRange,
}: {
  value: Value
  formatString: string
  allowPartialRange?: boolean
}): string => {
  const isNoValue =
    !value ||
    (allowPartialRange && Array.isArray(value) && !value[0] && !value[1]) ||
    (!allowPartialRange && Array.isArray(value) && (!value[0] || !value[1]))

  if (isNoValue) return ''

  if (Array.isArray(value))
    return `${formatValue({ value: value[0], formatString })} - ${formatValue({
      value: value[1],
      formatString,
    })}`

  return moment(value).format(formatString)
}

const navigationLabelDefault = ({ label, view }: NavigationLabelArgs) => {
  return view === 'month'
    ? `${label.slice(0, 1).toUpperCase()}${label.slice(1, -3)}`
    : label
}

// 'DD.MM.YYYY HH:mm'

export const BaseCalendar = ({
  value,
  onChange,
  onBlur,
  onToggle,
  locale,
  nextLabel,
  prevLabel,
  next2Label,
  prev2Label,
  navigationLabel,
  minDate,
  maxDate,
  disabled,
  hasError,
  placeholder,
  position = 'bottom',
  formatString = 'DD.MM.YYYY',
  allowPartialRange,
  className,
  style,
  children,
  type,
  ...rest
}: OmitCalendarProps & IBaseCalendarProps & IProps) => {
  const theme = useGetTheme()
  const { isOn, off, on } = useToggle(false)

  const { timezone, isFetching } = useTimezone()

  const handleOpen = () => {
    on()
    onToggle?.(true)
  }

  const handleClose = () => {
    if (isOn) {
      off()
      onToggle?.(false)
      onBlur?.()
    }
  }

  const ref = useClickOut(handleClose)

  const isClearBtnShown = Array.isArray(value)
    ? allowPartialRange
      ? value[0] || value[1]
      : value[0] && value[1]
    : !!value

  const handleChange = (
    value: Value,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onChange(value)
  }

  const handleClear = () => {
    onChange(null)
  }

  const newDateInTz = timezone
    ? new Date(moment().tz(timezone).format('YYYY/MM/DD HH:mm:ss'))
    : undefined

  const minCalendarDate = minDate
    ? minDate
    : type === 'future'
    ? newDateInTz
    : undefined
  const maxCalendarDate = maxDate
    ? maxDate
    : type === 'past'
    ? newDateInTz
    : undefined

  return (
    <FieldSkeleton isLoading={isFetching}>
      <Wrapper className={className} style={style} ref={ref}>
        <StyledButton onClick={handleOpen} disabled={disabled} type={'button'}>
          <StyledBaseInput
            value={formatValue({
              value,
              formatString,
              allowPartialRange,
            })}
            border={true}
            readonly
            disabled={disabled}
            rightButton={
              <DateIcon color={theme.colors[disabled ? 'gray70' : 'gray50']} />
            }
            hasError={hasError}
            placeholder={placeholder}
          />
        </StyledButton>
        {isOn && (
          <CalendarBox
            position={position}
            selectRange={rest.selectRange}
            className={'calendar-box'}
          >
            {children?.(handleClose)}
            <Calendar
              {...rest}
              onChange={handleChange}
              value={value}
              locale={locale || 'ru'}
              maxDate={maxCalendarDate}
              minDate={minCalendarDate}
              allowPartialRange={allowPartialRange}
              nextLabel={
                nextLabel || (
                  <ArrowRight color={theme.colors.gray30} size={10} />
                )
              }
              prevLabel={
                prevLabel || <ArrowLeft color={theme.colors.gray30} size={10} />
              }
              next2Label={next2Label || null}
              prev2Label={prev2Label || null}
              navigationLabel={navigationLabel || navigationLabelDefault}
            />
          </CalendarBox>
        )}

        {isClearBtnShown && (
          <ClearButton onClick={handleClear} disabled={disabled}>
            Очистить
          </ClearButton>
        )}
      </Wrapper>
    </FieldSkeleton>
  )
}

const Wrapper = styled(Box)`
  position: relative;
  padding-bottom: 19px;
  margin-bottom: -19px;
`

const CalendarBox = styled(Box)<{
  position: CalendarPosition
  selectRange?: boolean
}>`
  width: ${CALENDAR_WIDTH + 'px'};
  position: absolute;
  top: ${({ position }) => (position === 'top' ? '0' : `${INPUT_HEIGHT}px`)};
  left: 0;
  padding: 20px;
  border-radius: 7px;
  border: 1px solid ${({ theme }) => theme.colors.gray77};
  z-index: 22;
  transform: ${({ position }) =>
    position === 'top' ? 'translateY(-100%);' : 'none'};
  background: ${({ theme }) => theme.colors.light};

  .react-calendar__navigation {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .react-calendar__navigation__prev-button,
  .react-calendar__navigation__next-button {
    display: block;

    &:disabled {
      opacity: 0.5;
    }
  }

  .react-calendar__navigation__label span {
    text-transform: uppercase;
    font-size: ${({ theme }) => theme.fontSizes.s};
    line-height: 24px;
    color: ${({ theme }) => theme.colors.text20};
    font-weight: ${({ theme }) => theme.fontWeights.semibold};
  }

  .react-calendar__month-view__days,
  .react-calendar__year-view__months,
  .react-calendar__decade-view__years,
  .react-calendar__century-view__decades {
    row-gap: 5px;

    button {
      color: ${({ theme }) => theme.colors.text20};
      font-size: ${({ theme }) => theme.fontSizes.s};
      line-height: 24px;

      &:disabled {
        opacity: 0.5;
      }
    }

    .react-calendar__tile--now {
      color: ${({ theme }) => theme.colors.primary};
      background: ${({ theme }) => theme.colors.primaryLight};
      border-radius: 3px;
    }

    .react-calendar__tile--hasActive {
      color: ${({ theme }) => theme.colors.light};
      background: ${({ theme }) => theme.colors.primary};
      border-radius: 3px;
    }
  }

  .react-calendar__month-view__days {
    button {
      display: flex;
      justify-content: center;
      transition: none;
    }

    abbr {
      font-weight: 500;
      transition: none;
    }

    .react-calendar__month-view__days__day--neighboringMonth {
      abbr {
        opacity: 0.5;
      }
    }

    .react-calendar__month-view__days__day--weekend {
      abbr {
        color: ${({ theme }) => theme.colors.error};
      }
    }

    .react-calendar__tile--active {
      background: ${({ theme, selectRange }) =>
        selectRange ? theme.colors.primaryLight : 'none'} !important;
    }

    .react-calendar__tile--now {
      background: none;

      abbr {
        display: block;
        width: 24px;
        height: 24px;
        color: ${({ theme }) => theme.colors.primary};
        background: ${({ theme }) => theme.colors.primaryLight};
        border-radius: 3px;
      }
    }

    .react-calendar__tile--rangeStart,
    .react-calendar__tile--rangeEnd {
      position: relative;

      abbr {
        display: block;
        width: 24px;
        height: 24px;
        border-radius: 3px;
        background: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.light};
        position: relative;
        z-index: 2;
      }
    }

    .react-calendar__tile--rangeStart:before {
      content: '';
      width: 50%;
      height: 24px;
      background: white;
      position: absolute;
      top: 0;
      left: 0;
    }

    .react-calendar__tile--rangeEnd:after {
      content: '';
      width: 50%;
      height: 24px;
      background: white;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .react-calendar__month-view__weekdays {
    margin-bottom: 15px;

    abbr {
      display: block;
      text-decoration: none;
      color: ${({ theme }) => theme.colors.gray30};
      font-size: ${({ theme }) => theme.fontSizes.xs};
      line-height: 20px;
      text-align: center;

      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
`

const ClearButton = styled(BaseButton)`
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  line-height: 17px;
`

const StyledBaseInput = styled(BaseInput)`
  position: relative;
  z-index: 2;

  input {
    cursor: pointer;
    font-variant-numeric: tabular-nums;
    text-overflow: ellipsis;

    &:disabled {
      cursor: default;
    }
  }
`

const StyledButton = styled.button`
  display: block;
  width: 100%;
`
