import { IPurchasedFeature } from '@pages/PaidFeatures/types/IPurchasedFeature'
import { IRateInfo } from '@pages/PaidFeatures/types/Rate/IRateInfo'
import { IProfileInfo } from '@src/shared/interfaces/profile/Profile'
import { clientApiSlice } from '@src/store/slices/clientApiSlice'

export interface ISpaceList {
  success: string
  data: ISpace[]
}

export interface ISpace {
  id: number
  name: string
  access_available: boolean
  is_owner?: boolean
  user_is_driver?: boolean
  logo: {
    id: number
    filename: string
    public_url: string
  }
  executor: {
    id: number
    type: 'executor'
    is_active: boolean
    type_translated: string
    tariff_info?: IRateInfo
  }
  customer: {
    id: number
    type: 'customer'
    is_active: boolean
    type_translated: string
    tariff_info?: IRateInfo
  }
  partner: {
    id: number
    type: 'partner'
    is_active: boolean
    type_translated: string
    tariff_info?: IRateInfo
  }
  purchased_features_list: IPurchasedFeature[]
  owner: {
    id: number
  }
}

export const spaceApiSlice = clientApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSpaces: builder.query<ISpaceList, void>({
      query: () => ({
        url: `spaces`,
        method: 'GET',
      }),
      providesTags: () => [{ type: 'Space', id: 'LIST' }],
    }),
    createSpace: builder.mutation<any, any>({
      query: (body) => ({
        url: `spaces/create`,
        method: 'POST',
        body,
      }),
    }),
    getSpaceById: builder.query<{ data: IProfileInfo }, string>({
      query: (id) => ({
        url: `spaces/${id}/view`,
      }),
    }),
  }),
})

export const {
  useGetSpacesQuery,
  useCreateSpaceMutation,
  useGetSpaceByIdQuery,
  useLazyGetSpaceByIdQuery,
} = spaceApiSlice
