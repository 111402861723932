import moment from 'moment'
import { useNavigate, useParams } from 'react-router-dom'

import { useCreateExecutorOfferMutation } from '@pages/TasksBoard/ExecutorOffer/executorOfferApi'
import {
  decrementModalContentStep,
  resetExecutorOffer,
  setIsFirstValidation,
  setIsPreviewMode,
  setOfferFieldErrorMessage,
} from '@pages/TasksBoard/ExecutorOffer/executorOfferSlice'
import { InputWrapper } from '@pages/TasksBoard/ExecutorOffer/inputs/InputWrapper'
import { TaskWrapper } from '@pages/TasksBoard/ExecutorOffer/tasks/TaskWrapper'
import { useAppDispatch, useAppSelector } from '@src/hooks/store'
import { store } from '@src/store'
import { useGetTheme } from '@theme/hooks'
import { Box } from '@ui/Box'
import { MainButton } from '@ui/Buttons/MainButton'
import { RegularText } from '@ui/Typography'

export const EditOffer = () => {
  const dispatch = useAppDispatch()
  const theme = useGetTheme()
  const navigate = useNavigate()
  const { id: spaceId } = useParams()
  const {
    selectedTasks,
    isPreviewMode,
    isFirstValidation,
    validUntil,
    ownershipTypeId,
    bankAccountId,
    projectId,
  } = useAppSelector((state) => state.executorOffer)
  const validateInputs = () => {
    let isValid = true
    Object.keys(selectedTasks).forEach((taskId) =>
      Object.keys(selectedTasks[taskId]).forEach((fieldName) => {
        const fieldData = selectedTasks[taskId][fieldName]

        if (typeof fieldData === 'object' && !fieldData.isDisabled) {
          const fieldErrorMessage = fieldData.getErrorMessage(fieldData.value)
          if (fieldErrorMessage !== '') {
            isValid = false
          }
          dispatch(
            setOfferFieldErrorMessage({
              taskId,
              fieldName,
              value: fieldErrorMessage,
            })
          )
        }
      })
    )
    return isValid
  }

  const [sendData, { isLoading: isSending }] = useCreateExecutorOfferMutation()

  const handleSendData = () => {
    const timezone = store.getState().profile?.profileInfo?.timezone || ''
    const rawTasks = Object.values(selectedTasks)
    const resultTasks = rawTasks.map((task: any) => {
      const result: any = {}
      result.task_id = task.task_id
      Object.keys(task).forEach((field) => {
        if (
          typeof task[field] === 'object' &&
          task[field].value !== null &&
          task[field].value !== '' &&
          task[field].value !== -1 &&
          !task[field].isDisabled
        ) {
          if (typeof task[field].value === 'string') {
            result[field] =
              !isNaN(parseFloat(task[field].value)) &&
              isFinite(task[field].value)
                ? +task[field].value
                : task[field].value
          } else if (typeof task[field].value === 'object') {
            result[field] =
              task[field].value instanceof Date
                ? moment(task[field].value).tz(timezone, true).toISOString()
                : task[field].value.value
          } else {
            result[field] = task[field].value
          }
        }
      })
      return result
    })

    const data: any = {
      spaceId: +spaceId!,
      projectId,
      ownershipTypeId,
      validUntil: moment(validUntil).tz(timezone, true).toISOString(),
      tasks: resultTasks,
    }
    if (bankAccountId >= 1) {
      data.bankAccountId = bankAccountId
    }

    sendData(data)
      .unwrap()
      .then(() => {
        dispatch(resetExecutorOffer())
        navigate(`/space/${spaceId}/offers/executor`)
      })
  }

  const handlePrev = () => {
    if (isPreviewMode) {
      dispatch(setIsPreviewMode(false))
    } else {
      dispatch(decrementModalContentStep())
      dispatch(setIsFirstValidation(true))
    }
  }

  const handleNext = () => {
    if (isPreviewMode) {
      handleSendData()
      dispatch(setIsFirstValidation(true))
    } else {
      if (validateInputs()) {
        dispatch(setIsPreviewMode(true))
        dispatch(setIsFirstValidation(true))
      } else {
        dispatch(setIsFirstValidation(false))
      }
    }
  }

  return (
    <>
      <RegularText>* - поля, обязательные для заполнения</RegularText>
      {Object.keys(selectedTasks).map((taskId, index) => {
        return (
          <TaskWrapper
            key={taskId}
            selectedTask={selectedTasks[taskId]}
            index={index}
          />
        )
      })}
      {isPreviewMode && (
        <div
          style={{
            borderBottom: `1px solid ${theme.colors.gray60}`,
            padding: '20px 0',
          }}
        >
          <InputWrapper required={false} title={'Предложение действительно до'}>
            <RegularText>
              {moment(validUntil).format('DD.MM.YYYY HH:mm')}
            </RegularText>
          </InputWrapper>
        </div>
      )}
      <Box flex cg={10} justifyContent={'flex-end'} mt={30}>
        <MainButton
          disabled={isSending}
          fillType={'lightFilled'}
          onClick={handlePrev}
        >
          Назад
        </MainButton>
        <MainButton
          fillType={'fullFilled'}
          onClick={handleNext}
          isLoading={isSending}
        >
          {isPreviewMode ? 'Отправить' : 'Продолжить'}
        </MainButton>
      </Box>
    </>
  )
}
