import { CSSProperties } from 'react'
import styled from 'styled-components'

import { getBgColor } from '@theme/helpers'
import { Box } from '@ui/Box'

interface IProps {
  width?: number | string
  height?: number | string
  borderRadius?: number | string
  minWight?: number
  style?: CSSProperties
}

const getBorderRadius = (radius?: number | string) => {
  if (typeof radius === 'undefined') return '10px'
  else if (typeof radius === 'number') return `${radius}px`
  else return radius
}

export const BaseSkeleton = styled(Box)<IProps>`
  width: ${({ width }) => (typeof width === 'string' ? width : `${width}px`)};
  ${({ minWight }) => minWight && `min-width: ${minWight}px`};
  height: ${({ height }) =>
    typeof height === 'string' ? height : `${height}px`};
  position: relative;
  border-radius: ${({ borderRadius }) => getBorderRadius(borderRadius)};
  ${getBgColor('gray80')}
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 20%;
    animation: load 1s ease-out 0s infinite;
    background: ${({ theme }) =>
      `linear-gradient(90deg, ${theme.colors.gray80} 0%, ${theme.colors.gray90} 50%, ${theme.colors.gray80} 100%)`};
    @keyframes load {
      from {
        transform: translateX(-100%);
      }
      to {
        transform: translateX(700%);
      }
    }
  }
`
