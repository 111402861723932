import { CSSProperties, useEffect } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'

import { useClickOut } from '@src/hooks/useClickout'
import { useToggle } from '@src/hooks/useToggle'
import { checkAndAddModalDiv } from '@src/utils/helpers/checkAndAddModalDiv'
import { scrollLocker } from '@src/utils/helpers/scrollLocker'
import { media } from '@theme/helpers'
import { Overlay } from '@ui/Popups/styled/Overlay'
import { withScrollbar } from '@ui/withScrollbar'

interface IProps {
  children: React.ReactNode
  handleClickOut?: () => void
  isOpened: boolean
  divId?: string
  className?: string
  mobileHeight?: string
  style?: CSSProperties
}

interface IModalPortalProps extends IProps {
  onTransitionEnd: (e: React.TransitionEvent<HTMLDivElement>) => void
  isActive: boolean
}

const OverlayStyled = styled(Overlay)<{
  isOpened: boolean
  isShowPortal: boolean
}>`
  align-items: center;
  opacity: ${({ isOpened, isShowPortal }) =>
    isOpened && isShowPortal ? '1' : '0'};

  ${media(
    'small',
    () => `
    align-items: flex-end;
  `
  )}
`
const InnerStyled = withScrollbar(styled.div<{
  mobileHeight?: string
  isOpened: boolean
  isActive: boolean
  isShowPortal: boolean
}>`
  margin: auto;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 20px);
  transition: ${({ theme }) => theme.transitions.base};

  ${media(
    'small',
    () => `
    max-height: 0;
    margin: 0;
    transform: none;
  `
  )}

  transform: ${({ isOpened, isShowPortal }) =>
    isOpened && isShowPortal ? 'scale(1)' : 'scale(0.2)'};

  ${({ theme }) => theme.mediaQueries.small`
    max-height:  ${(props: {
      mobileHeight?: string
      isOpened: boolean
      isShowPortal: boolean
    }) => {
      if (!(props.isOpened && props.isShowPortal)) return 0
      else return props.mobileHeight ? props.mobileHeight : 'calc(100vh - 54px)'
    }};
    
    overflow-y: ${(props: { isActive: boolean }) =>
      props.isActive ? 'auto' : 'hidden'};
    `}
  overflow-x: hidden;
`)

const ModalPortal = ({
  handleClickOut,
  divId = 'modal',
  children,
  className,
  mobileHeight,
  isOpened,
  isActive,
  onTransitionEnd,
  style,
}: IModalPortalProps) => {
  const ref = useClickOut(handleClickOut)
  //стейт нужен для предотвращения рывка самой модалки из-за заблокированного скролла
  const { isOn: isShowPortal, on: showPortal } = useToggle(false)
  useEffect(() => {
    scrollLocker.lock()
    showPortal()
    const handleEscPress = (e: any) => {
      if (e.keyCode === 27) {
        handleClickOut?.()
      }
    }
    document.addEventListener('keydown', handleEscPress)
    return () => {
      document.removeEventListener('keydown', handleEscPress)
      const modal = document.querySelector('#modal')
      if (modal && modal.children.length < 1) {
        scrollLocker.unlock()
      }
    }
  }, [])

  checkAndAddModalDiv(divId)

  return createPortal(
    <OverlayStyled
      isOpened={isOpened}
      isShowPortal={isShowPortal}
      onClick={(e) => e.stopPropagation()}
    >
      <InnerStyled
        ref={isOpened && isShowPortal ? ref : null}
        mobileHeight={mobileHeight}
        className={className}
        isOpened={isOpened}
        isActive={isActive}
        isShowPortal={isShowPortal}
        onTransitionEnd={onTransitionEnd}
        style={style}
      >
        {children}
      </InnerStyled>
    </OverlayStyled>,
    document.querySelector(`body>#${divId}`) as HTMLElement
  )
}

export const BasePopup = ({
  children,
  isOpened,
  handleClickOut,
  divId = 'modal',
  className,
  mobileHeight,
  style,
}: IProps) => {
  const { isOn: isActive, on: setActive, off: setInactive } = useToggle(false)
  const onTransitionEnd = (e: React.TransitionEvent<HTMLDivElement>) => {
    if (!isOpened && e.currentTarget === e.target) {
      setInactive()
    }

    if (isOpened && e.currentTarget === e.target) {
      setActive()
    }
  }

  if (!isOpened && !isActive) {
    return null
  }

  //отдельный компонент нужен, чтобы изначально на document не вешался обработчик из useClickOut
  return (
    <ModalPortal
      handleClickOut={handleClickOut}
      divId={divId}
      className={className}
      mobileHeight={mobileHeight}
      isOpened={isOpened}
      isActive={isActive}
      onTransitionEnd={onTransitionEnd}
      style={style}
    >
      {children}
    </ModalPortal>
  )
}
