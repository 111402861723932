import { CloseButton } from '@ui/Buttons/CloseButton'

export const ClearIndicator = (props: any) => {
  const {
    innerProps: { ref, ...restInnerProps },
  } = props
  return (
    <div {...restInnerProps} ref={ref} style={{ marginRight: '5px' }}>
      <CloseButton type={'small'} />
    </div>
  )
}
