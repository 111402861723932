import { BaseSkeleton } from '@ui/BaseSkeleton'
import { Box } from '@ui/Box'

export const TaskSkeleton = () => {
  return (
    <>
      <BaseSkeleton width={250} height={28} mb={20} />
      <Box flex cg={20} mb={20}>
        <BaseSkeleton width={'50%'} height={24} />
        <BaseSkeleton width={150} height={45} />
      </Box>
      <Box flex cg={20} mb={20}>
        <BaseSkeleton width={'50%'} height={24} />
        <BaseSkeleton width={150} height={45} />
      </Box>
      <Box flex cg={20} mb={20}>
        <BaseSkeleton width={'50%'} height={24} />
        <BaseSkeleton width={150} height={45} />
      </Box>
      <Box flex cg={20} mb={20}>
        <BaseSkeleton width={'50%'} height={24} />
        <BaseSkeleton width={150} height={45} />
      </Box>
    </>
  )
}
