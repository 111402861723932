import moment from 'moment'

import { createSlice } from '@reduxjs/toolkit'

import { LIMIT_TRANSACTION_RECORDS } from './constants'
import { ITransactionRecord } from './interfaces'

const initialState: {
  transactionItems: ITransactionRecord[]
  lastItemsCount: number
  page: number

  total: number

  filtersAreOpened: boolean
  filtersAreActive: boolean

  period: string | null
  ownership: number
  source: number
  type: number
} = {
  transactionItems: [],
  page: 1,
  lastItemsCount: LIMIT_TRANSACTION_RECORDS,

  total: 1000123, //баланс средств согласно выборке транзакций по фильтрам

  filtersAreOpened: false, //показываются ли фильтры в ui
  filtersAreActive: false, //применяются ли фильтры при запросах
  period: `${moment()
    .startOf('month')
    .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')},${moment()
    .endOf('month')
    .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')}`,
  ownership: 0,
  source: 0,
  type: 0,
}

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    addPageDataTransactions: (state, action) => {
      state.transactionItems = [...state.transactionItems, ...action.payload]
    },
    clearTransactionsData: (state) => {
      state.transactionItems = []
      state.page = 1
      state.lastItemsCount = LIMIT_TRANSACTION_RECORDS
    },
    setLastItemsCountTransactions: (state, action) => {
      state.lastItemsCount = action.payload
    },
    incrementPageTransactions: (state) => {
      state.page += 1
    },
    setTransactionsTotal: (state, action) => {
      state.total = action.payload
    },
    setFiltersAreOpened: (state, action) => {
      state.filtersAreOpened = action.payload
    },
    setFiltersAreActive: (state, action) => {
      state.filtersAreActive = action.payload
    },
    setPeriod: (state, action) => {
      state.period = action.payload
    },
    setOwnership: (state, action) => {
      state.ownership = action.payload
    },
    setSource: (state, action) => {
      state.source = action.payload
    },
    setType: (state, action) => {
      state.type = action.payload
    },
  },
})

export const {
  addPageDataTransactions,
  clearTransactionsData,
  setLastItemsCountTransactions,
  incrementPageTransactions,
  setTransactionsTotal,
  setFiltersAreOpened,
  setFiltersAreActive,
  setPeriod,
  setOwnership,
  setSource,
  setType,
} = transactionsSlice.actions

export const transactionsReducer = transactionsSlice.reducer
