import { generateMedia } from 'styled-media-query'

export const breakpoints = {
  small: '768px',
  medium: '768px',
  large: '1024px',
  xlarge: '1240px',
  xxlarge: '1900px',
}

const customMedia = generateMedia(breakpoints)

export const mediaQueries = {
  small: customMedia.lessThan('small'),
  smallGreater: customMedia.greaterThan('small'),
  medium: customMedia.between('small', 'medium'),
  large: customMedia.between('medium', 'large'),
  xlargeLess: customMedia.lessThan('xlarge'),
  xlarge: customMedia.greaterThan('large'),
  xlargeGreater: customMedia.greaterThan('xlarge'),
  xxlargeGreater: customMedia.greaterThan('xxlarge'),
  xxlargeLess: customMedia.lessThan('xxlarge'),
}
