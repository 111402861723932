import { createSlice } from '@reduxjs/toolkit'

import { LIMIT_BLACKLIST } from './constants'
import { IBlacklistItem } from './interfaces'

const initialState: {
  items: IBlacklistItem[]
  lastItemsCount: number
  page: number
} = {
  items: [],
  page: 1,
  lastItemsCount: LIMIT_BLACKLIST,
}

const blacklistSlice = createSlice({
  name: 'blacklist',
  initialState,
  reducers: {
    addPageDataBlacklist: (state, action) => {
      state.items = [...state.items, ...action.payload]
    },
    clearBlacklistData: (state) => {
      state.items = []
      state.page = 1
      state.lastItemsCount = LIMIT_BLACKLIST
    },
    setLastItemsCount: (state, action) => {
      state.lastItemsCount = action.payload
    },
    incrementPage: (state) => {
      state.page += 1
    },
  },
})

export const {
  addPageDataBlacklist,
  clearBlacklistData,
  incrementPage,
  setLastItemsCount,
} = blacklistSlice.actions

export default blacklistSlice.reducer
