import { EditOffer } from '@pages/TasksBoard/ExecutorOffer/EditOffer'
import { SelectTasks } from '@pages/TasksBoard/ExecutorOffer/SelectTasks'
import { EditTaskCargo } from '@pages/TasksBoard/ExecutorOffer/tasks/EditTaskCargo'
import { EditTaskDelivery } from '@pages/TasksBoard/ExecutorOffer/tasks/EditTaskDelivery'
import { EditTaskPassenger } from '@pages/TasksBoard/ExecutorOffer/tasks/EditTaskPassenger'
import { EditTaskRent } from '@pages/TasksBoard/ExecutorOffer/tasks/EditTaskRent'
import { TaskType } from '@src/shared/interfaces/task/TaskType'

export const steps = [null, <SelectTasks key={1} />, <EditOffer key={2} />]

export const getTaskComponentByType = (
  taskId: number,
  taskType: TaskType,
  taskData: any
) => {
  if (taskType === 'rent') {
    return <EditTaskRent taskId={taskId} data={taskData} />
  }
  if (taskType === 'passenger') {
    return <EditTaskPassenger taskId={taskId} data={taskData} />
  }
  if (taskType === 'delivery') {
    return <EditTaskDelivery taskId={taskId} data={taskData} />
  }
  if (taskType === 'cargo') {
    return <EditTaskCargo taskId={taskId} data={taskData} />
  }

  return null
}
export const selectPeriodOptions = [
  { name: 'Час', value: 1 },
  { name: 'Сутки', value: 2 },
]
export const PERIOD_VALUES = ['', 'hour', 'twenty_four_hours']

export const selectEmployeeCustomerOptions = [
  { name: 'Заказчик', value: 1 },
  { name: 'Исполнитель', value: 2 },
]
export const EMPLOYEE_CUSTOMER_VALUES = [null, true, false]

export const FIELD_IS_REQUIRED_TEXT = 'Поле обязательно для заполнения.'
