export const FileRtf = () => {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3">
        <path
          opacity="0.3"
          d="M13.967 4.92409C13.4203 4.92356 12.8962 4.70614 12.5096 4.31955C12.123 3.93295 11.9056 3.40877 11.905 2.86205V0H2.69101C1.97748 -4.92673e-08 1.29316 0.283384 0.788531 0.787837C0.283898 1.29229 0.000265153 1.97651 0 2.69005V18.9123C0.000795073 19.6255 0.284662 20.3092 0.789238 20.8132C1.29381 21.3173 1.97783 21.6004 2.69101 21.6004H13.751C14.1045 21.6005 14.4546 21.531 14.7812 21.3958C15.1078 21.2606 15.4045 21.0624 15.6545 20.8125C15.9045 20.5626 16.1028 20.2659 16.2381 19.9394C16.3734 19.6128 16.4431 19.2628 16.4431 18.9093V4.92409H13.967Z"
          fill="#FF3E4C"
        />
      </g>
      <path
        d="M16.4443 4.92409H13.9683C13.4215 4.92356 12.8974 4.70614 12.5108 4.31955C12.1242 3.93295 11.9068 3.40877 11.9062 2.86205V0L16.4443 4.92409Z"
        fill="#FF3E4C"
      />
      <path
        d="M18.3913 17.0586H5.88726C5.00028 17.0586 4.28125 17.7776 4.28125 18.6646V22.3927C4.28125 23.2797 5.00028 23.9987 5.88726 23.9987H18.3913C19.2783 23.9987 19.9973 23.2797 19.9973 22.3927V18.6646C19.9973 17.7776 19.2783 17.0586 18.3913 17.0586Z"
        fill="#FF3E4C"
      />
      <path d="M7.752 21.3359V22.063H7V21.3359H7.752Z" fill="white" />
      <path
        d="M9.67682 22.0623L9.02582 20.9272H8.85982V22.0623H8.13281V19.0552H9.37482C9.58173 19.0494 9.78716 19.0919 9.97482 19.1792C10.1292 19.2522 10.2582 19.37 10.3448 19.5172C10.4286 19.6671 10.471 19.8366 10.4678 20.0082C10.4735 20.209 10.4099 20.4057 10.2878 20.5652C10.1532 20.7267 9.96775 20.8378 9.76182 20.8802L10.4838 22.0623H9.67682ZM8.85982 20.4292H9.31082C9.42602 20.4379 9.54017 20.4021 9.62982 20.3292C9.66536 20.2919 9.69263 20.2475 9.70986 20.1989C9.72709 20.1503 9.73389 20.0986 9.72982 20.0472C9.7332 19.996 9.72607 19.9447 9.70887 19.8964C9.69168 19.8481 9.66477 19.8038 9.62982 19.7662C9.5415 19.6923 9.42754 19.6564 9.31282 19.6662H8.86182L8.85982 20.4292Z"
        fill="white"
      />
      <path
        d="M13.2398 19.0586V19.6376H12.4228V22.0656H11.6918V19.6376H10.8828V19.0586H13.2398Z"
        fill="white"
      />
      <path
        d="M16.1085 19.0586V19.6366H14.8754V20.2956H15.8284V20.8486H14.8754V22.0657H14.1484V19.0586H16.1085Z"
        fill="white"
      />
      <path
        d="M13.699 10.749H3.374C3.27472 10.7488 3.17958 10.7091 3.10947 10.6388C3.03936 10.5685 3 10.4733 3 10.374C3.00026 10.2749 3.03976 10.1799 3.10984 10.1098C3.17992 10.0398 3.27489 10.0003 3.374 10H13.699C13.7981 10.0003 13.8931 10.0398 13.9632 10.1098C14.0333 10.1799 14.0728 10.2749 14.073 10.374C14.073 10.4733 14.0337 10.5685 13.9636 10.6388C13.8934 10.7091 13.7983 10.7488 13.699 10.749Z"
        fill="#FF3E4C"
      />
      <path
        d="M13.699 12.499H3.374C3.27472 12.4988 3.17958 12.4591 3.10947 12.3888C3.03936 12.3185 3 12.2233 3 12.124C3.00026 12.0249 3.03976 11.9299 3.10984 11.8598C3.17992 11.7898 3.27489 11.7503 3.374 11.75H13.699C13.7981 11.7503 13.8931 11.7898 13.9632 11.8598C14.0333 11.9299 14.0728 12.0249 14.073 12.124C14.073 12.2233 14.0337 12.3185 13.9636 12.3888C13.8934 12.4591 13.7983 12.4988 13.699 12.499Z"
        fill="#FF3E4C"
      />
    </svg>
  )
}
