import IconPropsInterface from './IconPropsInterface'

export const ArrowDown = ({
  size = 20,
  color,
  style,
  className,
}: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 11 7"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{ ...style, flexShrink: 0, transition: '300ms' }}
      className={className}
    >
      <path
        d="M1 1L4.75671 5.17412C5.15395 5.6155 5.84605 5.6155 6.24329 5.17412L10 1"
        strokeLinecap="round"
      />
    </svg>
  )
}
