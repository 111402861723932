import IconPropsInterface from '@icons/IconPropsInterface'

export const AutoAnswers = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5 37.5L15 42.5V35H12.5C9.7375 35 7.5 32.7625 7.5 30V15C7.5 12.2375 9.7375 10 12.5 10H35C37.7625 10 40 12.2375 40 15V20"
        stroke="#34C759"
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35 45L45 52.5V45H47.5C50.2625 45 52.5 42.7625 52.5 40V25C52.5 22.2375 50.2625 20 47.5 20H27.5C24.7375 20 22.5 22.2375 22.5 25V40C22.5 42.7625 24.7375 45 27.5 45H35Z"
        stroke={color}
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.0767 32.4116C31.1255 32.4604 31.1255 32.5396 31.0767 32.5884C31.0279 32.6372 30.9487 32.6372 30.8999 32.5884C30.8511 32.5396 30.8511 32.4604 30.8999 32.4116C30.9487 32.3628 31.0279 32.3628 31.0767 32.4116"
        stroke="#34C759"
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.5884 32.4116C37.6372 32.4604 37.6372 32.5396 37.5884 32.5884C37.5396 32.6372 37.4604 32.6372 37.4116 32.5884C37.3628 32.5396 37.3628 32.4604 37.4116 32.4116C37.4604 32.3628 37.5396 32.3628 37.5884 32.4116"
        stroke="#34C759"
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.1001 32.4116C44.1489 32.4604 44.1489 32.5396 44.1001 32.5884C44.0513 32.6372 43.9721 32.6372 43.9233 32.5884C43.8745 32.5396 43.8745 32.4604 43.9233 32.4116C43.9721 32.3628 44.0513 32.3628 44.1001 32.4116"
        stroke="#34C759"
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
