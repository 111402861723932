import { ITheme } from '@theme/interfaces'

export const getMenuStyles = (theme: ITheme, hasError?: boolean) => {
  return {
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderRadius: '7px',
    boxShadow: 'none',
    border: `1px solid ${hasError ? theme.colors.error : theme.colors.gray77}`,
    borderTop: 'none',
    background: `${theme.colors.light}`,
  }
}
