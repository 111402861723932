import { getTaskComponentByType } from '@pages/TasksBoard/ExecutorOffer/constants'
import { TaskSkeleton } from '@pages/TasksBoard/ExecutorOffer/tasks/TaskSkeleton'
import { useGetTaskByIdQuery } from '@pages/TasksBoard/taskBoardApi'
import { useGetTheme } from '@theme/hooks'
import { TaskTypeTag } from '@ui/Tags/TaskTypeTag'
import { MediumTitle } from '@ui/Typography'

export const TaskWrapper = ({
  selectedTask,
  index,
}: {
  selectedTask: any
  index: number
}) => {
  const theme = useGetTheme()
  const { data, isLoading } = useGetTaskByIdQuery(selectedTask.task_id)
  if (isLoading) {
    return <TaskSkeleton />
  }
  return (
    <div
      key={selectedTask.task_id}
      style={{
        borderBottom: `1px solid ${theme.colors.gray60}`,
        padding: '20px 0',
      }}
    >
      <TaskTypeTag
        style={{ marginBottom: '10px' }}
        taskType={selectedTask.task_type}
        customText={
          selectedTask.task_type === 'rent'
            ? data?.details?.without_driver
              ? 'Аренда спецтехники без экипажа'
              : 'Аренда спецтехники с экипажем'
            : ''
        }
      />
      <MediumTitle style={{ marginBottom: '20px' }}>
        {`${index + 1}. `}
        {selectedTask.task_title}
      </MediumTitle>
      {getTaskComponentByType(
        +selectedTask.task_id,
        selectedTask.task_type,
        data
      )}
    </div>
  )
}
