import {
  BaseAnswerAddingRequest,
  BaseAnswerEditingRequest,
} from '@pages/Space/Autoanswers/types/api/IAnswerAddingRequest'
import { IAnswerRequest } from '@pages/Space/Autoanswers/types/api/IAnswerRequest'
import { IAnswerResponse } from '@pages/Space/Autoanswers/types/api/IAnswerResponse'
import { IAnswersDeletionRequest } from '@pages/Space/Autoanswers/types/api/IAnswersDeletionRequest'
import { IAnswersListRequest } from '@pages/Space/Autoanswers/types/api/IAnswersListRequest'
import { IAnswersListResponse } from '@pages/Space/Autoanswers/types/api/IAnswersListResponse'
import { IAutoanswerCounter } from '@pages/Space/Autoanswers/types/api/IAutoanswerCounter'
import { ICreateGeometryRequest } from '@pages/Space/Autoanswers/types/api/ICreateGeometryRequest'
import { ICreateGeometryResponse } from '@pages/Space/Autoanswers/types/api/ICreateGeometryResponse'
import { ICreateMaterialRequest } from '@pages/Space/Autoanswers/types/api/ICreateMaterialRequest'
import {
  IMaterialItemRequest,
  IMaterialItemResponse,
  IMaterialsListResponse,
} from '@pages/Space/Autoanswers/types/api/IMaterialsListResponse'
import { IUpdateGeometryRequest } from '@pages/Space/Autoanswers/types/api/IUpdateGeometryRequest'
import { IUpdateMaterialRequest } from '@pages/Space/Autoanswers/types/api/IUpdateMaterialRequest'
import { ISuccessResponse } from '@src/shared/interfaces/apiResponse/ISuccessResponse'
import { clientApiSlice } from '@src/store/slices/clientApiSlice'
import { formatUrlGetParamsFromObject } from '@src/utils/helpers/formatUrlGetParamsFromObject'

export const autoAnswerApiSlice = clientApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // список автоответов
    getAutoAnswerList: builder.query<IAnswersListResponse, IAnswersListRequest>(
      {
        query: ({ spaceId, task_type }) => ({
          url: `/spaces/${spaceId}/auto-offers/items${formatUrlGetParamsFromObject(
            {
              task_type,
            }
          )}`,
          method: 'GET',
        }),
        providesTags: (result, error, arg) =>
          result && result.success
            ? [{ type: 'Autoanswer', id: `LIST_${arg.task_type}` }]
            : [],
      }
    ),
    // создание автоответа
    createAutoAnswer: builder.mutation<
      IAnswerResponse,
      BaseAnswerAddingRequest
    >({
      query: ({ spaceId, body }) => ({
        url: `/spaces/${spaceId}/auto-offers/items`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: (result, error, arg) => {
        const common = [
          { type: 'Autoanswer' as const, id: `LIST_${arg.body.task_type}` },
          { type: 'PaidFeatures' as const, id: 'AUTO_OFFERS' },
        ]

        return result && result.success
          ? arg.isAvailableByRate
            ? [...common, { type: 'Space', id: 'LIST' }]
            : common
          : []
      },
    }),
    // изменение автоответа
    changeAutoAnswer: builder.mutation<
      ISuccessResponse<unknown>,
      BaseAnswerEditingRequest
    >({
      query: ({ spaceId, answerId, body }) => ({
        url: `/spaces/${spaceId}/auto-offers/items/${answerId}`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: (result, error, arg) =>
        result && result.success
          ? [
              { type: 'Autoanswer', id: `LIST_${arg.task_type}` },
              { type: 'Autoanswer', id: arg.answerId },
            ]
          : [],
    }),
    // удаление автоответов
    deleteAutoAnswers: builder.mutation<
      ISuccessResponse<unknown>,
      IAnswersDeletionRequest
    >({
      query: ({ spaceId, body }) => ({
        url: `/spaces/${spaceId}/auto-offers/items`,
        method: 'DELETE',
        body: body,
      }),
      invalidatesTags: (result, error, arg) =>
        result && result.success
          ? [
              { type: 'Autoanswer', id: `LIST_${arg.task_type}` },
              { type: 'PaidFeatures', id: 'AUTO_OFFERS' },
            ]
          : [],
    }),
    // просмотр автоответа
    viewAutoAnswer: builder.query<IAnswerResponse, IAnswerRequest>({
      query: ({ spaceId, answerId }) => ({
        url: `/spaces/${spaceId}/auto-offers/items/${answerId}`,
        method: 'GET',
      }),
      providesTags: (result, error, arg) =>
        result && result.success
          ? [{ type: 'Autoanswer', id: arg.answerId }]
          : [],
    }),
    // создание геометрии автоответа
    createGeometryAutoAnswer: builder.mutation<
      ICreateGeometryResponse,
      ICreateGeometryRequest
    >({
      query: ({ spaceId, body }) => ({
        url: `/spaces/${spaceId}/auto-offers/geometries`,
        method: 'POST',
        body: body,
      }),
    }),
    // обновление геометрии автоответа
    updateGeometryAutoAnswer: builder.mutation<
      ICreateGeometryResponse,
      IUpdateGeometryRequest
    >({
      query: ({ spaceId, body, geometryId }) => ({
        url: `/spaces/${spaceId}/auto-offers/geometries/${geometryId}`,
        method: 'PATCH',
        body: body,
      }),
    }),
    // список автоответов
    geometryAutoAnswerList: builder.query({
      query: ({ spaceId, task_type }) => ({
        url: `/spaces/${spaceId}/auto-offers/geometries${formatUrlGetParamsFromObject(
          {
            task_type,
          }
        )}`,
        method: 'GET',
      }),
    }),
    // удаление геометрии автоответа
    deleteGeoAutoAnswer: builder.mutation({
      query: ({ spaceId, body }) => ({
        url: `/spaces/${spaceId}/auto-offers/geometries`,
        method: 'DELETE',
        body: body,
      }),
    }),
    // создание материала для автоответов
    createMaterialForAutoAnswer: builder.mutation<
      ISuccessResponse<unknown>,
      ICreateMaterialRequest
    >({
      query: ({ spaceId, body }) => ({
        url: `/spaces/${spaceId}/auto-offers/materials`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: (result) =>
        result && result.success
          ? [{ type: 'AutoanswerMaterial', id: 'LIST' }]
          : [],
    }),
    // обновление материала для автоответов
    updateMaterialForAutoAnswer: builder.mutation<
      ISuccessResponse<unknown>,
      IUpdateMaterialRequest
    >({
      query: ({ spaceId, body, materialId }) => ({
        url: `/spaces/${spaceId}/auto-offers/materials/${materialId}`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: (result) =>
        result && result.success
          ? [{ type: 'AutoanswerMaterial', id: 'LIST' }]
          : [],
    }),
    // список материалов для автоответов
    materialsAutoAnswerList: builder.query<IMaterialsListResponse, number>({
      query: (spaceId) => ({
        url: `/spaces/${spaceId}/auto-offers/materials`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result && result.success
          ? [{ type: 'AutoanswerMaterial', id: 'LIST' }]
          : [],
    }),
    getMaterialsAutoAnswer: builder.query<
      IMaterialItemResponse,
      IMaterialItemRequest
    >({
      query: ({ spaceId, materialId }) => ({
        url: `/spaces/${spaceId}/auto-offers/materials/${materialId}`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result && result.success
          ? [{ type: 'AutoanswerMaterial', id: 'LIST' }]
          : [],
    }),
    // удаление материалов для автоответов
    deleteMaterialsAutoAnswer: builder.mutation({
      query: ({ spaceId, body }) => ({
        url: `/spaces/${spaceId}/auto-offers/materials`,
        method: 'DELETE',
        body: body,
      }),
    }),
    getAutoAnswersCounters: builder.query<
      ISuccessResponse<IAutoanswerCounter[]>,
      number
    >({
      query: (spaceId) => ({
        url: `/spaces/${spaceId}/auto-offers/counters`,
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useGetAutoAnswerListQuery,
  useCreateAutoAnswerMutation,
  useDeleteAutoAnswersMutation,
  useViewAutoAnswerQuery,
  useChangeAutoAnswerMutation,
  useCreateGeometryAutoAnswerMutation,
  useGeometryAutoAnswerListQuery,
  useUpdateGeometryAutoAnswerMutation,
  useCreateMaterialForAutoAnswerMutation,
  useDeleteGeoAutoAnswerMutation,
  useDeleteMaterialsAutoAnswerMutation,
  useMaterialsAutoAnswerListQuery,
  useUpdateMaterialForAutoAnswerMutation,
  useGetMaterialsAutoAnswerQuery,
  useGetAutoAnswersCountersQuery,
} = autoAnswerApiSlice
