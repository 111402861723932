import { EventBus } from '@src/shared/EventBus'

export const LIMIT_CHAT_MESSAGES = 15
export const LIMIT_CHAT_LIST = 20
export const LIMIT_CHAT_ENTITIES = 30

export const AUDIO_VISUALIZATION_ARRAY_LENGTH = 60
export const INDICATOR_WIDTH = 2
export const INDICATOR_WIDTH_MOBILE = 1.3
export const INDICATOR_MARGIN = 1
export const INDICATOR_MARGIN_MOBILE = 0.5
export const MIN_INDICATOR_HEIGHT = 2
export const MAX_INDICATOR_HEIGHT = 24

export const MAX_AUDIO_MESSAGE_LENGTH_SEC = 60
export const RECORD_BUTTON_OUTLINE_THRESHOLD = 15

export const MAX_DOCUMENT_NAME_LENGTH = 30
export const MAX_UPLOADING_FILE_NAME_LENGTH = 20
export const MAX_TEXT_MESSAGE_LENGTH = 4000

export const MULTIPLE_MEDIA_MESSAGE_GAP = 2

export const ATTACH_POPUP_TITLES_MAP = {
  document_content: 'Отправить файлы',
  video_content: 'Отправить медиа-файлы',
  image_content: 'Отправить медиа-файлы',
  driver_content: 'Отправить сотрудников',
  vehicle_content: 'Отправить технику',
}

export const STATUSES_TEXTS_MAP = {
  deal: 'Сделка заключена',
  offer: 'Предложение сформировано',
  task: 'Чат инициирован из задачи',
}

//При какой роли и типе статуса он будет ссылаться на сущность
export const getLinkFromStatus = ({
  entityId,
  currentRole,
  projectId,
  statusType,
}: {
  entityId: number
  currentRole: 'customer' | 'executor'
  projectId: number
  statusType: 'deal' | 'offer' | 'task'
}) => {
  if (currentRole === 'customer' && statusType === 'deal') {
    return `/space/${localStorage.getItem(
      'ownSpaceId'
    )}/deal/${entityId}/general`
  }
  if (currentRole === 'customer' && statusType === 'task') {
    return `/space/${localStorage.getItem(
      'ownSpaceId'
    )}/myApplications/${projectId}/tasks/${entityId}`
  }
  if (currentRole === 'executor' && statusType === 'task') {
    return `/space/${localStorage.getItem('ownSpaceId')}/tasks/${entityId}`
  }
  if (currentRole === 'executor' && statusType === 'offer') {
    return `/space/${localStorage.getItem('ownSpaceId')}/offers/executor`
  }
  return ''
}

export const letters = [
  'А',
  'Б',
  'В',
  'Г',
  'Д',
  'Е',
  'Ё',
  'Ж',
  'З',
  'И',
  'Й',
  'К',
  'Л',
  'М',
  'Н',
  'О',
  'П',
  'Р',
  'С',
  'Т',
  'У',
  'Ф',
  'Х',
  'Ц',
  'Ч',
  'Ш',
  'Щ',
  'Э',
  'Ю',
  'Я',

  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'Z',
  'X',
  'Y',
]

const getHueParamForLetters = (letters: string[]) => {
  const result = {}
  const step = Math.trunc(360 / letters.length)
  let counter = 1 - step
  letters.forEach((item) => {
    //@ts-ignore
    result[item] = counter + step
    counter += step
  })
  return result
}

export const HUE_AVATAR_LETTERS_MAP = getHueParamForLetters(letters)

//Нельзя переопределить импортируемые переменные, поэтому используем рефоподобный объект.
//В redux также нельзя положить эти данные, т.к. свойства рефа аудио в процессе воспроизведения постоянно меняются,
//и из-за использования immer возникают постоянные обновления и вылет страницы.
export const currentPlayingAudio: {
  current: HTMLAudioElement | null
} = {
  current: null,
}

export const audioBuffer: {
  current: any | null
} = {
  current: null,
}

export const audioContext: {
  current: any | null
} = {
  //@ts-ignore
  current: new (window.AudioContext || window.webkitAudioContext)(),
}

export const WS_CONNECTION_TRY_COUNT = 5

export const wsConnectionTryRef: {
  current: number
} = {
  current: WS_CONNECTION_TRY_COUNT,
}

export const CHAT_MOBILE_BREAKPOINT = 1241

export const chatEventBus = new EventBus()

export const CHAT_MENU_SHIFT_LEFT = 180
export const CHAT_MENU_SHIFT_TOP = 100
