export const FileFodt = () => {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.3"
        d="M13.967 4.92409C13.4203 4.92356 12.8962 4.70614 12.5096 4.31955C12.123 3.93295 11.9056 3.40877 11.905 2.86205V0H2.69101C1.97748 -4.92673e-08 1.29316 0.283384 0.788531 0.787837C0.283898 1.29229 0.000265153 1.97651 0 2.69005V18.9123C0.000795073 19.6255 0.284662 20.3092 0.789238 20.8132C1.29381 21.3173 1.97783 21.6004 2.69101 21.6004H13.751C14.1045 21.6005 14.4546 21.531 14.7812 21.3958C15.1078 21.2606 15.4045 21.0624 15.6545 20.8125C15.9045 20.5626 16.1028 20.2659 16.2381 19.9394C16.3734 19.6128 16.4431 19.2628 16.4431 18.9093V4.92409H13.967Z"
        fill="#FFB9BD"
      />
      <path
        d="M8.29974 14.5873C8.17466 14.5873 8.05468 14.5378 7.96624 14.4498C7.8778 14.3618 7.82813 14.2424 7.82813 14.1179V13.4012C7.82812 13.3397 7.84035 13.2787 7.86407 13.2219C7.88779 13.165 7.92256 13.1134 7.96637 13.0699C8.01019 13.0265 8.06218 12.9921 8.11939 12.9687C8.17659 12.9453 8.23789 12.9334 8.29974 12.9336C8.42433 12.9336 8.54381 12.9829 8.63191 13.0706C8.72002 13.1583 8.7695 13.2772 8.7695 13.4012V14.1179C8.76974 14.1795 8.75776 14.2404 8.73426 14.2974C8.71076 14.3543 8.67622 14.4061 8.63257 14.4497C8.58892 14.4933 8.53702 14.5279 8.47991 14.5515C8.42279 14.5751 8.36158 14.5873 8.29974 14.5873Z"
        fill="#C4000A"
      />
      <path
        d="M8.29974 8.77556C8.23789 8.77579 8.17659 8.76387 8.11939 8.74048C8.06218 8.71708 8.01019 8.68268 7.96637 8.63924C7.92256 8.59579 7.88779 8.54415 7.86407 8.4873C7.84035 8.43044 7.82812 8.36949 7.82813 8.30793V7.46942C7.82813 7.34492 7.8778 7.22554 7.96624 7.1375C8.05468 7.04947 8.17466 7 8.29974 7C8.36158 7 8.42279 7.01217 8.47991 7.03578C8.53702 7.05939 8.58892 7.094 8.63257 7.13761C8.67622 7.18123 8.71076 7.23298 8.73426 7.28992C8.75776 7.34686 8.76974 7.40786 8.7695 7.46942V8.30793C8.7695 8.43196 8.72002 8.5509 8.63191 8.6386C8.54381 8.7263 8.42433 8.77556 8.29974 8.77556Z"
        fill="#C4000A"
      />
      <path
        d="M9.96885 14.138C9.88621 14.1382 9.80493 14.1168 9.73322 14.0759C9.66151 14.035 9.60189 13.9761 9.56025 13.9051L9.20922 13.2995C9.14772 13.1906 9.13215 13.062 9.16589 12.9417C9.19964 12.8214 9.27996 12.7194 9.38922 12.6581C9.44322 12.6278 9.50272 12.6084 9.56425 12.601C9.62578 12.5936 9.68817 12.5984 9.74785 12.6151C9.80753 12.6317 9.8633 12.6599 9.91203 12.698C9.96076 12.7361 10.0015 12.7834 10.0318 12.8372L10.3918 13.4428C10.4222 13.4966 10.4417 13.5558 10.4491 13.617C10.4565 13.6782 10.4517 13.7403 10.435 13.7998C10.4183 13.8592 10.39 13.9147 10.3517 13.9632C10.3134 14.0117 10.2659 14.0522 10.2118 14.0824C10.1375 14.1227 10.0534 14.142 9.96885 14.138Z"
        fill="#C4000A"
      />
      <path
        d="M7.07133 9.12423C6.98855 9.12408 6.90726 9.10234 6.83557 9.06115C6.76387 9.01997 6.70428 8.9608 6.66273 8.88953L6.23256 8.14598C6.20215 8.09223 6.18267 8.03303 6.17526 7.97178C6.16785 7.91053 6.17265 7.84843 6.18936 7.78903C6.20608 7.72962 6.23438 7.67409 6.27268 7.62558C6.31098 7.57708 6.35852 7.53657 6.41256 7.50636C6.4665 7.47585 6.52596 7.45628 6.58754 7.44878C6.64913 7.44127 6.71162 7.44599 6.77136 7.46264C6.83111 7.4793 6.88692 7.50756 6.93563 7.54582C6.98434 7.58407 7.02494 7.63157 7.05511 7.68553L7.48533 8.42908C7.51574 8.48284 7.53522 8.54201 7.54263 8.60326C7.55004 8.66451 7.54524 8.72661 7.52853 8.78602C7.51181 8.84542 7.48351 8.90098 7.44521 8.94948C7.40691 8.99799 7.35937 9.03849 7.30533 9.0687C7.23333 9.10666 7.1528 9.12576 7.07133 9.12423Z"
        fill="#C4000A"
      />
      <path
        d="M11.1942 12.922C11.1114 12.9224 11.03 12.9007 10.9585 12.8593L10.3501 12.501C10.296 12.4707 10.2485 12.4302 10.2102 12.3817C10.1719 12.3332 10.1436 12.2777 10.1269 12.2183C10.1101 12.1589 10.1053 12.0968 10.1128 12.0355C10.1202 11.9743 10.1396 11.9151 10.1701 11.8613C10.2002 11.8073 10.2408 11.7599 10.2895 11.7216C10.3383 11.6834 10.3941 11.6551 10.4539 11.6384C10.5136 11.6218 10.576 11.6171 10.6376 11.6246C10.6992 11.6321 10.7587 11.6516 10.8127 11.6821L11.421 12.0405C11.4752 12.0705 11.523 12.111 11.5614 12.1594C11.5998 12.2079 11.6282 12.2635 11.645 12.323C11.6617 12.3824 11.6664 12.4446 11.6589 12.5059C11.6514 12.5672 11.6317 12.6264 11.601 12.6801C11.561 12.7528 11.5022 12.8136 11.4307 12.8561C11.3591 12.8986 11.2775 12.9214 11.1942 12.922Z"
        fill="#C4000A"
      />
      <path
        d="M6.15314 10.0265C6.07033 10.0269 5.98896 10.0052 5.91738 9.96377L5.17037 9.53556C5.11633 9.50535 5.06879 9.46484 5.03049 9.41634C4.9922 9.36784 4.96389 9.31228 4.94718 9.25288C4.93046 9.19347 4.92566 9.13137 4.93307 9.07012C4.94048 9.00887 4.95996 8.94969 4.99037 8.89594C5.05201 8.78718 5.15449 8.70721 5.27531 8.67362C5.39613 8.64003 5.52544 8.65555 5.63477 8.71677L6.37994 9.14499C6.4892 9.20634 6.56956 9.30834 6.60331 9.42861C6.63706 9.54888 6.62144 9.67756 6.55994 9.7864C6.51951 9.85865 6.46059 9.91893 6.38913 9.9611C6.31766 10.0033 6.23622 10.0258 6.15314 10.0265Z"
        fill="#C4000A"
      />
      <path
        d="M11.6429 11.2613H10.9229C10.861 11.2613 10.7998 11.2491 10.7427 11.2255C10.6856 11.2019 10.6338 11.1673 10.5901 11.1237C10.5465 11.0801 10.5119 11.0283 10.4884 10.9713C10.4649 10.9144 10.4529 10.8534 10.4531 10.7918C10.4531 10.6678 10.5026 10.5489 10.5907 10.4612C10.6788 10.3735 10.7983 10.3242 10.9229 10.3242H11.6429C11.7047 10.324 11.766 10.3359 11.8232 10.3593C11.8804 10.3827 11.9324 10.4171 11.9762 10.4605C12.0201 10.504 12.0548 10.5556 12.0785 10.6125C12.1023 10.6693 12.1145 10.7303 12.1145 10.7918C12.1145 10.9163 12.0648 11.0358 11.9764 11.1238C11.8879 11.2118 11.768 11.2613 11.6429 11.2613Z"
        fill="#C4000A"
      />
      <path
        d="M5.81218 11.2613H4.96981C4.90797 11.2613 4.84671 11.2491 4.7896 11.2255C4.73248 11.2019 4.68063 11.1673 4.63698 11.1237C4.59333 11.0801 4.55875 11.0283 4.53525 10.9713C4.51174 10.9144 4.49977 10.8534 4.5 10.7918C4.5 10.7304 4.51217 10.6696 4.53577 10.6129C4.55938 10.5562 4.59397 10.5046 4.63759 10.4612C4.68122 10.4178 4.73304 10.3833 4.79004 10.3598C4.84703 10.3363 4.90812 10.3242 4.96981 10.3242H5.81218C5.93678 10.3242 6.05626 10.3735 6.14436 10.4612C6.23246 10.5489 6.28199 10.6678 6.28199 10.7918C6.28223 10.8534 6.27025 10.9144 6.24675 10.9713C6.22325 11.0283 6.18867 11.0801 6.14502 11.1237C6.10137 11.1673 6.04952 11.2019 5.9924 11.2255C5.93529 11.2491 5.87403 11.2613 5.81218 11.2613Z"
        fill="#C4000A"
      />
      <path
        d="M10.5318 9.99034C10.449 9.99019 10.3677 9.96845 10.296 9.92727C10.2243 9.88608 10.1647 9.82689 10.1232 9.75562C10.0928 9.70187 10.0733 9.64269 10.0659 9.58144C10.0585 9.52019 10.0633 9.45809 10.08 9.39869C10.0967 9.33928 10.125 9.28373 10.1633 9.23522C10.2016 9.18672 10.2491 9.14621 10.3032 9.116L10.9638 8.73617C11.0178 8.7059 11.0773 8.68653 11.1388 8.67915C11.2003 8.67177 11.2627 8.67653 11.3224 8.69317C11.3821 8.70981 11.4379 8.73801 11.4866 8.77613C11.5353 8.81425 11.576 8.86155 11.6064 8.91534C11.637 8.96903 11.6567 9.02824 11.6642 9.08954C11.6718 9.15084 11.667 9.21302 11.6503 9.27249C11.6336 9.33196 11.6051 9.38754 11.5667 9.43602C11.5283 9.48451 11.4806 9.52492 11.4264 9.55496L10.7658 9.93479C10.6938 9.97275 10.6132 9.99187 10.5318 9.99034Z"
        fill="#C4000A"
      />
      <path
        d="M5.40849 12.9209C5.32591 12.9209 5.24479 12.8993 5.17335 12.8581C5.1019 12.8168 5.04267 12.7575 5.0017 12.6861C4.97044 12.633 4.95008 12.5743 4.94176 12.5133C4.93344 12.4524 4.93733 12.3903 4.95323 12.3309C4.96913 12.2714 4.99672 12.2156 5.03439 12.1668C5.07206 12.118 5.11905 12.0771 5.17269 12.0465L5.8693 11.647C5.92324 11.6165 5.9827 11.5969 6.04428 11.5894C6.10587 11.5819 6.16836 11.5866 6.2281 11.6033C6.28785 11.6199 6.34366 11.6482 6.39237 11.6864C6.44107 11.7247 6.48168 11.7722 6.51185 11.8262C6.54226 11.8799 6.56174 11.9391 6.56916 12.0004C6.57657 12.0616 6.57177 12.1237 6.55505 12.1831C6.53833 12.2425 6.51003 12.2981 6.47173 12.3466C6.43343 12.3951 6.38589 12.4356 6.33186 12.4658L5.63709 12.8671C5.56664 12.9039 5.48802 12.9224 5.40849 12.9209Z"
        fill="#C4000A"
      />
      <path
        d="M8.35503 13.8655H8.27764C7.74022 13.8539 7.21596 13.6979 6.76029 13.414C6.30619 13.1365 5.93367 12.7447 5.68031 12.2782C5.44353 11.8372 5.31987 11.3449 5.32031 10.8449V10.7678C5.33341 10.2558 5.47774 9.75567 5.73968 9.31484C6.00165 8.87068 6.37343 8.50063 6.81965 8.23988C7.26525 7.97957 7.77013 7.83653 8.28665 7.82423H8.35503C8.85748 7.82267 9.3524 7.94582 9.795 8.18255C10.2637 8.43481 10.6573 8.80559 10.9362 9.25751C11.2206 9.71469 11.3772 10.2392 11.3898 10.7768V10.8467C11.3902 11.3666 11.255 11.8777 10.9974 12.3301C10.7275 12.8057 10.3323 13.1991 9.85441 13.4678C9.39795 13.7281 8.88109 13.8652 8.35503 13.8655ZM8.34246 8.77556H8.31544C7.95742 8.78153 7.60707 8.87966 7.29847 9.06042C6.99231 9.23979 6.73707 9.49382 6.55687 9.79856C6.37649 10.1037 6.27739 10.4497 6.2689 10.8037V10.852C6.26871 11.1945 6.35339 11.5317 6.51547 11.8338C6.68924 12.1546 6.9443 12.4245 7.25528 12.6168C7.57153 12.812 7.9344 12.9196 8.30643 12.9285H8.35503C8.71519 12.9287 9.0693 12.8361 9.38285 12.6598C9.7123 12.4742 9.98481 12.203 10.1712 11.875C10.348 11.5628 10.4409 11.2104 10.4412 10.852V10.809C10.4331 10.4372 10.3256 10.0742 10.1298 9.75736C9.936 9.4477 9.6642 9.19386 9.34141 9.02101C9.03395 8.85817 8.69073 8.77383 8.34246 8.77556Z"
        fill="#C4000A"
      />
      <path
        d="M9.56844 9.07669C9.48623 9.07685 9.40545 9.05521 9.33443 9.01398C9.28039 8.98377 9.23285 8.94324 9.19456 8.89474C9.15626 8.84624 9.12796 8.7907 9.11124 8.7313C9.09452 8.67189 9.08972 8.60979 9.09713 8.54854C9.10454 8.4873 9.12402 8.4281 9.15444 8.37434L9.55763 7.68096C9.58797 7.62717 9.62865 7.57987 9.67737 7.54175C9.7261 7.50363 9.78192 7.47544 9.8416 7.4588C9.90127 7.44216 9.96366 7.4374 10.0252 7.44478C10.0867 7.45216 10.1462 7.47152 10.2002 7.5018C10.2542 7.532 10.3018 7.57251 10.3401 7.62101C10.3784 7.66952 10.4067 7.72507 10.4234 7.78448C10.4401 7.84388 10.4449 7.90598 10.4375 7.96723C10.4301 8.02848 10.4106 8.08766 10.3802 8.14141L9.97699 8.83659C9.93656 8.90922 9.8774 8.96978 9.80556 9.01199C9.73372 9.0542 9.65186 9.07655 9.56844 9.07669Z"
        fill="#C4000A"
      />
      <path
        d="M6.63094 14.1406C6.54873 14.1408 6.46795 14.1191 6.39693 14.0779C6.34289 14.0477 6.29535 14.0072 6.25706 13.9587C6.21876 13.9102 6.19046 13.8546 6.17374 13.7952C6.15702 13.7358 6.15222 13.6737 6.15963 13.6125C6.16704 13.5512 6.18652 13.492 6.21694 13.4383L6.59675 12.7825C6.62709 12.7287 6.66777 12.6814 6.7165 12.6433C6.76522 12.6052 6.82104 12.577 6.88072 12.5604C6.9404 12.5437 7.00278 12.539 7.06432 12.5463C7.12585 12.5537 7.1853 12.5731 7.2393 12.6034C7.29334 12.6336 7.34088 12.6741 7.37918 12.7226C7.41748 12.7711 7.44578 12.8266 7.4625 12.886C7.47921 12.9454 7.48402 13.0075 7.4766 13.0688C7.46919 13.13 7.44971 13.1892 7.4193 13.243L7.03953 13.9005C6.99853 13.9727 6.9392 14.0328 6.86749 14.075C6.79578 14.1171 6.7142 14.1398 6.63094 14.1406Z"
        fill="#C4000A"
      />
      <path
        d="M16.4443 4.92409H13.9683C13.4215 4.92356 12.8974 4.70614 12.5108 4.31955C12.1242 3.93295 11.9068 3.40877 11.9062 2.86205V0L16.4443 4.92409Z"
        fill="#C4000A"
      />
      <path
        d="M18.3913 17.0586H5.88726C5.00028 17.0586 4.28125 17.7776 4.28125 18.6646V22.3927C4.28125 23.2797 5.00028 23.9987 5.88726 23.9987H18.3913C19.2783 23.9987 19.9973 23.2797 19.9973 22.3927V18.6646C19.9973 17.7776 19.2783 17.0586 18.3913 17.0586Z"
        fill="#C4000A"
      />
      <path d="M7.07329 21.2734V21.9978H6V21.2734H7.07329Z" fill="white" />
      <path
        d="M9.49226 18.9922V19.5712H8.25926V20.2301H9.21126V20.7831H8.25926V21.9991H7.53125V18.9922H9.49226Z"
        fill="white"
      />
      <path
        d="M12.1759 19.1532C12.4047 19.2831 12.5938 19.4729 12.7229 19.7021C12.8582 19.9442 12.9272 20.2177 12.9229 20.495C12.9279 20.7731 12.8589 21.0474 12.7229 21.29C12.594 21.5203 12.4045 21.7108 12.1749 21.8409C11.9411 21.9744 11.6761 22.0434 11.4069 22.0409C11.1377 22.0434 10.8727 21.9744 10.6389 21.8409C10.4093 21.7108 10.2197 21.5203 10.0909 21.29C9.95487 21.0474 9.88584 20.7731 9.8909 20.495C9.88571 20.2176 9.95476 19.9439 10.0909 19.7021C10.2204 19.4728 10.4098 19.2831 10.6389 19.1532C10.8727 19.0197 11.1377 18.9507 11.4069 18.9532C11.6764 18.9508 11.9417 19.0198 12.1759 19.1532ZM10.8359 19.8631C10.6884 20.0395 10.6133 20.2654 10.6259 20.495C10.6133 20.7238 10.6884 20.9487 10.8359 21.124C10.9078 21.2021 10.9958 21.2635 11.094 21.3039C11.1921 21.3443 11.2979 21.3628 11.4039 21.358C11.5096 21.3623 11.6149 21.3437 11.7127 21.3033C11.8104 21.2629 11.8982 21.2017 11.9699 21.124C12.1181 20.949 12.1939 20.724 12.1819 20.495C12.1939 20.2664 12.1189 20.0417 11.9719 19.8661C11.9001 19.788 11.812 19.7265 11.7139 19.6861C11.6157 19.6457 11.5099 19.6272 11.4039 19.6321C11.2981 19.6273 11.1926 19.6454 11.0945 19.6853C10.9965 19.7252 10.9082 19.7858 10.8359 19.8631Z"
        fill="white"
      />
      <path
        d="M15.8441 21.2745C15.7186 21.5016 15.5296 21.6872 15.3001 21.8084C15.0417 21.9406 14.7543 22.0062 14.4641 21.9994H13.3281V18.9927H14.4641C14.7545 18.9857 15.0423 19.0498 15.3021 19.1797C15.5309 19.2984 15.7194 19.4821 15.8441 19.7076C15.9734 19.9477 16.0385 20.217 16.0331 20.4896C16.0383 20.7631 15.9732 21.0333 15.8441 21.2745ZM15.0621 21.1235C15.1434 21.0401 15.2064 20.9406 15.247 20.8314C15.2877 20.7223 15.3051 20.6058 15.2981 20.4896C15.3051 20.3734 15.2877 20.2571 15.2471 20.1481C15.2064 20.0391 15.1434 19.9398 15.0621 19.8566C14.8799 19.6969 14.642 19.6157 14.4001 19.6306H14.0561V21.3485H14.4001C14.6418 21.3633 14.8795 21.2825 15.0621 21.1235Z"
        fill="white"
      />
      <path
        d="M18.7867 18.9922V19.5712H17.9697V21.9992H17.2387V19.5712H16.4297V18.9922H18.7867Z"
        fill="white"
      />
    </svg>
  )
}
