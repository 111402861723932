import IconPropsInterface from './IconPropsInterface'

export const PinnedOn = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 20 20"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M9.4987 1.75L6.16536 5.08333L2.83203 6.33333L1.58203 7.58333L7.41536 13.4167L8.66536 12.1667L9.91536 8.83333L13.2487 5.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 10.5L0.75 14.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.08203 1.33398L13.6654 5.91732"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
