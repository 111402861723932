import IconPropsInterface from '@icons/IconPropsInterface'

export const Moderation = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6465_60586)">
        <path
          d="M8.33229 17.3143C7.60696 17.1498 6.91036 16.8776 6.26562 16.5068"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.668 2.68555C13.3248 3.06394 14.804 3.99362 15.8635 5.32239C16.923 6.65115 17.5 8.30026 17.5 9.99971C17.5 11.6992 16.923 13.3483 15.8635 14.677C14.804 16.0058 13.3248 16.9355 11.668 17.3139"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.81547 14.244C3.36089 13.5835 3.01564 12.8542 2.79297 12.084"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.60156 8.74993C2.7349 7.95827 2.99156 7.20827 3.35156 6.52077L3.4924 6.2666"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.75391 3.81555C6.53362 3.2788 7.40834 2.89531 8.33141 2.68555"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 6.66699V10.0003L12.5 12.5003"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6465_60586">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
