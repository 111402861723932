import {
  BaseQueryApi,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/dist/query/react'
import { RootState, store } from '@src/store'
import { reAuthQuery, refreshToken } from '@src/store/slices/baseAuth'
import { ACCESS_TOKEN, baseClientURL } from '@src/utils/constants/auth'

export const baseClientQuery = fetchBaseQuery({
  baseUrl: baseClientURL,
  prepareHeaders: (headers, { getState }) => {
    const state = getState() as RootState
    const token = state.reg.access_token || refreshToken || ACCESS_TOKEN
    const spaceId =
      state.profile.currentSpaceId || localStorage.getItem('ownSpaceId')
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
      headers.set('x-space-id', String(spaceId))
      const timezoneAuto = sessionStorage.getItem('timezone-auto')
      timezoneAuto && headers.set('Timezone', String(timezoneAuto))
    }
    return headers
  },
})

export const clientApiSlice = createApi({
  reducerPath: 'clientApi',
  tagTypes: [
    'Profile',
    'Objects',
    'Object',
    'Contracts',
    'Contract',
    'Application',
    'ApplicationTabs',
    'Autoanswer',
    'AutoanswerMaterial',
    'Space',
    'Partnership',
    'Account',
    'Balance',
    'PaidFeatures',
    'Favorites',
    'AutoSearch',
  ],
  baseQuery: reAuthQuery(baseClientQuery),
  endpoints: (builder) => {
    return {}
  },
})
