import {
  CreateBillRequest,
  CreateBillResponse,
  CreateVerifyByUUIDRequest,
  FormOfOwnership,
  IFindOwnershipTypeByInnResponse,
  IFindOwnershipTypeByNameRequest,
  IFindOwnershipTypeByNameResponse,
  IIndividual,
  IJuridical,
  IPhysicalResponse,
  UpdateIPRequest,
  UpdateJuridicalRequest,
  UpdateJuridicalVerifyRequest,
  UpdateLogoRequest,
  VerificationByESignRequest,
  VerifyPhysicalByUUIDRequest,
  VerifyPhysicalRequest,
} from '@pages/Space/OwnershipTypes/interfaces'
import {
  BankAccountItem,
  CreateBankAccountRequest,
} from '@pages/Space/OwnershipTypes/interfaces/BankAccountTypes'
import {
  CreatePhysicalRequest,
  CreatePhysicalResponse,
  CreateVerificationRequest,
  EditPhysicalRequest,
} from '@pages/Space/OwnershipTypes/interfaces/PhysicalPersonTypes'
import { ISuccessResponse } from '@src/shared/interfaces/apiResponse/ISuccessResponse'
import { clientApiSlice } from '@src/store/slices/clientApiSlice'
import { formatArrayToGetParams } from '@src/utils/helpers/formatArrayToGetParams'

export const ownershipApiSlice = clientApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // создание физ лица
    createPhysical: builder.mutation<
      CreatePhysicalResponse,
      CreatePhysicalRequest
    >({
      query: ({ spaceId, body }) => ({
        url: `/spaces/${spaceId}/ownership-types/verification/create`,
        method: 'POST',
        body: body,
      }),
    }),
    getIsVerifyUser: builder.query<
      ISuccessResponse<IPhysicalResponse>,
      { spaceId?: string | null }
    >({
      query: ({ spaceId }) => ({
        url: `/spaces/${spaceId}/ownership-types/verification/view`,
      }),
    }),
    getOwnersipData: builder.query<
      ISuccessResponse<FormOfOwnership[]>,
      { spaceId?: string }
    >({
      query: ({ spaceId }) => ({
        url: `/spaces/${spaceId}/ownership-types`,
      }),
    }),
    // обновление не вериф.физ лица
    updatePhysical: builder.mutation<unknown, EditPhysicalRequest>({
      query: ({ spaceId, body }) => ({
        url: `/spaces/${spaceId}/ownership-types/verification/update`,
        method: 'PATCH',
        body: body,
      }),
    }),
    // обновление вериф.физ лица
    updateVerifPhysical: builder.mutation<unknown, EditPhysicalRequest>({
      query: ({ spaceId, body, ownershipId }) => ({
        url: `/spaces/${spaceId}/ownership-types/verification/${ownershipId}/renew`,
        method: 'PUT',
        body: body,
      }),
    }),
    // обновление имени ФС
    nameEdit: builder.mutation({
      query: ({ spaceId, body, ownershipId }) => ({
        url: `/spaces/${spaceId}/ownership-types/edit/${ownershipId}`,
        method: 'PATCH',
        body: body,
      }),
    }),
    //  отправка запроса на верификацию ФС
    createVerification: builder.mutation<unknown, CreateVerificationRequest>({
      query: ({ spaceId, body, ownershipId }) => ({
        url: `/spaces/${spaceId}/ownership-types/${ownershipId}/verification/start`,
        method: 'POST',
        body: body,
      }),
    }),
    // отмена запроса на верификацию ФС
    cancelVerification: builder.mutation<
      unknown,
      { spaceId?: string; ownershipId?: string | number }
    >({
      query: ({ spaceId, ownershipId }) => ({
        url: `/spaces/${spaceId}/ownership-types/${ownershipId}/verification/cancel`,
        method: 'POST',
      }),
    }),
    updateNewLogoPhysical: builder.mutation<
      unknown,
      { spaceId?: string; body: { logo: string } }
    >({
      query: ({ spaceId, body }) => ({
        url: `/spaces/${spaceId}/ownership-types/verification/logo-update`,
        method: 'POST',
        body: body,
      }),
    }),
    deleteOwnership: builder.mutation<
      unknown,
      { spaceId?: string; ownershipTypeId: number }
    >({
      query: ({ spaceId, ownershipTypeId }) => ({
        url: `/spaces/${spaceId}/ownership-types/${ownershipTypeId}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [{ type: 'Partnership', id: 'PERSONAL' }],
    }),
    findByInn: builder.mutation<
      IFindOwnershipTypeByInnResponse,
      { spaceId?: string; body: { inn: string } }
    >({
      query: ({ spaceId, body }) => ({
        url: `/spaces/${spaceId}/ownership-types/find-by-inn`,
        method: 'POST',
        body: body,
      }),
    }),
    createIp: builder.mutation<
      ISuccessResponse<{ id: number }>,
      { spaceId?: string; body: IIndividual }
    >({
      query: ({ spaceId, body }) => ({
        url: `/spaces/${spaceId}/ownership-types/individual-entrepreneur`,
        method: 'POST',
        body: body,
      }),
    }),
    getIP: builder.query<
      ISuccessResponse<IPhysicalResponse>,
      { spaceId?: string }
    >({
      query: ({ spaceId }) => ({
        url: `/spaces/${spaceId}/ownership-types/individual-entrepreneur/view`,
        method: 'GET',
      }),
    }),
    createJuridical: builder.mutation<
      ISuccessResponse<{ id: number }>,
      { spaceId?: string; body: IJuridical }
    >({
      query: ({ spaceId, body }) => ({
        url: `/spaces/${spaceId}/ownership-types/juridical/create`,
        method: 'POST',
        body: body,
      }),
    }),
    createPayBill: builder.mutation<CreateBillResponse, CreateBillRequest>({
      query: ({ spaceId, ownershipTypeId, body }) => ({
        url: `/spaces/${spaceId}/ownership-types/${ownershipTypeId}/verification/bill`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: () => [{ type: 'Partnership', id: 'PERSONAL' }],
    }),
    // обновление не верифицированного ИП
    updateNoVerifIp: builder.mutation<
      unknown,
      { spaceId?: string; body: IIndividual; parentId?: number }
    >({
      query: ({ spaceId, body }) => ({
        url: `/spaces/${spaceId}/ownership-types/individual-entrepreneur/update`,
        method: 'PATCH',
        body: body,
      }),
    }),
    // обновление верифицированного ИП
    updateIp: builder.mutation<unknown, UpdateIPRequest>({
      query: ({ spaceId, body, ownershipId }) => ({
        url: `/spaces/${spaceId}/ownership-types/individual-entrepreneur/${ownershipId}/renew`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: () => [{ type: 'Partnership', id: 'PERSONAL' }],
    }),
    // обновление не верифицированного ООО
    updateJuridical: builder.mutation<unknown, UpdateJuridicalRequest>({
      query: ({ spaceId, body, juridicalId }) => ({
        url: `/spaces/${spaceId}/ownership-types/juridical/${juridicalId}/update`,
        method: 'PATCH',
        body: body,
      }),
    }),
    // обновление  верифицированного ООО
    updateVerifyJuridical: builder.mutation<
      unknown,
      UpdateJuridicalVerifyRequest
    >({
      query: ({ spaceId, body, parentId }) => ({
        url: `/spaces/${spaceId}/ownership-types/juridical/${parentId}/renew`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: () => [{ type: 'Partnership', id: 'PERSONAL' }],
    }),
    // получить хеш-строку для подписи
    addStringForSignature: builder.query({
      query: ({ spaceId, ownershipTypeId }) => ({
        url: `/spaces/${spaceId}/ownership-types/${ownershipTypeId}/digital-signature/hash-to-sign`,
        method: 'GET',
      }),
    }),
    // просмотр форм. собств. ООО
    viewJuridicalOwnership: builder.query<
      ISuccessResponse<FormOfOwnership>,
      { spaceId?: string; juridicalId?: string }
    >({
      query: ({ spaceId, juridicalId }) => ({
        url: `/spaces/${spaceId}/ownership-types/juridical/${juridicalId}`,
        method: 'GET',
      }),
    }),
    validateSignature: builder.query({
      query: ({ spaceId, ownershipTypeId }) => ({
        url: `/spaces/${spaceId}/ownership-types/${ownershipTypeId}/digital-signature/verify-by-signature`,
        method: 'GET',
      }),
    }),
    createBankAccount: builder.mutation<unknown, CreateBankAccountRequest>({
      query: ({ spaceId, ownershipTypeId, body }) => ({
        url: `/spaces/${spaceId}/ownership-types/${ownershipTypeId}/bank-accounts/create`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Account', id: arg.ownershipTypeId },
      ],
    }),
    updateImagesEntrepreneur: builder.mutation<
      unknown,
      {
        spaceId?: string
        body: { passport_photo?: string; selfie?: string }
      }
    >({
      query: ({ spaceId, body }) => ({
        url: `/spaces/${spaceId}/ownership-types/verification/attach-picture`,
        method: 'PATCH',
        body: body,
      }),
    }),
    getBankAccountsList: builder.query<
      ISuccessResponse<BankAccountItem[]>,
      { spaceId?: string; ownershipTypeId?: string }
    >({
      query: ({ spaceId, ownershipTypeId }) => ({
        url: `/spaces/${spaceId}/ownership-types/${ownershipTypeId}/bank-accounts/index`,
        method: 'GET',
      }),
    }),
    verifyPhysical: builder.mutation<unknown, VerifyPhysicalRequest>({
      query: ({ spaceId, ownershipTypeId, body }) => ({
        url: `/spaces/${spaceId}/ownership-types/verification/by-photo/${ownershipTypeId}/attach`,
        method: 'POST',
        body,
      }),
    }),
    getPublicAccessUUID: builder.mutation<
      ISuccessResponse<{ key: string }>,
      { spaceId?: string; ownershipTypeId: number }
    >({
      query: ({ spaceId, ownershipTypeId }) => ({
        url: `/spaces/${spaceId}/ownership-types/verification/by-photo/${ownershipTypeId}/public-access`,
        method: 'POST',
      }),
    }),
    verifyByUUID: builder.mutation<unknown, VerifyPhysicalByUUIDRequest>({
      query: ({ uuid, body }) => ({
        url: `/public-access/${uuid}/verification`,
        method: 'POST',
        body,
      }),
    }),
    createVerifyByUUID: builder.mutation<unknown, CreateVerifyByUUIDRequest>({
      query: ({ uuid, ownershipTypeId, body }) => ({
        url: `/public-access/${uuid}/verification/${ownershipTypeId}/start`,
        method: 'POST',
        body,
      }),
    }),
    verificationESignHash: builder.mutation<
      unknown,
      { spaceId?: string; ownershipTypeId?: string | number }
    >({
      query: ({ spaceId, ownershipTypeId }) => ({
        url: `/spaces/${spaceId}/ownership-types/${ownershipTypeId}/digital-signature/hash-to-sign`,
        method: 'POST',
      }),
    }),
    verificationByESign: builder.mutation<unknown, VerificationByESignRequest>({
      query: ({ spaceId, ownershipTypeId, body }) => ({
        url: `/spaces/${spaceId}/ownership-types/${ownershipTypeId}/digital-signature/verify-by-signature`,
        method: 'POST',
        body,
      }),
    }),
    findOwnershipTypeByName: builder.mutation<
      IFindOwnershipTypeByNameResponse,
      IFindOwnershipTypeByNameRequest
    >({
      query: ({ spaceId, name }) => ({
        url: `/spaces/${spaceId}/ownership-types/find-by-name`,
        method: 'POST',
        body: {
          name,
        },
      }),
    }),
    updateJuridicalLogo: builder.mutation<unknown, UpdateLogoRequest>({
      query: ({ spaceId, body, ownershipTypeId }) => ({
        url: `/spaces/${spaceId}/ownership-types/juridical/${ownershipTypeId}/logo-update`,
        method: 'POST',
        body,
      }),
    }),
    updateIndividualLogo: builder.mutation<unknown, UpdateLogoRequest>({
      query: ({ spaceId, body }) => ({
        url: `/spaces/${spaceId}/ownership-types/individual-entrepreneur/logo-update`,
        method: 'POST',
        body,
      }),
    }),
    getOwnershipTypeCounterDeals: builder.query<
      ISuccessResponse<{ counter: number }>,
      { ownershipTypeId: number; state?: number[] }
    >({
      query: ({ ownershipTypeId, state }) => ({
        url: `/ownership-types/${ownershipTypeId}/counter-deals?${formatArrayToGetParams(
          'state[]',
          state
        )}`,
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useGetIsVerifyUserQuery,
  useLazyGetIsVerifyUserQuery,
  useGetOwnersipDataQuery,
  useDeleteOwnershipMutation,
  useFindByInnMutation,
  useCreateIpMutation,
  useCreatePayBillMutation,
  useUpdateIpMutation,
  useCreateJuridicalMutation,
  useUpdateJuridicalMutation,
  useViewJuridicalOwnershipQuery,
  useCreateBankAccountMutation,
  useGetBankAccountsListQuery,
  useUpdatePhysicalMutation,
  useGetIPQuery,
  useUpdateVerifPhysicalMutation,
  useNameEditMutation,
  useCancelVerificationMutation,
  useCreatePhysicalMutation,
  useUpdateNoVerifIpMutation,
  useUpdateVerifyJuridicalMutation,
  useCreateVerificationMutation,
  useUpdateNewLogoPhysicalMutation,
  useUpdateImagesEntrepreneurMutation,
  useGetPublicAccessUUIDMutation,
  useCreateVerifyByUUIDMutation,
  useVerifyByUUIDMutation,
  useVerifyPhysicalMutation,
  useVerificationESignHashMutation,
  useVerificationByESignMutation,
  useFindOwnershipTypeByNameMutation,
  useUpdateJuridicalLogoMutation,
  useUpdateIndividualLogoMutation,
  useGetOwnershipTypeCounterDealsQuery,
} = ownershipApiSlice
