import { Reducer } from '@reduxjs/toolkit'

export const decrementGroupNewMessagesCountReducer: Reducer = (state) => {
  if (state.currentChatGroup) {
    //для декремента в табах
    state.currentChatGroup.new_message_cnt -= 1
    //для декремента в заголовках групп
    const currentGroup = state.chatListProjectGroups.find(
      (item: any) => item.id === state.currentChatGroup?.id
    )
    if (currentGroup) {
      currentGroup.new_message_cnt -= 1
    }
  }
}
