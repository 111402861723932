import IconPropsInterface from '@icons/IconPropsInterface'

export const Moon = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="moon-svg"
    >
      <g clipPath="url(#clip0_8794_68016)">
        <path
          d="M12.5476 3.05029C12.651 3.08791 12.7536 3.12525 12.8554 3.1623C11.5092 3.73092 10.4048 4.75329 9.73414 6.05171C9.06353 7.35014 8.86911 8.8425 9.18468 10.2694C9.50024 11.6963 10.3058 12.9675 11.4614 13.862C12.617 14.7566 14.0496 15.2179 15.51 15.1658C14.5916 16.1657 13.4189 16.8976 12.1172 17.2833C10.8154 17.669 9.43335 17.6941 8.11843 17.356C6.80351 17.0178 5.60504 16.3291 4.65088 15.3632C3.69671 14.3973 3.02263 13.1905 2.70055 11.8715C2.37846 10.5526 2.42045 9.17095 2.82203 7.87399C3.22361 6.57703 3.96973 5.4134 4.98079 4.50724C5.99185 3.60107 7.22992 2.98636 8.56295 2.72867C9.89598 2.47097 11.274 2.57997 12.5499 3.04403L12.5476 3.05029Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_8794_68016">
          <rect width={size} height={size} fill={color} />
        </clipPath>
      </defs>
    </svg>
  )
}
