import IconPropsInterface from './IconPropsInterface'

export const Close = ({ size = 20, color, style }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 20 20"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{ ...style, flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M13.5355 6.46522L6.46447 13.5363M6.46447 6.46522L13.5355 13.5363"
        strokeLinecap="round"
      />
    </svg>
  )
}
