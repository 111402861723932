import IconPropsInterface from '@icons/IconPropsInterface'

export const MoonFill = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8791_68144)">
        <path
          d="M12.5457 3.05029C12.649 3.08791 12.7516 3.12525 12.8534 3.1623C11.5072 3.73092 10.4028 4.75329 9.73219 6.05171C9.06157 7.35014 8.86715 8.8425 9.18272 10.2694C9.49829 11.6963 10.3039 12.9675 11.4595 13.862C12.6151 14.7566 14.0476 15.2179 15.5081 15.1658C14.5896 16.1657 13.417 16.8976 12.1152 17.2833C10.8135 17.669 9.4314 17.6941 8.11648 17.356C6.80156 17.0178 5.60309 16.3291 4.64892 15.3632C3.69476 14.3973 3.02068 13.1905 2.69859 11.8715C2.37651 10.5526 2.41849 9.17095 2.82008 7.87399C3.22166 6.57703 3.96778 5.4134 4.97883 4.50724C5.98989 3.60107 7.22797 2.98636 8.561 2.72867C9.89403 2.47097 11.272 2.57997 12.5479 3.04403L12.5457 3.05029Z"
          fill={color}
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_8791_68144">
          <rect width={size} height={size} fill={color} />
        </clipPath>
      </defs>
    </svg>
  )
}
