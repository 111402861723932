import IconPropsInterface from './IconPropsInterface'

export const Complex = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill={color}
      stroke="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path d="M3.79989 1.5C3.35898 1.5 3 1.86668 3 2.31703V3.93297C3 4.38332 3.35898 4.75 3.79989 4.75H4.81818V11.7143C4.81818 12.4822 5.43 13.1071 6.18182 13.1071H8.45455V13.6503C8.45455 14.1192 8.82731 14.5 9.2864 14.5H12.1681C12.6272 14.5 13 14.1192 13 13.6503V11.6354C13 11.1665 12.6272 10.7857 12.1681 10.7857H9.2864C8.82731 10.7857 8.45455 11.1665 8.45455 11.6354V12.1786H6.18182C5.93091 12.1786 5.72727 11.9706 5.72727 11.7143V8H8.45455V8.54318C8.45455 9.01211 8.82731 9.39286 9.2864 9.39286H12.1681C12.6272 9.39286 13 9.01211 13 8.54318V6.52825C13 6.05932 12.6272 5.67857 12.1681 5.67857H9.2864C8.82731 5.67857 8.45455 6.05932 8.45455 6.52825V7.07143H5.72727V4.75H6.74556C7.18647 4.75 7.54545 4.38332 7.54545 3.93297V2.31703C7.54545 1.86668 7.18647 1.5 6.74556 1.5H3.79989ZM9.36364 6.60714H12.0909V8.46429H9.36364V6.60714Z" />
    </svg>
  )
}
