import IconPropsInterface from './IconPropsInterface'

export const Safety = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 20 20"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M10 2C11.8194 3.83696 14.1939 3.79339 16.6206 3.66667C16.9739 5.03824 17.082 7.47686 16.9385 8.89705C16.7949 10.3172 16.4027 11.69 15.7851 12.9338C15.1675 14.1776 14.3372 15.267 13.3434 16.1373C12.3495 17.0076 11.2126 17.6411 10 18C8.78743 17.6411 7.65046 17.0076 6.65665 16.1373C5.66283 15.267 4.83246 14.1776 4.21486 12.9338C3.59727 11.69 3.20506 10.3172 3.06154 8.89705C2.91803 7.47686 3.02613 5.03824 3.37943 3.66667C5.80608 3.79339 8.18064 3.83696 10 2Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0013 10.0007C10.4615 10.0007 10.8346 9.62756 10.8346 9.16732C10.8346 8.70708 10.4615 8.33398 10.0013 8.33398C9.54106 8.33398 9.16797 8.70708 9.16797 9.16732C9.16797 9.62756 9.54106 10.0007 10.0013 10.0007Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10 10V12.0833" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
