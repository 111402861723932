import IconPropsInterface from './IconPropsInterface'

export const PinFilled = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 15 18"
      fill={color}
      stroke="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path d="M7.4987 0.103516C3.57526 0.103516 0.394531 3.28425 0.394531 7.20768C0.394531 11.8796 5.01353 14.1162 5.5922 14.6367C6.18314 15.1682 6.67139 16.3598 6.86836 17.0353C6.96395 17.3634 7.23261 17.5294 7.4987 17.5365C7.76543 17.5287 8.03345 17.3628 8.12903 17.0353C8.32601 16.3598 8.81426 15.1689 9.4052 14.6367C9.98386 14.1162 14.6029 11.8796 14.6029 7.20768C14.6029 3.28425 11.4221 0.103516 7.4987 0.103516ZM7.4987 9.14518C6.42855 9.14518 5.5612 8.27783 5.5612 7.20768C5.5612 6.13754 6.42855 5.27018 7.4987 5.27018C8.56884 5.27018 9.4362 6.13754 9.4362 7.20768C9.4362 8.27783 8.56884 9.14518 7.4987 9.14518Z" />
    </svg>
  )
}
