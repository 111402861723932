import IconPropsInterface from './IconPropsInterface'

export const BarProperty2 = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4695_20199)">
        <path
          d="M3.25024 4.58333L4.50024 5.83333L6.58358 3.75"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.25024 9.58333L4.50024 10.8333L6.58358 8.75"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.25024 14.5833L4.50024 15.8333L6.58358 13.75"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.50024 5H17.0002"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.50024 10H17.0002"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.50024 15H17.0002"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4695_20199">
          <rect
            width={size}
            height={size}
            fill="white"
            transform="translate(0.333252)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
