import IconPropsInterface from '@icons/IconPropsInterface'

export const BubbleLines = ({
  size = 20,
  color,
  style,
}: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style, flexShrink: 0, transition: '300ms' }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4596 8.61107C18.4596 4.09718 14.9874 1.66663 10.1263 1.66663C5.26519 1.66663 1.79297 4.09718 1.79297 8.61107C1.79297 13.125 5.26519 15.5355 9.43186 15.5355V17.3125C9.43186 17.6646 9.43186 17.8406 9.54199 18.0121C9.61923 18.1323 9.80341 18.268 9.94117 18.3061C10.1376 18.3605 10.2676 18.3201 10.5275 18.2394C14.293 16.9444 18.4596 13.125 18.4596 8.61107ZM14.293 8.33329V6.66663H6.79297V8.33329H14.293ZM11.793 11.6666V9.99996H6.79297V11.6666H11.793Z"
        fill={color}
      />
    </svg>
  )
}
