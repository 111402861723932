import IconPropsInterface from './IconPropsInterface'

export const Review = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4987 12.6667L14.9987 10.1667H9.16536C8.94435 10.1667 8.73239 10.0789 8.57611 9.92259C8.41983 9.76631 8.33203 9.55435 8.33203 9.33333V4.33333C8.33203 4.11232 8.41983 3.90036 8.57611 3.74408C8.73239 3.5878 8.94435 3.5 9.16536 3.5H16.6654C16.8864 3.5 17.0983 3.5878 17.2546 3.74408C17.4109 3.90036 17.4987 4.11232 17.4987 4.33333V12.6667Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6667 13.4997V15.1663C11.6667 15.3874 11.5789 15.5993 11.4226 15.7556C11.2663 15.9119 11.0543 15.9997 10.8333 15.9997H5L2.5 18.4997V10.1663C2.5 9.94533 2.5878 9.73337 2.74408 9.57709C2.90036 9.42081 3.11232 9.33301 3.33333 9.33301H5"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
