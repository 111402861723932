export const FileOdt = () => {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.3"
        d="M13.967 4.92409C13.4203 4.92356 12.8962 4.70614 12.5096 4.31955C12.123 3.93295 11.9056 3.40877 11.905 2.86205V0H2.69101C1.97748 -4.92673e-08 1.29316 0.283384 0.788531 0.787837C0.283898 1.29229 0.000265153 1.97651 0 2.69005V18.9123C0.000795073 19.6255 0.284662 20.3092 0.789238 20.8132C1.29381 21.3173 1.97783 21.6004 2.69101 21.6004H13.751C14.1045 21.6005 14.4546 21.531 14.7812 21.3958C15.1078 21.2606 15.4045 21.0624 15.6545 20.8125C15.9045 20.5626 16.1028 20.2659 16.2381 19.9394C16.3734 19.6128 16.4431 19.2628 16.4431 18.9093V4.92409H13.967Z"
        fill="#EDCDFF"
      />
      <path
        d="M7.4597 11.807H5.42695C5.26794 11.3902 5.18679 10.9479 5.1875 10.5021C5.18689 10.0565 5.26804 9.61449 5.42695 9.19791C5.49023 9.03296 5.5655 8.87282 5.65216 8.71875L5.93263 9.19791L6.70203 10.5121L7.03691 11.0904L7.4597 11.807Z"
        fill="#BF3EFF"
      />
      <path
        d="M11.7257 12.8182C11.1612 13.5072 10.3644 13.9686 9.48365 14.1165C9.29792 14.1471 9.11013 14.1639 8.92188 14.1665L9.21825 13.6665L9.97174 12.3865L10.3133 11.8032L10.7319 11.0898L11.1422 11.8032L11.4771 12.3865L11.7257 12.8182Z"
        fill="#BF3EFF"
      />
      <path
        d="M12.564 10.4989C12.5648 10.9446 12.4834 11.3866 12.3238 11.803C12.2605 11.9688 12.1852 12.1298 12.0986 12.2847L11.8223 11.803L11.0805 10.5139L10.7456 9.93056L10.3203 9.19141H12.3213C12.4821 9.60871 12.5644 10.0519 12.564 10.4989Z"
        fill="#BF3EFF"
      />
      <path
        d="M12.0342 8.61887H8.46094L8.88708 7.87889L9.22196 7.29557L9.46057 6.87891C10.3645 7.02403 11.1815 7.49944 11.752 8.21222C11.8552 8.34127 11.9495 8.47713 12.0342 8.61887Z"
        fill="#BF3EFF"
      />
      <path
        d="M8.81973 6.83203L8.55433 7.29286L7.79331 8.61616L7.45842 9.19949L7.03982 9.93364L6.60949 9.19949L6.2746 8.61616L6.02344 8.18451C6.5994 7.4787 7.41859 7.01145 8.32159 6.8737C8.48651 6.84871 8.65293 6.83479 8.81973 6.83203Z"
        fill="#BF3EFF"
      />
      <path
        d="M9.30091 12.3867L8.8823 13.0917L8.54742 13.665L8.27867 14.1234C7.39927 13.9802 6.60183 13.5245 6.0341 12.8409C5.91525 12.6977 5.80723 12.5458 5.71094 12.3867H9.30091Z"
        fill="#BF3EFF"
      />
      <path
        d="M16.4443 4.92409H13.9683C13.4215 4.92356 12.8974 4.70614 12.5108 4.31955C12.1242 3.93295 11.9068 3.40877 11.9062 2.86205V0L16.4443 4.92409Z"
        fill="#BF3EFF"
      />
      <path
        d="M18.3913 17.0586H5.88726C5.00028 17.0586 4.28125 17.7776 4.28125 18.6646V22.3927C4.28125 23.2797 5.00028 23.9987 5.88726 23.9987H18.3913C19.2783 23.9987 19.9973 23.2797 19.9973 22.3927V18.6646C19.9973 17.7776 19.2783 17.0586 18.3913 17.0586Z"
        fill="#BF3EFF"
      />
      <path d="M7.752 21.3359V22.063H7V21.3359H7.752Z" fill="white" />
      <path
        d="M10.8165 19.2079C11.0453 19.3378 11.2344 19.5276 11.3635 19.7568C11.4988 19.9989 11.5678 20.2724 11.5635 20.5497C11.5686 20.8278 11.4995 21.1021 11.3635 21.3447C11.2347 21.575 11.0451 21.7655 10.8155 21.8956C10.5817 22.0291 10.3167 22.0981 10.0475 22.0956C9.77832 22.0981 9.5133 22.0291 9.27953 21.8956C9.04991 21.7655 8.86036 21.575 8.73152 21.3447C8.59549 21.1021 8.52646 20.8278 8.53152 20.5497C8.52634 20.2723 8.59538 19.9986 8.73152 19.7568C8.86102 19.5275 9.05046 19.3378 9.27953 19.2079C9.5133 19.0744 9.77832 19.0054 10.0475 19.0079C10.317 19.0055 10.5823 19.0745 10.8165 19.2079ZM9.47652 19.9178C9.32901 20.0942 9.25396 20.3201 9.26653 20.5497C9.25392 20.7785 9.32902 21.0034 9.47652 21.1787C9.54838 21.2568 9.63647 21.3182 9.73459 21.3586C9.83272 21.399 9.9385 21.4175 10.0445 21.4126C10.1502 21.417 10.2556 21.3984 10.3533 21.358C10.451 21.3176 10.5388 21.2564 10.6105 21.1787C10.7587 21.0037 10.8346 20.7787 10.8225 20.5497C10.8345 20.3211 10.7595 20.0964 10.6125 19.9208C10.5407 19.8426 10.4526 19.7812 10.3545 19.7408C10.2564 19.7003 10.1505 19.6819 10.0445 19.6868C9.93877 19.6819 9.83323 19.7001 9.73517 19.74C9.63711 19.7798 9.54887 19.8405 9.47652 19.9178Z"
        fill="white"
      />
      <path
        d="M14.4769 21.3292C14.3514 21.5563 14.1624 21.7419 13.9329 21.8631C13.6745 21.9953 13.3871 22.0609 13.0969 22.0541H11.9609V19.0474H13.0969C13.3873 19.0403 13.6751 19.1045 13.9349 19.2344C14.1637 19.3531 14.3522 19.5368 14.4769 19.7623C14.6062 20.0023 14.6713 20.2717 14.6659 20.5443C14.6711 20.8178 14.606 21.088 14.4769 21.3292ZM13.6949 21.1782C13.7762 21.0948 13.8392 20.9953 13.8798 20.8861C13.9205 20.777 13.9379 20.6605 13.9309 20.5443C13.9379 20.4281 13.9205 20.3118 13.8799 20.2028C13.8393 20.0938 13.7762 19.9945 13.6949 19.9113C13.5127 19.7516 13.2748 19.6703 13.0329 19.6853H12.6889V21.4032H13.0329C13.2746 21.418 13.5124 21.3372 13.6949 21.1782Z"
        fill="white"
      />
      <path
        d="M17.4273 19.0469V19.6259H16.6103V22.0539H15.8793V19.6259H15.0703V19.0469H17.4273Z"
        fill="white"
      />
    </svg>
  )
}
