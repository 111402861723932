import { Individual } from '@icons/Individual'
import {
  FileCsv,
  FileDoc,
  FileDocx,
  FileFodt,
  FileOdt,
  FilePdf,
  FileRtf,
  FileXla,
  FileXls,
  FileXlsx,
  FileXlt,
  IndividualEntrepreneur,
  Juridical,
  Shreder,
  Trash,
} from '@src/assets/icons'
import { capitalizeFirstLetter } from '@src/utils/helpers/capitalizeFirstLetter'

export const getIconMap = {
  individual: (color: string) => <Individual color={color} />,
  self_employed: (color: string) => <Individual color={color} />,
  individual_entrepreneur: (color: string) => (
    <IndividualEntrepreneur color={color} />
  ),
  juridical: (color: string) => <Juridical color={color} />,
}

export const titleMap = {
  individual: 'Физ.лицо',
  individual_entrepreneur: 'ИП',
  self_employed: 'Самозанятый',
  juridical: 'ООО',
}

export const fileExtensionIconMap = {
  doc: <FileDoc />,
  docx: <FileDocx />,
  pdf: <FilePdf />,
  csv: <FileCsv />,
  rtf: <FileRtf />,
  odt: <FileOdt />,
  fodt: <FileFodt />,
  xlsx: <FileXlsx />,
  xls: <FileXls />,
  xlt: <FileXlt />,
  xla: <FileXla />,
}
export const LIMIT_CONTRACTS = 20
export const DOCUMENT_FORMATS =
  '.doc,.docx,.pdf,.csv,.rtf,.odt,.fodt,.xlsx,.xls,.xlt,.xla'

export const getPopupIcon = {
  file: (color: string) => <Trash color={color} size={32} />,
  contract: (color: string) => <Shreder color={color} size={32} />,
}

const popupEntityMap = {
  file: 'файл',
  contract: 'документ',
}
export const getPopupText = ({
  isPlural,
  entity,
}: {
  isPlural: boolean
  entity: 'file' | 'contract'
}) => {
  return {
    title: `Удаление ${popupEntityMap[entity]}${isPlural ? 'ов' : 'а'}`,
    subtitle: `Вы уверены что хотите удалить ${popupEntityMap[entity]}${
      isPlural ? 'ы' : ''
    }?`,
    successTitle: `${capitalizeFirstLetter(popupEntityMap[entity])}${
      isPlural ? 'ы' : ''
    } успешно удал${isPlural ? 'ены' : 'ён'}`,
    deleteBtnText: `Удалить ${popupEntityMap[entity]}${isPlural ? 'ы' : ''}`,
  }
}
