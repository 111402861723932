import { Tooltip } from '@src/store/slices/hintsSlice'

export const LIMIT_OBJECTS = 10
export const LIMIT_TASKS = 10

export const OBJECTS_HINTS: Tooltip[] = [
  {
    position: 'bottom',
    title: '+ Добавить объект',
    description:
      'Добавление объектов, для которых требуется спецтехника, доставка материалов, грузоперевозки. С добавленными объектами легче оформлять новые заявки.',
    targetElementId: 'add-object', // id элемента, возле которого должна отобразиться подсказка
  },
]
