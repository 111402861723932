import IconPropsInterface from './IconPropsInterface'

export const PinnedOff = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 20 20"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M2.5 2.5L17.5 17.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4987 3.75L9.7912 6.4575M7.64953 7.65167L5.83203 8.33333L4.58203 9.58333L10.4154 15.4167L11.6654 14.1667L12.3487 12.345M13.5404 10.2092L16.2487 7.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 12.5L3.75 16.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.082 3.33398L16.6654 7.91732"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
