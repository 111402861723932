import IconPropsInterface from './IconPropsInterface'

export const CheckCircle = ({
  size = 20,
  color,
  style,
}: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 18 18"
      fill={color}
      stroke="white"
      width={size}
      height={size}
      style={{ ...style, flexShrink: 0, transition: '300ms' }}
    >
      <circle cx="9" cy="9" r="8.5" />
      <path
        d="M5 9.5L7.19149 11.7189C7.60542 12.138 8.29012 12.1103 8.66886 11.6591L13 6.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
