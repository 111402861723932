import { ChangeEventHandler, memo } from 'react'

import {
  setOfferFieldErrorMessage,
  setOfferFieldValue,
} from '@pages/TasksBoard/ExecutorOffer/executorOfferSlice'
import { InputWrapper } from '@pages/TasksBoard/ExecutorOffer/inputs/InputWrapper'
import { useAppDispatch, useAppSelector } from '@src/hooks/store'
import { BaseInput } from '@ui/Inputs/Base/BaseInput'
import { RegularText } from '@ui/Typography'

interface IProps {
  taskId: number
  fieldName: string
  isPreviewMode: boolean
  isFirstValidation: boolean
  title: string
  description: string
  additionalHandler?: (value: string) => void
}

// eslint-disable-next-line react/display-name
export const NumberInput = memo(
  ({
    taskId,
    fieldName,
    isPreviewMode,
    isFirstValidation,
    title,
    description,
    additionalHandler,
  }: IProps) => {
    const fieldData = useAppSelector(
      (state) => state.executorOffer.selectedTasks[taskId][fieldName]
    )
    const dispatch = useAppDispatch()
    const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
      const value = e.target.value.replace(/[^0-9.]/g, '')
      if (value.split('').filter((item) => item === '.').length > 1) {
        return
      }
      const numberParts = value.split('.')
      if (numberParts.length > 1 && numberParts[1].length > 2) {
        return
      }
      if (!isFirstValidation) {
        dispatch(
          setOfferFieldErrorMessage({
            taskId,
            fieldName,
            value: fieldData.getErrorMessage(value),
          })
        )
      }
      if (additionalHandler) {
        additionalHandler(value)
      }
      dispatch(
        setOfferFieldValue({
          taskId,
          fieldName,
          value: value,
        })
      )
    }

    return (
      <InputWrapper
        title={title}
        description={description}
        required={fieldData.required}
        isDisabled={fieldData.disabled}
        errorMessage={fieldData.errorMessage}
      >
        {isPreviewMode ? (
          <RegularText>
            {fieldData.value === '' ? 'Не задано' : fieldData.value}
          </RegularText>
        ) : (
          <BaseInput
            type={'text'}
            //style={{ width: isMobile ? '100%' : '49%' }}
            hasError={!!fieldData.errorMessage}
            border
            value={fieldData.value}
            onChange={handleChange}
            disabled={fieldData.isDisabled}
          />
        )}
      </InputWrapper>
    )
  }
)
