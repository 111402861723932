import IconPropsInterface from './IconPropsInterface'

export const Danger = ({ size = 20, color, className }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 32 28"
      fill={color}
      stroke="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
      className={className}
    >
      <path d="M14.26 1.07066C15.0261 -0.281304 16.9739 -0.2813 17.74 1.07067L31.3079 25.014C32.0634 26.3472 31.1003 28 29.5679 28H2.43213C0.899672 28 -0.063438 26.3472 0.692081 25.014L14.26 1.07066Z" />
      <path
        d="M15.2433 18.3927L14.9553 10.7287H16.7633L16.4593 18.3927H15.2433ZM15.8673 22.0247C15.5686 22.0247 15.3179 21.9234 15.1153 21.7207C14.9126 21.518 14.8113 21.278 14.8113 21.0007C14.8113 20.702 14.9126 20.4567 15.1153 20.2647C15.3179 20.0727 15.5686 19.9767 15.8673 19.9767C16.1659 19.9767 16.4113 20.0727 16.6033 20.2647C16.7953 20.4567 16.8913 20.702 16.8913 21.0007C16.8913 21.278 16.7953 21.518 16.6033 21.7207C16.4113 21.9234 16.1659 22.0247 15.8673 22.0247Z"
        fill="white"
      />
    </svg>
  )
}
