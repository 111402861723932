export const setParamByType = (
  defaultValue: string | number,
  param?: string | number
) => {
  if (param === undefined) {
    return defaultValue
  } else if (typeof param === 'string') {
    return param
  } else {
    return `${param}px`
  }
}
