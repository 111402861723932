import IconPropsInterface from './IconPropsInterface'

export const BarEmployee = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 14 14"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M4.9987 6.33333C6.47146 6.33333 7.66536 5.13943 7.66536 3.66667C7.66536 2.19391 6.47146 1 4.9987 1C3.52594 1 2.33203 2.19391 2.33203 3.66667C2.33203 5.13943 3.52594 6.33333 4.9987 6.33333Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 13V11.6667C1 10.9594 1.28095 10.2811 1.78105 9.78105C2.28115 9.28095 2.95942 9 3.66667 9H6.33333C7.04058 9 7.71885 9.28095 8.21895 9.78105C8.71905 10.2811 9 10.9594 9 11.6667V13"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.66406 1.08594C10.2377 1.2328 10.7461 1.5664 11.1092 2.03414C11.4722 2.50188 11.6693 3.07716 11.6693 3.66927C11.6693 4.26138 11.4722 4.83666 11.1092 5.3044C10.7461 5.77214 10.2377 6.10574 9.66406 6.2526"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 12.9996V11.6663C12.9966 11.0777 12.7986 10.5068 12.4368 10.0425C12.0751 9.57828 11.5699 9.24674 11 9.09961"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
