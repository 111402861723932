import IconPropsInterface from '@icons/IconPropsInterface'

export const SocialWs = ({ size = 20, color, style }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style, verticalAlign: 'middle' }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1698 4.80187C13.7981 3.42937 11.974 2.67271 10.0306 2.67188C6.02479 2.67188 2.76563 5.92937 2.76479 9.93354C2.76313 11.2077 3.09729 12.4602 3.73396 13.5644L2.70312 17.3277L6.55479 16.3177C7.62063 16.8977 8.81396 17.2019 10.0273 17.2019H10.0306C14.0348 17.2019 17.294 13.9435 17.2956 9.93937C17.2965 7.99937 16.5415 6.17521 15.1698 4.80187Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9121 11.3009L11.2504 10.9651C11.5613 10.6567 12.0529 10.6176 12.4113 10.8692C12.7579 11.1126 13.0713 11.3309 13.3629 11.5342C13.8263 11.8559 13.8821 12.5159 13.4829 12.9142L13.1838 13.2134"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.78711 6.81632L7.08628 6.51715C7.48461 6.11882 8.14461 6.17465 8.46628 6.63715C8.66878 6.92882 8.88711 7.24215 9.13128 7.58882C9.38294 7.94715 9.34461 8.43882 9.03544 8.74965L8.69961 9.08798"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.1841 13.2131C11.9499 14.4414 9.87578 13.3981 8.23828 11.7598"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.24006 11.7622C6.60256 10.1239 5.55922 8.05057 6.78756 6.81641"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.7002 9.08789C8.96603 9.50706 9.30686 9.92206 9.69186 10.3071L9.69353 10.3087C10.0785 10.6937 10.4935 11.0346 10.9127 11.3004"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
