import IconPropsInterface from './IconPropsInterface'

export const Juridical = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.5 22.5H22H2.5ZM3.38636 2H21.1136H3.38636ZM4.27273 2V22.5V2ZM20.2273 2V22.5V2ZM8.70455 22.5V18.6562C8.70455 17.949 9.30018 17.375 10.0341 17.375H14.4659C15.1998 17.375 15.7955 17.949 15.7955 18.6562V22.5" />
      <path
        d="M2.5 22.5H22M3.38636 2H21.1136M4.27273 2V22.5M20.2273 2V22.5M8.70455 22.5V18.6562C8.70455 17.949 9.30018 17.375 10.0341 17.375H14.4659C15.1998 17.375 15.7955 17.949 15.7955 18.6562V22.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line x1="14" y1="13" x2="16" y2="13" strokeLinecap="round" />
      <line x1="8.5" y1="13" x2="10.5" y2="13" strokeLinecap="round" />
      <line x1="14" y1="9.5" x2="16" y2="9.5" strokeLinecap="round" />
      <line x1="8.5" y1="9.5" x2="10.5" y2="9.5" strokeLinecap="round" />
      <line x1="14" y1="6" x2="16" y2="6" strokeLinecap="round" />
      <line x1="8.5" y1="6" x2="10.5" y2="6" strokeLinecap="round" />
    </svg>
  )
}
