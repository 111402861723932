import styled, { css } from 'styled-components'

import { getColor, getFontSize, getFontWeight, media } from '@theme/helpers'
import { ITheme } from '@theme/interfaces'

export const LargeTitle = styled.h1`
  ${getColor('text')}
  ${getFontWeight('semibold')}
  ${getFontSize('xxxl')}
  line-height: 55px;

  ${media(
    'small',
    ({ theme }) => `
    font-size: ${theme.fontSizes.xl};
    line-height: 26px;
  `
  )}
`
export const MediumTitle = styled.h2`
  ${getColor('text')}
  ${getFontWeight('semibold')}
  ${getFontSize('xxl')}
  line-height: 41px;
  overflow-wrap: break-word;
  overflow: hidden;

  ${media(
    'small',
    ({ theme }) => `
    font-size: ${theme.fontSizes.xl};
    line-height: 26px;
  `
  )}
`

export const CardTitle = styled.h2`
  ${getColor('text')}
  ${getFontWeight('semibold')}
  ${getFontSize('l')}
  line-height: 30px;

  ${media(
    'small',
    ({ theme }) => `
    font-size: ${theme.fontSizes.m};
    line-height: 22px;
  `
  )}
`

export const TaskTitle = styled.h3`
  ${getColor('text')}
  ${getFontWeight('semibold')}
  ${getFontSize('xl')}
  line-height: 34px;
  overflow-wrap: break-word;
  overflow: hidden;
`

export const SmallTitle = styled.h3`
  ${(props) => (props?.color ? getColor(props.color) : getColor('text20'))};
  ${getFontWeight('semibold')}
  ${getFontSize('m')}
  line-height: 27px;
  overflow-wrap: break-word;
  overflow: hidden;
`

export const RegularText = styled.p<{ color?: string }>`
  color: ${({ color, theme }) => theme.colors[color ?? 'gray30']};
  ${getFontWeight('medium')}
  ${getFontSize('s')}
  line-height: 24px;
  overflow-wrap: break-word;
  overflow: hidden;
  ${media(
    'small',
    () => `
    line-height: 19px;
  `
  )}
`

export const CardText = styled.p<{ color?: string }>`
  ${(props) => (props?.color ? getColor(props.color) : getColor('gray20'))};
  ${getFontWeight('medium')}
  ${getFontSize('m')}
  line-height: 27px;
`

export const SmallCardText = styled.p`
  ${getColor('gray20')}
  ${getFontWeight('medium')}
  ${getFontSize('xs')}
  line-height: 14px;
`

export const RegularBrightText = styled.p`
  ${getColor('text')}
  ${getFontWeight('normal')}
  ${getFontSize('s')}
  line-height: 24px;
`

export const Signature = styled.p<{ color?: string }>`
  ${(props) => (props?.color ? getColor(props.color) : getColor('gray60'))};
  ${getFontWeight('medium')}
  ${getFontSize('xs')}
  line-height: 14px;
  overflow-wrap: break-word;
  overflow: hidden;
`

export const SignatureLight = styled.p<{ color?: string }>`
  ${(props) => (props?.color ? getColor(props.color) : getColor('gray60'))};
  ${getFontWeight('medium')}
  ${getFontSize('s')}
  line-height: 14px;
`

export const InputTitle = styled.h4`
  ${getColor('text20')}
  ${getFontWeight('semibold')}
  ${getFontSize('s')}
  line-height: 24px;
  overflow: hidden;
  overflow-wrap: break-word;

  ${media(
    'small',
    ({ theme }) => `
     font-size: ${theme.fontSizes.xs};
     line-height: 17px;
  `
  )}
`

export const InputTitleSmall = styled.h4`
  ${getColor('text20')}
  ${getFontWeight('semibold')}
  ${getFontSize('xs')}
  line-height: 24px;
`

export const PrimaryLarge = styled.h2`
  ${getColor('primary')}
  ${getFontWeight('semibold')}
  ${getFontSize('xxxl')}
  line-height: 55px;
`
export const PrimaryMedium = styled.h3`
  ${getColor('primary')}
  ${getFontWeight('semibold')}
  ${getFontSize('xl')}
  line-height: 34px;
`
export const PrimarySmall = styled.p`
  ${getColor('primary')}
  ${getFontWeight('semibold')}
  ${getFontSize('s')}
  line-height: 24px;
`
export const StandardLink = styled.a`
  ${getColor('primary')}
  ${getFontWeight('normal')}
  ${getFontSize('s')}
  line-height: 24px;
  cursor: pointer;
  transition: color 300ms;

  &:hover {
    ${getColor('primaryHover')}
  }
`

export const DealStoryTitle = styled.p`
  ${getColor('gray50')}
  ${getFontWeight('semibold')}
  ${getFontSize('s')}
  line-height: 20px;
`
export const SmallSignature = styled.p`
  ${getColor('gray50')}
  ${getFontWeight('medium')}
  ${getFontSize('xs')}
  line-height: 20px;
`
export const MobileTitleStyles = css`
  ${getColor('text20')}
  ${getFontWeight('semibold')}
  ${getFontSize('s')}
  line-height: 19px;
  overflow: hidden;
  overflow-wrap: break-word;
`
export const ExtraSmallText = styled.p<{
  color?: string
  fontWeight?: keyof ITheme['fontWeights']
  fontSize?: string
}>`
  ${({ color }) => getColor(color ?? 'gray30')};
  ${({ fontWeight }) => getFontWeight(fontWeight ?? 'medium')}
  ${({ fontSize }) => getFontSize(fontSize ?? 'xxs')}
  line-height: 12px;
`
