import IconPropsInterface from './IconPropsInterface'

export const Edit = ({ size = 20, color, style }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill={color}
      stroke="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms', ...style }}
    >
      <path d="M12.796 4.96877C12.5644 4.96726 12.3373 5.04901 12.162 5.22312L11.7718 5.6228L14.3446 8.19047L14.7348 7.79079C15.0869 7.44106 15.0899 6.87333 14.7348 6.51906L13.4423 5.23523C13.264 5.05809 13.0277 4.97028 12.796 4.96877ZM11.3328 5.97404L10.7232 6.5554L13.4057 9.21997L14.0276 8.65072L11.3328 5.97404ZM10.333 6.97931L6.11404 11.1336C6.01649 11.1836 5.94638 11.2714 5.91895 11.3758L5.01663 14.4159C4.97548 14.5491 5.01206 14.696 5.11113 14.7944C5.21021 14.8928 5.35805 14.9291 5.49218 14.8882L8.55273 13.992C8.69447 13.9708 8.81184 13.8724 8.85756 13.7376L13.0155 9.64387L11.9791 8.61438H11.9669L11.2719 7.91191L10.333 6.97931ZM6.57739 11.824L6.78468 12.0299L7.79673 12.1147L7.84551 13.0957L8.10157 13.35L6.45546 13.8224L6.08966 13.4591L6.57739 11.824Z" />
    </svg>
  )
}
