import { ComponentType } from 'react'
import styled from 'styled-components'

export const withScrollbar = <T extends object>(
  Component: ComponentType<T>
) => {
  return styled(Component)`
    ::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    ::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.colors.gray77};
      border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.colors.gray20};
      border-radius: 5px;
    }

    &:hover {
      ::-webkit-scrollbar-thumb {
        background-color: ${({ theme }) => theme.colors.primary};
      }
    }
  `
}
