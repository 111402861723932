import { ChangeEventHandler, memo } from 'react'
import styled from 'styled-components'

import {
  setOfferFieldErrorMessage,
  setOfferFieldValue,
} from '@pages/TasksBoard/ExecutorOffer/executorOfferSlice'
import { InputWrapper } from '@pages/TasksBoard/ExecutorOffer/inputs/InputWrapper'
import { useAppDispatch, useAppSelector } from '@src/hooks/store'
import { getBgColor, getColor } from '@theme/helpers'
import { RegularText } from '@ui/Typography'

const StyledTextarea = styled.textarea<{
  hasError: boolean
  disabled: boolean
}>`
  border: 1px solid
    ${({ theme, hasError }) => theme.colors[hasError ? 'accent' : 'gray77']};
  padding: 10px 15px;
  border-radius: 7px;
  font-size: 14px;
  line-height: 24px;
  font-family: inherit;
  outline: none;
  ${getColor('text20')}
  ${getBgColor('light')}
  resize: none;
  height: fit-content;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
`
// eslint-disable-next-line react/display-name
export const TextInput = memo(
  ({
    taskId,
    fieldName,
    title,

    isPreviewMode,
    isFirstValidation,
  }: any) => {
    const dispatch = useAppDispatch()
    const fieldData = useAppSelector(
      (state) => state.executorOffer.selectedTasks[taskId][fieldName]
    )
    const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
      const value = e.target.value

      if (!isFirstValidation) {
        dispatch(
          setOfferFieldErrorMessage({
            taskId,
            fieldName,
            value: fieldData.getErrorMessage(value),
          })
        )
      }
      dispatch(
        setOfferFieldValue({
          taskId,
          fieldName,
          value: value,
        })
      )
    }

    return (
      <InputWrapper
        title={title}
        required={fieldData.required}
        errorMessage={fieldData.errorMessage}
      >
        {isPreviewMode ? (
          <RegularText>{fieldData.value || 'Не задано'}</RegularText>
        ) : (
          <StyledTextarea
            style={{ width: '100%' }}
            hasError={!!fieldData.errorMessage}
            value={fieldData.value}
            disabled={fieldData.isDisabled}
            onChange={handleChange}
          />
        )}
      </InputWrapper>
    )
  }
)
