import IconPropsInterface from './IconPropsInterface'

export const TechLoader = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 17 13"
      fill={color}
      stroke="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path d="M16.139 10.0847H11.4535V7.13456V0.540143C11.4535 0.252765 11.2202 0.0195312 10.9328 0.0195312C10.6455 0.0195312 10.4122 0.252765 10.4122 0.540143V6.61395H9.69865L8.06532 1.98744C7.79668 1.22527 7.07338 0.71368 6.26539 0.71368H3.99136C3.70398 0.71368 3.47075 0.946914 3.47075 1.23429V4.87857H1.90891C0.855886 4.87857 0 5.73446 0 6.78748V9.91115C0 10.581 0.544907 11.1259 1.21476 11.1259H1.4237C1.58474 11.9172 2.28583 12.5142 3.12367 12.5142C3.96151 12.5142 4.6626 11.9172 4.82364 11.1259H6.28205C6.44309 11.9172 7.14418 12.5142 7.98202 12.5142C8.81986 12.5142 9.52095 11.9172 9.68199 11.1259H10.9322H16.1383C16.4256 11.1259 16.6589 10.8927 16.6589 10.6053C16.6589 10.3179 16.4263 10.0847 16.139 10.0847ZM4.51197 1.7549H6.26539C6.63259 1.7549 6.96162 1.98744 7.08379 2.33382L8.59426 6.61395H5.37965C4.90139 6.61395 4.51197 6.22453 4.51197 5.74626V5.39919V1.7549ZM3.12367 11.473C2.7405 11.473 2.42952 11.1613 2.42952 10.7788C2.42952 10.3964 2.7405 10.0847 3.12367 10.0847C3.50684 10.0847 3.81782 10.3964 3.81782 10.7788C3.81782 11.1613 3.50684 11.473 3.12367 11.473ZM7.98271 11.473C7.59954 11.473 7.28856 11.1613 7.28856 10.7788C7.28856 10.3964 7.59954 10.0847 7.98271 10.0847C8.36588 10.0847 8.67686 10.3964 8.67686 10.7788C8.67686 11.1613 8.36588 11.473 7.98271 11.473Z" />
      <path d="M15.0952 5.57422V6.09483C15.0952 6.38221 14.862 6.61544 14.5746 6.61544C14.2873 6.61544 14.054 6.38221 14.054 6.09483V5.57422H12.8393C12.6477 5.57422 12.4922 5.72971 12.4922 5.92129V8.69789C12.4922 8.88947 12.6477 9.04496 12.8393 9.04496H16.31C16.5016 9.04496 16.6571 8.88947 16.6571 8.69789V5.92129C16.6571 5.72971 16.5016 5.57422 16.31 5.57422H15.0952Z" />
      <path d="M15.0992 1.41016V1.93077C15.0992 2.21815 14.8659 2.45138 14.5785 2.45138C14.2912 2.45138 14.0579 2.21815 14.0579 1.93077V1.41016H12.8432C12.6516 1.41016 12.4961 1.56565 12.4961 1.75723V4.53383C12.4961 4.72541 12.6516 4.8809 12.8432 4.8809H16.3139C16.5055 4.8809 16.661 4.72541 16.661 4.53383V1.75723C16.661 1.56565 16.5055 1.41016 16.3139 1.41016H15.0992Z" />
    </svg>
  )
}
