import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RangeCalendarValue } from '@ui/CalendarComponent/RangeCalendar'

import { LIMIT_USERACTIONS } from './constants'
import { IUserActionItem } from './interfaces'

const initialState: {
  items: IUserActionItem[]
  lastItemsCount: number
  page: number
  selectedEvent: number
  selectedEntity: number
  date: RangeCalendarValue
  modalData: {
    date: string
  }
} = {
  items: [],
  page: 1,
  lastItemsCount: LIMIT_USERACTIONS,
  selectedEvent: -1,
  selectedEntity: -1,
  date: null,
  modalData: {
    date: '',
  },
}

const userActionsSlice = createSlice({
  name: 'userActions',
  initialState,
  reducers: {
    addPageDataUserActions: (state, action) => {
      state.items = [...state.items, ...action.payload]
    },
    clearUserActionsData: (state) => {
      state.items = []
      state.page = 1
      state.lastItemsCount = LIMIT_USERACTIONS
    },
    setLastItemsCount: (state, action) => {
      state.lastItemsCount = action.payload
    },
    incrementPage: (state) => {
      state.page += 1
    },
    // dropdown "Событие"
    setSelectObject: (state, action) => {
      state.selectedEvent = action.payload
      state.items = []
      state.page = 1
      state.lastItemsCount = LIMIT_USERACTIONS
    },
    // dropdown "Объект события"
    setSelectObjectEvent: (state, action) => {
      state.selectedEntity = action.payload
      state.items = []
      state.page = 1
      state.lastItemsCount = LIMIT_USERACTIONS
    },
    userActionRemove: (state) => {
      state.items = []
      state.page = 1
      state.lastItemsCount = LIMIT_USERACTIONS
    },
    setDate: (state, { payload }: PayloadAction<RangeCalendarValue>) => {
      state.date = payload
    },
    modalDataAdded: (state, action) => {
      state.modalData = action.payload
    },
  },
})

export const {
  addPageDataUserActions,
  clearUserActionsData,
  incrementPage,
  setLastItemsCount,
  setSelectObject,
  setSelectObjectEvent,
  userActionRemove,
  setDate,
  modalDataAdded,
} = userActionsSlice.actions

export default userActionsSlice.reducer
