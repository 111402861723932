export const FileXls = () => {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3">
        <path
          opacity="0.3"
          d="M13.9671 4.92491C13.4204 4.92438 12.8962 4.70696 12.5096 4.32038C12.123 3.9338 11.9056 3.40964 11.905 2.86294V0H2.69103C1.97733 0 1.29285 0.283518 0.788187 0.788169C0.283524 1.29282 0 1.97726 0 2.69094V18.9126C0 19.6263 0.283524 20.3108 0.788187 20.8154C1.29285 21.3201 1.97733 21.6036 2.69103 21.6036H13.7511C14.4648 21.6036 15.1492 21.3201 15.6539 20.8154C16.1586 20.3108 16.4421 19.6263 16.4421 18.9126V4.92491H13.9671Z"
          fill="#00C650"
        />
      </g>
      <path
        d="M16.4433 4.92491H13.9683C13.4216 4.92438 12.8974 4.70696 12.5108 4.32038C12.1242 3.9338 11.9068 3.40964 11.9062 2.86294V0L16.4433 4.92491Z"
        fill="#00C650"
      />
      <path
        d="M18.3913 17.0586H5.88726C5.00029 17.0586 4.28125 17.7776 4.28125 18.6646V22.3925C4.28125 23.2795 5.00029 23.9985 5.88726 23.9985H18.3913C19.2783 23.9985 19.9973 23.2795 19.9973 22.3925V18.6646C19.9973 17.7776 19.2783 17.0586 18.3913 17.0586Z"
        fill="#00C650"
      />
      <path
        d="M8.65926 21.3711V22.0981H7.90625V21.3711H8.65926Z"
        fill="white"
      />
      <path
        d="M11.0019 22.0968L10.369 21.1608L9.82395 22.0968H8.99094L9.95193 20.5488L8.96094 19.0898H9.82395L10.4409 19.9958L10.973 19.0898H11.8019L10.8539 20.6038L11.866 22.0968H11.0019Z"
        fill="white"
      />
      <path
        d="M12.8901 21.5398H13.8641V22.0968H12.1641V19.0898H12.8921L12.8901 21.5398Z"
        fill="white"
      />
      <path
        d="M16.2939 21.6574C16.2077 21.7955 16.0838 21.9061 15.9369 21.9764C15.7581 22.0601 15.5623 22.1008 15.3649 22.0953C15.0672 22.1078 14.7738 22.0209 14.5309 21.8484C14.4235 21.7677 14.3356 21.6639 14.2738 21.5446C14.212 21.4253 14.1778 21.2937 14.1739 21.1594H14.9479C14.9535 21.2607 14.9966 21.3562 15.0689 21.4274C15.1409 21.4945 15.2365 21.5304 15.3349 21.5274C15.375 21.5297 15.4152 21.5241 15.4531 21.5109C15.491 21.4977 15.526 21.4771 15.5559 21.4504C15.5826 21.4245 15.6036 21.3933 15.6175 21.3589C15.6315 21.3245 15.6381 21.2875 15.6369 21.2504C15.6379 21.2151 15.6318 21.18 15.6189 21.1472C15.606 21.1144 15.5866 21.0845 15.5619 21.0594C15.509 21.0058 15.4462 20.963 15.3769 20.9334C15.3043 20.9014 15.2023 20.8624 15.0709 20.8164C14.9116 20.7638 14.7559 20.7007 14.6049 20.6274C14.4809 20.5635 14.3744 20.4701 14.2949 20.3554C14.2027 20.2166 14.1571 20.0519 14.1649 19.8854C14.1613 19.7204 14.2088 19.5583 14.3009 19.4214C14.3955 19.2876 14.5263 19.1835 14.6779 19.1214C14.8527 19.0503 15.0403 19.0163 15.2289 19.0214C15.5153 19.0056 15.7982 19.0901 16.0289 19.2604C16.1272 19.3403 16.2076 19.4398 16.2651 19.5526C16.3226 19.6654 16.3559 19.789 16.3629 19.9154H15.5759C15.5672 19.8269 15.5292 19.7439 15.4679 19.6794C15.4367 19.6494 15.3996 19.6262 15.359 19.611C15.3184 19.5959 15.2752 19.5892 15.2319 19.5914C15.1569 19.588 15.0833 19.6122 15.0249 19.6594C14.998 19.6849 14.977 19.7161 14.9636 19.7507C14.9501 19.7852 14.9444 19.8224 14.9469 19.8594C14.9454 19.9266 14.9706 19.9917 15.0169 20.0404C15.0667 20.0923 15.1266 20.1335 15.1929 20.1614C15.2639 20.1924 15.3659 20.2324 15.4989 20.2804C15.6612 20.3332 15.8193 20.3981 15.9719 20.4744C16.0966 20.5416 16.2041 20.6368 16.2859 20.7524C16.3792 20.8934 16.4255 21.0604 16.4179 21.2294C16.4166 21.3807 16.3737 21.5288 16.2939 21.6574Z"
        fill="white"
      />
      <path
        d="M11.8903 15.0832H4.55826C3.92325 15.0832 3.40625 14.4512 3.40625 13.6732V8.3543C3.40625 7.57731 3.92325 6.94531 4.55826 6.94531H11.8933C12.5273 6.94531 13.0443 7.57731 13.0443 8.3543V13.6732C13.0413 14.4512 12.5243 15.0832 11.8903 15.0832ZM4.55826 7.66529C4.24726 7.66529 3.99527 7.9743 3.99527 8.3543V13.6732C3.99527 14.0532 4.24726 14.3622 4.55826 14.3622H11.8933C12.2033 14.3622 12.4553 14.0532 12.4553 13.6732V8.3543C12.4553 7.9743 12.2033 7.66529 11.8933 7.66529H4.55826Z"
        fill="#00C650"
      />
      <path
        d="M12.7443 11.8789H3.69531V12.5999H12.7443V11.8789Z"
        fill="#00C650"
      />
      <path
        d="M12.7443 9.40625H3.69531V10.1273H12.7443V9.40625Z"
        fill="#00C650"
      />
      <path
        d="M10.4241 7.30469H9.70312V14.7216H10.4241V7.30469Z"
        fill="#00C650"
      />
      <path
        d="M6.73662 7.30469H6.01562V14.7216H6.73662V7.30469Z"
        fill="#00C650"
      />
    </svg>
  )
}
