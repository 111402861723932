import IconPropsInterface from './IconPropsInterface'

export const AddVideo = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 26 27"
      fill={color}
      stroke="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path d="M25.072 20.9291H22.2863V18.1434C22.2863 17.8971 22.1884 17.661 22.0143 17.4868C21.8401 17.3127 21.604 17.2148 21.3577 17.2148C21.1114 17.2148 20.8752 17.3127 20.7011 17.4868C20.5269 17.661 20.4291 17.8971 20.4291 18.1434V20.9291H17.6434C17.3971 20.9291 17.161 21.0269 16.9868 21.2011C16.8127 21.3752 16.7148 21.6114 16.7148 21.8577C16.7148 22.104 16.8127 22.3401 16.9868 22.5143C17.161 22.6884 17.3971 22.7863 17.6434 22.7863H20.4291V25.572C20.4291 25.8182 20.5269 26.0544 20.7011 26.2286C20.8752 26.4027 21.1114 26.5005 21.3577 26.5005C21.604 26.5005 21.8401 26.4027 22.0143 26.2286C22.1884 26.0544 22.2863 25.8182 22.2863 25.572V22.7863H25.072C25.3182 22.7863 25.5544 22.6884 25.7286 22.5143C25.9027 22.3401 26.0005 22.104 26.0005 21.8577C26.0005 21.6114 25.9027 21.3752 25.7286 21.2011C25.5544 21.0269 25.3182 20.9291 25.072 20.9291Z" />
      <path d="M13.9285 20.9285H2.78571C2.53944 20.9285 2.30325 20.8307 2.12911 20.6565C1.95497 20.4824 1.85714 20.2462 1.85714 20V3.28571C1.85714 3.03944 1.95497 2.80325 2.12911 2.62911C2.30325 2.45497 2.53944 2.35714 2.78571 2.35714H19.5C19.7462 2.35714 19.9824 2.45497 20.1565 2.62911C20.3307 2.80325 20.4285 3.03944 20.4285 3.28571V14.4285C20.4285 14.6748 20.5264 14.911 20.7005 15.0851C20.8746 15.2593 21.1108 15.3571 21.3571 15.3571C21.6034 15.3571 21.8395 15.2593 22.0137 15.0851C22.1878 14.911 22.2857 14.6748 22.2857 14.4285V3.28571C22.2857 2.54689 21.9922 1.83834 21.4697 1.31591C20.9473 0.793493 20.2388 0.5 19.5 0.5H2.78571C2.04689 0.5 1.33834 0.793493 0.815915 1.31591C0.293493 1.83834 0 2.54689 0 3.28571V20C0 20.7388 0.293493 21.4473 0.815915 21.9697C1.33834 22.4922 2.04689 22.7857 2.78571 22.7857H13.9285C14.1748 22.7857 14.411 22.6878 14.5851 22.5137C14.7593 22.3395 14.8571 22.1034 14.8571 21.8571C14.8571 21.6108 14.7593 21.3746 14.5851 21.2005C14.411 21.0264 14.1748 20.9285 13.9285 20.9285Z" />
      <rect
        height="2.0594"
        width="2.83168"
        rx="0.51485"
        x="3.08984"
        y="3.58984"
      />
      <rect
        height="2.0594"
        width="2.83168"
        rx="0.51485"
        x="3.08984"
        y="17.4922"
      />
      <rect
        height="2.0594"
        width="2.83168"
        rx="0.51485"
        x="7.46484"
        y="3.58984"
      />
      <rect
        height="2.0594"
        width="2.83168"
        rx="0.51485"
        x="7.46484"
        y="17.4922"
      />
      <rect
        height="2.0594"
        width="2.83168"
        rx="0.51485"
        x="11.8438"
        y="3.58984"
      />
      <rect
        height="2.0594"
        width="2.83168"
        rx="0.51485"
        x="11.8438"
        y="17.4922"
      />
      <rect
        height="2.0594"
        width="2.83168"
        rx="0.51485"
        x="16.2188"
        y="3.58984"
      />
      <rect
        height="2.0594"
        width="2.83168"
        rx="0.51485"
        x="16.2188"
        y="17.4922"
      />
    </svg>
  )
}
