import { businessNotificationsReducer } from '@pages/BusinessNotifications/businessNotificationsSlice'
import { chatReducer } from '@pages/Chat/chatSlice/chatSlice'
import { applicationReducer } from '@pages/CreateApplications/slices/ApplicationSlice'
import { dealReducer } from '@pages/Deal/DealSlice'
import { reviewReducer } from '@pages/Deal/pages/Reviews/reviewSlice'
import detailedApplicationReducer from '@pages/MyApplications/slices/detailedApplicationSlice'
import myApplicationsReducer from '@pages/MyApplications/slices/myApplicationsSlice'
import { customerTaskDetailReducer } from '@pages/MyApplications/slices/taskSlice'
import { offerReducer } from '@pages/Offers/OffersSlice'
import { paidFeaturesSliceReducer } from '@pages/PaidFeatures/paidFeaturesSlice'
import { partnershipSliceReducer } from '@pages/Partnership/partnershipSlice'
import { profileReducer } from '@pages/Profile/profileSlice'
import autoanswersReducer from '@pages/Space/Autoanswers/autoanswersSlice'
import autoSearchReducer from '@pages/Space/Autosearch/autoSearchSlice'
import { transactionsReducer } from '@pages/Space/Balance/transactions/transactionsSlice'
import blacklistReducer from '@pages/Space/Blacklist/blacklistSlice'
import { bonusesReducer } from '@pages/Space/Bonuses/bonusesSlice'
import { contractsReducer } from '@pages/Space/Contracts/contractsSlice'
import employeeReducer from '@pages/Space/Employee/EmployeeSlice'
import { objectsReducer } from '@pages/Space/Objects/objectsSlise'
import { ownershipReducer } from '@pages/Space/OwnershipTypes/ownershipTypesSlice'
import userActionsReducer from '@pages/Space/UserActions/userActionsSlice'
import { vehiclesReducer } from '@pages/Space/Vehicles/vehiclesSlice'
import { executorOfferReducer } from '@pages/TasksBoard/ExecutorOffer/executorOfferSlice'
import { filterSliceCargoReducer } from '@pages/TasksBoard/filterSliceCargo'
import { filterSliceDeliveryReducer } from '@pages/TasksBoard/filterSliceDelivery'
import { filterSlicePassengerReducer } from '@pages/TasksBoard/filterSlicePassenger'
import { filterSliceRentReducer } from '@pages/TasksBoard/filterSliceRent'
import { taskSliceReducer } from '@pages/TasksBoard/taskSlice'
import { configureStore } from '@reduxjs/toolkit'
import { checkOnlineReducer } from '@src/shared/CheckOnline/checkOnlineSlice'
import notificationsReducer from '@src/shared/MutationResultNotifications/notificationsSlice'
import { appealsReducer } from '@src/shared/appeals/appealsSlice'
import breadcrumbsReducer from '@src/shared/breadcrumbs/breadcrumbsSlice'
import { apiSlice } from '@src/store/slices/apiSlice'
import { errorScreenSliceReducer } from '@src/store/slices/errorScreenSlice'
import { hintsSliceReducer } from '@src/store/slices/hintsSlice'
import mobileLayoutReducer from '@src/store/slices/mobileLayoutSlice'
import regReducer from '@src/store/slices/registrationSlice'
import windowSizeReducer from '@src/store/slices/windowSizeSlice'
import { contextMenuReducer } from '@ui/ContextMenu/contextMenuSlice'
import { mediaViewerReducer } from '@ui/MediaViewer/mediaViewerSlice'

import { clientApiSlice } from './slices/clientApiSlice'
import eSignCertificateReducer from './slices/eSignCertificateSlice'
import userReducer from './slices/userSlice'

export const store = configureStore({
  reducer: {
    reg: regReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
    [clientApiSlice.reducerPath]: clientApiSlice.reducer,
    employee: employeeReducer,
    blacklist: blacklistReducer,
    userActions: userActionsReducer,
    user: userReducer,
    notifications: notificationsReducer,
    breadcrumbs: breadcrumbsReducer,
    profile: profileReducer,
    tasks: taskSliceReducer,
    contracts: contractsReducer,
    objects: objectsReducer,
    filterCargo: filterSliceCargoReducer,
    filterPassenger: filterSlicePassengerReducer,
    filterRent: filterSliceRentReducer,
    filterDelivery: filterSliceDeliveryReducer,
    vehicles: vehiclesReducer,
    ownership: ownershipReducer,
    application: applicationReducer,
    review: reviewReducer,
    chat: chatReducer,
    mediaViewer: mediaViewerReducer,
    contextMenu: contextMenuReducer,
    offer: offerReducer,
    autoanswers: autoanswersReducer,
    autoSearch: autoSearchReducer,
    myApplications: myApplicationsReducer,
    detailedApplication: detailedApplicationReducer,
    customerTaskDetail: customerTaskDetailReducer,
    windowSize: windowSizeReducer,
    deal: dealReducer,
    checkIsOnline: checkOnlineReducer,
    mobileLayout: mobileLayoutReducer,
    businessNotifications: businessNotificationsReducer,
    bonuses: bonusesReducer,
    partnership: partnershipSliceReducer,
    transactions: transactionsReducer,
    appeals: appealsReducer,
    executorOffer: executorOfferReducer,
    eSignCertificate: eSignCertificateReducer,
    errorScreen: errorScreenSliceReducer,
    paidFeatures: paidFeaturesSliceReducer,
    hints: hintsSliceReducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      apiSlice.middleware,
      clientApiSlice.middleware
    ),
  devTools: true,
})

// Вывод типов `RootState` и `AppDispatch` из стора.
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
