import IconPropsInterface from './IconPropsInterface'

export const Upload = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 13 13"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M1.16406 9.5332V10.8665C1.16406 11.2202 1.30454 11.5593 1.55459 11.8093C1.80464 12.0594 2.14377 12.1999 2.4974 12.1999H10.4974C10.851 12.1999 11.1902 12.0594 11.4402 11.8093C11.6903 11.5593 11.8307 11.2202 11.8307 10.8665V9.5332"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.16406 4.20052L6.4974 0.867188L9.83073 4.20052"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 0.867188V8.86719"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
