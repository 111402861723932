import Cookies from 'js-cookie'

import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  fetchBaseQuery,
} from '@reduxjs/toolkit/dist/query/react'
import { useCheckUrlPattern } from '@src/hooks/useCheckUrlPattern'
import { pushNotification } from '@src/shared/MutationResultNotifications/notificationsSlice'
import { RootState, store } from '@src/store'
import { baseClientQuery } from '@src/store/slices/clientApiSlice'
import { toggleErrorScreen } from '@src/store/slices/errorScreenSlice'
import { setCredentials } from '@src/store/slices/registrationSlice'
import { ACCESS_TOKEN, baseClientURL, baseURL } from '@src/utils/constants/auth'

interface ErrorFetch {
  data: {
    data?: null
    localizedMessage?: string
    message?: string
    example_field?: string
  }
  success: boolean
}

let hasAuthErrorOccurred = false
let isTokenRefreshing = false
let isRefreshTokenReceived = false
export let refreshToken: string | undefined = undefined

export const baseQuery = fetchBaseQuery({
  baseUrl: baseURL,
  prepareHeaders: (headers, { getState }) => {
    const state = getState() as RootState
    if (hasAuthErrorOccurred) {
      throw new Error(
        'Unauthorized: Aborting request due to a previous 401 response.'
      )
    }
    if (ACCESS_TOKEN) {
      headers.set(
        'authorization',
        `Bearer ${state.reg.access_token || refreshToken || ACCESS_TOKEN}`
      )
    }
    return headers
  },
})

const getDeepestErrorMessage = (errorObj: any): string[] => {
  let messages: string[] = []

  if (typeof errorObj !== 'object' || errorObj === null) {
    return messages
  }

  // Сначала собераем все сообщения из вложенных объектов
  for (const key in errorObj) {
    // eslint-disable-next-line no-prototype-builtins
    if (errorObj.hasOwnProperty(key)) {
      if (Array.isArray(errorObj[key])) {
        errorObj[key].forEach((item: any) => {
          if (typeof item === 'string') {
            messages.push(item)
          }
        })
      } else if (typeof errorObj[key] === 'object') {
        messages = messages.concat(getDeepestErrorMessage(errorObj[key]))
      }
    }
  }

  // Если во вложенных объектах нет сообщений, добавим message из текущего объекта
  if (messages.length === 0 && typeof errorObj.message === 'string') {
    messages.push(errorObj.message)
  }

  return messages
}

const refreshTokenRequest = async (localToken: string | null | undefined) => {
  try {
    const response = await fetch(`${baseURL}/auth/refresh-token`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localToken || refreshToken || ACCESS_TOKEN}`,
      },
    })

    if (!response.ok) {
      throw new Error('Failed to refresh token')
    }

    return response.json()
  } catch (error) {
    throw new Error('Failed to refresh token')
  }
}

let pendingRequests: any[] = []

const isUserApiQuery = (url: string) => {
  const userApiRoutes = 'auth' || 'users' || 'forgot-password'
  return url.startsWith(userApiRoutes)
}
const processPendingRequests = async () => {
  const originalRequests = [...pendingRequests]
  pendingRequests = []

  for (const request of originalRequests) {
    try {
      const newArgs = {
        ...request.args,
        headers: {
          ...request.args.headers,
          Authorization: `Bearer ${refreshToken || ACCESS_TOKEN}`,
        },
      }
      const queryFunction = isUserApiQuery(request.args.url)
        ? baseQuery
        : baseClientQuery
      await queryFunction(newArgs, request.api, request.extraOptions)
    } catch (error) {
      console.error('Ошибка при повторении запроса', error)
    }
  }
}

export const reAuthQuery = (
  base: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError,
    Record<string, never>,
    FetchBaseQueryMeta
  >
) => {
  return async (args: any, api: any, extraOptions: any) => {
    if (hasAuthErrorOccurred && refreshToken) {
      throw new Error(
        'Unauthorized: Aborting request due to a previous 401 response.'
      )
    }

    let result = await base(args, api, extraOptions)
    const state = api.getState() as RootState
    const sendErrorNotifications = (api: any, messages: string[]) => {
      const combinedMessage = messages.join('\n')
      messages.forEach((message) => {
        api.dispatch(
          pushNotification({
            text: combinedMessage,
            type: 'error',
          })
        )
      })
    }
    if (result?.error?.status === 400) {
      try {
        const message = result.error.data as ErrorFetch
        const deepestMessage = getDeepestErrorMessage(message.data)
        sendErrorNotifications(api, deepestMessage)
      } catch (error) {
        if (error instanceof Error) {
          throw new Error(error.message)
        } else {
          throw new Error(
            'Извините, у нас технические неполадки. Попробуйте позже.'
          )
        }
      }
    }
    if (result?.error?.status === 403) {
      try {
        const message = result.error.data as ErrorFetch
        const deepestMessage = getDeepestErrorMessage(message.data)
        api.dispatch(
          pushNotification({
            text: deepestMessage,
            type: 'error',
          })
        )
      } catch (error) {
        if (error instanceof Error) {
          throw new Error(error.message)
        } else {
          throw new Error(
            'Извините, у нас технические неполадки. Попробуйте позже.'
          )
        }
      }
    }

    if (result?.error?.status === 422) {
      try {
        const message = result.error.data as ErrorFetch
        const deepestMessage = getDeepestErrorMessage(message.data)
        sendErrorNotifications(api, deepestMessage)
      } catch (error) {
        if (error instanceof Error) {
          throw new Error(error.message)
        } else {
          throw new Error(
            'Извините, у нас технические неполадки. Попробуйте позже.'
          )
        }
      }
    }
    if (result?.error?.status === 500) {
      try {
        const message = result.error.data as ErrorFetch
        const deepestMessage = getDeepestErrorMessage(message.data)

        if (message.data.localizedMessage?.includes('SQL')) {
          api.dispatch(
            pushNotification({
              text: 'Извините, у нас технические неполадки. Попробуйте позже.',
              type: 'error',
            })
          )
        } else {
          api.dispatch(
            pushNotification({
              text: deepestMessage,
              type: 'error',
            })
          )
        }
      } catch (error) {
        if (error instanceof Error) {
          throw new Error(error.message)
        } else {
          throw new Error(
            'Извините, у нас технические неполадки. Попробуйте позже.'
          )
        }
      }
    }
    if (result?.error?.status === 401) {
      pendingRequests.push({ args, api, extraOptions })
      if (!isRefreshTokenReceived) {
        isRefreshTokenReceived = false
      }
      try {
        isTokenRefreshing = true
        hasAuthErrorOccurred = false
        const refreshResult = await refreshTokenRequest(state.reg.access_token)
        isRefreshTokenReceived = true
        api.dispatch(
          setCredentials({
            data: {
              ...state.reg,
              access_token: refreshResult.data.access_token,
            },
          })
        )
        refreshToken = refreshResult.data.access_token
        await processPendingRequests()
        const queryFunction = isUserApiQuery(args) ? baseQuery : baseClientQuery
        result = await queryFunction(args, api, extraOptions)
        isTokenRefreshing = false
      } catch {
        isTokenRefreshing = false
        isRefreshTokenReceived = false
        if (!isRefreshTokenReceived) {
          window.location.reload()
          if (
            !api.getState().reg.access_token &&
            (!refreshToken || !ACCESS_TOKEN)
          ) {
            localStorage.removeItem('ACCESS_TOKEN')
            Cookies.remove('ACCESS_TOKEN')
            window.location.replace('app/sign-in')
          }
        }
      }
    }
    return result
  }
}
