import { memo } from 'react'

import { InputWrapper } from '@pages/TasksBoard/ExecutorOffer/inputs/InputWrapper'

// eslint-disable-next-line react/display-name
export const InfoField = memo(
  ({ title, description, style, children }: any) => {
    return (
      <InputWrapper
        title={title}
        description={description}
        required={false}
        isDisabled={false}
        errorMessage={''}
        style={style}
      >
        {children}
      </InputWrapper>
    )
  }
)
