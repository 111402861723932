import IconPropsInterface from './IconPropsInterface'

export const CleanUp = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 32 32"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M6.4794 18.2964H9.10608C9.57152 18.2964 9.94883 18.6737 9.94883 19.1391V21.1367H5.63672V19.1391C5.63672 18.6737 6.01397 18.2964 6.4794 18.2964Z"
        fill={color}
      />
      <path
        d="M13.6902 31.375C13.0142 31.375 12.4662 30.8269 12.4662 30.1509V24.726C12.4662 22.744 10.8594 21.1372 8.87739 21.1372H6.70739C4.7254 21.1372 3.11865 22.744 3.11865 24.726V28.5234C3.11865 30.0983 4.39534 31.375 5.97021 31.375H13.6902Z"
        fill={color}
      />
      <path
        d="M6.45703 7.51091V1.96042C6.45703 1.22293 7.0549 0.625053 7.7924 0.625053C8.5299 0.625053 9.12777 1.22293 9.12777 1.96042V18.2969"
        fill={color}
      />
      <path
        d="M8.23743 31.375C7.13118 31.375 6.23438 30.4782 6.23438 29.3719V26.9053"
        fill={color}
      />
      <path
        d="M11.3532 31.375C10.2469 31.375 9.3501 30.4782 9.3501 29.3719V26.9053"
        fill={color}
      />
      <path
        d="M19.199 23.8281C19.941 23.0861 19.941 21.8829 19.199 21.1409C18.4569 20.3988 17.2538 20.3988 16.5117 21.1409"
        stroke={color}
        fill={'none'}
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.1256 12.4199C19.1256 11.3591 18.2657 10.4992 17.2049 10.4992C16.1441 10.4992 15.2842 11.3591 15.2842 12.4199"
        stroke={color}
        fill={'none'}
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.8802 28C27.0902 28 28.8817 26.2084 28.8817 23.9985C28.8817 22.0393 27.4732 20.41 25.6138 20.0653C26.2049 19.4408 26.5681 18.5985 26.5681 17.6708C26.5681 15.7461 25.0078 14.1858 23.0831 14.1858C22.1472 14.1858 21.2974 14.5547 20.6714 15.1551"
        stroke={color}
        fill={'none'}
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
