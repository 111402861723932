import IconPropsInterface from '@icons/IconPropsInterface'

export const AutoSearch = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.1619 21.16C16.2794 26.0425 16.2794 33.955 21.1619 38.8375C26.0444 43.72 33.9569 43.72 38.8394 38.8375C43.7219 33.955 43.7219 26.04 38.8394 21.16C36.3994 18.72 33.2019 17.5 30.0019 17.5"
        stroke={color}
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0906 14.0892C5.30312 22.8767 5.30312 37.1217 14.0906 45.9092C22.8781 54.6967 37.1231 54.6967 45.9106 45.9092C54.6981 37.1217 54.6981 22.8767 45.9106 14.0892C39.3431 7.52174 29.7256 5.86674 21.6181 9.11674"
        stroke={color}
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1016 14.0996L30.0016 29.9996"
        stroke="#34C759"
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
