import IconPropsInterface from '@icons/IconPropsInterface'

export const RateCheck = ({ color, style, className }: IconPropsInterface) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style, verticalAlign: 'middle' }}
      className={className}
    >
      <path
        d="M21.2102 5.28799C21.1172 5.19426 21.0066 5.11986 20.8848 5.06909C20.7629 5.01833 20.6322 4.99219 20.5002 4.99219C20.3682 4.99219 20.2375 5.01833 20.1156 5.06909C19.9937 5.11986 19.8831 5.19426 19.7902 5.28799L8.50019 16.588L4.21019 12.288C4.02188 12.0997 3.76649 11.9939 3.50019 11.9939C3.23388 11.9939 2.97849 12.0997 2.79019 12.288C2.60188 12.4763 2.49609 12.7317 2.49609 12.998C2.49609 13.2643 2.60188 13.5197 2.79019 13.708L7.79018 18.708C7.88315 18.8017 7.99375 18.8761 8.11561 18.9269C8.23747 18.9776 8.36817 19.0038 8.50019 19.0038C8.6322 19.0038 8.7629 18.9776 8.88476 18.9269C9.00662 18.8761 9.11722 18.8017 9.21019 18.708L21.2102 6.70799C21.3039 6.61502 21.3783 6.50442 21.4291 6.38256C21.4798 6.2607 21.506 6.13 21.506 5.99799C21.506 5.86597 21.4798 5.73527 21.4291 5.61341C21.3783 5.49155 21.3039 5.38095 21.2102 5.28799Z"
        fill={color}
      />
    </svg>
  )
}
