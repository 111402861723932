import styled from 'styled-components'

import { getBgColor } from '@theme/helpers'
import { Box } from '@ui/Box'

export const Paper = styled(Box)`
  ${getBgColor('light')}
  box-shadow: 5px 5px 35px hsla(216, 21%, 14%, 0.05);
  border-radius: 10px;
`
