import styled from 'styled-components'

import { Box } from '@ui/Box'

export const Overlay = styled(Box)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: hsla(213, 11%, 13%, 0.65);
  display: flex;
  justify-content: center;
  z-index: 1000;
  transition: opacity ${({ theme }) => theme.transitions.base};
`
