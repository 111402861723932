import {
  incrementCommonMessagesCount,
  setValueInStateChat,
} from '@pages/Chat/chatSlice/chatSlice'
import { store } from '@src/store'

export const onMessageHeaderNotificationHandler = (
  readyMessage: any,
  userId: number
) => {
  if (readyMessage.type === 'read') {
    return
  }
  if (readyMessage.type === 'message_for_connection') {
    store.dispatch(
      setValueInStateChat({
        field: `${readyMessage.content.profile_type}MessagesCount`,
        data: readyMessage.content.count,
      })
    )
    store.dispatch(
      setValueInStateChat({
        field: `${readyMessage.content.siblings.profile_type}MessagesCount`,
        data: readyMessage.content.siblings.count,
      })
    )

    store.dispatch(
      incrementCommonMessagesCount(
        readyMessage.content.count as unknown as void
      )
    )
    return
  }

  if (readyMessage.user.id !== userId) {
    store.dispatch(incrementCommonMessagesCount(1 as unknown as void))
  }
}
