import { partnershipApi } from '@pages/Partnership/partnershipApi'
import { IPartnerReferralEvent } from '@pages/Partnership/types/IPartnerReferralEvents'
import { IPartnerTransaction } from '@pages/Partnership/types/IPartnerTransactions'
import {
  EntityState,
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'
import { IOption } from '@src/shared/interfaces/partials/IOption'
import { RootState } from '@src/store'
import {
  IPaginationAdditionalState,
  addItemsToAdapter,
  clearAdapterItems,
  getInitialPaginationState,
} from '@src/utils/helpers/getInitialPaginationState'

interface IPartnershipState {
  personal: {
    spaceName: string
    ownershipType: IOption<number> | null
    bankAccount: IOption<number> | null
    isPartnerEditing: boolean
    isOwnershipTypeApproved: boolean
    isOwnershipTypeExists: boolean
    isBankAccountValid: boolean
  }
  transactions: EntityState<IPartnerTransaction> & ITransactionsAdditionalState
  events: EntityState<IPartnerReferralEvent> & IReferralEventsAdditionalState
}

interface ITransactionsAdditionalState extends IPaginationAdditionalState {
  total: string | null
}

interface IReferralEventsAdditionalState extends IPaginationAdditionalState {
  total: string | null
}

const transactionsInitialState: ITransactionsAdditionalState = {
  ...getInitialPaginationState(),
  total: null,
}

const eventsInitialState: IReferralEventsAdditionalState = {
  ...getInitialPaginationState(),
  total: null,
}

const transactionsAdapter = createEntityAdapter<IPartnerTransaction>()
const eventsAdapter = createEntityAdapter<IPartnerReferralEvent>()

const partnershipInitialState: IPartnershipState = {
  personal: {
    spaceName: '',
    ownershipType: null,
    bankAccount: null,
    isPartnerEditing: false,
    isOwnershipTypeApproved: true,
    isBankAccountValid: true,
    isOwnershipTypeExists: true,
  },
  transactions: transactionsAdapter.getInitialState(transactionsInitialState),
  events: eventsAdapter.getInitialState(eventsInitialState),
}

const partnershipSlice = createSlice({
  name: 'partnership',
  initialState: partnershipInitialState,
  reducers: {
    partnerEditingChanged: (state, { payload }: PayloadAction<boolean>) => {
      state.personal.isPartnerEditing = payload
    },
    transactionsCleared: (state) => {
      clearAdapterItems(transactionsAdapter, state.transactions)
      state.transactions.total = null
    },
    eventsCleared: (state) => {
      clearAdapterItems(eventsAdapter, state.events)
      state.events.total = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        partnershipApi.endpoints.getPartnerPersonal.matchFulfilled,
        (state, { payload }) => {
          const inn =
            'inn' in payload.data.ownership_type.model
              ? payload.data.ownership_type.model.inn
              : payload.data.ownership_type.model.inn_code

          state.personal.spaceName = payload.data.space.name
          state.personal.ownershipType = {
            value: payload.data.ownership_type.id,
            label: `${payload.data.ownership_type.name} ИНН ${inn}`,
          }
          state.personal.bankAccount = {
            value: payload.data.bank_account.id,
            label: `${payload.data.bank_account.bank}, счет ${payload.data.bank_account.checking_account}`,
          }

          const isApproved =
            payload.data.ownership_type.model.state === 'approved'
          const isValid = !payload.data.bank_account.disabled_at
          const isExists = !payload.data.ownership_type.disabled_at

          state.personal.isOwnershipTypeApproved = isApproved
          state.personal.isBankAccountValid = isValid
          state.personal.isOwnershipTypeExists = isExists

          if (!isApproved || !isValid || !isExists) {
            state.personal.isPartnerEditing = true
          }
        }
      )
      .addMatcher(
        partnershipApi.endpoints.updatePartner.matchFulfilled,
        (state) => {
          state.personal.isPartnerEditing = false
        }
      )
      .addMatcher(
        partnershipApi.endpoints.getPartnerTransactions.matchFulfilled,
        (state, { payload }) => {
          addItemsToAdapter(transactionsAdapter, state.transactions, payload)
          state.transactions.total = payload.data.total
        }
      )
      .addMatcher(
        partnershipApi.endpoints.getPartnerReferralEvents.matchFulfilled,
        (state, { payload }) => {
          addItemsToAdapter(eventsAdapter, state.events, payload)
          state.events.total = payload.data.total
        }
      )
  },
})

export const { partnerEditingChanged, transactionsCleared, eventsCleared } =
  partnershipSlice.actions

export const partnershipSliceReducer = partnershipSlice.reducer

export const {
  selectAll: selectPartnershipTransactions,
  selectById: selectPartnershipTransactionById,
  selectIds: selectPartnershipTransactionsIds,
} = transactionsAdapter.getSelectors<RootState>(
  (state) => state.partnership.transactions
)

export const {
  selectAll: selectPartnershipEvents,
  selectById: selectPartnershipEventById,
  selectIds: selectPartnershipEventsIds,
} = eventsAdapter.getSelectors<RootState>((state) => state.partnership.events)
