import IconPropsInterface from '@icons/IconPropsInterface'

export const Verification = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.8284 13.1716C21.3905 14.7337 21.3905 17.2663 19.8284 18.8284C18.2663 20.3905 15.7337 20.3905 14.1716 18.8284C12.6095 17.2663 12.6095 14.7337 14.1716 13.1716C15.7337 11.6095 18.2663 11.6095 19.8284 13.1716"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.5 22L19.5 19.5"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.86633 5.49512C9.86333 5.49512 9.86133 5.49712 9.86133 5.50012C9.86133 5.50312 9.86333 5.50512 9.86633 5.50512C9.86933 5.50512 9.87133 5.50312 9.87133 5.50012C9.87133 5.49712 9.86933 5.49512 9.86633 5.49512"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.32715 5.495C7.32415 5.495 7.32215 5.497 7.32215 5.5C7.32215 5.503 7.32415 5.505 7.32715 5.505C7.33015 5.505 7.33215 5.503 7.33215 5.5C7.33215 5.497 7.33015 5.495 7.32715 5.495"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.7793 5.49012C4.7763 5.49012 4.7743 5.49212 4.7743 5.49512C4.7743 5.49812 4.7763 5.50012 4.7793 5.50012C4.7823 5.50012 4.7843 5.49812 4.7843 5.49512C4.7843 5.49212 4.7823 5.49012 4.7793 5.49012"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 8H19"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 11.417V5C19 3.895 18.105 3 17 3H4C2.895 3 2 3.895 2 5V16C2 17.105 2.895 18 4 18H12.418"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5 15L16.5 17L15.5 16"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
