import IconPropsInterface from './IconPropsInterface'

export const Individual = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
    >
      <path
        d="M3 17C3 15.067 4.567 13.5 6.5 13.5H17.5C19.433 13.5 21 15.067 21 17V21C21 21.5523 20.5523 22 20 22H4C3.44772 22 3 21.5523 3 21V17Z"
        strokeLinejoin="round"
      />
      <circle cx="11.75" cy="6.25" r="4.25" />
      <path
        d="M10.1736 21.5L10.9389 15.3286L10 13.5H13.7449L13.0647 15.3286L14 21.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.50547 13.5C6.41064 15.6022 7.54117 20.2452 10.5 22"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4945 13.5C17.5894 15.6022 16.4588 20.2452 13.5 22"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
