import moment from 'moment-timezone'

import { currenciesMap } from '@src/utils/constants/currenciesMap'
import { getWorkTypeName } from '@src/utils/helpers/getWorkTypeName'

import {
  IMediaViewSliderData,
  ITaskItem,
  ITaskItemAddDetailed,
  IWhenToStart,
} from '../interfaces'

export const convertDateToYmdAndTime = (date: string) => {
  if (!date) {
    return {
      ymd: '',
      time: '',
    }
  }

  const dateObject = new Date(date)
  const dateInTimeZone = moment(dateObject).utc()
  dateInTimeZone.locale('ru')
  const ymd = dateInTimeZone.format('DD MMMM YYYY')
  const time = dateInTimeZone.format('HH:mm')
  return { ymd, time }
}

export const getWorkType = (data: ITaskItem) => {
  const taskType = data.type?.value
  if (taskType === 'delivery') {
    const materialName = data.details?.material?.material_type?.name
    return `${data.type.name}${materialName ? ` / ${materialName}` : ''}`
  }
  if (taskType === 'rent') {
    return data.details?.work_type
      ? getWorkTypeName(data.details.work_type)
      : ''
  }

  return `${data.type.name}`
}

export const getWhenData = (data: IWhenToStart) => {
  const dateFrom = data?.from?.datetime
  const dateTo = data?.to?.datetime

  if (!dateFrom && !dateTo) {
    return 'Время не указано'
  }

  const { ymd: ymdFrom, time: timeFrom } = convertDateToYmdAndTime(dateFrom)
  const { ymd: ymdTo, time: timeTo } = convertDateToYmdAndTime(dateTo || '')

  return ymdTo
    ? `${ymdFrom}, с ${timeFrom} до ${timeTo}`
    : `${ymdFrom}, в ${timeFrom}`
}

export const getCost = (data: ITaskItem) => {
  const amount = data.details?.cost?.amount
  const currency = data.details?.cost?.currency || ''
  const isRent = data.type?.value === 'rent'
  if (!amount) {
    return 'Стоимость не указана'
  }
  if (isRent) {
    return `${amount} ${
      currenciesMap[currency as keyof typeof currenciesMap]
    }/час`
  }
  return `${amount} ${currenciesMap[currency as keyof typeof currenciesMap]}`
}

export const getDeliveryCost = (data: ITaskItem) => {
  const amount = data.details?.cost_delivery?.amount
  const currency = data.details?.cost_delivery?.currency
  if (!amount) {
    return ''
  }
  return `Доставка техники: ${amount} ${
    currenciesMap[currency as keyof typeof currenciesMap]
  }`
}

export const getTaskCreated = (data: ITaskItem) => {
  const date = data?.updated_at?.datetime || data?.created_at.datetime
  if (!date) {
    return 'Время не указано'
  }
  const { ymd, time } = convertDateToYmdAndTime(date)

  return `${ymd}, в ${time}`
}

export const transformTaskResponse = (data: ITaskItem) => {
  return {
    imageSrc: data.space.logo?.public_url,
    rating: data.space?.customer?.rating,
    employerName: data.project?.ownership?.name,
    createdDiff: data.project?.ownership?.created_at?.diff_for_humans,
    isVerified: data.project?.ownership?.is_verified,
    id: data.id,
    type: data.type?.value,
    taskName: data.title,
    description: data.description,
    isFavorite: data.is_favorite,
    isHidden: data.is_hidden,
    taskCreated: getTaskCreated(data),
    paymentType: data.project?.payment_method?.name,
    paymentDefer: data.project?.deferred_payment,
    workType: getWorkType(data),
    when: getWhenData(data.when_to_start),
    cost: getCost(data),
    deliveryCost: getDeliveryCost(data),
    views: data.counters?.views || 0,
    addTasksCount: (data.project?.counters?.tasks || 1) - 1,
    details: data.details,
    spaceId: data.space.id,
    background: data?.background,
    phones: data.phones,
  }
}

export const getMediaData = (
  data: ITaskItemAddDetailed
): IMediaViewSliderData[] => {
  const photos =
    data.photos?.map((item) => ({
      type: 'image',
      path: item.public_url,
    })) || []
  const videos =
    data.videos?.map((item) => ({
      type: 'video',
      path: item.public_url,
    })) || []
  return [...photos, ...videos] as IMediaViewSliderData[]
}
