import { TypeBusinessNotification } from '@pages/BusinessNotifications/interfaces'
import { setMyRole } from '@pages/Profile/profileSlice'
import { EventBus } from '@src/shared/EventBus'
import { store } from '@src/store'
import { noop } from '@src/utils/helpers/noop'

export const tagHueValues = {
  ProjectFavorites: 20,
  Ownership: 60,
  BonusProgramReceive: 120,
  OfferCustomer: 200,
  OfferExecutor: 220,
  DealCustomer: 260,
  DealExecutor: 280,
  WorkShiftCustomer: 320,
  WorkShiftExecutor: 340,
  PaidFeaturePurchased: 135,
  AutoOffer: 40,
  Vehicle: 80,
  DealReview: 100,
}

export const LIMIT_NOTIFICATIONS_LIST = 30

export const CHECK_NEW_MESSAGES_COUNT_POLLING_INTERVAL_MS = 60_000

export const idsToRead: {
  current: number[]
} = {
  current: [],
}

export const getEntitiesLinks: Record<
  TypeBusinessNotification,
  ({ spaceId, entityId }: { spaceId: string; entityId: string }) => string
> = {
  DealCustomer: ({ spaceId, entityId }) => {
    return `/space/${spaceId}/deal/${entityId}/general`
  },
  DealExecutor: ({ spaceId, entityId }) => {
    return `/space/${spaceId}/deal/${entityId}/general`
  },
  Ownership: ({ spaceId, entityId }) => {
    return `/space/${spaceId}/ownership-types/${entityId}`
  },
  Vehicle: ({ spaceId, entityId }) => {
    return `/space/${spaceId}/publicVehicles/${entityId}`
  },
  DealReview: ({ spaceId, entityId }) => {
    return `/space/${spaceId}/review/${entityId}`
  },
  //TODO разобраться где находятся сущности по этим ссылкам
  OfferCustomer: ({ spaceId }) => {
    return ``
  },
  OfferExecutor: ({ spaceId }) => {
    return ``
  },
  ProjectFavorites: ({ spaceId }) => {
    return ``
  },
  BonusProgramReceive: ({ spaceId }) => {
    return ``
  },
  WorkShiftCustomer: ({ spaceId }) => {
    return ``
  },
  WorkShiftExecutor: ({ spaceId }) => {
    return ``
  },
  PaidFeaturePurchased: ({ spaceId }) => {
    return ``
  },
  AutoOffer: ({ spaceId }) => {
    return ``
  },
}

export const notificationsCallbacks: Record<
  TypeBusinessNotification,
  () => void
> = {
  DealCustomer: () => {
    store.dispatch(setMyRole('customer'))
  },
  DealExecutor: () => {
    store.dispatch(setMyRole('executor'))
  },
  Ownership: noop,
  Vehicle: noop,
  OfferCustomer: noop,
  OfferExecutor: noop,
  ProjectFavorites: noop,
  BonusProgramReceive: noop,
  WorkShiftCustomer: noop,
  WorkShiftExecutor: noop,
  PaidFeaturePurchased: noop,
  AutoOffer: noop,
  DealReview: noop,
}

export const businessNotificationsEventBus = new EventBus()
