import moment from 'moment/moment'

import { setValidUntil } from '@pages/TasksBoard/ExecutorOffer/executorOfferSlice'
import { useAppDispatch, useAppSelector } from '@src/hooks/store'
import { useWindowResize } from '@src/hooks/useWindowResize'
import { pushNotification } from '@src/shared/MutationResultNotifications/notificationsSlice'
import { Box } from '@ui/Box'
import { CalendarWithTime } from '@ui/CalendarComponent/CalendarWithTime'
import { SmallTitle } from '@ui/Typography'

export const ValidUntil = () => {
  const dispatch = useAppDispatch()
  const { validUntil } = useAppSelector((state) => state.executorOffer)
  const { isMobile } = useWindowResize()

  const handleChange = (value: Date | null) => {
    if (moment(value).isAfter()) {
      dispatch(setValidUntil(value))
    } else {
      dispatch(
        pushNotification({
          type: 'error',
          text: 'Невозможно выбрать текущую дату',
        })
      )
    }
  }

  return (
    <Box
      flex
      mt={20}
      cg={20}
      rg={10}
      flexDirection={isMobile ? 'column' : 'row'}
      justifyContent={'flex-start'}
      alignItems={isMobile ? 'stretch' : 'center'}
    >
      <SmallTitle>Предложение действительно до:</SmallTitle>

      <CalendarWithTime
        type={'future'}
        position={'top'}
        onChange={handleChange}
        value={validUntil}
        style={{ flexGrow: '1' }}
      />
    </Box>
  )
}
