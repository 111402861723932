import { KeyboardEventHandler, useEffect, useRef } from 'react'
import styled from 'styled-components'

import { useAppDispatch, useAppSelector } from '@src/hooks/store'
import { scrollLocker } from '@src/utils/helpers/scrollLocker'
import { getBgColor, getColor } from '@theme/helpers'
import { useGetTheme } from '@theme/hooks'
import { BaseIconButton } from '@ui/Buttons/Base/BaseIconButton'
import { ImageBox } from '@ui/Images/Base/ImageBox'
import { VideoBox } from '@ui/VideoBox'

import { closeViewer, nextMedia, prevMedia } from './mediaViewerSlice'

const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.95);
`
const StyledDescription = styled.p`
  ${getBgColor('primaryLight')}
  ${getColor('primary')}
  text-align: center;
  padding: 15px;
  width: fit-content;
  max-width: 1200px;
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  opacity: 0.9;
`

export const MediaViewer = () => {
  const dispatch = useAppDispatch()
  const theme = useGetTheme()
  const { items, currentIndex, type, infinite } = useAppSelector(
    (state) => state.mediaViewer
  )
  const overlayRef = useRef<HTMLDivElement>(null)

  const typeMap = {
    image: ImageBox,
    video: VideoBox,
  }

  const MediaComponent =
    type === 'mixed' ? typeMap[items[currentIndex!].type!] : typeMap[type]

  useEffect(() => {
    if (items.length > 0) {
      overlayRef.current?.focus()
      scrollLocker.lock()
    } else {
      overlayRef.current?.blur()
      scrollLocker.unlock()
    }
  }, [items])

  const handleArrowLeft = () => {
    dispatch(prevMedia())
  }

  const handleArrowRight = () => {
    dispatch(nextMedia())
  }
  const handleClose = () => {
    dispatch(closeViewer())
  }

  const description = items[currentIndex!]?.description

  const showLeftArrow = (infinite || currentIndex !== 0) && items.length > 1
  const showRightArrow =
    (infinite || currentIndex !== items.length - 1) && items.length > 1

  const handleKeyPress: KeyboardEventHandler<HTMLDivElement> = (e) => {
    const code = e.code
    if (code === 'Escape') {
      handleClose()
    }
    if (code === 'ArrowRight' && showRightArrow) {
      handleArrowRight()
    }
    if (code === 'ArrowLeft' && showLeftArrow) {
      handleArrowLeft()
    }
  }

  return (
    <>
      {items.length > 0 && (
        <StyledOverlay ref={overlayRef} onKeyDown={handleKeyPress} tabIndex={0}>
          <MediaComponent
            src={items[currentIndex!]?.path}
            boxHeight="100%"
            boxWidth="100%"
            fit="contain"
            autoplay
          />
          {showLeftArrow && (
            <BaseIconButton
              iconName="ArrowLeft"
              onClick={handleArrowLeft}
              style={{
                position: 'absolute',
                left: 0,
                top: '50%',
                zIndex: 5,
                transform: 'translateY(-50%)',
              }}
              hoverIconColor={theme.colors.primary}
              iconColor={theme.colors.light}
              iconSize={40}
            />
          )}
          {showRightArrow && (
            <BaseIconButton
              iconName="ArrowRight"
              onClick={handleArrowRight}
              style={{
                position: 'absolute',
                right: 0,
                top: '50%',
                zIndex: 5,
                transform: 'translateY(-50%)',
              }}
              hoverIconColor={theme.colors.primary}
              iconColor={theme.colors.light}
              iconSize={40}
            />
          )}
          <BaseIconButton
            iconName="Close"
            iconSize={60}
            hoverIconColor={theme.colors.accent}
            iconColor={theme.colors.light}
            onClick={handleClose}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              zIndex: 5,
            }}
          />
          {description && <StyledDescription>{description}</StyledDescription>}
        </StyledOverlay>
      )}
    </>
  )
}
