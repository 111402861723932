import IconPropsInterface from '@icons/IconPropsInterface'

export const PhotoCamera = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7789 8.04948C13.0115 9.28212 13.0115 11.2806 11.7789 12.5133C10.5463 13.7459 8.54775 13.7459 7.31511 12.5133C6.08246 11.2806 6.08246 9.28212 7.31511 8.04948C8.54775 6.81684 10.5463 6.81684 11.7789 8.04948"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.625 7.12435V14.2493C16.625 15.1241 15.9165 15.8327 15.0417 15.8327H3.95833C3.08354 15.8327 2.375 15.1241 2.375 14.2493V7.12435C2.375 6.24956 3.08354 5.54102 3.95833 5.54102H5.54167L6.69908 3.55868C6.84079 3.31564 7.10125 3.16602 7.38308 3.16602H11.5821C11.86 3.16602 12.1173 3.31168 12.2605 3.54918L13.4583 5.54102H15.0417C15.9165 5.54102 16.625 6.24956 16.625 7.12435Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
