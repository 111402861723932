import IconPropsInterface from '@icons/IconPropsInterface'

export const SunFill = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8794_68012)">
        <path
          d="M9.9974 13.3327C11.8383 13.3327 13.3307 11.8403 13.3307 9.99935C13.3307 8.1584 11.8383 6.66602 9.9974 6.66602C8.15645 6.66602 6.66406 8.1584 6.66406 9.99935C6.66406 11.8403 8.15645 13.3327 9.9974 13.3327Z"
          fill={color}
        />
        <path
          d="M2 10H4M10 2V4M16 10H18M10 16V18M4 4L6 6M16 4L14 6M14 14L16 16M6 14L4 16"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_8794_68012">
          <rect width={size} height={size} fill={color} />
        </clipPath>
      </defs>
    </svg>
  )
}
