import { ITaskDetailsDelivery } from '@pages/TasksBoard/interfaces'

export const convertCharacteristicsToText = (
  characteristics: ITaskDetailsDelivery['material']['characteristics']
) => {
  return characteristics
    ?.filter((item) => item.value)
    ?.map(
      (item) =>
        `${item?.name}: ${
          'title' in (item?.value || {})
            ? item?.value?.title
            : item?.value?.value
        }`
    )
    .join(', ')
}
