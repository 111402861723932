import { ChangeEventHandler, memo } from 'react'

import {
  PERIOD_VALUES,
  selectPeriodOptions,
} from '@pages/TasksBoard/ExecutorOffer/constants'
import {
  setOfferFieldErrorMessage,
  setOfferFieldValue,
} from '@pages/TasksBoard/ExecutorOffer/executorOfferSlice'
import { InputWrapper } from '@pages/TasksBoard/ExecutorOffer/inputs/InputWrapper'
import { useAppDispatch, useAppSelector } from '@src/hooks/store'
import { useWindowResize } from '@src/hooks/useWindowResize'
import { Box } from '@ui/Box'
import DropDown from '@ui/DropDown'
import { BaseInput } from '@ui/Inputs/Base/BaseInput'
import { RegularText } from '@ui/Typography'

interface IProps {
  taskId: number
  costFieldName: string
  periodFieldName: string
  title: string
  isPreviewMode: boolean
  isFirstValidation: boolean
}

// eslint-disable-next-line react/display-name
export const SelectPeriod = memo(
  ({
    taskId,
    costFieldName,
    periodFieldName,
    title,
    isPreviewMode,
    isFirstValidation,
  }: IProps) => {
    const dispatch = useAppDispatch()
    const periodFieldData = useAppSelector(
      (state) => state.executorOffer.selectedTasks[taskId][periodFieldName]
    )
    const costFieldData = useAppSelector(
      (state) => state.executorOffer.selectedTasks[taskId][costFieldName]
    )
    const { isMobile } = useWindowResize()
    const handleChangeSelect = (value: any) => {
      dispatch(
        setOfferFieldValue({
          taskId,
          fieldName: periodFieldName,
          value: value,
        })
      )
    }

    const handleChangeInput: ChangeEventHandler<HTMLInputElement> = (e) => {
      const value = e.target.value.replace(/[^0-9.]/g, '')
      if (!isFirstValidation) {
        dispatch(
          setOfferFieldErrorMessage({
            taskId,
            fieldName: costFieldName,
            value: costFieldData.getErrorMessage(value),
          })
        )
      }
      dispatch(
        setOfferFieldValue({
          taskId,
          fieldName: costFieldName,
          value: value,
        })
      )
    }

    //TODO preview mode
    //

    return (
      <InputWrapper
        title={title}
        required={costFieldData.required}
        isDisabled={costFieldData.disabled}
        errorMessage={costFieldData.errorMessage}
      >
        {isPreviewMode ? (
          <Box flex cg={10}>
            <RegularText>{costFieldData.value ?? 'Не задано'}</RegularText>
            <RegularText>/</RegularText>
            <RegularText>
              {selectPeriodOptions.find(
                (item) =>
                  item.value ===
                  PERIOD_VALUES.indexOf(
                    periodFieldData.value || 'twenty_for_hours'
                  )
              )?.name || 'Не задано'}
            </RegularText>
          </Box>
        ) : (
          <Box flex cg={10}>
            <BaseInput
              type={'text'}
              style={{ width: isMobile ? '100%' : '49%' }}
              hasError={!!costFieldData.errorMessage}
              border
              value={costFieldData.value}
              onChange={handleChangeInput}
              disabled={costFieldData.isDisabled}
            />
            <DropDown
              disabled={false}
              hasError={!!periodFieldData.errorMessage}
              width={100}
              height={45}
              items={selectPeriodOptions}
              title={'Выбрать период'}
              activeValue={PERIOD_VALUES.indexOf(
                periodFieldData.value || 'hour'
              )}
              handleChange={(value) => {
                handleChangeSelect(PERIOD_VALUES[value || 0])
              }}
            />
          </Box>
        )}
      </InputWrapper>
    )
  }
)
