export class EventBus {
  private listeners: Record<string, ((args: unknown) => unknown)[]> = {}
  on(eventName: string, listener: (args: unknown) => unknown) {
    const listeners = this.listeners[eventName]
    if (listeners) {
      listeners.push(listener)
    } else {
      this.listeners[eventName] = [listener]
    }
  }
  off(eventName: string, listener: unknown) {
    const listeners = this.listeners[eventName]
    if (listeners) {
      this.listeners[eventName] = listeners.filter((item) => item !== listener)
    }
  }
  emit(eventName: string, payload?: unknown) {
    const listeners = this.listeners[eventName]
    if (listeners) {
      listeners.forEach((item) => item(payload))
    }
  }
}
