import { IFilterSelectValue } from '@pages/Space/Employee/interfaces'

export const LIMIT_EMPLOYEES_LIST = 20
export const LIMIT_WORK_OBJECTS_LIST = 20
export const LIMIT_INVITATIONS = 20
export const LIMIT_VEHICLES_LIST = 20

export const texts = {
  add: 'Добавление',
  edit: 'Редактирование',
}

export const filterValues: IFilterSelectValue[] = [
  {
    name: 'Все',
    id: 1,
  },
  {
    name: 'Водители',
    id: 2,
  },
  {
    name: 'Остальные сотрудники',
    id: 3,
  },
]

export const dependentPermissions = {
  balance_management: [
    'ownership_type_edit',
    'ownership_type_view',
    'ownership_bank_account_view',
    'ownership_bank_account_edit',
    'ownership_contract_view',
    'ownership_contract_edit',
  ],
  ownership_type_edit: ['ownership_type_view'],
  ownership_bank_account_edit: ['ownership_bank_account_view'],
  ownership_contract_edit: ['ownership_contract_view'],
  employee_edit: ['employee_view'],
  deal_work_shift_edit: [
    'employee_view',
    'ownership_type_view',
    'ownership_bank_account_view',
    'deal_work_shift_decision',
    'deal_management',
  ],
  deal_work_shift_decision: [
    'employee_view',
    'ownership_type_view',
    'ownership_bank_account_view',
    'deal_work_shift_edit',
    'deal_management',
  ],
  deal_management: [
    'employee_view',
    'ownership_type_view',
    'ownership_bank_account_view',
    'deal_work_shift_edit',
    'deal_work_shift_decision',
  ],
}
