import { chatEventBus } from '@pages/Chat/constants'
import { Reducer } from '@reduxjs/toolkit'

export const addChatListMessageReducer: Reducer = (state, action) => {
  const message = action.payload.message
  const userId = action.payload.userId
  const profileId = action.payload.profileId
  const spaceId = action.payload.spaceId
  const group = state.chatListProjectGroups.find(
    (item: any) => item.id === message.group_id
  )
  const currentOpenedGroupId = state.currentChatGroup?.id

  if (group) {
    //перемещаем группу наверх списка
    const index = state.chatListProjectGroups.indexOf(group)
    state.chatListProjectGroups.splice(index, 1)
    state.chatListProjectGroups.unshift(group)

    //если сообщение не свое, инкрементируем счетчик в заголовке группы
    if (message.user.id !== userId) {
      group.new_message_cnt += 1
    }

    //Проверяем, открыта ли группа, к чату которой относится сообщение
    if (currentOpenedGroupId === message.group_id) {
      const chat = state.currentChatGroup?.chats?.find(
        (item: any) => item.id === message.chat_id
      )

      if (chat) {
        const index = state.currentChatGroup?.chats?.indexOf(chat) || 0
        if (chat.pinned) {
          //Если чат закреплен, просто двигаем его наверх

          state.currentChatGroup?.chats?.splice(index, 1)
          state.currentChatGroup?.chats?.unshift(chat)
        } else {
          //находим последний закрепленный
          const lastPinnedChatIndex =
            //@ts-ignore
            state.currentChatGroup?.chats?.findLastIndex(
              (item: any) => item.pinned
            )
          //перемещаем чат с новым сообщением сразу после закрепленного
          state.currentChatGroup?.chats?.splice(index, 1)
          state.currentChatGroup?.chats?.splice(
            lastPinnedChatIndex + 1,
            0,
            chat
          )
        }
        chat.last_message = message
        if (message.user.id !== userId) {
          chat.new_message_cnt += 1
        }
      } else {
        const groupId = message.group_id
        setTimeout(() => {
          chatEventBus.emit('newChatInOpenedGroup', {
            groupId,
            spaceId,
            profileId,
          })
        })
      }
    }
  } else {
    const groupId = message.group_id
    setTimeout(() => {
      chatEventBus.emit('newMessageInUnloadedGroup', {
        groupId,
        spaceId,
        profileId,
      })
    })
  }
}
