import IconPropsInterface from './IconPropsInterface'

export const ArrowRight = ({ size = 20, color, style }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 7 12"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{ ...style, flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M1 11L6.07814 6.76822C6.55789 6.36843 6.55789 5.63157 6.07813 5.23178L1 1"
        strokeLinecap="round"
      />
    </svg>
  )
}
