import IconPropsInterface from '@icons/IconPropsInterface'

export const UserPlusLine = ({
  size = 20,
  color,
  style,
  className,
}: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style, flexShrink: 0, transition: '300ms' }}
      className={className}
    >
      <path
        d="M27.3386 7.33268C27.3386 2.63759 23.727 0.666016 18.6707 0.666016C13.6145 0.666016 10.0028 2.63759 10.0028 7.33268C10.0028 12.0278 13.6712 13.9993 18.0051 13.9993V16.9399C18.0051 17.3062 18.0051 17.4893 18.1197 17.6677C18.2 17.7927 18.3916 17.9339 18.5349 17.9735C18.7392 18.03 18.8744 17.988 19.1448 17.9041C23.0614 16.5571 27.3386 12.0278 27.3386 7.33268Z"
        fill={color}
      />
      <path
        d="M2.8758 9.10868C3.25522 8.57898 4.0171 8.51382 4.47498 8.97193L7.45484 11.9532C7.79523 12.2938 7.85865 12.8239 7.60863 13.2386L5.61054 15.7127L12.2957 22.3979L14.7682 20.4016C15.1828 20.1515 15.7126 20.215 16.053 20.5555L19.0328 23.5368C19.4906 23.9948 19.4256 24.7571 18.8961 25.1367L17.1448 26.3923C15.5762 27.5169 13.4561 27.672 11.7467 26.6447C6.95785 23.7663 4.21927 21.082 1.34581 16.2453C0.892271 15.4819 0.67448 14.6387 0.671898 13.8005C0.668717 12.7637 0.994878 11.7346 1.61151 10.8737L2.8758 9.10868Z"
        fill={color}
      />
    </svg>
  )
}
