import { bonusesApi } from '@pages/Space/Bonuses/bonusesApi'
import { ICatalogItem } from '@pages/Space/Bonuses/types/ICatalogItem'
import { IHistory } from '@pages/Space/Bonuses/types/IHistory'
import { IPrize } from '@pages/Space/Bonuses/types/IPrize'
import { IRecipient } from '@pages/Space/Bonuses/types/IRecipient'
import { ICatalogResponse } from '@pages/Space/Bonuses/types/api/ICatalogResponse'
import { IHistoryResponse } from '@pages/Space/Bonuses/types/api/IHistoryResponse'
import { IRecipientsResponse } from '@pages/Space/Bonuses/types/api/IRecipientsResponse'
import {
  EntityAdapter,
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'
import { IPaginatedItems } from '@src/shared/interfaces/apiResponse/IPagination'
import { ISuccessResponse } from '@src/shared/interfaces/apiResponse/ISuccessResponse'
import { IOption } from '@src/shared/interfaces/partials/IOption'
import { RootState } from '@src/store'
import {
  IPaginationAdditionalState,
  clearAdapterItems,
  getInitialPaginationState,
} from '@src/utils/helpers/getInitialPaginationState'

interface IPrizeAdditionalState extends IPaginationAdditionalState {
  cancelPrize: null | IPrize
}

interface IHistoryAdditionalState extends IPaginationAdditionalState {
  type: IOption | null
  event: IOption | null
}

interface ICatalogAdditionalState extends IPaginationAdditionalState {
  selectedPrize: ICatalogItem | null
}

type IResponse =
  | IRecipientsResponse
  | ICatalogResponse
  | ISuccessResponse<IPaginatedItems<IPrize>>
  | IHistoryResponse

const addItems = (
  adapter: EntityAdapter<any>,
  state: any,
  payload: IResponse
) => {
  adapter.addMany(state, payload.data.items)
  state.page = state.page + 1
  if (state.totalCount === null) {
    state.totalCount = payload.data.pagination.total_items
  }
}

const recipientsInitialState: IPaginationAdditionalState =
  getInitialPaginationState()

const catalogInitialState: ICatalogAdditionalState = {
  ...getInitialPaginationState(),
  selectedPrize: null,
}

const prizesInitialState: IPrizeAdditionalState = {
  ...getInitialPaginationState(),
  cancelPrize: null,
}

const historyInitialState: IHistoryAdditionalState = {
  ...getInitialPaginationState(),
  type: null,
  event: null,
}

const recipientsAdapter = createEntityAdapter<IRecipient>()
const catalogAdapter = createEntityAdapter<ICatalogItem>()
const prizesAdapter = createEntityAdapter<IPrize>()
const historyAdapter = createEntityAdapter<IHistory>()

const initialState = {
  recipients: recipientsAdapter.getInitialState(recipientsInitialState),
  catalog: catalogAdapter.getInitialState(catalogInitialState),
  prizes: prizesAdapter.getInitialState(prizesInitialState),
  history: historyAdapter.getInitialState(historyInitialState),
}

const bonusesSlice = createSlice({
  name: 'bonuses',
  initialState: initialState,
  reducers: {
    catalogCleared: (state) => {
      clearAdapterItems(catalogAdapter, state.catalog)
    },
    prizesCleared: (state) => {
      clearAdapterItems(prizesAdapter, state.prizes)
    },
    recipientsCleared: (state) => {
      clearAdapterItems(recipientsAdapter, state.recipients)
    },
    historyCleared: (state) => {
      clearAdapterItems(historyAdapter, state.history)
    },
    historyTypeChanged: (state, { payload }: PayloadAction<IOption | null>) => {
      state.history.type = payload
    },
    historyEventChanged: (
      state,
      { payload }: PayloadAction<IOption | null>
    ) => {
      state.history.event = payload
    },
    cancelPrizeChanged: (state, { payload }: PayloadAction<IPrize | null>) => {
      state.prizes.cancelPrize = payload
    },
    selectedPrizeChanged: (
      state,
      { payload }: PayloadAction<ICatalogItem | null>
    ) => {
      state.catalog.selectedPrize = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        bonusesApi.endpoints.getTopRecipients.matchFulfilled,
        (state, { payload }) => {
          addItems(recipientsAdapter, state.recipients, payload)
        }
      )
      .addMatcher(
        bonusesApi.endpoints.getActualPrizes.matchFulfilled,
        (state, { payload }) => {
          addItems(catalogAdapter, state.catalog, payload)
        }
      )
      .addMatcher(
        bonusesApi.endpoints.getOldPrizes.matchFulfilled,
        (state, { payload }) => {
          addItems(catalogAdapter, state.catalog, payload)
        }
      )
      .addMatcher(
        bonusesApi.endpoints.getMyPrizes.matchFulfilled,
        (state, { payload }) => {
          addItems(prizesAdapter, state.prizes, payload)
        }
      )
      .addMatcher(bonusesApi.endpoints.cancelPrize.matchFulfilled, (state) => {
        clearAdapterItems(prizesAdapter, state.prizes)
        state.prizes.cancelPrize = null
      })
      .addMatcher(
        bonusesApi.endpoints.getHistory.matchFulfilled,
        (state, { payload }) => {
          addItems(historyAdapter, state.history, payload)
        }
      )
      .addMatcher(bonusesApi.endpoints.getPrize.matchFulfilled, (state) => {
        clearAdapterItems(catalogAdapter, state.catalog)
        state.catalog.selectedPrize = null
      })
  },
})

export const bonusesReducer = bonusesSlice.reducer

export const {
  catalogCleared,
  recipientsCleared,
  historyCleared,
  prizesCleared,
  cancelPrizeChanged,
  selectedPrizeChanged,
  historyTypeChanged,
  historyEventChanged,
} = bonusesSlice.actions

export const {
  selectAll: selectRecipients,
  selectById: selectRecipientById,
  selectIds: selectRecipientsIds,
} = recipientsAdapter.getSelectors<RootState>(
  (state) => state.bonuses.recipients
)

export const {
  selectAll: selectCatalogItems,
  selectById: selectCatalogItemById,
  selectIds: selectCatalogItemsIds,
} = catalogAdapter.getSelectors<RootState>((state) => state.bonuses.catalog)

export const {
  selectAll: selectPrizes,
  selectById: selectPrizeById,
  selectIds: selectPrizesIds,
} = prizesAdapter.getSelectors<RootState>((state) => state.bonuses.prizes)

export const {
  selectAll: selectHistoryItems,
  selectById: selectHistoryItemById,
  selectIds: selectHistoryItemsIds,
} = historyAdapter.getSelectors<RootState>((state) => state.bonuses.history)
