import { css } from 'styled-components'

import { getBgColor, getColor } from '@theme/helpers'
import {
  BorderVariant,
  FillVariant,
  SizeVariant,
} from '@ui/Buttons/Base/interfaces'

//В большинстве своем варианты названы, основываясь на названиях из макета.
//Из трех видов вариантов (fill, size border), как из конструктора, собираются нужные параметры.

// Создавать новые кнопки (которых еще нет на макете, или которые не были сверстаны) нужно
// путем расширения вариантов (если не нашлось нужной комбинации).
// И далее уже у себя в модуле в отдельном компоненте создаем нужную кнопку - комбинацию вариантов.

// Не нужно смешивать стили из разных видов вариантов. То есть, если создаем новый вариант для border,
//то пишем там только толщину, цвет и вид бордера. Если в данной кнопке также новый бэкграунд, которого в данный момент нет,
//то не пишем его в border, а создаем новый вариант fill

// Border-radius вынес не в border, а в size, потому что на макете прослеживается прямая корреляция
//между размерами кнопки и радиусом бордера.

//Color вынес в fill, так как по сути оба параметра отвечают за цвет

// Общие стили для нескольких вариантов
const commonSizeVariantsMixin = css`
  width: 100%;
  line-height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const sizeVariants: Record<
  NonNullable<SizeVariant>,
  ReturnType<typeof css>
> = {
  regular: css`
    font-size: 16px;
    line-height: 27px;
    padding: 9px 42px;
    border-radius: 7px;
  `,
  reduced: css`
    font-size: 16px;
    line-height: 27px;
    padding: 9px 35px;
    border-radius: 7px;
  `,
  small: css`
    font-size: 16px;
    line-height: 27px;
    padding: 7px 25px;
    border-radius: 7px;
  `,
  arrow: css`
    ${commonSizeVariantsMixin}
    max-width: 20px;
    height: 20px;
    border-radius: 3px;
  `,
  addMedia: css`
    ${commonSizeVariantsMixin}
    max-width: 92px;
    height: 64px;
    border-radius: 5px;
  `,
  addAccount: css`
    ${commonSizeVariantsMixin}
    max-width: 30px;
    height: 30px;
    border-radius: 4px;
  `,
  close: css`
    ${commonSizeVariantsMixin}
    max-width: 15px;
    height: 15px;
    border-radius: 3px;
  `,
  closeLarge: css`
    ${commonSizeVariantsMixin}
    max-width: 35px;
    height: 35px;
    border-radius: 7px;
  `,
  calendar: css`
    ${commonSizeVariantsMixin}
    max-width: 25px;
    height: 25px;
    border-radius: 3px;
  `,
  text: css``,
  audio: css`
    ${commonSizeVariantsMixin}
    max-width: 24px;
    height: 24px;
    border-radius: 50%;
  `,
  period: css`
    ${commonSizeVariantsMixin}
    font-size: 14px;
    line-height: 24px;
    padding: 5px;
    border-radius: 7px;
  `,
}

export const fillVariants: Record<
  NonNullable<FillVariant>,
  ReturnType<typeof css>
> = {
  fullFilled: css`
    ${getColor('light')}
    ${getBgColor('primary')}
    &:hover {
      ${getBgColor('primaryHover')}
    }

    &:active {
      opacity: 0.75;
    }

    &:disabled {
      opacity: 0.15;
    }
  `,
  lightFilled: css`
    ${getBgColor('primaryLight')}
    &:hover {
      ${getBgColor('primaryHoverLight')}
    }

    &:active {
      opacity: 0.75;
    }

    &:disabled {
      opacity: 0.5;
    }
  `,
  accentFilled: css`
    ${getBgColor('accent20')}
    ${getColor('accent')}
    &:hover {
      ${getBgColor('accent15')}
    }

    &:active {
      opacity: 0.75;
    }

    &:disabled {
      opacity: 0.5;
    }
  `,
  arrow: css`
    ${getBgColor('light')}
    &:hover {
      ${getBgColor('primary')}
    }
  `,
  withBorder: css`
    background-color: transparent;

    &:hover {
      ${getBgColor('primaryLight')}
    }

    &:active {
      opacity: 0.75;
    }

    &:disabled {
      opacity: 0.7;
    }
  `,
  close: css`
    ${getBgColor('gray90')}
    &:hover {
      ${getBgColor('accent')}
    }
  `,
  addAccount: css`
    background-color: transparent;

    &:hover {
      ${getBgColor('primary')}
    }
  `,
  text: css`
    ${getColor('gray50')}
    background-color: transparent;

    &:hover {
      ${getColor('primary')}
    }
  `,
  hoverOpacity: css`
    &:hover {
      opacity: 0.8;
    }
  `,
  none: css`
    background-color: transparent;
  `,
  inverted: css`
    ${getColor('primary')}
    ${getBgColor('light')}
    &:hover {
      ${getBgColor('gray80')}
    }

    &:active {
      opacity: 0.75;
    }

    &:disabled {
      opacity: 0.15;
    }
  `,
  paid: css`
    ${getBgColor('refill')}
    ${getColor('light')}
    &:hover {
      ${getBgColor('refill10')}
    }

    &:disabled {
      opacity: 0.5;
    }
  `,
  paidLight: css`
    ${getBgColor('green20')}
    ${getColor('green')}
    &:disabled {
      opacity: 0.5;
    }
  `,
  delivery10: css`
    ${getBgColor('delivery10')}

    &:hover {
      ${getBgColor('delivery20')}
    }
  `,
}
export const borderVariants: Record<
  NonNullable<BorderVariant>,
  ReturnType<typeof css>
> = {
  regular: css`
    border: 1px solid ${({ theme }) => theme.colors.primary};
  `,
  audio: css`
    outline: 5px solid ${({ theme }) => theme.colors.primaryLight};
  `,
  ownAudio: css`
    border: 1px solid ${({ theme }) => theme.colors.light};
  `,
  none: css`
    border: none;
  `,
  gray30: css`
    border: 1px solid ${({ theme }) => theme.colors.gray30};

    &:disabled {
      border-color: ${({ theme }) => theme.colors.gray50};
    }
  `,
}
