import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from '@src/store'
import { reAuthQuery, refreshToken } from '@src/store/slices/baseAuth'
import { ACCESS_TOKEN, baseURL } from '@src/utils/constants/auth'

const baseQuery = fetchBaseQuery({
  baseUrl: baseURL,
  prepareHeaders: (headers, { getState }) => {
    const state = getState() as RootState
    const token = state.reg.access_token || refreshToken || ACCESS_TOKEN
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }
    return headers
  },
})

export const apiSlice = createApi({
  baseQuery: reAuthQuery(baseQuery),
  endpoints: (builder) => {
    return {}
  },
})
