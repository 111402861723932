import IconPropsInterface from '@icons/IconPropsInterface'
import { useWindowResize } from '@src/hooks/useWindowResize'

export const LogoBack = ({ size = 20, color }: IconPropsInterface) => {
  const { isDesktop } = useWindowResize()
  return (
    <svg
      width={isDesktop ? '309' : '100'}
      height={isDesktop ? '280' : '150'}
      viewBox="0 0 309 280"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.05">
        <path
          d="M263.563 262.998C270.605 255.983 276.973 248.294 282.544 240.029C263.949 233.206 247.259 223.668 233.536 209.421C217.762 193.059 207.102 172.925 202.013 151.374L182.477 185.731C189.664 201.852 199.649 216.82 212.215 229.867C219.137 237.05 226.71 243.513 234.886 249.159C188.555 288.177 120.204 288.177 73.8726 249.159C82.0245 243.513 89.6218 237.026 96.5438 229.867C108.699 217.253 118.443 202.837 125.558 187.293L106.263 153.32C101.006 174.151 90.5142 193.563 75.2232 209.445C61.4999 223.692 44.8342 233.206 26.2148 240.053C31.7862 248.318 38.1534 256.007 45.1959 263.022C105.395 322.991 203.339 322.991 263.539 263.022"
          fill="#006CEC"
        />
        <path
          d="M60.4645 72.4696C41.2905 94.2851 29.6655 122.852 29.6655 154.11C29.6655 173.138 33.9585 191.157 41.6523 207.279C39.4816 208.624 37.2627 209.898 35.0197 211.123C28.556 214.607 21.8029 217.514 14.8086 219.844C5.30602 199.927 0 177.631 0 154.11C0 111.728 17.2446 73.3346 45.1494 45.5607L60.4645 72.4696Z"
          fill="#006CEC"
        />
        <path
          d="M62.6328 30.9264C70.7124 24.9679 79.2503 19.8985 88.1017 15.718L153.752 127.895L217.786 17.8803C227.337 1.47065 251.238 -14.891 277.527 -27L153.559 191.035L62.6328 30.9264Z"
          fill="#006CEC"
        />
        <path
          d="M182.892 33.7221C163.694 29.2773 143.652 29.3734 124.502 34.0584L122.5 30.5506C118.11 22.8624 113.721 15.1981 109.355 7.55789C138.201 -1.13947 169.096 -1.30765 198.014 7.12543L182.892 33.7221Z"
          fill="#006CEC"
        />
        <path
          d="M263.568 45.877C291.449 73.6509 308.718 112.044 308.718 154.426C308.718 177.947 303.412 200.243 293.909 220.161C286.915 217.83 280.162 214.899 273.698 211.439C271.455 210.238 269.236 208.941 267.065 207.595C274.759 191.474 279.052 173.454 279.052 154.426C279.052 122.64 267.041 93.6645 247.312 71.7048L262.579 44.8679C262.917 45.2043 263.255 45.5166 263.592 45.853"
          fill="#006CEC"
        />
      </g>
    </svg>
  )
}
