import { createSlice } from '@reduxjs/toolkit'
import { IOption } from '@src/shared/interfaces/partials/IOption'

interface IDeliveryFilterAction {
  type: any
  payload: {
    materialId: number
    replacedMaterialId?: number
    characteristicId?: number
    data: any
  }
}

export interface IDeliveryFilterCharacteristics {
  [key: number]: {
    //key - id характеристики
    options?: IOption<number | null>[]
    type: 'list' | 'string'
    label: string
    value: string | IOption<null | number>
  }
}

export interface IDeliveryState {
  materialsOrderArray: number[] //нужен для сохранения порядка рендера фильтров материалов, потому что в объекте ключи сортируются
  materials: {
    [key: number]: {
      //key - id материала
      label: string
      currentUnit: IOption<number | null>
      units: IOption[]
      quantityDiapason: [string, string]
      characteristics: IDeliveryFilterCharacteristics
    }
  }
  radius: number
  coordinates: [number, number] | null
}

const initialState: IDeliveryState = {
  materialsOrderArray: [],
  materials: {},
  radius: 1,
  coordinates: null,
}

const filterSliceDelivery = createSlice({
  name: 'filterDelivery',
  initialState,
  reducers: {
    resetDeliveryFilter: () => initialState,
    setDiapason: (state, action) => {
      state.materials[action.payload.materialId].quantityDiapason =
        action.payload.data
    },
    setUnit: (state, action) => {
      state.materials[action.payload.materialId].currentUnit =
        action.payload.data
    },
    setRadius: (state, action) => {
      state.radius = action.payload
    },
    setCoords: (state, action) => {
      state.coordinates = action.payload
    },
    setCharacteristics: (state, action: IDeliveryFilterAction) => {
      state.materials[action.payload.materialId].characteristics =
        action.payload.data
    },
    setCharacteristicValue: (state, action: IDeliveryFilterAction) => {
      state.materials[action.payload.materialId].characteristics[
        action.payload.characteristicId!
      ].value = action.payload.data
    },
    addMaterial: (state, action: IDeliveryFilterAction) => {
      state.materialsOrderArray.push(action.payload.materialId)
      state.materials[action.payload.materialId] = {
        label: action.payload.data.label,
        quantityDiapason: ['', ''],
        units: action.payload.data.units,
        currentUnit: {
          label: 'Не выбрано',
          value: null,
        },
        characteristics: {},
      }
    },
    deleteMaterial: (state, action) => {
      const index = state.materialsOrderArray.indexOf(
        action.payload.materialId!
      )
      state.materialsOrderArray.splice(index, 1)
      delete state.materials[action.payload.materialId!]
    },
    replaceMaterial: (state, action: IDeliveryFilterAction) => {
      const index = state.materialsOrderArray.indexOf(
        action.payload.replacedMaterialId!
      )
      state.materialsOrderArray.splice(index, 1, action.payload.materialId)
      delete state.materials[action.payload.replacedMaterialId!]
      state.materials[action.payload.materialId] = {
        label: action.payload.data.label,
        quantityDiapason: ['', ''],
        units: action.payload.data.units,
        currentUnit: {
          label: 'Не выбрано',
          value: null,
        },
        characteristics: {},
      }
    },
  },
})

export const {
  resetDeliveryFilter,
  setDiapason,
  setUnit,
  setRadius,
  setCoords,
  setCharacteristicValue,
  setCharacteristics,
  addMaterial,
  replaceMaterial,
  deleteMaterial,
} = filterSliceDelivery.actions

export const filterSliceDeliveryReducer = filterSliceDelivery.reducer
