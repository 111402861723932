import { CSSProperties, MouseEventHandler } from 'react'

import { checkFeature } from '@src/shared/featureToggle/checkFeature'
import { useGetTheme } from '@theme/hooks'
import { BaseIconButton } from '@ui/Buttons/Base/BaseIconButton'
import { SizeVariant } from '@ui/Buttons/Base/interfaces'

type CloseButtonType = 'small' | 'large'

interface IProps {
  onClick?: MouseEventHandler<HTMLButtonElement>
  type?: CloseButtonType
  style?: CSSProperties
  className?: string
}

const types: {
  [key in CloseButtonType]: { sizeVariant: SizeVariant; iconSize: number }
} = {
  small: {
    sizeVariant: 'close',
    iconSize: 15,
  },
  large: {
    sizeVariant: 'closeLarge',
    iconSize: 24,
  },
}

export const CloseButton = ({
  onClick,
  style,
  className,
  type = checkFeature('#8693bkx1c') ? 'large' : 'small',
}: IProps) => {
  const theme = useGetTheme()
  return (
    <BaseIconButton
      fillVariant="close"
      sizeVariant={types[type].sizeVariant}
      iconName="Close"
      iconColor={theme.colors.gray40}
      iconSize={types[type].iconSize}
      hoverIconColor={theme.colors.light}
      onClick={onClick}
      style={style}
      className={className}
      type={'button'}
    />
  )
}
