import styled, { CSSProperties } from 'styled-components'

import { TechBus } from '@icons/TechBus'
import { TechLoader } from '@icons/TechLoader'
import { TechTractor } from '@icons/TechTractor'
import { TechTruck } from '@icons/TechTruck'
import { useToggle } from '@src/hooks/useToggle'
import { useWindowResize } from '@src/hooks/useWindowResize'
import { useGetTaskTypesQuery } from '@src/services/dirs/taskTypes'
import { TaskType } from '@src/shared/interfaces/task/TaskType'
import { useGetTheme } from '@theme/hooks'
import { BaseSkeleton } from '@ui/BaseSkeleton'

interface IProps {
  taskType: TaskType //для определения цвета, текста и иконки
  isFullColored?: boolean //вариация полноцветного и бледного фона
  iconOnly?: boolean // будет ли отображаться текст
  isInteractive?: boolean // компонент выступает в роли простого тега или переключателя для сортировки. В случае переключателя нужно обернуть в BaseButton
  style?: CSSProperties
  className?: string
  iconSize?: number
  customText?: string
}

const TagStyled = styled.div<{
  iconOnly: boolean
  color: string
  bgColor: string
  isInteractive: boolean
}>`
  overflow: hidden;
  padding: 0 ${({ iconOnly }) => (iconOnly ? 0 : 12)}px;
  display: flex;
  height: 34px;
  width: ${({ iconOnly }) => (iconOnly ? '34px' : 'fit-content')};
  align-items: center;
  justify-content: ${({ iconOnly }) => (iconOnly ? 'center' : 'flex-start')};
  border-radius: 7px;
  color: ${({ color }) => color};
  background-color: ${({ bgColor }) => bgColor};
  pointer-events: ${({ isInteractive }) => (isInteractive ? 'all' : 'none')};
  cursor: ${({ isInteractive }) => (isInteractive ? 'pointer' : 'default')};
  transition: 300ms;

  ${({ theme }) => theme.mediaQueries.small`
    height: 26px;
    width: ${(props: { iconOnly: boolean }) =>
      props.iconOnly ? '26px' : 'fit-content'}; 
    border-radius: 4px;  
  `}
`

const TextStyled = styled.div`
  margin-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const TaskTypeTag = ({
  taskType,
  isFullColored = false,
  iconOnly = false,
  isInteractive = false,
  style,
  className,
  iconSize,
  customText,
}: IProps) => {
  const theme = useGetTheme()
  const { data: taskTypesData, isLoading: isLoadingTaskTypes } =
    useGetTaskTypesQuery({})

  const texts = taskTypesData?.data.reduce<Record<TaskType, string>>(
    (acc, item) => {
      acc[item.slug] = item.name
      return acc
    },
    {} as Record<TaskType, string>
  )
  const { isOn: isHovered, on: hover, off: unhover } = useToggle(false)
  const { isMobile } = useWindowResize()

  const textAndIconsColors = {
    cargo: theme.colors.cargo,
    passenger: theme.colors.passenger,
    rent: theme.colors.rent,
    delivery: theme.colors.delivery,
  }
  const bgColors = {
    cargo: theme.colors[isFullColored ? 'cargo' : 'cargo20'],
    passenger: theme.colors[isFullColored ? 'passenger' : 'passenger20'],
    rent: theme.colors[isFullColored ? 'rent' : 'rent20'],
    delivery: theme.colors[isFullColored ? 'delivery' : 'delivery20'],
  }
  const hoverBgColors = {
    cargo: theme.colors.cargo10,
    passenger: theme.colors.passenger10,
    rent: theme.colors.rent10,
    delivery: theme.colors.delivery10,
  }

  const textAndIconColor =
    isFullColored && !isHovered
      ? theme.colors.light
      : textAndIconsColors[taskType]

  const icons = {
    cargo: (
      <TechTruck
        size={iconSize ?? isMobile ? 14 : 16}
        color={textAndIconColor}
      />
    ),
    passenger: (
      <TechBus size={iconSize ?? isMobile ? 14 : 16} color={textAndIconColor} />
    ),
    rent: (
      <TechTractor
        size={iconSize ?? isMobile ? 14 : 18}
        color={textAndIconColor}
      />
    ),
    delivery: (
      <TechLoader
        size={iconSize ?? isMobile ? 14 : 16}
        color={textAndIconColor}
      />
    ),
  }

  if (isLoadingTaskTypes) {
    return (
      <BaseSkeleton
        style={{
          width: iconOnly ? (isMobile ? '26px' : '33px') : '180px',
          height: isMobile ? '26px' : '33px',
          borderRadius: '5px',
          marginRight: style?.marginRight,
          marginBottom: style?.marginBottom,
        }}
        className={className}
      />
    )
  }

  return (
    <TagStyled
      style={style}
      iconOnly={iconOnly}
      isInteractive={isInteractive}
      color={textAndIconColor}
      bgColor={
        isHovered || iconOnly ? hoverBgColors[taskType] : bgColors[taskType]
      }
      onMouseEnter={hover}
      onMouseLeave={unhover}
      className={className}
    >
      {icons[taskType]}
      {!iconOnly && (
        <TextStyled>{customText ? customText : texts?.[taskType]}</TextStyled>
      )}
    </TagStyled>
  )
}
