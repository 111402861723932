import { addChatListMessageReducer } from '@pages/Chat/chatSlice/addChatListMessageReducer'
import { addChatListProjectGroupsDataReducer } from '@pages/Chat/chatSlice/addChatListProjectGroupsDataReducer'
import { addChatMessageReducer } from '@pages/Chat/chatSlice/addChatMessageReducer'
import { addGroupToExcludeReducer } from '@pages/Chat/chatSlice/addGroupToExcludeReducer'
import { decrementChatNewMessagesCountReducer } from '@pages/Chat/chatSlice/decrementChatNewMessagesCountReducer'
import { decrementCommonMessagesCountReducer } from '@pages/Chat/chatSlice/decrementCommonMessagesCountReducer'
import { decrementGroupNewMessagesCountReducer } from '@pages/Chat/chatSlice/decrementGroupNewMessagesCountReducer'
import { handleGotNewGroupReducer } from '@pages/Chat/chatSlice/handleGotNewGroupReducer'
import { incrementCommonMessagesCountReducer } from '@pages/Chat/chatSlice/incrementCommonMessagesCountReducer'
import { readMessageReducer } from '@pages/Chat/chatSlice/readMessageReducer'
import { renameChatReducer } from '@pages/Chat/chatSlice/renameChatReducer'
import { resetChatStateReducer } from '@pages/Chat/chatSlice/resetChatStateReducer'
import { selectMessageReducer } from '@pages/Chat/chatSlice/selectMessageReducer'
import { unselectMessageReducer } from '@pages/Chat/chatSlice/unselectMessageReducer'
import { createSlice } from '@reduxjs/toolkit'

import { LIMIT_CHAT_LIST, LIMIT_CHAT_MESSAGES } from '../constants'
import {
  IChatGroupedByDateMessages,
  IChatInfo,
  IChatListProjectGroup,
  TypeContent,
} from '../interfaces'

export interface IChatState {
  isSocketOnline: boolean

  executorMessagesCount: number
  customerMessagesCount: number

  lastMessagesCount: number
  cursorMessages: string | null
  messagesDateGroups: IChatGroupedByDateMessages[]
  selectedMessages: number[]
  attachType: TypeContent | null

  cursorChatList: string | null
  lastChatGroupsCount: number
  chatListProjectGroups: IChatListProjectGroup[]
  currentChatInfo: IChatInfo | null
  currentChatGroup: IChatListProjectGroup | null
  excludeGroupIds: number[]

  isDisabledChatList: boolean

  commonNewMessagesCount: number
}

export interface IAddMessageAction {
  payload: {
    // message: IChatMessage
    message: any
    isSocket?: boolean
  }
  type: string
}

const initialState: IChatState = {
  isSocketOnline: true,
  executorMessagesCount: 0,
  customerMessagesCount: 0,
  //сообщения одного чата
  lastMessagesCount: LIMIT_CHAT_MESSAGES,
  cursorMessages: null,
  messagesDateGroups: [],
  selectedMessages: [],
  attachType: null,

  //список чатов, сгруппированных по проектам (заявкам)
  cursorChatList: null,
  lastChatGroupsCount: LIMIT_CHAT_LIST,
  chatListProjectGroups: [],
  currentChatInfo: null,
  currentChatGroup: null,
  excludeGroupIds: [],

  //Блокируем переключение чатов, пока идет загрузка чата, для согласования данных
  isDisabledChatList: false,

  commonNewMessagesCount: 0,
}

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    renameChat: renameChatReducer,
    handleGotNewGroup: handleGotNewGroupReducer,
    addChatListMessage: addChatListMessageReducer,
    incrementCommonMessagesCount: incrementCommonMessagesCountReducer,
    decrementCommonMessagesCount: decrementCommonMessagesCountReducer,
    decrementGroupNewMessagesCount: decrementGroupNewMessagesCountReducer,
    decrementChatNewMessagesCount: decrementChatNewMessagesCountReducer,
    addGroupToExclude: addGroupToExcludeReducer,
    readMessage: readMessageReducer,
    addChatMessage: addChatMessageReducer,
    resetChatState: resetChatStateReducer,
    selectMessage: selectMessageReducer,
    unSelectMessage: unselectMessageReducer,
    addChatListProjectGroupsData: addChatListProjectGroupsDataReducer,
    removeGroup: (state, action) => {
      state.chatListProjectGroups = state.chatListProjectGroups.filter(
        (item) => item.id !== action.payload
      )
    },
    setValueInStateChat: (state, action) => {
      const field = action.payload.field as keyof IChatState
      state[field] = action.payload.data as never
    },
  },
})

export const {
  renameChat,
  readMessage,
  setValueInStateChat,
  selectMessage,
  unSelectMessage,
  addChatMessage,
  addChatListMessage,
  addChatListProjectGroupsData,
  resetChatState,
  incrementCommonMessagesCount,
  decrementCommonMessagesCount,
  decrementGroupNewMessagesCount,
  decrementChatNewMessagesCount,
  handleGotNewGroup,
  removeGroup,
} = chatSlice.actions
export const chatReducer = chatSlice.reducer
