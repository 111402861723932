import {
  getFilterCargoPayload,
  getFilterDeliveryPayload,
  getFilterPassengerPayload,
  getFilterRentPayload,
} from './helpers/sendPayloadTransformHelpers'

export const LIMIT_TASKS = 10
export const POINT_TYPE_TRANSLATE = {
  from: 'Откуда',
  stop: 'Остановка',
  where: 'Куда',
}
export const filtersPayloadHelpersMap = {
  cargo: getFilterCargoPayload,
  delivery: getFilterDeliveryPayload,
  rent: getFilterRentPayload,
  passenger: getFilterPassengerPayload,
}

export const filterRadioGroupVariants = [
  {
    label: 'Показать избранные',
    value: 'favorite',
  },
  {
    label: 'Показать скрытые',
    value: 'hidden',
  },
  {
    label: 'Показать все',
    value: 'all',
  },
]

export const DEFAULT_COORDINATES = [55.75222, 37.61556] //Москва
export const DEFAULT_MAP_ZOOM = 10
export const REVERSE_GEOCODING_URL =
  'https://suggestions.dadata.ru/suggestions/api/4_1/rs/geolocate/address'
export const tabItems = [
  {
    id: 'all',
    text: 'Все задачи',
  },
  {
    id: 'forMe',
    text: 'Задачи для меня',
  },
]

export const RANGE_INPUT_POINTS = [
  {
    label: '1',
    number: 1,
  },
  {
    label: '100',
    number: 100,
  },
  {
    label: '200',
    number: 200,
  },
  {
    label: '300',
    number: 300,
  },
  {
    label: '400',
    number: 400,
  },
  {
    label: '500',
    number: 500,
  },
]
