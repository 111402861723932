import { store } from '@src/store'
import { clientApiSlice } from '@src/store/slices/clientApiSlice'
import { chatUrl } from '@src/utils/constants/auth'
import { formatUrlFromParamsArray } from '@src/utils/helpers/formatUrlFromParamsArray'
import { formatUrlGetParamsFromObject } from '@src/utils/helpers/formatUrlGetParamsFromObject'

import {
  addChatListProjectGroupsData,
  addChatMessage,
  handleGotNewGroup,
} from './chatSlice/chatSlice'
import { setChatStateValue } from './helpers'
import {
  IChatGroupedByDateMessages,
  IChatMessage,
  IResponseMessagesData,
} from './interfaces'
import { transformMessage } from './transformResponseHelpers'

const chatApi = clientApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getChatMessages: builder.query<IChatGroupedByDateMessages[], any>({
      query: ({ chatId, cursor, size, profile_id, space_id }) => ({
        url: `${chatUrl}/chat/${chatId}/messages${formatUrlGetParamsFromObject({
          cursor,
          size,
          profile_id,
          space_id,
        })}`,
        method: 'GET',
      }),
      //@ts-ignore
      transformResponse: ({ data }: IResponseMessagesData) => {
        const userId = store.getState().user.data?.id
        const items = data.items

        const readyItems = items.map((item) => {
          return transformMessage(item, userId || 0)
        })

        for (let i = 0; i < readyItems.length; i++) {
          store.dispatch(
            addChatMessage({
              message: readyItems[i] as IChatMessage,
              isSocket: false,
            })
          )
        }

        setChatStateValue('cursorMessages')(data.pagination.next_cursor)
        setChatStateValue('lastMessagesCount')(readyItems.length)
      },
    }),
    getChatList: builder.query<any, any>({
      query: ({ space_id, profile_id, cursor, size, excludeGroupIds }) => ({
        url: `${chatUrl}/chat/index${formatUrlGetParamsFromObject({
          space_id,
          profile_id,
          size,
          cursor,
        })}${formatUrlFromParamsArray('excluded_ids', excludeGroupIds)}`,

        method: 'GET',
      }),

      transformResponse: (result: any) => {
        const items = result?.data?.items
        if (items) {
          setChatStateValue('cursorChatList')(
            result?.data?.pagination.next_cursor
          )
          setChatStateValue('lastChatGroupsCount')(items.length)
          store.dispatch(addChatListProjectGroupsData(items))
        }
      },
    }),
    getChatGroup: builder.query<any, any>({
      query: ({ spaceId, profileId, groupId }) => ({
        url: `${chatUrl}/chat/group/${groupId}${formatUrlGetParamsFromObject({
          space_id: spaceId,
          profile_id: profileId,
        })}`,
        method: 'GET',
      }),
      transformResponse: (result: any) => {
        const data = result?.data
        if (data) {
          setChatStateValue('currentChatGroup')(data)
          return data
        }
      },
    }),
    getChatGroupWithoutChats: builder.query<any, any>({
      query: ({ spaceId, profileId, groupId }) => ({
        url: `${chatUrl}/chat/group-without-chats/${groupId}${formatUrlGetParamsFromObject(
          {
            space_id: spaceId,
            profile_id: profileId,
          }
        )}`,
        method: 'GET',
      }),
      transformResponse: (result: any) => {
        const data = result?.data
        if (data) {
          store.dispatch(handleGotNewGroup(data))
        }
      },
    }),
    renameChatGroup: builder.mutation<any, any>({
      query: ({ chatGroupId, spaceId, profileId, name }) => ({
        url: `${chatUrl}/chat/group/rename/${chatGroupId}`,
        body: {
          name,
          space_id: spaceId,
          profile_id: profileId,
        },
        method: 'POST',
      }),
    }),
    renameChat: builder.mutation<any, any>({
      query: ({ chatId, spaceId, profileId, name }) => ({
        url: `${chatUrl}/chat/rename/${chatId}`,
        body: {
          name,
          space_id: spaceId,
          profile_id: profileId,
        },
        method: 'POST',
      }),
    }),
    togglePinChat: builder.mutation<any, any>({
      query: ({ chatId, spaceId, profileId, pinned }) => ({
        url: `${chatUrl}/chat/pinned/${chatId}`,
        body: {
          pinned,
          space_id: spaceId,
          profile_id: profileId,
        },
        method: 'POST',
      }),
    }),
    getDrivers: builder.query<any, any>({
      query: ({ spaceId, fio, page, limit }) => ({
        url: `/spaces/${spaceId}/employees/drop-down${formatUrlGetParamsFromObject(
          {
            fio,
            page,
            limit,
          }
        )}`,
        method: 'GET',
      }),
    }),
    getVehicles: builder.query<any, any>({
      query: ({ spaceId, name, page, limit }) => ({
        url: `/vehicles${formatUrlGetParamsFromObject({
          name,
          page,
          limit,
          space_id: spaceId,
        })}`,
        method: 'GET',
      }),
    }),
    createChatFromTask: builder.mutation<any, any>({
      query: ({ projectId, taskId, message }) => ({
        url: `/chat/create/from-task`,
        body: {
          message,
          project_id: projectId,
          task_id: taskId,
        },
        method: 'POST',
      }),
    }),
    createChatFromOffer: builder.mutation<any, any>({
      query: ({ offerId, message }) => ({
        url: `/chat/create/from-offer`,
        body: {
          message,
          offer_id: offerId,
        },
        method: 'POST',
      }),
    }),
    exitChat: builder.mutation<any, any>({
      query: ({ chatId }) => ({
        url: `${chatUrl}/chat/${chatId}/remove-user`,
        method: 'DELETE',
        body: {},
      }),
    }),
    addUsers: builder.mutation<any, any>({
      query: ({ chatId, spaceId, profileId, employees }) => ({
        url: `${chatUrl}/chat/${chatId}/add-users`,
        method: 'POST',
        body: {
          space_id: spaceId,
          profile_id: profileId,
          employees,
        },
      }),
    }),
  }),
})

export const {
  useLazyGetChatMessagesQuery,
  useLazyGetChatListQuery,
  useLazyGetDriversQuery,
  useLazyGetVehiclesQuery,
  useLazyGetChatGroupQuery,
  useRenameChatGroupMutation,
  useRenameChatMutation,
  useTogglePinChatMutation,
  useLazyGetChatGroupWithoutChatsQuery,
  useExitChatMutation,
  useCreateChatFromOfferMutation,
  useCreateChatFromTaskMutation,
  useAddUsersMutation,
} = chatApi
