import {
  addChatListMessage,
  addChatMessage,
  readMessage,
} from '@pages/Chat/chatSlice/chatSlice'
import { onMessageHeaderNotificationHandler } from '@pages/Chat/chatWebsocket/onMessageHeaderNotificationHandler'
import { audioBuffer, audioContext } from '@pages/Chat/constants'
import { transformMessage } from '@pages/Chat/transformResponseHelpers'
import { pushNotification } from '@src/shared/MutationResultNotifications/notificationsSlice'
import { store } from '@src/store'

export const onMessage = (message: MessageEvent) => {
  const chatId = store.getState().chat.currentChatInfo?.id
  const chatGroupsLength = store.getState().chat.chatListProjectGroups.length
  const userId = store.getState().user.data?.id

  const readyMessage = JSON.parse(message.data)

  if (readyMessage.type === 'read') {
    store.dispatch(readMessage(readyMessage))
    return
  }

  //инкрементируем счетчик в хедере
  onMessageHeaderNotificationHandler(readyMessage, userId || 0)

  //если мы на странице чатов и подгружены группы
  if (readyMessage.type !== 'message') {
    return
  }

  if (readyMessage?.user?.id !== userId) {
    //Звуковое уведомление
    if (audioBuffer.current) {
      try {
        audioContext.current.resume()
        const source = audioContext.current.createBufferSource()
        source.buffer = audioBuffer.current
        source.connect(audioContext.current.destination)
        source.start(0)
      } catch (e) {
        console.log(e)
      }
    }

    //Всплывающее уведомление
    store.dispatch(
      pushNotification({
        type: 'success',
        text: 'Новое сообщение в чате',
      })
    )
  }

  if (chatGroupsLength) {
    //@ts-ignore
    const { id: spaceId } = store.getState().profile.profileInfo
    const { id: profileId } = store.getState().profile
    store.dispatch(
      addChatListMessage({
        message: readyMessage,
        userId,
        spaceId,
        profileId,
      } as unknown as void)
    )
  }

  //если есть открытый чат и сообщение - для него
  if (chatId === readyMessage.chat_id) {
    store.dispatch(
      addChatMessage({
        message: transformMessage(readyMessage, userId || 0),
        isSocket: true,
      })
    )
  }
}
