import IconPropsInterface from './IconPropsInterface'

export const Target = ({ size = 20, color, style }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 14 14"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{ ...style, flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M7 8.75C7.9665 8.75 8.75 7.9665 8.75 7C8.75 6.0335 7.9665 5.25 7 5.25C6.0335 5.25 5.25 6.0335 5.25 7C5.25 7.9665 6.0335 8.75 7 8.75Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.9987 11.6654C9.57603 11.6654 11.6654 9.57603 11.6654 6.9987C11.6654 4.42137 9.57603 2.33203 6.9987 2.33203C4.42137 2.33203 2.33203 4.42137 2.33203 6.9987C2.33203 9.57603 4.42137 11.6654 6.9987 11.6654Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 1.16797V2.33464"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 11.668V12.8346"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.668 7H12.8346"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.16797 7H2.33464"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
