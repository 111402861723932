import { memo, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useLazyGetVehicleExecutorOfferQuery } from '@pages/TasksBoard/ExecutorOffer/executorOfferApi'
import {
  setOfferFieldErrorMessage,
  setOfferFieldValue,
} from '@pages/TasksBoard/ExecutorOffer/executorOfferSlice'
import { InputWrapper } from '@pages/TasksBoard/ExecutorOffer/inputs/InputWrapper'
import { useAppDispatch, useAppSelector } from '@src/hooks/store'
import { IOption } from '@src/shared/interfaces/partials/IOption'
import DropDown from '@ui/DropDown'
import { InfiniteScrollSelect } from '@ui/Selects/InfiniteScrollSelect/InfiniteScrollSelect'
import { RegularText } from '@ui/Typography'

interface IProps {
  taskId: number
  isPreviewMode: boolean
  isFirstValidation: boolean
  taskVehicleTypes: number[]
}

// eslint-disable-next-line react/display-name
export const SelectVehicleConfiguration = memo(
  ({ taskId, isPreviewMode, isFirstValidation, taskVehicleTypes }: IProps) => {
    const dispatch = useAppDispatch()
    const { id: spaceId } = useParams()

    const [currentVehicle, setCurrentVehicle] = useState<any>(null)
    const fieldData = useAppSelector(
      (state) =>
        state.executorOffer.selectedTasks[taskId]['vehicle_configuration_id']
    )
    const [getVehicleExecutorOffer] = useLazyGetVehicleExecutorOfferQuery()

    const handleChangeVehicle = (value: IOption<any> | null) => {
      setCurrentVehicle(value)
    }
    const handleChangeConfiguration = (value: any) => {
      if (!isFirstValidation) {
        dispatch(
          setOfferFieldErrorMessage({
            taskId,
            fieldName: 'vehicle_configuration_id',
            value: fieldData.getErrorMessage(value),
          })
        )
      }
      dispatch(
        setOfferFieldValue({
          taskId,
          fieldName: 'vehicle_configuration_id',
          value,
        })
      )
    }

    function loadOptions(
      search: string,
      loadedOptions: IOption[],
      { page }: any
    ) {
      return getVehicleExecutorOffer({
        spaceId: Number(spaceId),
        page: page,
      })
        .unwrap()
        .then((response) => {
          return {
            options: response.data.items
              .filter((item: any) => {
                return item.configurations.some((configuration: any) => {
                  return taskVehicleTypes.includes(
                    configuration.vehicle_type.id
                  )
                })
              })
              .map((item: any) => ({
                label: item.name,
                value: item.configurations,
              })),
            hasMore:
              Math.ceil(response.data.pagination.total_items / 50) > page,
            additional: {
              page: page + 1,
            },
          }
        })
    }

    const configurationsOptions = useMemo(() => {
      return (
        currentVehicle?.value
          ?.filter((item: any) => {
            return taskVehicleTypes.includes(item.vehicle_type.id)
          })
          .map((item: any) => {
            return {
              name: item?.name,
              value: item?.id,
            }
          }) || []
      )
    }, [currentVehicle])

    return (
      <>
        <InputWrapper
          title={'Название техники'}
          required={true}
          errorMessage={''}
        >
          {isPreviewMode ? (
            <RegularText>{currentVehicle?.label}</RegularText>
          ) : (
            <div style={{ width: '100%' }}>
              <InfiniteScrollSelect
                isClearable={false}
                isSearchable={false}
                hasError={!!fieldData.errorMessage}
                value={currentVehicle}
                onChange={handleChangeVehicle}
                placeholder={'Выбрать технику'}
                defaultOptions
                additional={{
                  page: 1,
                }}
                loadOptions={loadOptions}
              />
            </div>
          )}
        </InputWrapper>
        <InputWrapper
          title={'Конфигурация техники'}
          required={true}
          errorMessage={fieldData.errorMessage}
        >
          {isPreviewMode ? (
            <RegularText>
              {currentVehicle?.value?.find(
                (item: any) => item.id === fieldData.value
              )?.name || 'Не задано'}
            </RegularText>
          ) : (
            <DropDown
              width={'100%'}
              hasError={!!fieldData.errorMessage}
              height={45}
              items={configurationsOptions}
              title={'Выбрать конфигурацию'}
              activeValue={fieldData?.value || -1}
              handleChange={handleChangeConfiguration}
            />
          )}
        </InputWrapper>
      </>
    )
  }
)
