import { createGlobalStyle } from 'styled-components'

import { getBgColor, media } from '@theme/helpers'

export const GlobalStyle = createGlobalStyle`
  @supports not selector(::-webkit-scrollbar) {
    * {
      scrollbar-width: thin;
      scrollbar-color: ${({ theme }) => theme.colors.gray20} ${({ theme }) =>
  theme.colors.gray77};

      &:hover {
        scrollbar-color: ${({ theme }) => theme.colors.primary} ${({ theme }) =>
  theme.colors.gray77};
      }
    }
  }


  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    transition: color, background-color 300ms;
  }

  button {
    border: none;
    background: none;
    cursor: pointer;
    font-family: inherit;
    color: inherit;
    outline: none;

    &:disabled {
      cursor: default;
    }
  }

  html {
    height: 100%;
  }

  body {
    margin: 0;
    height: 100%;
    font-size: 14px;
    font-family: 'Gilroy';
    font-weight: 400;
    box-sizing: border-box;
    ${getBgColor('body')};
    overflow-y: scroll;
    scroll-behavior: smooth;
    ${({ theme }) => theme.colors.gray77};
    ::-webkit-scrollbar {
      width: 12px;
    }
    ::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.colors.gray77};
      //border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.colors.gray20};
      border-radius: 5px;
    }


    > div.calculated-scrollbar {
      ::-webkit-scrollbar {
        ${media(
          'xlarge',
          () => `
          width: 12px;
        `
        )}
      }
    }
  }

  a {
    text-decoration: none;
  }

  body .chatDateWrapper {
    position: static;
  }

  body[data-is-scrolling] .chatDateWrapper {
    position: sticky;
  }

  .leaflet-control-attribution.leaflet-control {
    display: none;
  }
`
