import IconPropsInterface from './IconPropsInterface'

export const Exit = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 14 12"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M8.33333 3.33268V1.99935C8.33333 1.64573 8.19286 1.30659 7.94281 1.05654C7.69276 0.806491 7.35362 0.666016 7 0.666016H2.33333C1.97971 0.666016 1.64057 0.806491 1.39052 1.05654C1.14048 1.30659 1 1.64573 1 1.99935V9.99935C1 10.353 1.14048 10.6921 1.39052 10.9422C1.64057 11.1922 1.97971 11.3327 2.33333 11.3327H7C7.35362 11.3327 7.69276 11.1922 7.94281 10.9422C8.19286 10.6921 8.33333 10.353 8.33333 9.99935V8.66602"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.66797 6H13.0013L11.0013 4M11.0013 8L13.0013 6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
