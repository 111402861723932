import { IApplication } from '@pages/MyApplications/types/application/IApplication'
import { IApplicationListRequest } from '@pages/MyApplications/types/application/api/IApplicationListRequest'
import { IApplicationRequest } from '@pages/MyApplications/types/application/api/IApplicationRequest'
import { IChangeApplicationStatusRequest } from '@pages/MyApplications/types/application/api/IChangeApplicationStatusRequest'
import { IEditApplicationRequest } from '@pages/MyApplications/types/application/api/IEditApplicationRequest'
import {
  IStateCount,
  IStatesCountRequest,
} from '@pages/MyApplications/types/application/api/IStatesCount'
import { IPaginatedItems } from '@src/shared/interfaces/apiResponse/IPagination'
import { ISuccessResponse } from '@src/shared/interfaces/apiResponse/ISuccessResponse'
import { clientApiSlice } from '@src/store/slices/clientApiSlice'
import { formatArrayToGetParams } from '@src/utils/helpers/formatArrayToGetParams'
import { formatUrlGetParamsFromObject } from '@src/utils/helpers/formatUrlGetParamsFromObject'

export const myApplicationsApi = clientApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getApplications: builder.query<
      ISuccessResponse<IPaginatedItems<IApplication>>,
      IApplicationListRequest
    >({
      query: ({
        spaceIdView,
        page,
        limit,
        sort,
        state,
        datetimeFrom: datetime_from,
        datetimeTo: datetime_to,
        ownershipTypeId: ownership_type_id,
      }) => ({
        url: `/spaces/${spaceIdView}/projects${formatUrlGetParamsFromObject({
          page,
          limit,
          sort,
          ownership_type_id,
          datetime_from,
          datetime_to,
        })}${formatArrayToGetParams('state[]', state)}`,
      }),
    }),
    changeApplicationState: builder.mutation<
      ISuccessResponse<IApplication>,
      IChangeApplicationStatusRequest
    >({
      query: ({ spaceId, projectId, state }) => ({
        url: `/spaces/${spaceId}/projects/${projectId}/states`,
        method: 'POST',
        body: {
          state: state,
        },
      }),

      async onQueryStarted(
        { spaceId, projectId },
        { dispatch, queryFulfilled }
      ) {
        try {
          const { data } = await queryFulfilled
          dispatch(
            myApplicationsApi.util.updateQueryData(
              'getApplication',
              { spaceId, projectId },
              (draft) => {
                draft.data.state = data.data.state
              }
            )
          )
        } catch {
          /* empty */
        }
      },

      invalidatesTags: (result, error, arg) =>
        arg.noInvalidate
          ? []
          : [{ type: 'Application', id: arg.projectId }, 'ApplicationTabs'],
    }),
    getStatesCount: builder.query<
      ISuccessResponse<IStateCount[]>,
      IStatesCountRequest
    >({
      query: ({
        spaceId,
        datetimeTo: datetime_to,
        datetimeFrom: datetime_from,
        ownershipTypeId: ownership_type_id,
      }) => ({
        url: `/spaces/${spaceId}/projects/states-count${formatUrlGetParamsFromObject(
          {
            ownership_type_id,
            datetime_from,
            datetime_to,
          }
        )}`,
      }),
      providesTags: ['ApplicationTabs'],
    }),
    getApplication: builder.query<
      ISuccessResponse<IApplication>,
      IApplicationRequest
    >({
      query: ({ spaceId, projectId }) => ({
        url: `/spaces/${spaceId}/projects/${projectId}`,
      }),
      providesTags: (result, error, arg) => [
        { type: 'Application', id: arg.projectId },
      ],
    }),
    editApplication: builder.mutation<
      ISuccessResponse<IApplication>,
      IEditApplicationRequest
    >({
      query: ({ spaceId, projectId, body }) => ({
        url: `/spaces/${spaceId}/projects/${projectId}`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: (result, error, arg) =>
        result && result.data.state.value !== 'rejected'
          ? arg.noInvalidate
            ? []
            : [{ type: 'Application', id: arg.projectId }, 'ApplicationTabs']
          : [],
    }),
  }),
})

export const {
  useLazyGetApplicationsQuery,
  useChangeApplicationStateMutation,
  useGetStatesCountQuery,
  useGetApplicationQuery,
  useEditApplicationMutation,
} = myApplicationsApi
