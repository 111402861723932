import { ITheme } from '@theme/interfaces'

export const getColor =
  (color: string) =>
  ({ theme }: { theme: ITheme }) =>
    `color: ${theme.colors[color]};`

export const getBgColor =
  (color: string) =>
  ({ theme }: { theme: ITheme }) =>
    `background-color: ${theme.colors[color]};`

export const getFontSize =
  (size: string) =>
  ({ theme }: { theme: ITheme }) =>
    `font-size: ${theme.fontSizes[size]};`

export const getFontWeight =
  (weight: string) =>
  ({ theme }: { theme: ITheme }) =>
    `font-weight: ${theme.fontWeights[weight]};`

export const media =
  (size: string, styles: (props: { theme: ITheme }) => string) =>
  ({ theme }: { theme: ITheme }) =>
    theme.mediaQueries[size]`${styles({ theme })}`

export const convertToDarkThemeColor = (
  lightThemeColor: string,
  alpha?: number
) => {
  const hslaRegex =
    /hsla\(\s*(\d+)\s*,\s*(\d+(?:\.\d+)?)%\s*,\s*(\d+(?:\.\d+)?)%\s*,\s*(\d+(?:\.\d+)?)\s*\)/
  const match = lightThemeColor.match(hslaRegex)
  if (match?.[1] && match?.[2] && match?.[3] && match?.[4]) {
    const hue = parseInt(match[1])
    const saturation = parseFloat(match[2])
    const lightness = parseFloat(match[3])
    const innerAlpha = parseFloat(match[4])

    let invertedLightness = 100 - lightness

    if (invertedLightness < 70) {
      invertedLightness += 15
    }

    return `hsla(${hue}, ${saturation}%, ${invertedLightness}%, ${
      alpha ?? innerAlpha
    })`
  }
}
