import IconPropsInterface from '@icons/IconPropsInterface'

export const DocumentsLines = ({
  size = 20,
  color,
  style,
}: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style, flexShrink: 0, transition: '300ms' }}
    >
      <g clipPath="url(#clip0_11176_43475)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.70866 2.50008C6.70866 2.03984 7.08175 1.66675 7.54199 1.66675H13.5295C15.0086 1.66675 16.1643 2.94379 16.0171 4.41551L15.8956 5.63072C15.8851 5.73551 15.9732 5.82362 16.078 5.81315L17.2932 5.69162C18.7649 5.54445 20.042 6.70016 20.042 8.17922V17.5001C20.042 17.9603 19.6689 18.3334 19.2087 18.3334H7.54199C7.08175 18.3334 6.70866 17.9603 6.70866 17.5001V2.50008ZM13.3753 9.16675H9.20866V10.8334H13.3753V9.16675ZM16.7087 12.5001H9.20866V14.1667H16.7087V12.5001Z"
          fill={color}
        />
        <path
          d="M5.45874 16.2501V6.25008C4.7643 8.75008 3.29207 14.0834 2.95874 15.4167C2.54207 17.0834 3.79207 17.5001 5.87541 17.9167C5.54207 17.5834 5.45874 16.6667 5.45874 16.2501Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_11176_43475">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.875)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
