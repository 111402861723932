import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  ICustomer,
  IExecutor,
  IPartner,
  IProfileInfo,
} from '@src/shared/interfaces/profile/Profile'
import { TypeRole } from '@src/shared/interfaces/profile/TypeRole'

import { LIMIT_REVIEWS } from './ProfileTabs/Reviews/constans'
import { VEHICLES_LIMIT } from './constants'
import { IVehicleCard, UserComment } from './interfaces'

export interface IProfileSliceInitialState {
  currentSpaceId: number | null
  id: number | null
  viewRole: TypeRole | null
  myRole: TypeRole | null
  viewedProfileInfo: null | any
  executor?: IExecutor
  customer?: ICustomer
  partner?: IPartner
  profileInfo?: IProfileInfo
  vehicles: {
    items: IVehicleCard[]
    page: number
    lastItemsCount: number
    name: string
    vehicleTypeIds: number[]
    taskTypes: number[]
  }
  reviews: {
    lastItemsCount: number
    page: number
    items: UserComment[]
    totalItems: number
  }
  editProfile: {
    typesActivities: { id?: number; name: string }[]
    phones: { title: string; phone: string; id?: number }[]
    name: string
    description: string
    working_mode: string
    timezone: string
    city: string
    city_type: string
    kladr_id: string
    fias_id: string
    logo: {
      public_url: string
      bind_key: string
      name: string
    }
  }
  newActivities: { name: string }[]
  isMobileMenuActive: boolean
  isBecomePartner: boolean
  errorFields: string[]
  quantityReviews: number
  newRole: TypeRole | null
}

const initialState: IProfileSliceInitialState = {
  currentSpaceId: null,
  id: null,
  // данные спейса пользователя
  executor: undefined,
  customer: undefined,
  profileInfo: undefined,
  viewRole: null,
  myRole: (localStorage.getItem('profileType') as TypeRole) || 'customer',
  // viewedProfileInfo - данные спейса просматриваемого пользователя
  viewedProfileInfo: null,
  vehicles: {
    items: [],
    page: 1,
    lastItemsCount: VEHICLES_LIMIT,
    name: '',
    taskTypes: [],
    vehicleTypeIds: [],
  },
  reviews: {
    lastItemsCount: LIMIT_REVIEWS,
    page: 1,
    items: [],
    totalItems: 0,
  },
  editProfile: {
    typesActivities: [],
    phones: [],
    name: '',
    description: '',
    working_mode: '',
    timezone: '',
    city: '',
    city_type: '',
    kladr_id: '',
    fias_id: '',
    logo: {
      public_url: '',
      bind_key: '',
      name: '',
    },
  },
  newActivities: [],
  isMobileMenuActive: false,
  isBecomePartner: false,
  errorFields: [],
  quantityReviews: 0,
  newRole: null,
}

const profileSlice = createSlice({
  name: 'profile',
  initialState: initialState,
  reducers: {
    setCurrentId: (state, action) => {
      state.currentSpaceId = action.payload
    },
    setProfileId: (state, action) => {
      state.id = action.payload
    },
    setExecutor: (state, action) => {
      state.executor = action.payload
    },
    setCustomer: (state, action) => {
      state.customer = action.payload
    },
    setPartner: (state, { payload }: PayloadAction<IPartner>) => {
      state.partner = payload
    },
    setIsBecomePartner: (state, { payload }: PayloadAction<boolean>) => {
      state.isBecomePartner = payload
    },
    setViewedProfileInfo: (state, action) => {
      state.viewedProfileInfo = action.payload
    },
    setProfileInfo: (state, action) => {
      state.profileInfo = action.payload
    },
    //Получение и пагинация списка карточек техники
    vehiclesAddPageData: (state, action) => {
      state.vehicles.items = [...state.vehicles.items, ...action.payload]
    },
    vehiclesIncrementPage: (state) => {
      state.vehicles.page += 1
    },
    vehiclesSetLastItemsCount: (state, action) => {
      state.vehicles.lastItemsCount = action.payload
    },
    vehiclesClear: (state) => {
      state.vehicles.items = []
      state.vehicles.page = 1
      state.vehicles.lastItemsCount = VEHICLES_LIMIT
    },
    vehiclesChangeName: (state, action) => {
      state.vehicles.name = action.payload
    },
    //фильтрация по типам задач
    addTaskType: (state, action) => {
      state.vehicles.taskTypes.push(action.payload)
    },
    removeTaskType: (state, action) => {
      state.vehicles.taskTypes = state.vehicles.taskTypes.filter(
        (task) => task !== action.payload
      )
    },
    // пагинация отзывов
    reviewsIncrementPage: (state) => {
      state.reviews.page += 1
    },
    reviewsSetLastItemsCount: (state, action) => {
      state.reviews.lastItemsCount = action.payload
    },
    reviewsAddPageData: (state, action) => {
      state.reviews.items = [...state.reviews.items, ...action.payload]
    },
    reviewsRemove: (state) => {
      state.reviews.items = []
      state.reviews.page = 1
      state.reviews.lastItemsCount = LIMIT_REVIEWS
    },
    // отображение текущей просматриваемой роли(и для своего профиля и для чужого)
    setViewRole: (state, action) => {
      state.viewRole = action.payload
    },
    setMyRole: (state, action) => {
      state.myRole = action.payload
    },
    // виды работ
    setAddNewActivities: (state, action) => {
      const hasEmptyName = state.editProfile.typesActivities.some(
        (item) => item.name === ''
      )

      if (!hasEmptyName) {
        if (state.newActivities.length) {
          state.newActivities = [...state.newActivities, action.payload]
        } else {
          state.newActivities = [action.payload]
        }
      }
    },
    setAllActivities: (state, action) => {
      state.editProfile.typesActivities = action.payload
    },
    deleteEditActivities: (state, action) => {
      if (state.editProfile.typesActivities.length) {
        const updateLinks = state.editProfile.typesActivities.filter(
          (_, i) => i !== action.payload
        )
        state.editProfile.typesActivities = updateLinks
      }
    },
    deleteNewActivities: (state, action) => {
      if (state.newActivities) {
        const updateLinks = state.newActivities.filter(
          (_, i) => i !== action.payload
        )
        state.newActivities = updateLinks
      }
    },
    deleteAllNewActivities: (state, action) => {
      state.newActivities = action.payload
    },
    setValueEditActivities: (state, action) => {
      const { index, value } = action.payload
      if (state.editProfile.typesActivities.length) {
        const newActivities = [...state.editProfile.typesActivities]
        newActivities[index].name = value
        state.editProfile.typesActivities = newActivities
      }
    },
    setValueNewActivities: (state, action) => {
      const { index, value } = action.payload
      if (state.newActivities.length) {
        const newActivities = [...state.newActivities]
        newActivities[index].name = value
        state.newActivities = newActivities
      }
    },
    // смена имени спейса
    setProfileName: (state, action) => {
      state.editProfile.name = action.payload
    },
    setProfileDescription: (state, action) => {
      state.editProfile.description = action.payload
    },
    // телефоны
    setPhones: (state, action) => {
      // если пустая строка, не добавляем элемент
      const hasEmptyName = state.editProfile.phones.some(
        (item) => item.phone === ''
      )

      if (!hasEmptyName) {
        if (state.editProfile.phones.length) {
          state.editProfile.phones = [
            ...state.editProfile.phones,
            action.payload,
          ]
        } else {
          state.editProfile.phones = [action.payload]
        }
      }
    },
    setAllPhones: (state, action) => {
      state.editProfile.phones = action.payload
    },
    deletePhones: (state, action) => {
      if (state.editProfile.phones.length) {
        const updateLinks = state.editProfile.phones.filter(
          (_, i) => i !== action.payload
        )
        state.editProfile.phones = updateLinks
      }
    },
    setValuePhones: (state, action) => {
      const { index, phone } = action.payload
      if (state.editProfile.phones.length) {
        const newPhones = [...state.editProfile.phones]

        newPhones[index].phone = phone
        state.editProfile.phones = newPhones
      }
    },
    setJobTitle: (state, action) => {
      const { index, title } = action.payload
      if (state.editProfile.phones.length) {
        const newPhones = [...state.editProfile.phones]
        newPhones[index].title = title
        state.editProfile.phones = newPhones
      }
    },
    setWorkingMode: (state, action) => {
      state.editProfile.working_mode = action.payload
    },
    setTimeZone: (state, action) => {
      state.editProfile.timezone = action.payload
    },
    setCity: (state, action) => {
      state.editProfile.city = action.payload
    },
    setCityType: (state, action) => {
      state.editProfile.city_type = action.payload
    },
    setCityKladr: (state, action) => {
      state.editProfile.kladr_id = action.payload
    },
    setCityFias: (state, action) => {
      state.editProfile.fias_id = action.payload
    },
    setLogoUrl: (state, action) => {
      state.editProfile.logo.public_url = action.payload
    },
    setLogoName: (state, action) => {
      state.editProfile.logo.name = action.payload
    },
    setBindKey: (state, action) => {
      state.editProfile.logo.bind_key = action.payload
    },
    // Пока нет фильтрации по типам техники на макете.
    // addVehicleType: (state, action) => {},
    // removeVehicleType: (state, action) => {},
    setIsMobileMenuActive: (state, action) => {
      state.isMobileMenuActive = action.payload
    },
    setErrorFields: (state, action) => {
      state.errorFields = action.payload
    },
    setQuantityReviews: (state, action) => {
      state.quantityReviews = action.payload
    },
    newRoleChanged: (state, { payload }: PayloadAction<TypeRole | null>) => {
      state.newRole = payload
    },
  },
})

export const {
  setProfileId,
  setProfileInfo,
  setCustomer,
  setExecutor,
  setPartner,
  vehiclesAddPageData,
  vehiclesIncrementPage,
  vehiclesSetLastItemsCount,
  vehiclesClear,
  vehiclesChangeName,
  addTaskType,
  removeTaskType,
  reviewsIncrementPage,
  reviewsSetLastItemsCount,
  reviewsAddPageData,
  reviewsRemove,
  setCurrentId,
  setViewRole,
  setMyRole,
  deleteEditActivities,
  setValueEditActivities,
  setProfileName,
  setProfileDescription,
  setPhones,
  setValuePhones,
  deletePhones,
  setWorkingMode,
  setTimeZone,
  setCity,
  setCityType,
  setCityKladr,
  setCityFias,
  setAllPhones,
  setViewedProfileInfo,
  setAllActivities,
  setAddNewActivities,
  setValueNewActivities,
  deleteNewActivities,
  setLogoName,
  setBindKey,
  setLogoUrl,
  deleteAllNewActivities,
  setJobTitle,
  setIsMobileMenuActive,
  setIsBecomePartner,
  setErrorFields,
  setQuantityReviews,
  newRoleChanged,
} = profileSlice.actions

export const profileReducer = profileSlice.reducer
