import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface IMobileLayoutState {
  backTo: null | string
  withBackButton: boolean
  withBottomMenu: boolean
  text?: string
}

export type PartialMobileLayoutState = Partial<IMobileLayoutState>

const initialState: IMobileLayoutState = {
  backTo: null,
  withBackButton: true,
  withBottomMenu: true,
  text: '',
}

const mobileLayoutSlice = createSlice({
  name: 'mobileLayout',
  initialState,
  reducers: {
    setLayout: (
      state,
      { payload }: PayloadAction<PartialMobileLayoutState>
    ) => {
      state.backTo = payload.backTo ?? null
      state.withBottomMenu = payload.withBottomMenu ?? true
      state.withBackButton = payload.withBackButton ?? true
      state.text = payload.text ?? ''
    },
  },
})

export const { setLayout } = mobileLayoutSlice.actions
export default mobileLayoutSlice.reducer
