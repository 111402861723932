import IconPropsInterface from './IconPropsInterface'

export const BarTechnic = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 14 12"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M3.66536 10.6667C4.40174 10.6667 4.9987 10.0697 4.9987 9.33333C4.9987 8.59695 4.40174 8 3.66536 8C2.92898 8 2.33203 8.59695 2.33203 9.33333C2.33203 10.0697 2.92898 10.6667 3.66536 10.6667Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3333 10.6667C11.0697 10.6667 11.6667 10.0697 11.6667 9.33333C11.6667 8.59695 11.0697 8 10.3333 8C9.59695 8 9 8.59695 9 9.33333C9 10.0697 9.59695 10.6667 10.3333 10.6667Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.33333 9.33203H1V1.9987C1 1.82189 1.07024 1.65232 1.19526 1.52729C1.32029 1.40227 1.48986 1.33203 1.66667 1.33203H7.66667V9.33203M5 9.33203H9M11.6667 9.33203H13V5.33203H7.66667M7.66667 1.9987H11L13 5.33203"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
