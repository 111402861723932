import { getDateString } from './helpers'
import { IResponseMessagesData } from './interfaces'

export const transformMessage = (
  item: IResponseMessagesData['data']['items'][0],
  userId: number
) => {
  const { fullDate, monthAndDay, time } = getDateString(item.created_at)
  const isSystem = item.content.type === 'system_content'
  const authorId = isSystem ? 'system' : item.user?.id
  const isOwn = userId === authorId
  const isNew = isOwn
    ? false
    : item.read_users.length === 0 ||
      item.read_users.every((item) => item.id !== userId)
  const isRead = isOwn
    ? item.read_users.some((item) => item.id !== userId)
    : true

  return {
    messageChatId: item.chat_id,
    messageType: item.type,
    messageId: item.id,
    messageContentType: item.content.type,
    time,
    fullDate,
    monthAndDay,
    authorName: item?.user
      ? `${item.user.first_name} ${item.user.last_name}`
      : 'system',
    authorId,
    authorOwnership: item?.user?.ownership_type_name || '',
    authorWorkPosition: item?.user?.work_position || '',
    content: item.content,
    isOwn,
    isRead,
    isSystem,
    //TODO сделать получение с бэка
    avatar: 'https://picsum.photos/200',
    authorFirstLetter: item?.user?.last_name?.[0] || 'S',
    isNew,
  }
}
