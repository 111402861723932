import Select, { GroupBase, Props as ReactSelectProps } from 'react-select'

import { useGetTheme } from '@theme/hooks'
import { ITheme } from '@theme/interfaces'
import {
  LOADING_MESSAGE,
  NO_OPTIONS_MESSAGE,
} from '@ui/Selects/BaseSelect/constants'
import { getCommonStyles } from '@ui/Selects/BaseSelect/getCommonStyles'
import { getMenuListStyles } from '@ui/Selects/BaseSelect/getMenuListStyles'
import { getMenuStyles } from '@ui/Selects/BaseSelect/getMenuStyles'
import { ClearIndicator } from '@ui/Selects/partials/ClearIndicator'
import { DropdownIndicator } from '@ui/Selects/partials/DropdownIndicator'
import { MultiValueRemove } from '@ui/Selects/partials/MultiValueRemove'
import { Option, getOptionStyles } from '@ui/Selects/partials/Option'

export const getCommonSelectStyles = (theme: ITheme, props: any) => {
  return {
    ...getCommonStyles(theme, props),
    control: (styles: any, state: any) => ({
      ...styles,
      minHeight: '45px',
      borderRadius: state.menuIsOpen ? '7px 7px 0 0' : '7px',
      padding: '0 15px',
      backgroundColor: theme.colors.light,
      boxShadow: 'none',
      border: props.noBorder
        ? 'none'
        : `1px solid ${
            props.hasError ? theme.colors.error : theme.colors.gray77
          }`,
      borderBottomColor: state.menuIsOpen
        ? 'transparent'
        : props.hasError
        ? theme.colors.error
        : theme.colors.gray77,
      opacity: state.isDisabled ? 0.5 : 1,
      cursor: !props.noCursor ? 'pointer' : 'auto',
      div: {
        padding: '0',
        margin: '0',
      },
      ':hover': {
        borderColor: props.hasError ? theme.colors.error : theme.colors.gray77,
        borderBottomColor: state.menuIsOpen
          ? 'transparent'
          : props.hasError
          ? theme.colors.error
          : theme.colors.gray77,
      },
    }),
    menu: (styles: any) => ({
      ...styles,
      ...getMenuStyles(theme, props.hasError),
      marginTop: '-5px',
      overflow: 'hidden',
      zIndex: '10',
    }),
    menuList: (styles: any) => ({
      ...styles,
      ...getMenuListStyles(theme, props?.menuListHeight),
      padding: '10px 0 0 0',
    }),
    option: (styles: any, state: any) => ({
      ...styles,
      ...getOptionStyles(theme, state),
    }),
    dropdownIndicator: (styles: any, state: any) => ({
      ...styles,
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
    }),
    groupHeading: (styles: any) => ({
      ...styles,
      color: theme.colors.gray50,
      textTransform: 'none',
      fontSize: theme.colors.s,
      lineHeight: '24px',
      fontWeight: '400',
    }),
    group: (styles: any) => ({
      ...styles,
      position: 'relative',
      padding: '5px 0',
      ':first-of-type': {
        paddingTop: 0,
      },
      ':last-of-type': {
        borderBottom: 'none',
        paddingBottom: 0,
        ':after': {
          content: 'none',
        },
      },
      ':after': {
        content: '""',
        position: 'absolute',
        bottom: '0',
        left: '12px',
        right: '12px',
        height: '1px',
        background: theme.colors.gray77,
      },
    }),
  }
}

export const BaseSelect = <
  TOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<TOption> = GroupBase<TOption>
>({
  noOptionsMessage = () => NO_OPTIONS_MESSAGE,
  loadingMessage = () => LOADING_MESSAGE,
  className,
  ...rest
}: ReactSelectProps<TOption, IsMulti, Group>) => {
  const theme = useGetTheme()

  return (
    <Select
      {...rest}
      loadingMessage={loadingMessage}
      noOptionsMessage={noOptionsMessage}
      components={{
        DropdownIndicator,
        ClearIndicator,
        MultiValueRemove,
        Option,
      }}
      className={className}
      styles={getCommonSelectStyles(theme, rest)}
    />
  )
}
