import IconPropsInterface from './IconPropsInterface'

export const CheckQuad = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill={color}
      stroke="white"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <rect height="16" width="16" rx="2" />
      <path
        d="M3 8L5.90824 11.1409C6.32585 11.5919 7.04767 11.563 7.42781 11.08L13 4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
