import IconPropsInterface from '@icons/IconPropsInterface'

export const ShowNumber = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.1413 32.8599C24.2163 29.9349 22.0113 26.6599 20.5463 23.3374C20.2363 22.6349 20.4188 21.8124 20.9613 21.2699L23.0088 19.2249C24.6863 17.5474 24.6863 15.1749 23.2213 13.7099L20.2863 10.7749C18.3338 8.82242 15.1688 8.82242 13.2163 10.7749L11.5863 12.4049C9.73384 14.2574 8.96134 16.9299 9.46134 19.5799C10.6963 26.1124 14.4913 33.2649 20.6138 39.3874C26.7363 45.5099 33.8888 49.3049 40.4213 50.5399C43.0713 51.0399 45.7438 50.2674 47.5963 48.4149L49.2238 46.7874C51.1763 44.8349 51.1763 41.6699 49.2238 39.7174L46.2913 36.7849C44.8263 35.3199 42.4513 35.3199 40.9888 36.7849L38.7313 39.0449C38.1888 39.5874 37.3663 39.7699 36.6638 39.4599C33.3413 37.9924 30.0663 35.7849 27.1413 32.8599Z"
        stroke={color}
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33 9.5H51"
        stroke="#34C759"
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39 16.5H51"
        stroke="#34C759"
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44 23.5H51"
        stroke="#34C759"
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
