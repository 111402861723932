export const weightUnits = [
  { id: 1, name: 'Грамм', slug: 'gram' },
  { id: 2, name: 'Килограмм', slug: 'kilogram' },
  { id: 3, name: 'Центнер', slug: 'centner' },
  { id: 4, name: 'Тон', slug: 'ton' },
]

export const weightToKgMultipliers = {
  gram: 0.001,
  kilogram: 1,
  centner: 100,
  ton: 1000,
}
export const dimensionsUnits = [
  { id: 1, name: 'Миллиметры', slug: 'millimeter', abbr: 'мм' },
  { id: 2, name: 'Сантиметры', slug: 'centimeter', abbr: 'см' },
  { id: 3, name: 'Метры', slug: 'meter', abbr: 'м' },
  { id: 4, name: 'Дециметры', slug: 'decimeter', abbr: 'дм' },
  { id: 5, name: 'Километры', slug: 'kilometer', abbr: 'км' },
]

export const dimensionsToCubicMetersMultipliers = {
  millimeter: 0.001,
  centimeter: 0.01,
  meter: 1,
  decimeter: 0.1,
  kilometer: 1000,
}

export const dimensionsUnitsShort = [
  {
    id: 1,
    name: 'мм.',
    slug: 'millimeter',
    abbr: 'мм',
    multiplier: 0.001,
  },
  {
    id: 2,
    name: 'см.',
    slug: 'centimeter',
    abbr: 'см',
    multiplier: 0.01,
  },
  {
    id: 3,
    name: 'м.',
    slug: 'meter',
    abbr: 'м',
    multiplier: 1,
  },
  {
    id: 4,
    name: 'дм.',
    slug: 'decimeter',
    abbr: 'дм',
    multiplier: 0.1,
  },
  {
    id: 5,
    name: 'км.',
    slug: 'kilometer',
    abbr: 'км',
    multiplier: 1000,
  },
]

export const weightUnitsShort = [
  { id: 1, name: 'гр.', slug: 'gram' },
  { id: 2, name: 'кг.', slug: 'kilogram' },
  { id: 3, name: 'ц.', slug: 'centner' },
  { id: 4, name: 'т.', slug: 'ton' },
]
