import IconPropsInterface from './IconPropsInterface'

export const Download = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg viewBox="0 0 21 20" fill={color} width={size} height={size}>
      <path d="M14.4539 8.28132L11.5835 11.0888V2.50195H9.91683V11.0888L7.04647 8.28131L5.88108 9.47281L10.7502 14.2352L15.6192 9.47281L14.4539 8.28132Z" />
      <path d="M17.4168 17.502V15.8353H4.0835V17.502H17.4168Z" />
    </svg>
  )
}
