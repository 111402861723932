import { Reducer } from '@reduxjs/toolkit'

export const decrementChatNewMessagesCountReducer: Reducer = (state) => {
  if (state.currentChatGroup?.chats) {
    const currentChat = state.currentChatGroup.chats.find(
      (item: any) => item.id === state.currentChatInfo?.id
    )
    if (currentChat) {
      currentChat.new_message_cnt -= 1
    }
  }
}
