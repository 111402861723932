import React, { useEffect, useLayoutEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'

import { useAppSelector } from '@src/hooks/store'
import { EventBus } from '@src/shared/EventBus'
import { commonColors, customerColors, executorColors } from '@theme/colors'
import { fontSizes } from '@theme/fontSizes'
import { fontWeights } from '@theme/fontWeights'
import { convertToDarkThemeColor } from '@theme/helpers'
import { ITheme, TypeMode } from '@theme/interfaces'
import { mediaQueries } from '@theme/mediaQueries'

declare module 'styled-components' {
  export interface DefaultTheme extends ITheme {
    mediaQueries: Record<string, any>
  }
}

const convertColorsToDark = (colors: any) => {
  const copy = { ...colors }
  Object.keys(copy).forEach((item) => {
    copy[item] = convertToDarkThemeColor(copy[item])
  })
  return copy
}

export const themeEventBus = new EventBus()

export const shadows = {
  light: '5px 5px 35px 0px rgba(29, 35, 44, 0.05)',
  medium: `1px 2px 10px 0px hsla(216, 21%, 14%, 0.1)`,
}

export const CustomThemeProvider = ({ children }: React.PropsWithChildren) => {
  const currentUrl = window.location.href

  const { myRole } = useAppSelector((state) => state.profile)

  const urlParts = currentUrl.split('/')

  const [mode, setMode] = useState<TypeMode>('light')

  const [colorsType, setColorsType] = useState<'executor' | 'customer'>(
    myRole === 'executor' ? 'executor' : 'customer'
  )

  const currentUserRoleColors =
    colorsType === 'executor' ? executorColors : customerColors

  const colors = { ...commonColors, ...currentUserRoleColors }

  const theme: ITheme = {
    mode,
    fonts: {
      primary: 'Gilroy',
    },
    colors: mode === 'dark' ? convertColorsToDark(colors) : colors,
    fontSizes: fontSizes,
    fontWeights: fontWeights,
    mediaQueries: mediaQueries,
    transitions: {
      base: '0.3s ease',
    },
  }

  useEffect(() => {
    const handleColorsTypeChange = (value: unknown) => {
      setColorsType(value as 'executor' | 'customer')
    }
    const handleThemeChange = (value: unknown) => {
      setMode(value as TypeMode)
    }

    themeEventBus.on('changeTheme', handleThemeChange)
    themeEventBus.on('changeColorsType', handleColorsTypeChange)

    return () => {
      themeEventBus.off('changeTheme', {})
      themeEventBus.off('changeColorsType', {})
    }
  }, [])

  useLayoutEffect(() => {
    const currentTheme = localStorage.getItem('DARK_THEME')
    if (currentTheme) {
      document.documentElement.setAttribute('data-theme', currentTheme)
      setMode('dark')
    } else if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      document.documentElement.setAttribute('data-theme', 'dark')
      setMode('light')
    } else {
      document.documentElement.setAttribute('data-theme', 'light')
      setMode('light')
    }
  }, [])

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
