import { CSSProperties } from 'react'

export const PaidRate = ({
  size = 20,
  style,
}: {
  size?: number
  style?: CSSProperties
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style, verticalAlign: 'middle' }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.126 13.8008L3 17.0008L5.5 18.5008L7 21.0008L10.899 16.9058"
        fill="#34C759"
      />
      <path
        d="M6.126 13.8008L3 17.0008L5.5 18.5008L7 21.0008L10.899 16.9058"
        stroke="#34C759"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1016 16.9058L17.0006 21.0008L18.5006 18.5008L21.0006 17.0008L17.8746 13.8008"
        fill="#34C759"
      />
      <path
        d="M13.1016 16.9058L17.0006 21.0008L18.5006 18.5008L21.0006 17.0008L17.8746 13.8008"
        stroke="#34C759"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <mask
        id="path-5-outside-1_6474_27034"
        maskUnits="userSpaceOnUse"
        x="4"
        y="2"
        width="16"
        height="16"
        fill="black"
      >
        <rect fill="white" x="4" y="2" width="16" height="16" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.9497 5.05025C19.6834 7.78392 19.6834 12.2161 16.9497 14.9497C14.2161 17.6834 9.78392 17.6834 7.05025 14.9497C4.31658 12.2161 4.31658 7.78392 7.05025 5.05025C9.78392 2.31658 14.2161 2.31658 16.9497 5.05025Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9497 5.05025C19.6834 7.78392 19.6834 12.2161 16.9497 14.9497C14.2161 17.6834 9.78392 17.6834 7.05025 14.9497C4.31658 12.2161 4.31658 7.78392 7.05025 5.05025C9.78392 2.31658 14.2161 2.31658 16.9497 5.05025Z"
        fill="#34C759"
      />
      <path
        d="M17.6568 4.34315C17.2663 3.95262 16.6332 3.95262 16.2426 4.34315C15.8521 4.73367 15.8521 5.36683 16.2426 5.75736L17.6568 4.34315ZM16.2426 5.75736C16.6332 6.14788 17.2663 6.14788 17.6568 5.75736C18.0474 5.36683 18.0474 4.73367 17.6568 4.34315L16.2426 5.75736ZM16.2426 5.75736C18.5858 8.1005 18.5858 11.8995 16.2426 14.2426L17.6568 15.6568C20.781 12.5327 20.781 7.46734 17.6568 4.34315L16.2426 5.75736ZM16.2426 14.2426C13.8995 16.5858 10.1005 16.5858 7.75736 14.2426L6.34315 15.6568C9.46734 18.781 14.5327 18.781 17.6568 15.6568L16.2426 14.2426ZM7.75736 14.2426C5.41421 11.8995 5.41421 8.1005 7.75736 5.75736L6.34315 4.34315C3.21895 7.46734 3.21895 12.5327 6.34315 15.6568L7.75736 14.2426ZM7.75736 5.75736C10.1005 3.41421 13.8995 3.41421 16.2426 5.75736L17.6568 4.34315C14.5327 1.21895 9.46734 1.21895 6.34315 4.34315L7.75736 5.75736Z"
        fill="#F5FCF6"
        mask="url(#path-5-outside-1_6474_27034)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0004 12.5833L13.7974 13.5273C14.1474 13.7113 14.5564 13.4143 14.4894 13.0243L14.1464 11.0233L15.6004 9.60731C15.8834 9.33131 15.7274 8.85031 15.3364 8.79331L13.3274 8.50131L12.4294 6.68031C12.2544 6.32531 11.7494 6.32531 11.5744 6.68031L10.6764 8.50131L8.66638 8.79431C8.27538 8.85131 8.11938 9.33231 8.40238 9.60831L9.85638 11.0243L9.51338 13.0253C9.44638 13.4153 9.85538 13.7123 10.2054 13.5283L12.0024 12.5843H12.0004V12.5833Z"
        stroke="#F5FCF6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
