import { LIMIT_NOTIFICATIONS_LIST } from '@pages/BusinessNotifications/constants'
import { IBusinessNotification } from '@pages/BusinessNotifications/interfaces'
import { createSlice } from '@reduxjs/toolkit'

const initialState: {
  list: IBusinessNotification[]
  page: number
  lastItemsCount: number
  onlyNew: boolean
  idsToDelete: number[]
} = {
  list: [],
  page: 1,
  lastItemsCount: LIMIT_NOTIFICATIONS_LIST,
  onlyNew: false,
  idsToDelete: [],
}

const businessNotificationsSlice = createSlice({
  name: 'businessNotifications',
  initialState,
  reducers: {
    addPageDataNotifications: (state, action) => {
      state.list = [...state.list, ...action.payload]
    },
    incrementPageNotifications: (state) => {
      state.page += 1
    },
    setLastItemsCountNotifications: (state, action) => {
      state.lastItemsCount = action.payload
    },
    clearNotificationsList: (state) => {
      state.list = []
      state.page = 1
      state.lastItemsCount = LIMIT_NOTIFICATIONS_LIST
      state.idsToDelete = []
    },
    readNotification: (state, action) => {
      const notification = state.list.find((item) => item.id === action.payload)
      if (notification) {
        notification.viewed_at = 'viewed'
      }
    },
    setOnlyNew: (state, action) => {
      state.onlyNew = action.payload
    },
    toggleIdsToDelete: (state, action) => {
      if (state.idsToDelete.includes(action.payload)) {
        state.idsToDelete = state.idsToDelete.filter(
          (id) => id !== action.payload
        )
      } else {
        state.idsToDelete.push(action.payload)
      }
    },
    resetIdsToDelete: (state) => {
      state.idsToDelete = []
    },
  },
})

export const {
  addPageDataNotifications,
  incrementPageNotifications,
  setLastItemsCountNotifications,
  clearNotificationsList,
  readNotification,
  toggleIdsToDelete,
  resetIdsToDelete,
  setOnlyNew,
} = businessNotificationsSlice.actions

export const businessNotificationsReducer = businessNotificationsSlice.reducer
