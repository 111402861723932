import IconPropsInterface from './IconPropsInterface'

export const TechBus = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 18 12"
      fill={color}
      stroke="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M0.171875 1.97794C0.171875 1.02579 0.945383 0.253906 1.89953 0.253906H14.857C16.2882 0.253906 17.4485 1.06691 17.4485 2.49515V8.52925C17.4485 9.38906 16.8177 10.1019 15.9927 10.232C15.7338 11.2357 14.8207 11.9773 13.734 11.9773C12.655 11.9773 11.7472 11.2462 11.4808 10.2533H6.48507C6.21872 11.2462 5.31087 11.9773 4.23187 11.9773C3.15288 11.9773 2.24503 11.2462 1.97868 10.2533C1.00917 10.2533 0.171875 9.53057 0.171875 8.52925V1.97794ZM12.9566 5.89159H3.45443C2.02318 5.89159 0.862939 4.7338 0.862939 3.30556C0.862939 3.09435 0.862939 2.88195 0.862939 2.66755C0.862939 2.57233 0.940286 2.49515 1.0357 2.49515H12.9566C13.052 2.49515 13.1293 2.57233 13.1293 2.66755V5.71918C13.1293 5.8144 13.052 5.89159 12.9566 5.89159ZM16.5126 6.99783L14.0939 5.89159C14.0325 5.8635 13.9932 5.80227 13.9932 5.73486V2.66755C13.9932 2.57233 14.0705 2.49515 14.1659 2.49515H16.5846C16.68 2.49515 16.7574 2.57233 16.7574 2.66755V6.84111C16.7574 6.96673 16.6271 7.05017 16.5126 6.99783ZM5.52762 9.64987C5.52762 10.364 4.94749 10.9429 4.23187 10.9429C3.51626 10.9429 2.93613 10.364 2.93613 9.64987C2.93613 8.93576 3.51626 8.35685 4.23187 8.35685C4.94749 8.35685 5.52762 8.93576 5.52762 9.64987ZM15.0297 9.64987C15.0297 10.364 14.4496 10.9429 13.734 10.9429C13.0184 10.9429 12.4383 10.364 12.4383 9.64987C12.4383 8.93576 13.0184 8.35685 13.734 8.35685C14.4496 8.35685 15.0297 8.93576 15.0297 9.64987Z"
        fillRule="evenodd"
      />
    </svg>
  )
}
