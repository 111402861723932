import IconPropsInterface from '@icons/IconPropsInterface'

export const SocialSkype = ({
  size = 20,
  color,
  style,
}: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style, verticalAlign: 'middle' }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.28083 3.26292C5.5725 2.33042 7.4 2.25042 8.775 3.04792C10.3258 2.77875 11.9658 3.03458 13.35 3.78875C14.895 4.61875 16.1075 6.04375 16.6725 7.70542C17.0608 8.82958 17.1508 10.0513 16.9492 11.2229C17.4458 12.0963 17.6183 13.1479 17.4167 14.1338C17.2042 15.2213 16.5333 16.2063 15.6067 16.8121C14.3233 17.6754 12.56 17.7238 11.2225 16.9496C9.73333 17.2054 8.16333 16.9838 6.81667 16.2963C5.25083 15.5096 4.0025 14.1179 3.39167 12.4754C2.94917 11.3021 2.83833 10.0104 3.04833 8.77542C2.54083 7.88625 2.37667 6.81208 2.59333 5.81208C2.80833 4.79042 3.42667 3.86125 4.28083 3.26292V3.26292Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.56583 12.0293C7.8175 12.7627 8.81 13.3118 10 13.3118C11.6667 13.3118 12.5 12.5752 12.5 11.666V11.6868V11.6868C12.5 10.9535 11.97 10.3277 11.2467 10.2077L10 9.99935L8.75333 9.79185C8.03 9.67102 7.5 9.04518 7.5 8.31185V8.31185C7.5 7.40268 8.33333 6.66602 10 6.66602C11.1908 6.66602 12.1825 7.21518 12.4342 7.94852"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
