import IconPropsInterface from './IconPropsInterface'

export const Applications = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4695_25210)">
        <path
          d="M10.833 4.16663H17.4997"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.833 7.5H14.9997"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.833 12.5H17.4997"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.833 15.8334H14.9997"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.66667 3.33331H3.33333C2.8731 3.33331 2.5 3.70641 2.5 4.16665V7.49998C2.5 7.96022 2.8731 8.33331 3.33333 8.33331H6.66667C7.1269 8.33331 7.5 7.96022 7.5 7.49998V4.16665C7.5 3.70641 7.1269 3.33331 6.66667 3.33331Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.66667 11.6667H3.33333C2.8731 11.6667 2.5 12.0398 2.5 12.5V15.8334C2.5 16.2936 2.8731 16.6667 3.33333 16.6667H6.66667C7.1269 16.6667 7.5 16.2936 7.5 15.8334V12.5C7.5 12.0398 7.1269 11.6667 6.66667 11.6667Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4695_25210">
          <rect width={size} height={size} fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
