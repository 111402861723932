import IconPropsInterface from './IconPropsInterface'

export const Offers = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4695_20192)">
        <path
          d="M7.66683 4.16669H6.00016C5.55814 4.16669 5.13421 4.34228 4.82165 4.65484C4.50909 4.9674 4.3335 5.39133 4.3335 5.83335V15.8334C4.3335 16.2754 4.50909 16.6993 4.82165 17.0119C5.13421 17.3244 5.55814 17.5 6.00016 17.5H14.3335C14.7755 17.5 15.1994 17.3244 15.512 17.0119C15.8246 16.6993 16.0002 16.2754 16.0002 15.8334V5.83335C16.0002 5.39133 15.8246 4.9674 15.512 4.65484C15.1994 4.34228 14.7755 4.16669 14.3335 4.16669H12.6668"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.66675 4.16667C7.66675 3.72464 7.84234 3.30072 8.1549 2.98816C8.46746 2.67559 8.89139 2.5 9.33341 2.5H11.0001C11.4421 2.5 11.866 2.67559 12.1786 2.98816C12.4912 3.30072 12.6667 3.72464 12.6667 4.16667C12.6667 4.60869 12.4912 5.03262 12.1786 5.34518C11.866 5.65774 11.4421 5.83333 11.0001 5.83333H9.33341C8.89139 5.83333 8.46746 5.65774 8.1549 5.34518C7.84234 5.03262 7.66675 4.60869 7.66675 4.16667Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.66675 10H12.6667"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.66675 13.3333H12.6667"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4695_20192">
          <rect
            width={size}
            height={size}
            fill="white"
            transform="translate(0.166748)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
