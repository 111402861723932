import { onClose } from '@pages/Chat/chatWebsocket/onClose'
import { onError } from '@pages/Chat/chatWebsocket/onError'
import { onMessage } from '@pages/Chat/chatWebsocket/onMessage'
import { ws } from '@pages/Chat/chatWebsocket/ws'
import {
  WS_CONNECTION_TRY_COUNT,
  audioBuffer,
  audioContext,
  wsConnectionTryRef,
} from '@pages/Chat/constants'
import { setChatStateValue } from '@pages/Chat/helpers'
import sound from '@src/assets/sounds/chat-sound.mp3'
import { store } from '@src/store'
import { refreshToken } from '@src/store/slices/baseAuth'
import { ACCESS_TOKEN, websocketChatUrl } from '@src/utils/constants/auth'

export const startWs = (profileId: number, userId: number) => {
  try {
    if (!ws.current) {
      ws.current = new WebSocket(websocketChatUrl)
      ws.current.onopen = () => {
        console.log('connectionOpened')
        if (ws.current?.readyState === 1) {
          wsConnectionTryRef.current = WS_CONNECTION_TRY_COUNT
          audioBuffer.current = fetch(sound)
            .then((data) => {
              return data.arrayBuffer()
            })
            .then((arrayBuffer) => {
              return audioContext.current.decodeAudioData(arrayBuffer)
            })
            .then((result) => {
              audioBuffer.current = result
            })
          setChatStateValue('isSocketOnline')(true)
          ws.current?.send(
            JSON.stringify({
              type: 'connect',
              user_id: userId.toString(),
              profile_id: profileId.toString(),
              token:
                store.getState().reg.access_token ||
                refreshToken ||
                ACCESS_TOKEN,
            })
          )
        } else {
          console.log('connection error')
        }
      }
      ws.current.onclose = onClose
      ws.current.onerror = onError
      ws.current.onmessage = onMessage
    } else {
      ws.current?.send(
        JSON.stringify({
          type: 'connect',
          user_id: userId.toString(),
          profile_id: profileId.toString(),
          token:
            store.getState().reg.access_token || refreshToken || ACCESS_TOKEN,
        })
      )
    }
  } catch (e) {
    console.log(e)
  }
}
