import { useEffect, useRef } from 'react'

export const useClickOut = (handleClickout?: () => void) => {
  const ref = useRef<any>(null)
  useEffect(() => {
    const handleClick = (event: any) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        handleClickout
      ) {
        handleClickout()
      }
    }
    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [handleClickout])
  return ref
}
