import IconPropsInterface from './IconPropsInterface'

export const Doc2 = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4235_45300)">
        <path
          d="M5.45597 17.7186C5.17918 17.573 4.9494 17.3636 4.7898 17.1113C4.63019 16.8591 4.54641 16.5729 4.54688 16.2818V4.68756C4.54688 4.24828 4.73843 3.82699 5.07941 3.51637C5.42039 3.20575 5.88285 3.03125 6.36506 3.03125H12.7287L17.2741 7.17204V16.2818C17.2741 16.7211 17.0826 17.1423 16.7416 17.453C16.4006 17.7636 15.9382 17.9381 15.456 17.9381H14.5469"
          stroke={color}
          strokeWidth="0.833333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.0018 14.626C10.484 14.626 10.9464 14.8005 11.2874 15.1111C11.6284 15.4217 11.82 15.843 11.82 16.2823V17.9386C11.82 18.1582 11.7242 18.3689 11.5537 18.5242C11.3832 18.6795 11.152 18.7668 10.9109 18.7668H9.09268C8.85158 18.7668 8.62035 18.6795 8.44986 18.5242C8.27937 18.3689 8.18359 18.1582 8.18359 17.9386V16.2823C8.18359 15.843 8.37515 15.4217 8.71613 15.1111C9.0571 14.8005 9.51956 14.626 10.0018 14.626Z"
          stroke={color}
          strokeWidth="0.833333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.99893 4.6875H9.08984"
          stroke={color}
          strokeWidth="0.833333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.8192 6.34375H10.9102"
          stroke={color}
          strokeWidth="0.833333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.99893 8H9.08984"
          stroke={color}
          strokeWidth="0.833333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.8192 9.65723H10.9102"
          stroke={color}
          strokeWidth="0.833333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.99893 11.3125H9.08984"
          stroke={color}
          strokeWidth="0.833333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.8192 12.9688H10.9102"
          stroke={color}
          strokeWidth="0.833333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4235_45300">
          <rect
            width="21.8182"
            height="19.8758"
            fill="white"
            transform="translate(0 0.546875)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
