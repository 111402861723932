import IconPropsInterface from './IconPropsInterface'

export const AddTask = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      stroke={color}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path d="M10 8V12M8 10H12" strokeLinecap="round" />
      <rect x="2.5" y="5.5" width="15" height="9" rx="2.5" />
      <line x1="2.5" y1="2.5" x2="17.5" y2="2.5" strokeLinecap="round" />
      <line x1="2.5" y1="17.5" x2="17.5" y2="17.5" strokeLinecap="round" />
    </svg>
  )
}
