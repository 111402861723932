import { IHistoryEvent } from '@pages/Space/Bonuses/types/IHistoryEvent'
import { IPrize } from '@pages/Space/Bonuses/types/IPrize'
import { IBasePaginationRequest } from '@pages/Space/Bonuses/types/api/IBasePaginationRequest'
import { ICancelPrizeRequest } from '@pages/Space/Bonuses/types/api/ICancelPrizeRequest'
import { ICatalogResponse } from '@pages/Space/Bonuses/types/api/ICatalogResponse'
import { IGetPrizeRequest } from '@pages/Space/Bonuses/types/api/IGetPrizeRequest'
import { IHistoryRequest } from '@pages/Space/Bonuses/types/api/IHistoryRequest'
import { IHistoryResponse } from '@pages/Space/Bonuses/types/api/IHistoryResponse'
import { IPeriodResponse } from '@pages/Space/Bonuses/types/api/IPeriodResponse'
import { IRecipientsResponse } from '@pages/Space/Bonuses/types/api/IRecipientsResponse'
import { IPaginatedItems } from '@src/shared/interfaces/apiResponse/IPagination'
import { ISuccessResponse } from '@src/shared/interfaces/apiResponse/ISuccessResponse'
import { clientApiSlice } from '@src/store/slices/clientApiSlice'
import { formatUrlGetParamsFromObject } from '@src/utils/helpers/formatUrlGetParamsFromObject'

export const bonusesApi = clientApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPeriod: builder.query<IPeriodResponse, number>({
      query: (spaceId) => ({
        url: `/spaces/${spaceId}/bonus-program/periods`,
      }),
    }),
    getTopRecipients: builder.query<
      IRecipientsResponse,
      IBasePaginationRequest
    >({
      query: ({ spaceId, page, limit }) => ({
        url: `/spaces/${spaceId}/bonus-program/prizes/receive/top${formatUrlGetParamsFromObject(
          {
            page,
            limit,
          }
        )}`,
      }),
    }),
    getActualPrizes: builder.query<ICatalogResponse, IBasePaginationRequest>({
      query: ({ spaceId, page, limit }) => ({
        url: `/spaces/${spaceId}/bonus-program/prizes/actual${formatUrlGetParamsFromObject(
          {
            page,
            limit,
          }
        )}`,
      }),
    }),
    getOldPrizes: builder.query<ICatalogResponse, IBasePaginationRequest>({
      query: ({ spaceId, page, limit }) => ({
        url: `/spaces/${spaceId}/bonus-program/prizes/old${formatUrlGetParamsFromObject(
          {
            page,
            limit,
          }
        )}`,
      }),
    }),
    getMyPrizes: builder.query<
      ISuccessResponse<IPaginatedItems<IPrize>>,
      IBasePaginationRequest
    >({
      query: ({ spaceId, page, limit }) => ({
        url: `/spaces/${spaceId}/bonus-program/prizes/receive${formatUrlGetParamsFromObject(
          {
            page,
            limit,
          }
        )}`,
      }),
    }),
    cancelPrize: builder.mutation<ISuccessResponse, ICancelPrizeRequest>({
      query: ({ spaceId, prizeId }) => ({
        url: `/spaces/${spaceId}/bonus-program/prizes/receive/${prizeId}/cancel`,
        method: 'PATCH',
      }),
    }),
    getHistory: builder.query<IHistoryResponse, IHistoryRequest>({
      query: ({ spaceId, page, limit, type, event_id }) => ({
        url: `/spaces/${spaceId}/bonus-program/history${formatUrlGetParamsFromObject(
          {
            page,
            limit,
            type,
            event_id,
          }
        )}`,
      }),
    }),
    getPrize: builder.mutation<ISuccessResponse, IGetPrizeRequest>({
      query: ({ spaceId, body }) => ({
        url: `/spaces/${spaceId}/bonus-program/prizes/receive`,
        method: 'POST',
        body: body,
      }),
    }),
    getHistoryEvents: builder.query<
      ISuccessResponse<IPaginatedItems<IHistoryEvent>>,
      IBasePaginationRequest
    >({
      query: ({ spaceId, page, limit }) => ({
        url: `/spaces/${spaceId}/bonus-program/events${formatUrlGetParamsFromObject(
          {
            page,
            limit,
          }
        )}`,
      }),
    }),
    getBonusBalance: builder.query<
      ISuccessResponse<{ bonuses: number; word: string }>,
      number
    >({
      query: (spaceId) => ({
        url: `/spaces/${spaceId}/bonus-program/balance`,
      }),
    }),
    getBonusProgramConditions: builder.query<any, number>({
      query: (spaceId) => ({
        url: `/spaces/${spaceId}/bonus-program/conditions/web`,
        responseHandler: (response: any) => response.text(),
      }),
    }),
  }),
})

export const {
  useGetPeriodQuery,
  useLazyGetTopRecipientsQuery,
  useLazyGetActualPrizesQuery,
  useLazyGetOldPrizesQuery,
  useLazyGetMyPrizesQuery,
  useCancelPrizeMutation,
  useLazyGetHistoryQuery,
  useGetPrizeMutation,
  useGetHistoryEventsQuery,
  useLazyGetHistoryEventsQuery,
  useGetBonusBalanceQuery,
  useGetBonusProgramConditionsQuery,
} = bonusesApi
