import IconPropsInterface from './IconPropsInterface'

export const Send = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 20 20"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M5 14.9993V9.99935C5 9.33631 5.26339 8.70042 5.73223 8.23158C6.20107 7.76274 6.83696 7.49935 7.5 7.49935H15.8333L12.5 4.16602M12.5 10.8327L15.8333 7.49935"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
