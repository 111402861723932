import IconPropsInterface from './IconPropsInterface'

export const BarFavorite = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 16 15"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M7.99794 11.8346L3.88327 13.9979L4.66927 9.41594L1.33594 6.17127L5.93594 5.5046L7.99327 1.33594L10.0506 5.5046L14.6506 6.17127L11.3173 9.41594L12.1033 13.9979L7.99794 11.8346Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
