import { useEffect, useRef } from 'react'

interface IUseIntersectionObserver {
  callback: Function
  once?: boolean
  dependencies?: unknown[]
  options?: any
}

export const useIntersectionObserver = ({
  callback,
  once = true,
  dependencies = [],
  options = {},
}: IUseIntersectionObserver) => {
  const observableElement = useRef(null)
  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        callback()
        if (once) {
          observer.disconnect()
        }
      }
    }, options)
    if (observableElement.current) {
      observer.observe(observableElement.current)
    }

    return () => {
      observer.disconnect()
    }
  }, dependencies)

  return { ref: observableElement }
}
