import IconPropsInterface from './IconPropsInterface'

export const AttachDocument = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
      viewBox="0 0 14 16"
    >
      <path
        d="M8.66797 0.5V3.83333C8.66797 4.05435 8.75577 4.26631 8.91205 4.42259C9.06833 4.57887 9.28029 4.66667 9.5013 4.66667H12.8346"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.168 15.5H2.83464C2.39261 15.5 1.96868 15.3244 1.65612 15.0118C1.34356 14.6993 1.16797 14.2754 1.16797 13.8333V2.16667C1.16797 1.72464 1.34356 1.30072 1.65612 0.988155C1.96868 0.675595 2.39261 0.5 2.83464 0.5H8.66797L12.8346 4.66667V13.8333C12.8346 14.2754 12.659 14.6993 12.3465 15.0118C12.0339 15.3244 11.61 15.5 11.168 15.5Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 7.16699V12.167"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M4.5 9.66699H9.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
