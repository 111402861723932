import { useLayoutEffect, useState } from 'react'

import { useGetCurrentSpacePermissionsQuery } from '@pages/Profile/api/ProfileApi'
import { useAppSelector } from '@src/hooks/store'

interface IUseGetSpaceRelationStatus {
  status: 'isOwn' | 'isAssociated' | 'isForeign' | null
  isLoading: boolean
  isError: boolean
  hasPermission: boolean
  isFetching: boolean
}

export const usePermissions = (
  permission?: string
): IUseGetSpaceRelationStatus => {
  const id = useAppSelector((state) => state.profile.currentSpaceId)

  const { data, isLoading, isFetching, isError } =
    useGetCurrentSpacePermissionsQuery(Number(id), { skip: !id })
  const [status, setStatus] =
    useState<IUseGetSpaceRelationStatus['status']>(null)

  useLayoutEffect(() => {
    const info = data?.data
    if (isFetching) setStatus(null)
    else if (info && !isFetching) {
      if (info.owner) {
        setStatus('isOwn')
      } else if (info.permissions.length > 0) {
        setStatus('isAssociated')
      } else {
        setStatus('isForeign')
      }
    }
  }, [data, isFetching])

  let hasPermission = false
  if (isFetching || isLoading) {
    hasPermission = false
  } else if (data && !isFetching) {
    hasPermission = data.data.owner
      ? true
      : !!data?.data?.permissions?.find((item) => item.name === permission) ||
        false
  }

  return {
    status,
    isLoading: isLoading,
    isFetching,
    isError: isError,
    hasPermission: hasPermission,
  }
}
