import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from '@src/store'
import { TooltipPosition } from '@ui/Tooltip/BaseTooltip'

export type SectionNames = string | null

export interface Tooltip {
  position?: TooltipPosition
  gap?: number
  title: string
  description: string
  targetElementId: string
  isInModal?: boolean
}

const initialState: {
  isShown: boolean
  completedSections: SectionNames[]
  currentTooltipIndex: number
  currentSectionName: null | string
  tooltips: Tooltip[] | null
} = {
  isShown:
    localStorage.getItem('isHintsShown') !== null
      ? !!localStorage.getItem('isHintsShown')
      : true,
  completedSections:
    localStorage.getItem('completedHintsSections') !== null
      ? JSON.parse(localStorage.getItem('completedHintsSections')!)
      : [],
  currentTooltipIndex: 0,
  currentSectionName: null,
  tooltips: null,
}

const hintsSlice = createSlice({
  name: 'hints',
  initialState,
  reducers: {
    isHintsShownChanged: (state, { payload }: PayloadAction<boolean>) => {
      state.isShown = payload

      state.currentTooltipIndex = initialState.currentTooltipIndex

      if (!payload) {
        localStorage.setItem('isHintsShown', '')
      } else {
        localStorage.setItem('isHintsShown', 'true')
        localStorage.removeItem('completedHintsSections')
        state.completedSections = []
      }
    },
    completedSectionsChanged: (
      state,
      { payload }: PayloadAction<SectionNames>
    ) => {
      if (state.completedSections.includes(payload)) return

      state.completedSections.push(payload)
      localStorage.setItem(
        'completedHintsSections',
        JSON.stringify(state.completedSections)
      )
    },
    currentTooltipIndexChanged: (state, { payload }: PayloadAction<number>) => {
      state.currentTooltipIndex = payload
    },
    // setCurrentSectionName: (
    //   state,
    //   { payload }: PayloadAction<string | null>
    // ) => {
    //   state.currentSectionName = payload
    // },
    setTooltips: (
      state,
      { payload }: PayloadAction<{ items: Tooltip[]; sectionName: string }>
    ) => {
      state.currentTooltipIndex = 0
      state.tooltips = payload.items
      state.currentSectionName = payload.sectionName
    },
    tooltipsCleared: (state) => {
      state.tooltips = initialState.tooltips
      state.currentTooltipIndex = initialState.currentTooltipIndex
      state.currentSectionName = initialState.currentSectionName
    },
  },
})

export const {
  isHintsShownChanged,
  completedSectionsChanged,
  currentTooltipIndexChanged,
  setTooltips,
  tooltipsCleared,
} = hintsSlice.actions
export const hintsSliceReducer = hintsSlice.reducer

export const selectSectionHintsIsShown = (
  state: RootState,
  sectionName?: string
) => {
  const isSectionShown = sectionName
    ? !state.hints.completedSections.includes(sectionName)
    : !state.hints.completedSections.includes(state.hints.currentSectionName)

  return state.hints.isShown && isSectionShown
}
