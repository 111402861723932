import { ICargoState } from '../filterSliceCargo'
import { IDeliveryState } from '../filterSliceDelivery'
import { IPassengerState } from '../filterSlicePassenger'
import { IRentState } from '../filterSliceRent'
import { ICommonTaskState } from '../taskSlice'

export const getFilterRentPayload = ({
  workType,
  coordinates,
  radius,
  vehicleTypes,
}: IRentState) => {
  const result: any = {}

  if (vehicleTypes.length > 0) {
    result.vehicle_type_ids = vehicleTypes.map(({ value }) => value)
  }

  if (workType?.value) {
    result.work_type_ids = [workType.value]
  }

  if (coordinates) {
    const [lat, lng] = coordinates
    result.point_where = {
      radius: radius * 1000,
      coordinates: {
        lat,
        lng,
      },
    }
  }

  return result
}

export const getFilterCargoPayload = ({
  vehicleLoadingTypes,
  vehicleShippingNames,
  isCombined,
  isDanger,
  needKoniki,
  weightDiapason,
  lengthDiapason,
  widthDiapason,
  heightDiapason,
  palletCountDiapason,
  radiusFrom,
  coordsFrom,
  radiusWhere,
  coordsWhere,
}: ICargoState) => {
  const result: any = {
    vehicle_shipping_name_ids: vehicleShippingNames.map(({ value }) => value),
    vehicle_loading_type_ids: vehicleLoadingTypes.map(({ value }) => value),
    is_combined: isCombined,
    is_danger: isDanger,
    need_koniki: needKoniki,
  }

  if (weightDiapason[0]) {
    result.weight_min = +weightDiapason[0]
  }
  if (weightDiapason[1]) {
    result.weight_max = +weightDiapason[1]
  }

  if (lengthDiapason[0]) {
    result.length_min = +lengthDiapason[0]
  }
  if (lengthDiapason[1]) {
    result.length_max = +lengthDiapason[1]
  }

  if (widthDiapason[0]) {
    result.width_min = +widthDiapason[0]
  }
  if (widthDiapason[1]) {
    result.width_max = +widthDiapason[1]
  }

  if (heightDiapason[0]) {
    result.height_min = +heightDiapason[0]
  }
  if (heightDiapason[1]) {
    result.height_max = +heightDiapason[1]
  }

  if (palletCountDiapason[0]) {
    result.pallet_count_min = +palletCountDiapason[0]
  }
  if (palletCountDiapason[1]) {
    result.pallet_count_max = +palletCountDiapason[1]
  }

  const [latFrom, lngFrom] = coordsFrom || []
  if (latFrom && lngFrom) {
    result.point_from = {
      radius: radiusFrom * 1000,
      coordinates: {
        lat: latFrom,
        lng: lngFrom,
      },
    }
  }

  const [latWhere, lngWhere] = coordsWhere || []
  if (latWhere && lngWhere) {
    result.point_where = {
      radius: radiusWhere * 1000,
      coordinates: {
        lat: latWhere,
        lng: lngWhere,
      },
    }
  }

  return result
}

export const getFilterPassengerPayload = ({
  vehicleTypes,
  numberOfPassengersDiapason,
  radiusFrom,
  radiusWhere,
  coordinatesFrom,
  coordinatesWhere,
}: IPassengerState) => {
  const result: any = {
    vehicle_type_ids: vehicleTypes.map((item) => item.value),
  }

  if (numberOfPassengersDiapason[0]) {
    result.number_of_passengers_min = +numberOfPassengersDiapason[0]
  }
  if (numberOfPassengersDiapason[1]) {
    result.number_of_passengers_max = +numberOfPassengersDiapason[1]
  }

  const [latFrom, lngFrom] = coordinatesFrom || []
  if (latFrom && lngFrom) {
    result.point_from = {
      radius: radiusFrom * 1000,
      coordinates: {
        lat: latFrom,
        lng: lngFrom,
      },
    }
  }

  const [latWhere, lngWhere] = coordinatesWhere || []
  if (latWhere && lngWhere) {
    result.point_where = {
      radius: radiusWhere * 1000,
      coordinates: {
        lat: latWhere,
        lng: lngWhere,
      },
    }
  }

  return result
}

export const getFilterDeliveryPayload = ({
  materials,
  radius,
  coordinates,
}: IDeliveryState) => {
  const result: any = {
    materials: [],
  }

  const [lat, lng] = coordinates || []
  if (lat && lng) {
    result.point_where = {
      radius: radius * 1000,
      coordinates: {
        lat: lat,
        lng: lng,
      },
    }
  }

  Object.keys(materials).forEach((key) => {
    const id = +key
    const materialItemResult: any = {
      material_type_id: id,
    }

    const unit = materials[id].currentUnit.value
    if (unit) {
      materialItemResult.material_type_unit_id = unit
    }

    const [quantityMin, quantityMax] = materials[id].quantityDiapason
    if (quantityMin) {
      materialItemResult.quantity_min = +quantityMin
    }
    if (quantityMax) {
      materialItemResult.quantity_max = +quantityMax
    }

    const stateCharacteristics = materials[id].characteristics

    const payloadCharacteristics = Object.keys(stateCharacteristics).map(
      (characteristicId) => {
        const { value } = stateCharacteristics[+characteristicId]
        return {
          id: +characteristicId,
          value: typeof value === 'string' ? value : value.value,
        }
      }
    )

    materialItemResult.characteristics = payloadCharacteristics.filter(
      ({ value }) => value
    )

    result.materials.push(materialItemResult)
  })

  return result
}

export const getGlobalFilterPayload = (
  variant: ICommonTaskState['filter']['global']
) => {
  let in_favorite = false
  let in_hidden = false
  if (variant === 'favorite') {
    in_favorite = true
    in_hidden = false
  }
  if (variant === 'hidden') {
    in_favorite = false
    in_hidden = true
  }
  return {
    in_favorite,
    in_hidden,
  }
}
