import { EntityAdapter } from '@reduxjs/toolkit'
import { IPaginatedItems } from '@src/shared/interfaces/apiResponse/IPagination'
import { ISuccessResponse } from '@src/shared/interfaces/apiResponse/ISuccessResponse'

export interface IPaginationAdditionalState {
  page: number
  totalCount: null | number
}

export const getInitialPaginationState = () => {
  return {
    page: 1,
    totalCount: null,
  }
}

export const addItemsToAdapter = (
  adapter: EntityAdapter<any>,
  state: any,
  payload: ISuccessResponse<IPaginatedItems<any>>
) => {
  adapter.addMany(state, payload.data.items)
  state.page = state.page + 1
  if (state.totalCount === null) {
    state.totalCount = payload.data.pagination.total_items
  }
}

export const clearAdapterItems = (adapter: EntityAdapter<any>, state: any) => {
  adapter.removeAll(state)
  state.page = 1
  state.totalCount = null
}
