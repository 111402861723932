import IconPropsInterface from '@icons/IconPropsInterface'

export const Doc4 = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 12 12`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3934_44865)">
        <path
          d="M4.5 2.5H3.5C3.23478 2.5 2.98043 2.60536 2.79289 2.79289C2.60536 2.98043 2.5 3.23478 2.5 3.5V9.5C2.5 9.76522 2.60536 10.0196 2.79289 10.2071C2.98043 10.3946 3.23478 10.5 3.5 10.5H8.5C8.76522 10.5 9.01957 10.3946 9.20711 10.2071C9.39464 10.0196 9.5 9.76522 9.5 9.5V3.5C9.5 3.23478 9.39464 2.98043 9.20711 2.79289C9.01957 2.60536 8.76522 2.5 8.5 2.5H7.5"
          stroke={color}
          strokeWidth="0.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.5 2.5C4.5 2.23478 4.60536 1.98043 4.79289 1.79289C4.98043 1.60536 5.23478 1.5 5.5 1.5H6.5C6.76522 1.5 7.01957 1.60536 7.20711 1.79289C7.39464 1.98043 7.5 2.23478 7.5 2.5C7.5 2.76522 7.39464 3.01957 7.20711 3.20711C7.01957 3.39464 6.76522 3.5 6.5 3.5H5.5C5.23478 3.5 4.98043 3.39464 4.79289 3.20711C4.60536 3.01957 4.5 2.76522 4.5 2.5Z"
          stroke={color}
          strokeWidth="0.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.5 6H7.5"
          stroke={color}
          strokeWidth="0.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.5 8H7.5"
          stroke={color}
          strokeWidth="0.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3934_44865">
          <rect width={size} height={size} fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
