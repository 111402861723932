import styled from 'styled-components'

import { useGetTheme } from '@theme/hooks'

import { Spinner } from './Spinner'

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

const Loader = () => {
  const theme = useGetTheme()
  return (
    <LoaderContainer>
      <Spinner color={theme.colors.primary} />
    </LoaderContainer>
  )
}

export default Loader
