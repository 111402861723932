import { DropdownIndicatorProps, GroupBase, components } from 'react-select'

import { ArrowDown } from '@icons/ArrowDown'
import { useGetTheme } from '@theme/hooks'

export const DropdownIndicator = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: DropdownIndicatorProps<Option, IsMulti, Group>
) => {
  const theme = useGetTheme()

  return (
    <components.DropdownIndicator {...props}>
      <ArrowDown size={12} color={theme.colors.gray50} />
    </components.DropdownIndicator>
  )
}
