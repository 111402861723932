import { CSSProperties } from 'react'
import styled from 'styled-components'

import { Star } from '@icons/Star'
import { getColor, getFontSize, getFontWeight } from '@theme/helpers'
import { useGetTheme } from '@theme/hooks'
import { ITheme } from '@theme/interfaces'

export interface IRatingProps {
  totalStars?: number
  rating: number
  starsOnly?: boolean
  style?: CSSProperties
  isInverted?: boolean
  ratingNumberPosition?: 'left' | 'right'
  iconSize?: number
  fontSize?: keyof ITheme['fontSizes']
  margin?: number
}

const StyledWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`
const StyledDigits = styled.span<{ fontSize?: keyof ITheme['fontSizes'] }>`
  ${(props) => getFontSize(props.fontSize ?? 'm')}
  ${getColor('gray50')}
  ${getFontWeight('semibold')}
`

export const Rating = ({
  totalStars = 5,
  rating,
  starsOnly = true,
  style,
  isInverted,
  ratingNumberPosition = 'left',
  iconSize,
  fontSize,
  margin,
}: IRatingProps) => {
  const theme = useGetTheme()

  const filledColor = isInverted ? 'light' : 'primary'

  const calculateStarPercentages = (rating: number) => {
    const filledStars = Math.floor(rating)
    const remainingStar = rating - filledStars

    return Array.from({ length: totalStars }, (_, index) => {
      if (index < filledStars) {
        return 100
      } else if (index === filledStars) {
        return Math.floor(remainingStar * 100)
      } else {
        return 0
      }
    })
  }

  const starPercentages = calculateStarPercentages(rating)

  return (
    <StyledWrapper style={style}>
      {!starsOnly && ratingNumberPosition === 'left' && (
        <StyledDigits
          style={{ marginRight: `${margin ?? 10}px` }}
          fontSize={fontSize}
        >
          {rating ? rating : ''}
        </StyledDigits>
      )}
      {starPercentages.map((item, index: number) => (
        <Star
          key={index}
          color={theme.colors[filledColor]}
          size={iconSize}
          fillPercentage={item}
        />
      ))}

      {!starsOnly && ratingNumberPosition === 'right' && (
        <StyledDigits
          style={{ marginLeft: `${margin ?? 10}px` }}
          fontSize={fontSize}
        >
          {rating}
        </StyledDigits>
      )}
    </StyledWrapper>
  )
}
