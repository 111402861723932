import IconPropsInterface from './IconPropsInterface'

export const ArrowUp = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 11 6"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M1 5.5L4.75671 1.32588C5.15395 0.884503 5.84605 0.884502 6.24329 1.32588L10 5.5"
        strokeLinecap="round"
      />
    </svg>
  )
}
