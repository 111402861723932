import IconPropsInterface from './IconPropsInterface'

export const Time2 = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 6C1 3.23858 3.23858 1 6 1L6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12C6.18195 12 6.3621 11.9919 6.54009 11.976C7.297 11.9084 8.01373 11.7002 8.66364 11.3777L8.66365 11.3777C8.9227 11.2492 9.17108 11.1025 9.40714 10.9394C9.54878 10.8415 9.68598 10.7377 9.81839 10.6284C10.0449 10.4413 10.2574 10.2379 10.4542 10.02L9.71202 9.34983C9.54786 9.5316 9.37056 9.70127 9.18161 9.85731C9.05503 9.96185 8.92325 10.0603 8.78671 10.1521C8.60554 10.274 8.416 10.3842 8.21917 10.4819L8.21917 10.4819C7.63296 10.7728 6.98159 10.9519 6.29247 10.9916C6.19573 10.9972 6.09821 11 6 11C3.23858 11 1 8.76142 1 6ZM10.9248 5.13071C10.9742 5.41269 11 5.70313 11 6C11 6.24907 10.9818 6.49359 10.9468 6.73238L11.9362 6.87748C11.9783 6.5908 12 6.29779 12 6C12 5.64503 11.9691 5.29687 11.9098 4.95816L10.9248 5.13071ZM10.7872 7.44803C10.644 7.92231 10.4321 8.36704 10.1628 8.77067L10.9948 9.32558C11.3177 8.84137 11.5723 8.30727 11.7445 7.73716L10.7872 7.44803ZM9.83051 2.78621C10.2023 3.22888 10.4986 3.73656 10.6999 4.28942L11.6395 3.94732C11.3977 3.28289 11.0419 2.67363 10.5962 2.14303L9.83051 2.78621ZM7.71058 1.30012C8.26344 1.50139 8.77112 1.79766 9.21379 2.16949L9.85697 1.40377C9.32636 0.958082 8.7171 0.602345 8.05268 0.360454L7.71058 1.30012ZM6 1C6.29687 1 6.5873 1.02582 6.86929 1.07522L7.04184 0.0902146C6.70313 0.0308799 6.35497 0 6 0L6 1ZM6.1 3V6.25147L7.92426 8.07574L7.07574 8.92426L5.07574 6.92426C4.96321 6.81174 4.9 6.65913 4.9 6.5V3H6.1Z"
      />
    </svg>
  )
}
