import IconPropsInterface from '@icons/IconPropsInterface'

export const MailOpenLines = ({
  size = 20,
  color,
  style,
}: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style, flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M16.4577 7.5V2.5H4.79102V7.5L6.45768 8.33333V4.16667H14.791V8.33333L16.4577 7.5Z"
        fill={color}
      />
      <path
        d="M18.9577 16.6667C18.9577 17.1269 18.5846 17.5 18.1243 17.5H3.12435C2.66411 17.5 2.29102 17.1269 2.29102 16.6667V9.16667L10.6243 12.9167L18.9577 9.16667V16.6667Z"
        fill={color}
      />
      <path d="M8.12435 5.83333H13.1243V7.5H8.12435V5.83333Z" fill={color} />
      <path d="M13.1243 8.33333H8.12435V10H13.1243V8.33333Z" fill={color} />
    </svg>
  )
}
