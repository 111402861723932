import { VEHICLES_LIMIT } from '@pages/Profile/constants'
import { ICreateVehicle } from '@pages/Space/Vehicles/types/CreateVehicleTypes'
import { VehicleItem } from '@pages/Space/Vehicles/types/VehiclesTypes'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { IOption } from '@src/shared/interfaces/partials/IOption'

interface initialStateType {
  items: VehicleItem[]
  totalCount: number | null
  page: number
  lastItemsCount: number
  name: string
  brand_id: IOption<number> | null
  create: ICreateVehicle
  characteristics: any[]
  selectedType: number[]
  isSendData: boolean
  vehicle_type_id: number[]
  imagePhotosSrc: { public_src: string; bind_key: string; id?: number }[]
  src: { type: string; src: string }[]
  filesTypes: { type: string; name: string; id?: number }[]
  imageDocumentsSrc: { public_src: string; bind_key: string }[]
  vehiclesRegions: { value: number; label: string }[]
  validationErrors: string[]
  validationErrorsForApplication: string[][]
}

const initialState: initialStateType = {
  items: [],
  totalCount: null,
  page: 1,
  lastItemsCount: VEHICLES_LIMIT,
  name: '',
  brand_id: null,
  create: {
    name: '',
    brand_id: null,
    task_type_ids: [],
    vin: '',
    video_links: [''],
    tech_documents: [],
    configurations: [
      {
        name: '',
        vehicle_type_id: -1,
        sort: 0,
        characteristics: [],
        index: 0,
      },
    ],
    image_gallery: [],
    weight: '',
    height: '',
    length: '',
    width: '',
    weight_unit_type: 'kilogram',
    dimensions_unit_type: 'centimeter',
    sts_serial: '',
    sts_number: '',
    color: '',
    engine_power: 0,
    year: 0,
    number: '',
    region_ids: [],
  },
  characteristics: [],
  selectedType: [],
  isSendData: false,
  vehicle_type_id: [],
  imagePhotosSrc: [],
  src: [],
  filesTypes: [],
  imageDocumentsSrc: [],
  vehiclesRegions: [],
  validationErrors: [],
  validationErrorsForApplication: [],
}

const vehiclesSlice = createSlice({
  name: 'vehicles',
  initialState: initialState,
  reducers: {
    totalCountChanged: (state, { payload }: PayloadAction<number | null>) => {
      state.totalCount = payload
    },
    vehiclesAddPageData: (state, action) => {
      state.items = [...state.items, ...action.payload]
    },
    vehiclesIncrementPage: (state) => {
      state.page += 1
    },
    vehiclesSetLastItemsCount: (state, action) => {
      state.lastItemsCount = action.payload
    },
    vehiclesClear: (state) => {
      state.items = []
      state.page = 1
      state.lastItemsCount = VEHICLES_LIMIT
    },
    changeVehicleSearchName: (state, action) => {
      state.name = action.payload
    },
    setHeight: (state, action) => {
      state.create.height = action.payload
    },
    setWidth: (state, action) => {
      state.create.width = action.payload
    },
    setWeight: (state, action) => {
      state.create.weight = action.payload
    },
    setLength: (state, action) => {
      state.create.length = action.payload
    },
    setName: (state, action) => {
      state.create.name = action.payload
    },
    setVin: (state, action) => {
      state.create.vin = action.payload
    },
    setTechDocument: (state, action) => {
      state.create.tech_documents = [
        ...state.create.tech_documents,
        action.payload,
      ]
    },
    deleteTechImage: (state, action) => {
      const updateImages = state.create.tech_documents.filter(
        (item) => item !== action.payload
      )
      state.create.tech_documents = updateImages
    },
    deleteImage: (state, action) => {
      const updateImages = state.create.image_gallery.filter(
        (item) => item.file !== action.payload
      )

      if (updateImages.length > 0) {
        updateImages[0].is_primary = true
      }

      state.create.image_gallery = updateImages
    },
    setImage: (state, action) => {
      state.create.image_gallery = [
        ...state.create.image_gallery,
        {
          is_primary: !state.create.image_gallery.length,
          description: 'Описание',
          file: action.payload,
        },
      ]
    },
    setTypesIds: (state, action) => {
      state.create.task_type_ids = state.create.task_type_ids || []

      state.create.task_type_ids = [
        ...state.create.task_type_ids,
        action.payload,
      ]
    },
    setAllTypesIds: (state, action) => {
      state.create.task_type_ids = action.payload
    },
    resetTypesIds: (state) => {
      state.create.task_type_ids = []
    },
    setVideoLink: (state, action) => {
      if (state.create.video_links) {
        state.create.video_links = [...state.create.video_links, action.payload]
      }
    },
    setVideoLinksAll: (state, action) => {
      if (state.create.video_links) {
        state.create.video_links = action.payload
      }
    },
    setChangeInput: (state, action) => {
      const { index, value } = action.payload
      if (state.create.video_links) {
        const newVideoGallery = [...state.create.video_links]
        newVideoGallery[index] = value
        state.create.video_links = newVideoGallery
      }
    },
    deleteVideoLink: (state, action) => {
      if (state.create.video_links) {
        const updateLinks = state.create.video_links.filter(
          (_, i) => i !== action.payload
        )
        state.create.video_links = updateLinks
      }
    },
    removeTypesId: (state, action) => {
      if (state.create.task_type_ids) {
        state.create.task_type_ids = state.create.task_type_ids.filter(
          (item) => item !== action.payload
        )
      }
    },
    addType: (state, action) => {
      state.create.configurations = [
        ...state.create.configurations,
        action.payload,
      ]
    },
    deleteType: (state, action) => {
      const { vehiclesTypeId, index } = action.payload

      if (state.create.configurations.length > 1) {
        if (!vehiclesTypeId) {
          state.create.configurations = state.create.configurations.filter(
            (_, i) => i !== index
          )
        } else {
          state.create.configurations = state.create.configurations.filter(
            (item) => item.vehicle_type_id !== vehiclesTypeId
          )
        }

        state.characteristics = state.characteristics.filter(
          (_, i) => i !== index
        )

        state.create.configurations.map((item, i) => (item.index = i))
        state.selectedType = state.selectedType.filter((_, i) => i !== index)
      } else if (state.create.configurations.length === 1) {
        state.create.configurations = [
          {
            name: '',
            vehicle_type_id: -1,
            sort: 0,
            characteristics: [],
            index: 0,
          },
        ]
        state.selectedType = []
        state.characteristics = []
      }
    },
    deleteColumn: (state, action) => {
      const { vehiclesTypeId, sort } = action.payload
      if (
        state.create.configurations.filter(
          (item) => item.vehicle_type_id === vehiclesTypeId
        ).length > 1
      ) {
        const updatedConfigurations = state.create.configurations.filter(
          (item) =>
            !(item.vehicle_type_id === vehiclesTypeId && item.sort === sort)
        )

        const filterColumnsAndChangeSort = updatedConfigurations
          .filter((item) => item.vehicle_type_id === vehiclesTypeId)
          .map((el, index) => ({ ...el, sort: index }))

        const filterArray = updatedConfigurations.filter(
          (item) => item.vehicle_type_id !== vehiclesTypeId
        )

        const updateArray = [...filterColumnsAndChangeSort, ...filterArray]

        return {
          ...state,
          create: {
            ...state.create,
            configurations: updateArray,
          },
        }
      }
    },
    setBrandId: (
      state,
      { payload }: PayloadAction<IOption<number> | null | number>
    ) => {
      state.create.brand_id = payload
    },
    handleInputStateChange: (state, action) => {
      const { id, text, sort, vehicle_type_id, index } = action.payload
      const foundConfigurationIndex = state.create.configurations.findIndex(
        (item) => item.vehicle_type_id === vehicle_type_id && item.sort === sort
      )

      if (foundConfigurationIndex !== -1) {
        state.create.configurations[foundConfigurationIndex].characteristics =
          state.create.configurations[
            foundConfigurationIndex
          ].characteristics.map((characteristic, i) => {
            if (i === index) {
              return { ...characteristic, value: text, id: id }
            }
            return characteristic
          })
      }

      return state
    },
    handleInputMinMaxChange: (state, action) => {
      const { id, text, sort, vehicle_type_id, index, type } = action.payload
      const foundConfigurationIndex = state.create.configurations.findIndex(
        (item) => item.vehicle_type_id === vehicle_type_id && item.sort === sort
      )

      if (foundConfigurationIndex !== -1) {
        state.create.configurations[foundConfigurationIndex].characteristics =
          state.create.configurations[
            foundConfigurationIndex
          ].characteristics.map((characteristic, i) => {
            if (i === index) {
              const currentValue = characteristic.value
              const newValue = {
                min:
                  typeof currentValue !== 'number' &&
                  !Array.isArray(currentValue)
                    ? currentValue.min
                    : 0,
                max:
                  typeof currentValue !== 'number' &&
                  !Array.isArray(currentValue)
                    ? currentValue.max
                    : 0,
              }

              if (type === 'min') {
                newValue.min = Number(text)
              } else if (type === 'max') {
                newValue.max = Number(text)
              }

              return {
                ...characteristic,
                value: newValue,
                id: id,
              }
            }
            return characteristic
          })
      }

      return state
    },
    handleDropDownChange: (state, action) => {
      const { id, dropElId, sort, vehicle_type_id, index } = action.payload
      const foundConfigurationIndex = state.create.configurations.findIndex(
        (item) => item.vehicle_type_id === vehicle_type_id && item.sort === sort
      )

      if (foundConfigurationIndex !== -1) {
        state.create.configurations[foundConfigurationIndex].characteristics =
          state.create.configurations[
            foundConfigurationIndex
          ].characteristics.map((characteristic, i) => {
            if (i === index) {
              return { ...characteristic, value: dropElId, id: id }
            }
            return characteristic
          })
      }

      return state
    },
    handleTitleChange: (state, action) => {
      const { text, sort, vehicle_type_id } = action.payload
      const foundConfigurationIndex = state.create.configurations.findIndex(
        (item) => item.vehicle_type_id === vehicle_type_id && item.sort === sort
      )
      if (foundConfigurationIndex !== -1) {
        state.create.configurations[foundConfigurationIndex].name = text
      }

      return state
    },
    setItem: (state, action) => {
      const { vehicle_type_id, sort, characteristics, name, index } =
        action.payload

      const existingIndex = state.create.configurations.findIndex(
        (config) => config.index === index
      )

      if (existingIndex !== -1) {
        state.create.configurations = state.create.configurations.filter(
          (item) => item.index !== index
        )
        state.create.configurations[existingIndex] = action.payload
      } else {
        const foundArrayWithSortAndId = state.create.configurations.find(
          (config) =>
            config.sort === sort && config.vehicle_type_id === vehicle_type_id
        )

        if (foundArrayWithSortAndId) {
          foundArrayWithSortAndId.characteristics = characteristics
          foundArrayWithSortAndId.name = name
          foundArrayWithSortAndId.vehicle_type_id = vehicle_type_id
          foundArrayWithSortAndId.index = index
        } else {
          state.create.configurations = [
            ...state.create.configurations,
            action.payload,
          ]
        }
      }
    },
    setColumn: (state, action) => {
      const { characteristics, sort, vehicle_type_id, index } = action.payload

      state.create.configurations = [
        ...state.create.configurations,
        {
          name: '',
          vehicle_type_id: vehicle_type_id,
          sort: sort,
          characteristics: characteristics,
          index: index,
        },
      ]
    },
    resetCreate: (state) => {
      state.selectedType = []
      state.characteristics = []
      state.create = {
        name: '',
        brand_id: null,
        task_type_ids: [],
        vin: '',
        video_links: [''],
        tech_documents: [],
        configurations: [
          {
            name: '',
            vehicle_type_id: -1,
            sort: 0,
            characteristics: [],
            index: 0,
          },
        ],
        image_gallery: [],
        weight: '',
        height: '',
        length: '',
        width: '',
        weight_unit_type: 'kilogram',
        dimensions_unit_type: 'centimeter',
        sts_number: '',
        sts_serial: '',
        color: '',
        engine_power: 0,
        year: 0,
        number: '',
        region_ids: [],
      }
    },
    setCharacteristics: (state, action) => {
      const { data, index } = action.payload
      const updatedCharacteristics = [...state.characteristics]

      if (updatedCharacteristics[index]) {
        updatedCharacteristics[index] = data
      } else {
        updatedCharacteristics.push(data)
      }

      state.characteristics = updatedCharacteristics
    },
    setSelectedTypeDispatch: (state, action) => {
      const { number, index } = action.payload
      const updatedTypes = [...state.selectedType]

      if (updatedTypes[index]) {
        updatedTypes[index] = number
      } else {
        number !== -1 ? updatedTypes.push(number) : ''
      }
      state.selectedType = updatedTypes
    },
    setIsSendData: (state, action) => {
      state.isSendData = action.payload
    },
    setWeightUnitType: (state, action) => {
      state.create.weight_unit_type = action.payload
    },
    setDimensions: (state, action) => {
      state.create.dimensions_unit_type = action.payload
    },
    setConfigurations: (state, action) => {
      state.create.configurations = action.payload
    },
    setStsNumber: (state, action) => {
      state.create.sts_number = action.payload
    },
    setStsSerial: (state, action) => {
      state.create.sts_serial = action.payload
    },
    setYear: (state, action) => {
      state.create.year = action.payload
    },
    setColor: (state, action) => {
      state.create.color = action.payload
    },
    setEngine: (state, action) => {
      state.create.engine_power = action.payload
    },
    setNumber: (state, action) => {
      state.create.number = action.payload
    },
    setVehicleTypeId: (state, action) => {
      const { index, id } = action.payload
      if (state.vehicle_type_id[index] === undefined) {
        state.vehicle_type_id = [...state.vehicle_type_id, id]
      } else {
        state.vehicle_type_id[index] = id
      }
    },
    deleteVehicleTypeId: (state, action) => {
      const { id } = action.payload

      state.vehicle_type_id = state.vehicle_type_id.filter((el) => el !== id)
    },
    clearVehicleTypeId: (state) => {
      state.vehicle_type_id = []
    },
    setEditPageVehicleTypeId: (state, action) => {
      state.vehicle_type_id = action.payload
    },
    setImagePhotosSrc: (state, action) => {
      state.imagePhotosSrc = action.payload
    },
    setSrc: (state, action) => {
      state.src = action.payload
    },
    setFilesTypes: (state, action) => {
      state.filesTypes = action.payload
    },
    setImageDocumentsSrc: (state, action) => {
      state.imageDocumentsSrc = action.payload
    },
    setRegionIds: (state, action) => {
      const idsValues = action.payload

      if (
        (idsValues.length &&
          idsValues.every((item: any) => typeof item === 'number')) ||
        !idsValues.length
      ) {
        state.create.region_ids = idsValues
      } else {
        state.create.region_ids = idsValues.map(
          (item: { value: string }) => item.value
        )
      }
    },
    setVehiclesRegions: (state, action) => {
      state.vehiclesRegions = action.payload
    },
    setValidationError: (state, action) => {
      state.validationErrors = action.payload
    },
    setValidationErrorForApplication: (state, action) => {
      const { errors, index } = action.payload

      state.validationErrorsForApplication[index] = errors
    },
    deleteValidationErrorByIndex: (state, action) => {
      state.validationErrorsForApplication =
        state.validationErrorsForApplication.filter(
          (_, i) => i !== action.payload
        )
    },
    filterValidationErrors: (state, action) => {
      if (state.validationErrors.includes(action.payload)) {
        state.validationErrors = state.validationErrors.filter(
          (el) => el !== action.payload
        )
      }
    },

    filterValidationErrorsForApplication: (state, action) => {
      const { value, index } = action.payload

      const validationErrors = state.validationErrorsForApplication[index]
      if (validationErrors && validationErrors.includes(value)) {
        state.validationErrorsForApplication[index] = validationErrors.filter(
          (el) => el !== value
        )
      }
    },
    clearValidationForApplication: (state) => {
      state.validationErrorsForApplication = []
    },
  },
})

export const {
  vehiclesAddPageData,
  vehiclesIncrementPage,
  vehiclesSetLastItemsCount,
  vehiclesClear,
  changeVehicleSearchName,
  setHeight,
  setLength,
  setName,
  setTypesIds,
  setVin,
  setWeight,
  setWidth,
  setTechDocument,
  deleteTechImage,
  setImage,
  deleteImage,
  removeTypesId,
  setVideoLink,
  deleteVideoLink,
  setChangeInput,
  addType,
  deleteType,
  setBrandId,
  handleInputStateChange,
  handleInputMinMaxChange,
  setItem,
  handleTitleChange,
  handleDropDownChange,
  resetCreate,
  setColumn,
  setCharacteristics,
  setSelectedTypeDispatch,
  setIsSendData,
  deleteColumn,
  setWeightUnitType,
  setDimensions,
  setConfigurations,
  setVideoLinksAll,
  setStsNumber,
  setYear,
  setColor,
  setEngine,
  setNumber,
  setVehicleTypeId,
  setImagePhotosSrc,
  setSrc,
  setFilesTypes,
  setImageDocumentsSrc,
  resetTypesIds,
  setRegionIds,
  setVehiclesRegions,
  clearVehicleTypeId,
  setEditPageVehicleTypeId,
  setAllTypesIds,
  setValidationError,
  filterValidationErrors,
  setValidationErrorForApplication,
  deleteValidationErrorByIndex,
  filterValidationErrorsForApplication,
  clearValidationForApplication,
  setStsSerial,
  totalCountChanged,
  deleteVehicleTypeId,
} = vehiclesSlice.actions

export const vehiclesReducer = vehiclesSlice.reducer
