import IconPropsInterface from './IconPropsInterface'

export const Cost = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 14 16"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M4.5 9.66699L9.5 4.66699"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.91667 5.50033C5.14679 5.50033 5.33333 5.31378 5.33333 5.08366C5.33333 4.85354 5.14679 4.66699 4.91667 4.66699C4.68655 4.66699 4.5 4.85354 4.5 5.08366C4.5 5.31378 4.68655 5.50033 4.91667 5.50033Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.08464 9.66634C9.31475 9.66634 9.5013 9.47979 9.5013 9.24967C9.5013 9.01956 9.31475 8.83301 9.08464 8.83301C8.85452 8.83301 8.66797 9.01956 8.66797 9.24967C8.66797 9.47979 8.85452 9.66634 9.08464 9.66634Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.16797 15.5V2.16667C1.16797 1.72464 1.34356 1.30072 1.65612 0.988155C1.96868 0.675595 2.39261 0.5 2.83464 0.5H11.168C11.61 0.5 12.0339 0.675595 12.3465 0.988155C12.659 1.30072 12.8346 1.72464 12.8346 2.16667V15.5L10.3346 13.8333L8.66797 15.5L7.0013 13.8333L5.33464 15.5L3.66797 13.8333L1.16797 15.5Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
