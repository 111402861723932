import IconPropsInterface from './IconPropsInterface'

export const Shreder = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 32 32"
      fill={color}
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path d="M18.1821 5.81836H8.0002C7.59851 5.81836 7.27295 6.14399 7.27295 6.54561C7.27295 6.94731 7.59858 7.27287 8.0002 7.27287H18.1821C18.5838 7.27287 18.9094 6.94724 18.9094 6.54561C18.9094 6.14399 18.5838 5.81836 18.1821 5.81836Z" />
      <path d="M30.5457 10.1819H29.0911V5.81823L23.2729 0H4.36366C3.56034 0 2.90909 0.651255 2.90909 1.45457V10.1819H1.45457C0.651255 10.1819 0 10.8331 0 11.6365V17.4547C0 18.258 0.651255 18.9093 1.45457 18.9093H2.90915V31.273C2.90915 31.6747 3.23478 32.0002 3.6364 32.0002C4.03803 32.0002 4.36366 31.6746 4.36366 31.273V18.9092H5.81823V26.9093C5.81823 27.311 6.14386 27.6365 6.54549 27.6365C6.94718 27.6365 7.27274 27.3109 7.27274 26.9093V18.9092H8.72732V22.5456C8.72732 22.9473 9.05295 23.2729 9.45457 23.2729C9.85627 23.2729 10.1818 22.9472 10.1818 22.5456V18.9092H11.6364V29.8184C11.6364 30.22 11.962 30.5456 12.3637 30.5456C12.7653 30.5456 13.0909 30.22 13.0909 29.8184V18.9092H15.2727V28.3638C15.2727 28.7655 15.5984 29.0911 16 29.0911C16.4016 29.0911 16.7273 28.7655 16.7273 28.3638V18.9092H18.9091V24.0002C18.9091 24.4019 19.2347 24.7274 19.6363 24.7274C20.038 24.7274 20.3636 24.4018 20.3636 24.0002V18.9092H21.8182V25.4547C21.8182 25.8564 22.1438 26.182 22.5454 26.182C22.9471 26.182 23.2727 25.8563 23.2727 25.4547V18.9092H24.7273V29.8184C24.7273 30.22 25.0529 30.5456 25.4545 30.5456C25.8562 30.5456 26.1818 30.22 26.1818 29.8184V18.9092H27.6363V28.3638C27.6363 28.7655 27.962 29.0911 28.3636 29.0911C28.7652 29.0911 29.0909 28.7655 29.0909 28.3638V18.9092H30.5454C31.3487 18.9092 32 18.258 32 17.4546V11.6364C32.0003 10.8331 31.349 10.1819 30.5457 10.1819ZM23.2729 2.18183L26.9093 5.81823H23.2729V2.18183ZM4.36366 1.45457H21.8184V6.54555C21.8184 6.94724 22.144 7.27281 22.5456 7.27281H27.6366V10.1819H4.36366V1.45457ZM30.5457 17.4547H1.45457V11.6365H30.5457V17.4547H30.5457Z" />
      <path d="M6.54561 15.2729C6.94727 15.2729 7.27287 14.9473 7.27287 14.5456C7.27287 14.144 6.94727 13.8184 6.54561 13.8184C6.14396 13.8184 5.81836 14.144 5.81836 14.5456C5.81836 14.9473 6.14396 15.2729 6.54561 15.2729Z" />
      <path d="M3.63644 15.2729C4.03809 15.2729 4.36369 14.9473 4.36369 14.5456C4.36369 14.144 4.03809 13.8184 3.63644 13.8184C3.23478 13.8184 2.90918 14.144 2.90918 14.5456C2.90918 14.9473 3.23478 15.2729 3.63644 15.2729Z" />
    </svg>
  )
}
