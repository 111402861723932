import { useEffect } from 'react'

import {
  StyledTitleLevel1,
  StyledTitleLevel2,
} from '@pages/TasksBoard/ExecutorOffer/StyledTitles'
import { setOfferFieldValue } from '@pages/TasksBoard/ExecutorOffer/executorOfferSlice'
import { usePrepaymentAmount } from '@pages/TasksBoard/ExecutorOffer/hooks'
import {
  NumberInput,
  SelectEmployee,
  TextInput,
} from '@pages/TasksBoard/ExecutorOffer/inputs'
import { DadataCityInput } from '@pages/TasksBoard/ExecutorOffer/inputs/DadataCityInput'
import { DoubleDateInput } from '@pages/TasksBoard/ExecutorOffer/inputs/DoubleDateInput'
import { InfoField } from '@pages/TasksBoard/ExecutorOffer/inputs/InfoField'
import { MaterialCostUnitInput } from '@pages/TasksBoard/ExecutorOffer/inputs/MaterialCostUnitInput'
import { MaterialQuantityField } from '@pages/TasksBoard/ExecutorOffer/inputs/MaterialQuantityField'
import { convertCharacteristicsToText } from '@pages/TasksBoard/helpers/convertCharacteristicsToText'
import { ITaskListItemViewData } from '@pages/TasksBoard/interfaces'
import { useAppDispatch, useAppSelector } from '@src/hooks/store'
import { RegularText } from '@ui/Typography'

interface IProps {
  taskId: number
  data: ITaskListItemViewData | undefined
}
export const EditTaskDelivery = ({ taskId, data }: IProps) => {
  const dispatch = useAppDispatch()
  const { isFirstValidation, isPreviewMode } = useAppSelector(
    (state) => state.executorOffer
  )

  const { cost, quantity } = useAppSelector(
    (state) => state.executorOffer.selectedTasks[taskId]
  )
  const sum = +cost.value * +quantity.value || 0

  const material = data?.details?.material?.material_type?.name || 'Неизвестно'
  const characteristics = data?.details?.material?.characteristics
  const units = data?.details?.material?.material_type?.units?.map((item) => {
    return {
      name: item.name,
      value: item.id,
    }
  })

  const { handler, showPrepaymentDays } = usePrepaymentAmount(taskId)

  useEffect(() => {
    if (data) {
      const taskQuantity = data?.details.material.quantity
      const taskUnit = data?.details.material.material_type_unit.id
      dispatch(
        setOfferFieldValue({
          taskId,
          fieldName: 'quantity',
          value: taskQuantity,
        })
      )
      dispatch(
        setOfferFieldValue({
          taskId,
          fieldName: 'material_type_unit_id',
          value: taskUnit,
        })
      )
    }
  }, [data, dispatch, taskId])

  return (
    <div>
      <StyledTitleLevel1>Основная информация</StyledTitleLevel1>
      <InfoField title={'Материал'}>
        <RegularText>{material}</RegularText>
      </InfoField>
      {characteristics && (
        <InfoField title={'Характеристики'}>
          <RegularText>
            {convertCharacteristicsToText(characteristics)}
          </RegularText>
        </InfoField>
      )}
      <MaterialCostUnitInput
        taskId={taskId}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
        title={'Цена, руб.'}
        units={units || []}
      />
      <MaterialQuantityField
        taskId={taskId}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
        title={'Количество'}
        units={units}
      />

      <InfoField title={'Сумма'}>
        <RegularText>{`${sum} руб.`}</RegularText>
      </InfoField>
      <NumberInput
        taskId={taskId}
        fieldName={'cost_delivery'}
        title={'Стоимость доставки, руб.'}
        description={''}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
      />
      <DoubleDateInput
        taskId={taskId}
        title={'Дата доставки'}
        isFirstValidation={isFirstValidation}
        isPreviewMode={isPreviewMode}
      />
      <NumberInput
        taskId={taskId}
        fieldName={'deferred_payment'}
        title={'Отсрочка оплаты, дней'}
        description={''}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
      />
      <NumberInput
        taskId={taskId}
        additionalHandler={handler}
        fieldName={'prepayment_amount'}
        title={'Сумма предоплаты, руб.'}
        description={''}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
      />

      <SelectEmployee
        taskId={taskId}
        fieldName={'employee_id'}
        additionalFieldName={'responsible_person'}
        title={'Ответственный сотрудник по задаче'}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
      />
      <StyledTitleLevel1>Данные для договора</StyledTitleLevel1>
      <DadataCityInput
        taskId={taskId}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
      />
      <NumberInput
        taskId={taskId}
        fieldName={'contract_period'}
        title={'Срок действия договора, мес.'}
        description={''}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
      />
      {showPrepaymentDays && (
        <NumberInput
          taskId={taskId}
          fieldName={'prepayment_days'}
          title={'Срок внесения предоплаты, дней'}
          description={
            'Количество дней на внесение предоплаты с момента получения счета.'
          }
          isPreviewMode={isPreviewMode}
          isFirstValidation={isFirstValidation}
        />
      )}
      <TextInput
        taskId={taskId}
        fieldName={'responsible_person'}
        title={'Ответственное лицо по договору'}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
      />

      <StyledTitleLevel2>Неустойки</StyledTitleLevel2>
      <NumberInput
        taskId={taskId}
        fieldName={'idle_payment_days'}
        title={'Срок оплаты, дней'}
        description={'С момента получения счета на оплату'}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
      />
      <NumberInput
        taskId={taskId}
        fieldName={'idle_payment_percent'}
        title={'Неустойка за просрочку оплаты, %'}
        description={'За каждый день просрочки'}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
      />
    </div>
  )
}
