import {
  AcceptCustomerOffersRequest,
  CustomerOffersRequest,
  FixOfferViewRequest,
  RejectCustomerOffersRequest,
} from '@pages/Offers/types/CustomerOffersTypes'
import {
  ExecutorOffersRequest,
  OffersResponse,
  RejectExecutorOffersRequest,
} from '@pages/Offers/types/ExecutorOffersTypes'
import { BankAccountItem } from '@pages/Space/OwnershipTypes/interfaces/BankAccountTypes'
import { ISuccessResponse } from '@src/shared/interfaces/apiResponse/ISuccessResponse'
import { clientApiSlice } from '@src/store/slices/clientApiSlice'

export const offersApi = clientApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    geExecutorOffers: builder.query<OffersResponse, ExecutorOffersRequest>({
      query: ({
        spaceId,
        page,
        limit,
        states,
        sort,
        ownership_type_id,
        created_at_from,
        created_at_to,
      }) => ({
        url: `/spaces/${spaceId}/executor/offers?page=${page}&limit=${limit}&states[]=${states}&sort=${sort}&ownership_type_id=${ownership_type_id}&created_at_from=${created_at_from}&created_at_to=${created_at_to}`,
      }),
    }),
    getCustomerOffers: builder.query<OffersResponse, CustomerOffersRequest>({
      query: ({ spaceId, projectId, page, limit }) => ({
        url: `/spaces/${spaceId}/projects/${projectId}/offers?page=${page}&limit=${limit}`,
      }),
    }),
    // предложние с стороны заказчика
    acceptCustomerOffer: builder.mutation<unknown, AcceptCustomerOffersRequest>(
      {
        query: ({ spaceId, projectId, offerId, body }) => ({
          url: `/spaces/${spaceId}/projects/${projectId}/offers/${offerId}/accept`,
          method: 'POST',
          body: body,
        }),
      }
    ),
    rejectCustomerOffer: builder.mutation<unknown, RejectCustomerOffersRequest>(
      {
        query: ({ spaceId, projectId, offerId }) => ({
          url: `/spaces/${spaceId}/projects/${projectId}/offers/${offerId}/reject`,
          method: 'POST',
        }),
      }
    ),
    // фиксация просмотра предложения
    fixView: builder.mutation<unknown, FixOfferViewRequest>({
      query: ({ spaceId, projectId, offerId }) => ({
        url: `/spaces/${spaceId}/projects/${projectId}/offers/${offerId}/fix-view`,
        method: 'POST',
      }),
    }),
    // предложения со стороны исполнителей
    acceptExecutorOffer: builder.mutation<
      unknown,
      { spaceId?: string; offerId?: number }
    >({
      query: ({ spaceId, offerId }) => ({
        url: `/spaces/${spaceId}/executor/offers/${offerId}/accept`,
        method: 'POST',
      }),
    }),
    revokeExecutorOffer: builder.mutation<unknown, RejectExecutorOffersRequest>(
      {
        query: ({ spaceId, offerId }) => ({
          url: `/spaces/${spaceId}/executor/offers/${offerId}/withdraw`,
          method: 'POST',
        }),
      }
    ),
    getBankAccount: builder.query<
      ISuccessResponse<BankAccountItem[]>,
      { spaceId?: string; ownershipTypeId?: string | number }
    >({
      query: ({ spaceId, ownershipTypeId }) => ({
        url: `/spaces/${spaceId}/ownership-types/${ownershipTypeId}/bank-accounts/index`,
      }),
    }),
  }),
})

export const {
  useLazyGeExecutorOffersQuery,
  useLazyGetCustomerOffersQuery,
  useAcceptCustomerOfferMutation,
  useRejectCustomerOfferMutation,
  useFixViewMutation,
  useAcceptExecutorOfferMutation,
  useGeExecutorOffersQuery,
  useGetCustomerOffersQuery,
  useRevokeExecutorOfferMutation,
  useGetBankAccountQuery,
  useLazyGetBankAccountQuery,
} = offersApi
