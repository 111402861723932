import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'

import * as Sentry from '@sentry/react'
import { checkFeature } from '@src/shared/featureToggle/checkFeature'
import { store } from '@src/store'

import App from './app/App'
import './main.css'

checkFeature('sentry') &&
  Sentry.init({
    dsn: 'https://3bea664417703758a68f89a0bf688839@o4507107866443776.ingest.de.sentry.io/4507107869392976',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      'localhost:3000',
      /^https:\/\/dev\.veresking\.com\/api/,
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <Provider store={store}>
    <App />
  </Provider>
)
