import { FaSpinner } from 'react-icons/fa'
import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const Spinner = styled(FaSpinner)<{ color?: string; size?: number }>`
  color: ${({ color, theme }) => color ?? theme.colors.primary};
  font-size: ${({ size }) => (size ? `${size}px` : '1.5rem')};
  animation: ${rotate} 1s linear infinite;
`
