import IconPropsInterface from './IconPropsInterface'

export const BarDrivers = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 14 12"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M11 0.667969H3C1.89543 0.667969 1 1.5634 1 2.66797V9.33464C1 10.4392 1.89543 11.3346 3 11.3346H11C12.1046 11.3346 13 10.4392 13 9.33464V2.66797C13 1.5634 12.1046 0.667969 11 0.667969Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.9974 5.9987C5.73378 5.9987 6.33073 5.40174 6.33073 4.66536C6.33073 3.92898 5.73378 3.33203 4.9974 3.33203C4.26102 3.33203 3.66406 3.92898 3.66406 4.66536C3.66406 5.40174 4.26102 5.9987 4.9974 5.9987Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 3.33203H10.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9 6H10.3333" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M3.66406 8.66797H10.3307"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
