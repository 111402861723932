import IconPropsInterface from '@icons/IconPropsInterface'

export const CopyLink = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5837 15.5H15.8337C16.7545 15.5 17.5003 14.7542 17.5003 13.8333V4.66667C17.5003 3.74583 16.7545 3 15.8337 3H8.33366C7.41283 3 6.66699 3.74583 6.66699 4.66667V5.91667"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33301 12.167L13.333 7.16699"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.333 9.66699V7.16699H10.833"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3333 12.167V16.3337C13.3333 17.2545 12.5875 18.0003 11.6667 18.0003H4.16667C3.24583 18.0003 2.5 17.2545 2.5 16.3337V8.83366C2.5 7.91283 3.24583 7.16699 4.16667 7.16699H8.33333"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
