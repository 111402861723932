import IconPropsInterface from './IconPropsInterface'

export const Plus = ({
  size = 20,
  color,
  style,
  className,
}: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 20 20"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{
        ...style,
        flexShrink: 0,
        transition: '300ms',
        verticalAlign: 'middle',
      }}
      className={className}
    >
      <path d="M10 5V15M5 10H15" strokeLinecap="round" />
    </svg>
  )
}
