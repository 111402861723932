import { MultiValueRemoveProps, components } from 'react-select'

import { SelectValueRemoveButton } from '@ui/Selects/partials/SelectValueRemoveButton'

export const MultiValueRemove = (props: MultiValueRemoveProps) => {
  return (
    <components.MultiValueRemove {...props}>
      <SelectValueRemoveButton />
    </components.MultiValueRemove>
  )
}
