import { IAddMessageAction, IChatState } from '@pages/Chat/chatSlice/chatSlice'

export const addChatMessageReducer = (
  state: IChatState,
  action: IAddMessageAction
) => {
  // если подгружаем старые сообщения по скроллу, то isSocket = false, если приходят новые сообщения
  // посредством вебсокетов, то isSocket = true
  // в зависимости от этого параметра меняются контейнеры даты или автора (первый или последний),
  // а также порядок добавления элементов

  const message = action.payload.message
  const isSocket = action.payload.isSocket

  //С помощью этих переменных будем определять, класть ли сообщение в существующие
  // контейнеры с датой или автором, или создавать новые
  const currentMessageDate = message.fullDate
  const currentMessageAuthor = message.authorId

  //Определяем крайние контейнеры даты и автора (первый или последний в зависимости от isSocket)
  const edgeDateGroup = isSocket
    ? state.messagesDateGroups[0]
    : state.messagesDateGroups[state.messagesDateGroups.length - 1]
  const edgeAuthorGroup = isSocket
    ? edgeDateGroup?.messagesAuthorGroups[
        edgeDateGroup?.messagesAuthorGroups?.length - 1
      ]
    : edgeDateGroup?.messagesAuthorGroups?.[0]

  const edgeDate = edgeDateGroup?.keyDate
  const edgeAuthor = edgeAuthorGroup?.authorId

  //Определим сразу новый контейнер даты.
  //Если дата или автор будут совпадать с тем, что уже имеется, возьмем результат частично
  //Нужно для экономии места, чтобы не объявлять похожие result для каждого исхода

  const result = {
    keyDate: message.fullDate,
    renderDate: message.monthAndDay,
    messagesAuthorGroups: [
      {
        authorId: message.authorId,
        authorName: message.authorName,
        authorFirstLetter: message.authorFirstLetter,
        isOwn: message.isOwn,
        isSystem: message.isSystem,
        messages: [message],
      },
    ],
  }

  if (edgeDate === currentMessageDate) {
    if (edgeAuthor === currentMessageAuthor) {
      edgeAuthorGroup.messages[isSocket ? 'push' : 'unshift'](
        result.messagesAuthorGroups[0].messages[0]
      )
    } else {
      edgeDateGroup.messagesAuthorGroups[isSocket ? 'push' : 'unshift'](
        result.messagesAuthorGroups[0]
      )
    }
  } else {
    //реверсинг контейнеров даты делается с помощью стилей
    // flex-direction = column-reverse, поэтому push и unshift меняются местами,
    // по сравнению с другими исходами
    state.messagesDateGroups[isSocket ? 'unshift' : 'push'](result)
  }
}
