import { LIMIT_CHAT_LIST, LIMIT_CHAT_MESSAGES } from '@pages/Chat/constants'
import { Reducer } from '@reduxjs/toolkit'

export const resetChatStateReducer: Reducer = (state) => {
  state.lastMessagesCount = LIMIT_CHAT_MESSAGES
  state.cursorMessages = null
  state.messagesDateGroups = []
  state.selectedMessages = []
  state.attachType = null
  state.cursorChatList = null
  state.lastChatGroupsCount = LIMIT_CHAT_LIST
  state.chatListProjectGroups = []
  state.currentChatInfo = null
  state.currentChatGroup = null
  state.isDisabledChatList = false
  state.excludeGroupIds = []
}
