import IconPropsInterface from './IconPropsInterface'

export const PptFile = ({ size = 20, style }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style, flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M16.5 22H7.5C6.70435 22 5.94129 21.6839 5.37868 21.1213C4.81607 20.5587 4.5 19.7956 4.5 19V5C4.5 4.20435 4.81607 3.44129 5.37868 2.87868C5.94129 2.31607 6.70435 2 7.5 2H14.09C14.3523 2.0011 14.6037 2.10526 14.79 2.29L19.21 6.71C19.3947 6.89626 19.4989 7.14766 19.5 7.41V19C19.5 19.7956 19.1839 20.5587 18.6213 21.1213C18.0587 21.6839 17.2956 22 16.5 22Z"
        fill="#FF9800"
      />
      <path
        d="M18.7992 7.74008H15.1992C14.8014 7.74008 14.4199 7.58205 14.1386 7.30074C13.8573 7.01944 13.6992 6.63791 13.6992 6.24008V2.64008C13.6988 2.53098 13.7308 2.42422 13.7912 2.33337C13.8516 2.24252 13.9377 2.17169 14.0384 2.12988C14.1392 2.08807 14.2501 2.07718 14.3571 2.09858C14.4641 2.11998 14.5623 2.17272 14.6392 2.25008L19.1892 6.80008C19.2666 6.87701 19.3193 6.9752 19.3407 7.08218C19.3621 7.18916 19.3512 7.30009 19.3094 7.40086C19.2676 7.50163 19.1968 7.58768 19.1059 7.64809C19.0151 7.70849 18.9083 7.74052 18.7992 7.74008Z"
        fill="#FFD180"
      />
      <path
        d="M7.82869 19.1302C7.76015 19.1385 7.6906 19.131 7.62544 19.1082C7.56027 19.0854 7.50123 19.0478 7.45289 18.9985C7.40455 18.9492 7.3682 18.8895 7.34666 18.8238C7.32513 18.7582 7.31898 18.6886 7.32869 18.6202V15.6902C7.31898 15.6218 7.32513 15.5521 7.34666 15.4865C7.3682 15.4209 7.40455 15.3612 7.45289 15.3118C7.50123 15.2625 7.56027 15.225 7.62544 15.2022C7.6906 15.1794 7.76015 15.1718 7.82869 15.1802C7.8902 15.1733 7.95247 15.1791 8.01162 15.1973C8.07076 15.2156 8.12551 15.2458 8.17244 15.2862C8.21937 15.3265 8.25746 15.3761 8.28435 15.4319C8.31124 15.4876 8.32633 15.5483 8.32869 15.6102C8.40564 15.4705 8.52459 15.3585 8.66869 15.2902C8.82695 15.2108 9.00164 15.1697 9.17869 15.1702C9.40517 15.1653 9.62798 15.2279 9.81869 15.3502C10.0103 15.4755 10.1628 15.6523 10.2587 15.8602C10.3646 16.1084 10.4192 16.3754 10.4192 16.6452C10.4192 16.915 10.3646 17.182 10.2587 17.4302C10.1664 17.6374 10.0173 17.8142 9.82869 17.9402C9.63331 18.0592 9.40732 18.1183 9.17869 18.1102C9.01252 18.1096 8.84855 18.072 8.69869 18.0002C8.55512 17.9367 8.4334 17.8324 8.34869 17.7002V18.6102C8.35506 18.6796 8.34589 18.7496 8.32186 18.8151C8.29783 18.8805 8.25953 18.9398 8.20975 18.9886C8.15997 19.0374 8.09995 19.0746 8.03405 19.0973C7.96814 19.1201 7.89799 19.1279 7.82869 19.1202V19.1302ZM8.82869 17.3702C8.90178 17.3726 8.97438 17.3575 9.04046 17.3261C9.10655 17.2948 9.16422 17.2482 9.20869 17.1902C9.32454 17.0315 9.37793 16.8357 9.35869 16.6402C9.37608 16.4448 9.32287 16.2497 9.20869 16.0902C9.16172 16.0357 9.10332 15.9922 9.03765 15.9628C8.97197 15.9334 8.90063 15.9189 8.82869 15.9202C8.75686 15.9196 8.68574 15.9344 8.62016 15.9638C8.55459 15.9931 8.4961 16.0362 8.44869 16.0902C8.33452 16.2497 8.28131 16.4448 8.29869 16.6402C8.27946 16.8357 8.33285 17.0315 8.44869 17.1902C8.49726 17.2513 8.55998 17.2996 8.63142 17.3309C8.70286 17.3623 8.78087 17.3758 8.85869 17.3702H8.82869Z"
        fill="#FFF3E0"
      />
      <path
        d="M11.5201 19.13C11.4516 19.1384 11.382 19.1309 11.3168 19.108C11.2517 19.0852 11.1926 19.0477 11.1443 18.9984C11.096 18.9491 11.0596 18.8893 11.0381 18.8237C11.0165 18.7581 11.0104 18.6884 11.0201 18.62V15.69C11.0095 15.5574 11.052 15.426 11.1383 15.3248C11.2245 15.2235 11.3475 15.1606 11.4801 15.15C11.6127 15.1394 11.7441 15.1819 11.8454 15.2682C11.9466 15.3545 12.0095 15.4774 12.0201 15.61C12.097 15.4703 12.216 15.3584 12.3601 15.29C12.5215 15.2095 12.6997 15.1683 12.8801 15.17C13.1034 15.1653 13.323 15.228 13.5101 15.35C13.7017 15.4754 13.8542 15.6521 13.9501 15.86C14.056 16.1082 14.1106 16.3752 14.1106 16.645C14.1106 16.9149 14.056 17.1819 13.9501 17.43C13.8578 17.6373 13.7088 17.8141 13.5201 17.94C13.3278 18.0577 13.1054 18.1168 12.8801 18.11C12.7113 18.1137 12.5439 18.0795 12.3901 18.01C12.2483 17.9438 12.1273 17.84 12.0401 17.71V18.62C12.0459 18.6889 12.0373 18.7583 12.0149 18.8237C11.9926 18.8891 11.9569 18.9491 11.9101 19C11.857 19.0466 11.7952 19.0821 11.7282 19.1044C11.6612 19.1267 11.5905 19.1354 11.5201 19.13ZM12.5201 17.37C12.5932 17.3724 12.6658 17.3573 12.7319 17.326C12.798 17.2947 12.8556 17.2481 12.9001 17.19C13.0159 17.0313 13.0693 16.8356 13.0501 16.64C13.0675 16.4447 13.0143 16.2495 12.9001 16.09C12.8531 16.0355 12.7947 15.9921 12.7291 15.9627C12.6634 15.9333 12.592 15.9187 12.5201 15.92C12.4483 15.9194 12.3771 15.9343 12.3116 15.9636C12.246 15.9929 12.1875 16.0361 12.1401 16.09C12.0259 16.2495 11.9727 16.4447 11.9901 16.64C11.9709 16.8356 12.0243 17.0313 12.1401 17.19C12.1887 17.2511 12.2514 17.2994 12.3228 17.3308C12.3943 17.3621 12.4723 17.3756 12.5501 17.37H12.5201Z"
        fill="#FFF3E0"
      />
      <path
        d="M16.1593 18.1099C15.8169 18.1406 15.4761 18.0366 15.2093 17.8199C15.0985 17.6993 15.0137 17.5571 14.9604 17.4023C14.907 17.2474 14.8862 17.0832 14.8993 16.9199V15.9999H14.7093C14.6571 16.0081 14.6037 16.0041 14.5533 15.988C14.5029 15.972 14.457 15.9444 14.4191 15.9076C14.3813 15.8707 14.3525 15.8255 14.3352 15.7755C14.3179 15.7256 14.3124 15.6723 14.3193 15.6199C14.3193 15.3699 14.4493 15.2499 14.7093 15.2499H14.8993V14.8899C14.888 14.8215 14.8911 14.7515 14.9083 14.6844C14.9255 14.6173 14.9565 14.5544 14.9993 14.4999C15.0508 14.4541 15.111 14.4191 15.1762 14.3967C15.2415 14.3744 15.3105 14.3653 15.3793 14.3699C15.4486 14.3598 15.5193 14.3661 15.5858 14.3882C15.6522 14.4102 15.7126 14.4475 15.7622 14.497C15.8117 14.5466 15.849 14.607 15.871 14.6734C15.8931 14.7399 15.8993 14.8106 15.8893 14.8799V15.2399H16.3593C16.6193 15.2399 16.7493 15.3599 16.7493 15.6099C16.7561 15.6623 16.7507 15.7156 16.7334 15.7655C16.716 15.8155 16.6873 15.8607 16.6494 15.8976C16.6116 15.9344 16.5656 15.962 16.5153 15.978C16.4649 15.9941 16.4115 15.9981 16.3593 15.9899H15.8893V16.8799C15.8809 16.937 15.8859 16.9953 15.9041 17.0501C15.9222 17.1049 15.9529 17.1547 15.9937 17.1955C16.0345 17.2363 16.0843 17.267 16.1391 17.2851C16.1939 17.3033 16.2522 17.3083 16.3093 17.2999H16.4593H16.5993C16.6271 17.2989 16.6548 17.3038 16.6806 17.3141C16.7064 17.3244 16.7298 17.34 16.7493 17.3599C16.7493 17.3599 16.7493 17.4899 16.7493 17.6299C16.7711 17.7253 16.7711 17.8245 16.7493 17.9199C16.7331 17.9594 16.7087 17.995 16.6776 18.0243C16.6466 18.0537 16.6097 18.076 16.5693 18.0899C16.4962 18.098 16.4224 18.098 16.3493 18.0899C16.2868 18.103 16.2231 18.1097 16.1593 18.1099Z"
        fill="#FFF3E0"
      />
    </svg>
  )
}
