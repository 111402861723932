import { Reducer } from '@reduxjs/toolkit'

export const readMessageReducer: Reducer = (state, action) => {
  if (action.payload.chat_id === state.currentChatInfo?.id) {
    dateGroups: for (let i = 0; i < state.messagesDateGroups.length; i++) {
      for (
        let j = state.messagesDateGroups[i].messagesAuthorGroups.length - 1;
        j >= 0;
        j--
      ) {
        for (
          let k =
            state.messagesDateGroups[i].messagesAuthorGroups[j].messages
              .length - 1;
          k >= 0;
          k--
        ) {
          const message =
            state.messagesDateGroups[i].messagesAuthorGroups[j].messages[k]
          if (message.messageId === +action.payload.content.message_id) {
            if (message.isOwn) {
              message.isRead = true
            } else {
              message.isNew = false
            }
            break dateGroups
          }
        }
      }
    }
  }
}
