import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { appealsApiSlice } from '@src/shared/appeals/appealsApi'
import { IAppealItem } from '@src/shared/appeals/interfaces'
import { RootState } from '@src/store'
import {
  addItemsToAdapter,
  clearAdapterItems,
  getInitialPaginationState,
} from '@src/utils/helpers/getInitialPaginationState'

const appealsAdapter = createEntityAdapter<IAppealItem>()

const appealsSlice = createSlice({
  name: 'appeals',
  initialState: appealsAdapter.getInitialState(getInitialPaginationState()),
  reducers: {
    appealsCleared: (state) => {
      clearAdapterItems(appealsAdapter, state)
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      appealsApiSlice.endpoints.getAppeals.matchFulfilled,
      (state, { payload }) => {
        addItemsToAdapter(appealsAdapter, state, payload)
      }
    )
    builder.addMatcher(
      appealsApiSlice.endpoints.createAppeal.matchFulfilled,
      (state) => {
        clearAdapterItems(appealsAdapter, state)
      }
    )
  },
})

export const { appealsCleared } = appealsSlice.actions

export const {
  selectAll: selectAppeals,
  selectById: selectAppealById,
  selectIds: selectAppealsIds,
} = appealsAdapter.getSelectors<RootState>((state) => state.appeals)

export const appealsReducer = appealsSlice.reducer
