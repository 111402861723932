import { ITheme } from '@theme/interfaces'

export const getMenuListStyles = (theme: ITheme, menuListHeight?: number) => {
  return {
    maxHeight: menuListHeight ? `${menuListHeight}px` : '200px',
    backgroundColor: theme.colors.light,
    color: theme.colors.text20,

    '::-webkit-scrollbar': {
      width: '4px',
    },
    '::-webkit-scrollbar-track': {
      background: theme.colors.gray77,
      borderRadius: '5px',
    },
    '::-webkit-scrollbar-thumb': {
      background: theme.colors.primary,
      borderRadius: '5px',
    },
  }
}
