import styled from 'styled-components'

import { toggleTask } from '@pages/TasksBoard/ExecutorOffer/executorOfferSlice'
import { useAppDispatch } from '@src/hooks/store'
import { useWindowResize } from '@src/hooks/useWindowResize'
import { TaskType } from '@src/shared/interfaces/task/TaskType'
import { media } from '@theme/helpers'
import { Box } from '@ui/Box'
import { Checkbox } from '@ui/Checkbox'
import { TaskTypeTag } from '@ui/Tags/TaskTypeTag'
import { MobileTitleStyles, RegularText } from '@ui/Typography'

interface ITaskItemProps {
  title: string
  description: string
  taskType: TaskType
  taskId: number
  isActive: boolean
  disabled?: boolean
}

export const ExecutorOfferTaskItem = ({
  title,
  description,
  taskType,
  taskId,
  isActive,
  disabled,
}: ITaskItemProps) => {
  const dispatch = useAppDispatch()
  const { isDesktop } = useWindowResize()

  const handleToggle = () => {
    dispatch(toggleTask(taskId))
  }

  return (
    <Wrapper>
      <StyledCheckbox
        isChecked={isActive}
        onChange={handleToggle}
        isDisabled={disabled}
      >
        <Info>
          {isDesktop ? (
            <>
              <TaskTypeTag
                taskType={taskType}
                iconOnly
                style={{ flexShrink: 0, marginRight: '10px' }}
              />
              <Box>
                <Title style={{ marginBottom: '3px' }}>{title}</Title>
                <RegularText color="gray30">{description}</RegularText>
              </Box>
            </>
          ) : (
            <>
              <Box flex alignItems="flex-start" mb={6}>
                <TaskTypeTag
                  taskType={taskType}
                  iconOnly
                  style={{ flexShrink: 0, marginRight: '10px' }}
                />
                <Title>{title}</Title>
              </Box>

              <RegularText color="gray30">{description}</RegularText>
            </>
          )}
        </Info>
      </StyledCheckbox>
    </Wrapper>
  )
}

const Wrapper = styled(Box)`
  padding: 11px 0;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray90};
  }

  ${media(
    'small',
    () => `
    padding: 14px 0;
    &:first-child{
    padding-top: 0;
    }
    &:last-child{
    padding-bottom: 0;
    }
  `
  )}
`
const Info = styled(Box)`
  display: flex;
  align-items: center;
  ${media(
    'small',
    () => `
     flex-direction: column;
     align-items: flex-start;
  `
  )}
`
const Title = styled.h3`
  ${MobileTitleStyles}
`
const StyledCheckbox = styled(Checkbox)`
  input + div {
    top: calc((100% - 16px) / 2) !important;

    ${media(
      'small',
      () => `
      top: 9px !important;
      `
    )}
  }
`
