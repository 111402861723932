export const formatArrayToGetParams = (name: string, arr?: unknown[]) => {
  if (!arr || !arr.length) return ''

  let result = ''
  for (const elem of arr) {
    result += `${encodeURI(name)}=${elem}&`
  }

  return result.slice(0, -1)
}
