import { memo } from 'react'
import { useParams } from 'react-router-dom'

import { useLazyGetEmployeesExecutorOfferQuery } from '@pages/TasksBoard/ExecutorOffer/executorOfferApi'
import {
  setOfferFieldErrorMessage,
  setOfferFieldValue,
} from '@pages/TasksBoard/ExecutorOffer/executorOfferSlice'
import { InputWrapper } from '@pages/TasksBoard/ExecutorOffer/inputs/InputWrapper'
import { useAppDispatch, useAppSelector } from '@src/hooks/store'
import { usePermissions } from '@src/hooks/usePermissions'
import { IOption } from '@src/shared/interfaces/partials/IOption'
import { InfiniteScrollSelect } from '@ui/Selects/InfiniteScrollSelect/InfiniteScrollSelect'
import { RegularText } from '@ui/Typography'

interface IProps {
  taskId: number
  fieldName: string
  title: string
  additionalFieldName?: string
  isPreviewMode: boolean
  isFirstValidation: boolean
}

// eslint-disable-next-line react/display-name
export const SelectEmployee = memo(
  ({
    taskId,
    fieldName,
    title,
    additionalFieldName, //для изменения дополнительных текстовых полей с ответственным лицом.
    isPreviewMode,
    isFirstValidation,
  }: IProps) => {
    const dispatch = useAppDispatch()
    const { id: spaceId } = useParams()
    const fieldData = useAppSelector(
      (state) => state.executorOffer.selectedTasks[taskId][fieldName]
    )
    const additionalFieldData = useAppSelector(
      (state) =>
        state.executorOffer.selectedTasks[taskId][additionalFieldName || '']
    )
    const { hasPermission } = usePermissions('employee_view')

    const [getEmployeesExecutor] = useLazyGetEmployeesExecutorOfferQuery()

    const handleChange = (value: any) => {
      dispatch(
        setOfferFieldValue({
          taskId,
          fieldName,
          value: value,
        })
      )
      if (additionalFieldName) {
        dispatch(
          setOfferFieldValue({
            taskId,
            fieldName: additionalFieldName,
            value: value.label,
          })
        )
        if (!isFirstValidation) {
          dispatch(
            setOfferFieldErrorMessage({
              taskId,
              fieldName: additionalFieldName,
              value: additionalFieldData.getErrorMessage(value.label),
            })
          )
        }
      }
    }

    function loadOptions(
      search: string,
      loadedOptions: IOption[],
      { page }: any
    ) {
      return getEmployeesExecutor({
        spaceId: Number(spaceId),
        page: page,
      })
        .unwrap()
        .then((response) => ({
          options: response.data.items.map((item: any) => ({
            label: `${item.last_name} ${item.first_name} ${item.patronymic}`,
            value: item.id,
          })),
          hasMore: Math.ceil(response.data.pagination.total_items / 50) > page,
          additional: {
            page: page + 1,
          },
        }))
    }

    const content = isPreviewMode ? (
      <RegularText>
        {(fieldData.value as IOption)?.label || 'Не задано'}
      </RegularText>
    ) : (
      <InfiniteScrollSelect
        isClearable={true}
        isSearchable={false}
        hasError={!!fieldData.errorMessage}
        value={fieldData.value as IOption}
        onChange={handleChange}
        placeholder={'Выберите сотрудника'}
        defaultOptions
        additional={{
          page: 1,
        }}
        loadOptions={loadOptions}
      />
    )

    return (
      <InputWrapper title={title} required={fieldData.required}>
        {hasPermission ? (
          content
        ) : (
          <RegularText>
            Чтобы назначить ответственного сотрудника, обратитесь к владельцу
            спейса за правами на просмотр сотрудников
          </RegularText>
        )}
      </InputWrapper>
    )
  }
)
