import IconPropsInterface from './IconPropsInterface'

export const BarBonus = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 14 14"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M12.3333 4.33203H1.66667C1.29848 4.33203 1 4.63051 1 4.9987V6.33203C1 6.70022 1.29848 6.9987 1.66667 6.9987H12.3333C12.7015 6.9987 13 6.70022 13 6.33203V4.9987C13 4.63051 12.7015 4.33203 12.3333 4.33203Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 4.33203V12.9987"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6654 7V11.6667C11.6654 12.0203 11.5249 12.3594 11.2748 12.6095C11.0248 12.8595 10.6857 13 10.332 13H3.66536C3.31174 13 2.9726 12.8595 2.72256 12.6095C2.47251 12.3594 2.33203 12.0203 2.33203 11.6667V7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.0026 4.33362C3.56058 4.33362 3.13665 4.15803 2.82409 3.84547C2.51153 3.53291 2.33594 3.10899 2.33594 2.66696C2.33594 2.22493 2.51153 1.80101 2.82409 1.48845C3.13665 1.17589 3.56058 1.00029 4.0026 1.00029C4.64573 0.989087 5.27595 1.30113 5.8111 1.89573C6.34624 2.49033 6.76145 3.33989 7.0026 4.33362C7.24375 3.33989 7.65897 2.49033 8.19411 1.89573C8.72925 1.30113 9.35948 0.989087 10.0026 1.00029C10.4446 1.00029 10.8686 1.17589 11.1811 1.48845C11.4937 1.80101 11.6693 2.22493 11.6693 2.66696C11.6693 3.10899 11.4937 3.53291 11.1811 3.84547C10.8686 4.15803 10.4446 4.33362 10.0026 4.33362"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
