import { Accrual } from '@icons/Accrual'
import { Refund } from '@icons/Refund'
import { Withdrawal } from '@icons/Withdrawal'

export const LIMIT_TRANSACTION_RECORDS = 20

//типы транзакций
export const REFILL_TRANSACTION = 'crediting'
export const REFUND_TRANSACTION = 'refund'
export const WITHDRAW_TRANSACTION = 'debiting'

//типы источников
export const BALANCE_SOURCE = 'balance'
export const CARD_SOURCE = 'card'
export const ACCOUNT_SOURCE = 'account'
export const PARTNER_SOURCE = 'partner'

export const TRANSACTION_COLORS = {
  [REFILL_TRANSACTION]: 'refill',
  [REFUND_TRANSACTION]: 'refund',
  [WITHDRAW_TRANSACTION]: 'withdraw',
}

export const TRANSACTION_NAMES = {
  [REFILL_TRANSACTION]: 'Зачисление',
  [REFUND_TRANSACTION]: 'Возврат',
  [WITHDRAW_TRANSACTION]: 'Списание',
}

export const TRANSACTION_ICONS = {
  [REFILL_TRANSACTION]: <Accrual color={'currentColor'} />,
  [REFUND_TRANSACTION]: <Refund color={'currentColor'} />,
  [WITHDRAW_TRANSACTION]: <Withdrawal color={'currentColor'} />,
}

export const SOURCE_NAMES = {
  [BALANCE_SOURCE]: 'Баланс', //Баланс - только для списаний.
  [CARD_SOURCE]: 'Карта',
  [ACCOUNT_SOURCE]: 'Счет',
  [PARTNER_SOURCE]: 'Партнер',
}

export const sourceItems = [
  {
    name: 'Все',
    value: 0,
  },
  {
    name: SOURCE_NAMES[BALANCE_SOURCE],
    key: BALANCE_SOURCE,
    value: 1,
  },
  {
    name: SOURCE_NAMES[CARD_SOURCE],
    key: CARD_SOURCE,
    value: 2,
  },
  {
    name: SOURCE_NAMES[ACCOUNT_SOURCE],
    key: ACCOUNT_SOURCE,
    value: 3,
  },
]

export const typeItems = [
  {
    name: 'Все',
    value: 0,
  },
  {
    name: TRANSACTION_NAMES[REFILL_TRANSACTION],
    key: REFILL_TRANSACTION,
    value: 1,
  },
  {
    name: TRANSACTION_NAMES[REFUND_TRANSACTION],
    key: REFUND_TRANSACTION,
    value: 2,
  },
  {
    name: TRANSACTION_NAMES[WITHDRAW_TRANSACTION],
    key: WITHDRAW_TRANSACTION,
    value: 3,
  },
]
