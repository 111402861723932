import {
  StyledTitleLevel1,
  StyledTitleLevel2,
} from '@pages/TasksBoard/ExecutorOffer/StyledTitles'
import { usePrepaymentAmount } from '@pages/TasksBoard/ExecutorOffer/hooks'
import {
  NumberInput,
  SelectEmployee,
  SelectVehicleConfiguration,
  TextInput,
} from '@pages/TasksBoard/ExecutorOffer/inputs'
import { DadataCityInput } from '@pages/TasksBoard/ExecutorOffer/inputs/DadataCityInput'
import { DoubleDateInput } from '@pages/TasksBoard/ExecutorOffer/inputs/DoubleDateInput'
import { ITaskListItemViewData } from '@pages/TasksBoard/interfaces'
import { useAppSelector } from '@src/hooks/store'

interface IProps {
  taskId: number
  data: ITaskListItemViewData | undefined
}
export const EditTaskPassenger = ({ taskId, data }: IProps) => {
  const { isFirstValidation, isPreviewMode } = useAppSelector(
    (state) => state.executorOffer
  )

  const vehicleTypes = data?.details?.vehicle_types?.map((item) => item.id) || [
    0,
  ]

  const { handler, showPrepaymentDays } = usePrepaymentAmount(taskId)

  return (
    <div>
      <StyledTitleLevel1>Основная информация</StyledTitleLevel1>
      <SelectVehicleConfiguration
        taskId={taskId}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
        taskVehicleTypes={vehicleTypes}
      />
      <NumberInput
        taskId={taskId}
        fieldName={'number_of_passengers'}
        title={'Вместимость, чел.'}
        description={''}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
      />
      <NumberInput
        taskId={taskId}
        fieldName={'cost'}
        title={'Стоимость, руб.'}
        description={''}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
      />
      <DoubleDateInput
        taskId={taskId}
        title={'Время начала посадки'}
        isFirstValidation={isFirstValidation}
        isPreviewMode={isPreviewMode}
      />
      <NumberInput
        taskId={taskId}
        fieldName={'deferred_payment'}
        title={'Отсрочка оплаты, дней'}
        description={''}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
      />
      <NumberInput
        taskId={taskId}
        additionalHandler={handler}
        fieldName={'prepayment_amount'}
        title={'Сумма предоплаты, руб.'}
        description={''}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
      />
      <SelectEmployee
        taskId={taskId}
        fieldName={'employee_id'}
        additionalFieldName={'responsible_person'}
        title={'Ответственный сотрудник по задаче'}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
      />
      <StyledTitleLevel1>Данные для договора</StyledTitleLevel1>
      <DadataCityInput
        taskId={taskId}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
      />
      <NumberInput
        taskId={taskId}
        fieldName={'contract_period'}
        title={'Срок действия договора, мес.'}
        description={''}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
      />
      <NumberInput
        taskId={taskId}
        fieldName={'cost_stop'}
        title={'Стоимость остановки, руб.'}
        description={''}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
      />
      <NumberInput
        taskId={taskId}
        fieldName={'cost_city'}
        title={'Стоимость изменения маршрута, руб./час'}
        description={''}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
      />
      <NumberInput
        taskId={taskId}
        fieldName={'waiting_minutes'}
        title={'Бесплатное ожидание, мин.'}
        description={''}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
      />
      <NumberInput
        taskId={taskId}
        fieldName={'baggage_allowance'}
        title={'Норма багажа на одного пассажира, кг'}
        description={''}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
      />
      <NumberInput
        taskId={taskId}
        fieldName={'carry_on_allowance'}
        title={'Норма ручной клади на одного пассажира, кг'}
        description={''}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
      />
      {showPrepaymentDays && (
        <NumberInput
          taskId={taskId}
          fieldName={'prepayment_days'}
          title={'Срок внесения предоплаты, дней'}
          description={
            'Количество дней на внесение предоплаты с момента получения счета.'
          }
          isPreviewMode={isPreviewMode}
          isFirstValidation={isFirstValidation}
        />
      )}
      <NumberInput
        taskId={taskId}
        fieldName={'term_of_signing'}
        title={'Срок принятия акта, дней'}
        description={''}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
      />
      <TextInput
        taskId={taskId}
        fieldName={'responsible_person'}
        title={'Ответственное лицо по договору'}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
      />
      <StyledTitleLevel2>Неустойки</StyledTitleLevel2>
      <NumberInput
        taskId={taskId}
        fieldName={'idle_use_vehicle_hours'}
        title={'Срок оповещения об отказе от Договора без неустойки, часов'}
        description={'До времени предоставления транспорта'}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
      />
      <NumberInput
        taskId={taskId}
        fieldName={'idle_pay_percent'}
        title={'Неустойка за просрочку оплаты, %'}
        description={'За каждый день просрочки'}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
      />
    </div>
  )
}
