import { ChangeEventHandler, memo } from 'react'

import {
  setOfferFieldErrorMessage,
  setOfferFieldValue,
} from '@pages/TasksBoard/ExecutorOffer/executorOfferSlice'
import { InputWrapper } from '@pages/TasksBoard/ExecutorOffer/inputs/InputWrapper'
import { useAppDispatch, useAppSelector } from '@src/hooks/store'
import { useWindowResize } from '@src/hooks/useWindowResize'
import { Box } from '@ui/Box'
import DropDown from '@ui/DropDown'
import { BaseInput } from '@ui/Inputs/Base/BaseInput'
import { RegularText } from '@ui/Typography'

interface IProps {
  taskId: number
  isPreviewMode: boolean
  isFirstValidation: boolean
  title: string
  units: { name: string; value: number }[]
}

// eslint-disable-next-line react/display-name
export const MaterialCostUnitInput = memo(
  ({ taskId, isPreviewMode, isFirstValidation, title, units }: IProps) => {
    const costFieldName = 'cost'
    const unitFieldName = 'material_type_unit_id'
    const costFieldData = useAppSelector(
      (state) => state.executorOffer.selectedTasks[taskId][costFieldName]
    )
    const unitFieldData = useAppSelector(
      (state) => state.executorOffer.selectedTasks[taskId][unitFieldName]
    )
    const { isMobile } = useWindowResize()
    const dispatch = useAppDispatch()
    const handleChangeCost: ChangeEventHandler<HTMLInputElement> = (e) => {
      const value = e.target.value.replace(/[^0-9.]/g, '')
      if (!isFirstValidation) {
        dispatch(
          setOfferFieldErrorMessage({
            taskId,
            fieldName: costFieldName,
            value: costFieldData.getErrorMessage(value),
          })
        )
      }

      dispatch(
        setOfferFieldValue({
          taskId,
          fieldName: costFieldName,
          value: value,
        })
      )
    }

    const handleChangeUnit = (value: number) => {
      if (!isFirstValidation) {
        dispatch(
          setOfferFieldErrorMessage({
            taskId,
            fieldName: unitFieldName,
            value: unitFieldData.getErrorMessage(value),
          })
        )
      }
      dispatch(
        setOfferFieldValue({
          taskId,
          fieldName: unitFieldName,
          value,
        })
      )
    }

    return (
      <InputWrapper
        title={title}
        description={''}
        required={true}
        isDisabled={false}
        errorMessage={costFieldData.errorMessage || unitFieldData.errorMessage}
      >
        {isPreviewMode ? (
          <Box flex cg={10}>
            <RegularText>
              {costFieldData.value === '' ? 'Не задано' : costFieldData.value}
            </RegularText>
            <RegularText>
              {units.find(
                (item: { name: string; value: number }) =>
                  item.value === unitFieldData.value
              )?.name || ''}
            </RegularText>
          </Box>
        ) : (
          <Box flex cg={10}>
            <BaseInput
              type={'text'}
              style={{ width: isMobile ? '100%' : '49%' }}
              hasError={!!costFieldData.errorMessage}
              border
              value={costFieldData.value}
              onChange={handleChangeCost}
              disabled={costFieldData.isDisabled}
            />
            <DropDown
              hasError={!!unitFieldData.errorMessage}
              width={isMobile ? '100%' : '49%'}
              height={45}
              items={units}
              title={'Выбрать единицу измерения'}
              activeValue={unitFieldData.value as unknown as number}
              handleChange={(value) => {
                handleChangeUnit(value)
              }}
            />
          </Box>
        )}
      </InputWrapper>
    )
  }
)
