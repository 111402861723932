import IconPropsInterface from './IconPropsInterface'

export const Photo = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 16 16"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M10.5 4.66699H10.5083"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1654 1.33301H3.83203C2.45132 1.33301 1.33203 2.4523 1.33203 3.83301V12.1663C1.33203 13.5471 2.45132 14.6663 3.83203 14.6663H12.1654C13.5461 14.6663 14.6654 13.5471 14.6654 12.1663V3.83301C14.6654 2.4523 13.5461 1.33301 12.1654 1.33301Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.33203 10.5L4.66536 7.16663C5.04541 6.80092 5.47652 6.6084 5.91536 6.6084C6.35421 6.6084 6.78532 6.80092 7.16536 7.16663L11.332 11.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.66797 9.66695L10.5013 8.83362C10.8814 8.46792 11.3125 8.27539 11.7513 8.27539C12.1901 8.27539 12.6213 8.46792 13.0013 8.83362L14.668 10.5003"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
