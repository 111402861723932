import IconPropsInterface from './IconPropsInterface'

export const Edit2 = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 10 9"
      fill={color}
      stroke="white"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M1 8.50015H3L8.25 3.25015C8.51522 2.98493 8.66421 2.62522 8.66421 2.25015C8.66421 1.87508 8.51522 1.51537 8.25 1.25015C7.98478 0.984935 7.62507 0.835938 7.25 0.835938C6.87493 0.835937 6.51522 0.984935 6.25 1.25015L1 6.50015V8.50015Z"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.855"
      />
      <path d="M3 8.50104H1V6.50104L5.75012 1.76172L7.76282 3.73633L3 8.50104Z" />
      <path
        d="M5.75 1.75L7.75 3.75"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.855"
      />
    </svg>
  )
}
