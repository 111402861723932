import moment from 'moment/moment'
import { memo } from 'react'

import {
  setOfferFieldErrorMessage,
  setOfferFieldValue,
} from '@pages/TasksBoard/ExecutorOffer/executorOfferSlice'
import { InputWrapper } from '@pages/TasksBoard/ExecutorOffer/inputs/InputWrapper'
import { useAppDispatch, useAppSelector } from '@src/hooks/store'
import { CalendarWithTime } from '@ui/CalendarComponent/CalendarWithTime'
import { RegularText } from '@ui/Typography'

interface IProps {
  taskId: number
  title: string
  isFirstValidation: boolean
  isPreviewMode: boolean
}
// eslint-disable-next-line react/display-name
export const DoubleDateInput = memo(
  ({ taskId, title, isFirstValidation, isPreviewMode }: IProps) => {
    const dispatch = useAppDispatch()

    const fieldData = useAppSelector(
      (state) =>
        state.executorOffer.selectedTasks[taskId]['delivery_datetime_to']
    )

    const handleChange = (value: Date | null) => {
      if (!isFirstValidation) {
        dispatch(
          setOfferFieldErrorMessage({
            taskId,
            fieldName: 'delivery_datetime_to',
            value: fieldData.getErrorMessage(value),
          })
        )
      }
      dispatch(
        setOfferFieldValue({
          taskId,
          fieldName: 'delivery_datetime_to',
          value: value,
        })
      )

      dispatch(
        setOfferFieldValue({
          taskId,
          fieldName: 'delivery_datetime_from',
          value: value ? moment(value).subtract(1, 'seconds').toDate() : null,
        })
      )
    }

    return (
      <InputWrapper
        title={title}
        required={fieldData.required}
        errorMessage={fieldData.errorMessage}
      >
        {isPreviewMode ? (
          <RegularText>
            {fieldData.value
              ? moment(fieldData.value as unknown as string).format(
                  'DD.MM.YYYY HH:mm'
                )
              : 'Не задано'}
          </RegularText>
        ) : (
          <CalendarWithTime
            disabled={fieldData.isDisabled}
            hasError={!!fieldData.errorMessage}
            type={'future'}
            onChange={handleChange}
            value={fieldData.value}
            position={'top'}
          />
        )}
      </InputWrapper>
    )
  }
)
