import IconPropsInterface from '@icons/IconPropsInterface'

export const EmailsSend = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 22.75H5"
        stroke="#34C759"
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 15.25H5"
        stroke="#34C759"
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.5956 45.25H17.1256C13.1056 45.25 10.2231 41.375 11.3781 37.525L16.7781 19.525C17.5406 16.9875 19.8756 15.25 22.5256 15.25H48.9981C53.0181 15.25 55.9006 19.125 54.7456 22.975L49.3456 40.975C48.5831 43.5125 46.2456 45.25 43.5956 45.25Z"
        stroke={color}
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.8516 42.0496L29.2516 30.4746"
        stroke={color}
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.5234 30.375L48.1984 43.05"
        stroke={color}
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.9961 17.3398L30.0036 31.3498C30.8136 32.2948 32.1936 32.4998 33.2436 31.8323L54.2736 18.4623"
        stroke={color}
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
