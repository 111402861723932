import IconPropsInterface from './IconPropsInterface'

export const BarObjects = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 14 14"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path d="M1 13H13" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M2.33203 13V3.66667L7.66536 1V13"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6641 13.0013V6.33464L7.66406 3.66797"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M5 5V5.00667" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5 7V7.00667" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5 9V9.00667" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5 11V11.0067" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
