import { useAppSelector } from '@src/hooks/store'
import { breakpoints } from '@theme/mediaQueries'

export const useWindowResize = () => {
  const innerWidth = useAppSelector((state) => state.windowSize.width)

  const isLargeDesktop = innerWidth > parseInt(breakpoints.xxlarge)
  const isDesktop = innerWidth + 1 > parseInt(breakpoints.xlarge)
  const isTablet =
    innerWidth <= parseInt(breakpoints.xlarge) &&
    innerWidth > parseInt(breakpoints.small)
  const isMobile = innerWidth <= parseInt(breakpoints.small)

  return { isLargeDesktop, isDesktop, isTablet, isMobile, innerWidth }
}
