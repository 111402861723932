import IconPropsInterface from './IconPropsInterface'

export const Info = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 16 16"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M8 15.5C12.1421 15.5 15.5 12.1421 15.5 8C15.5 3.85786 12.1421 0.5 8 0.5C3.85786 0.5 0.5 3.85786 0.5 8C0.5 12.1421 3.85786 15.5 8 15.5Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 4.66602H8.00833"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M7.16797 8H8.0013V11.3333H8.83464"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
