import { CSSProperties, ChangeEventHandler, useState } from 'react'
import styled from 'styled-components'

import {
  useCreateChatFromOfferMutation,
  useCreateChatFromTaskMutation,
} from '@pages/Chat/chatApi'
import { BUTTON_TEXT_MAP } from '@pages/Chat/components/external/constants'
import { MAX_TEXT_MESSAGE_LENGTH } from '@pages/Chat/constants'
import { useToggle } from '@src/hooks/useToggle'
import { getBgColor, getColor, getFontSize } from '@theme/helpers'
import { useGetTheme } from '@theme/hooks'
import { Box } from '@ui/Box'
import { BaseIconButton } from '@ui/Buttons/Base/BaseIconButton'
import { CloseButton } from '@ui/Buttons/CloseButton'
import { MainButton } from '@ui/Buttons/MainButton'
import { Paper } from '@ui/Paper'
import { BasePopup } from '@ui/Popups/BasePopup'
import { MediumTitle } from '@ui/Typography'
import { withScrollbar } from '@ui/withScrollbar'

interface IProps {
  from: 'offer' | 'task'
  offerId?: number
  taskId?: number
  projectId?: number
  fluid?: boolean
  style?: CSSProperties
  buttonId?: string
}

const StyledTextarea = withScrollbar(styled.textarea`
  resize: none;
  height: 160px;
  width: 100%;
  overflow-y: auto;
  margin: 20px 0;
  padding: 10px;
  border-radius: 10px;
  outline: none;
  border: 1px solid ${({ theme }) => theme.colors.gray80};
  font-family: inherit;

  ${getFontSize('s')}
  ${getColor('text20')}
  ${getBgColor('light')}
  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
`)
export const PostMessage = ({
  from,
  offerId,
  projectId,
  taskId,
  fluid,
  style,
  buttonId,
}: IProps) => {
  const { isOn, on, off } = useToggle(false)
  const [message, setMessage] = useState('')

  const theme = useGetTheme()

  const handleClosePopup = () => {
    off()
    setMessage('')
  }
  const handleChangeMessage: ChangeEventHandler<HTMLInputElement> = (e) => {
    setMessage(e.target.value)
  }

  const [postFromOffer, { isLoading: isSendingFromOffer }] =
    useCreateChatFromOfferMutation()
  const [postFromTask, { isLoading: isSendingFromTask }] =
    useCreateChatFromTaskMutation()
  const handleSendMessage = () => {
    ;(from === 'task' ? postFromTask : postFromOffer)({
      message,
      offerId,
      projectId,
      taskId,
    })
      .unwrap()
      .then(() => {
        handleClosePopup()
      })
  }

  const handleCancel = () => {
    handleClosePopup()
    setMessage('')
  }

  const isSendingMessage = isSendingFromOffer || isSendingFromTask

  return (
    <>
      <BasePopup isOpened={isOn} handleClickOut={handleClosePopup}>
        <Paper
          p={20}
          style={{ position: 'relative', maxWidth: '570px', width: '100vw' }}
        >
          <CloseButton
            style={{ position: 'absolute', top: '20px', right: '20px' }}
            onClick={handleClosePopup}
          />
          <MediumTitle>Отправить сообщение</MediumTitle>
          <StyledTextarea
            placeholder={'Напишите сообщение...'}
            value={message}
            onChange={handleChangeMessage}
            autoFocus
            maxLength={MAX_TEXT_MESSAGE_LENGTH}
          />
          <Box flex cg={20} justifyContent={'flex-end'}>
            <MainButton onClick={handleCancel} fillType={'lightFilled'}>
              Отменить
            </MainButton>
            <MainButton
              isLoading={isSendingMessage}
              onClick={handleSendMessage}
              fillType={'fullFilled'}
            >
              Отправить
            </MainButton>
          </Box>
        </Paper>
      </BasePopup>
      {from === 'task' ? (
        <MainButton
          onClick={on}
          fillType={'fullFilled'}
          fluid={fluid}
          style={style}
          id={buttonId}
        >
          {BUTTON_TEXT_MAP[from]}
        </MainButton>
      ) : (
        <BaseIconButton
          iconName="Message"
          iconColor={theme.colors.primary}
          style={{
            background: `${theme.colors.primaryLight}`,
            borderRadius: '7px',
            height: '45px',
            padding: '8px 42px',
          }}
          onClick={on}
          fluid={fluid}
          id={buttonId}
        >
          Написать в чат
        </BaseIconButton>
      )}
    </>
  )
}
