import IconPropsInterface from '@icons/IconPropsInterface'

export const Copy = ({ size = 20, color, style }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        ...style,
        flexShrink: 0,
        transition: '300ms',
        verticalAlign: 'middle',
      }}
    >
      <path
        d="M14.375 0H6.875C5.55249 0 4.37501 1.21313 4.37501 2.50001L3.65251 2.51689C2.33063 2.51689 1.25 3.71312 1.25 5V17.5C1.25 18.7869 2.42751 20 3.75001 20H13.125C14.4475 20 15.625 18.7869 15.625 17.5H16.25C17.5725 17.5 18.75 16.2869 18.75 15V5.01501L14.375 0ZM13.125 18.75H3.75001C3.09376 18.75 2.50002 18.1369 2.50002 17.5V5C2.50002 4.36314 3.0344 3.77062 3.69065 3.77062L4.37501 3.75001V15C4.37501 16.2869 5.55249 17.5 6.875 17.5H14.375C14.375 18.1369 13.7812 18.75 13.125 18.75ZM17.5 15C17.5 15.6369 16.9063 16.25 16.25 16.25H6.875C6.21875 16.25 5.625 15.6369 5.625 15V2.50001C5.625 1.86315 6.21875 1.25002 6.875 1.25002H13.125C13.115 2.6894 13.125 3.76565 13.125 3.76565C13.125 5.0644 14.2937 6.25002 15.625 6.25002H17.5V15ZM15.625 5C14.9594 5 14.375 3.79064 14.375 3.14188V1.26812L17.5 5H15.625ZM14.375 8.76127H8.75001C8.40502 8.76127 8.12502 9.04064 8.12502 9.38564C8.12502 9.73064 8.40502 10.01 8.75001 10.01H14.375C14.72 10.01 15 9.73061 15 9.38564C15 9.04064 14.72 8.76127 14.375 8.76127ZM14.375 11.8831H8.75001C8.40502 11.8831 8.12502 12.1625 8.12502 12.5075C8.12502 12.8525 8.40502 13.1319 8.75001 13.1319H14.375C14.72 13.1319 15 12.8525 15 12.5075C15 12.1625 14.72 11.8831 14.375 11.8831Z"
        fill={color}
      />
    </svg>
  )
}
