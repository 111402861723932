import { Certificate } from 'crypto-pro'

import { createSlice } from '@reduxjs/toolkit'

interface IESignCertificate {
  data: Certificate | null
}

const initialState: IESignCertificate = {
  data: null,
}

const eSignCertificateSlice = createSlice({
  name: 'certificate',
  initialState,
  reducers: {
    setSignCertificate: (state, action) => {
      state.data = action.payload
    },
  },
})

export const { setSignCertificate } = eSignCertificateSlice.actions

export default eSignCertificateSlice.reducer
