import { createSlice } from '@reduxjs/toolkit'
import { IOption } from '@src/shared/interfaces/partials/IOption'

export interface IPassengerState {
  vehicleTypes: IOption[]
  numberOfPassengersDiapason: [string, string]
  radiusFrom: number
  radiusWhere: number
  coordinatesFrom: [number, number] | null
  coordinatesWhere: [number, number] | null
}

const initialState: IPassengerState = {
  vehicleTypes: [],
  numberOfPassengersDiapason: ['', ''],
  radiusFrom: 1,
  radiusWhere: 1,
  coordinatesFrom: null,
  coordinatesWhere: null,
}

const filterSlicePassenger = createSlice({
  name: 'filterPassenger',
  initialState,
  reducers: {
    setValueInStatePassenger: (state, action) => {
      const field = action.payload.field as keyof IPassengerState
      state[field] = action.payload.data as never
    },
    resetPassengerFilter: () => initialState,
  },
})

export const { setValueInStatePassenger, resetPassengerFilter } =
  filterSlicePassenger.actions

export const filterSlicePassengerReducer = filterSlicePassenger.reducer
