import { Reducer } from '@reduxjs/toolkit'

export const renameChatReducer: Reducer = (state, action) => {
  const chat = state?.currentChatGroup?.chats?.find(
    (item: any) => item.id === action.payload.id
  )
  if (chat) {
    chat.name = action.payload.name
  }
  if (
    state.currentChatInfo &&
    state.currentChatInfo?.id === action.payload.id
  ) {
    state.currentChatInfo.name = action.payload.name
  }
}
