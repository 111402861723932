export const FileXlsx = () => {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3">
        <path
          opacity="0.3"
          d="M13.9671 4.92491C13.4204 4.92438 12.8962 4.70696 12.5096 4.32038C12.123 3.9338 11.9056 3.40964 11.905 2.86294V0H2.69103C1.97733 0 1.29285 0.283518 0.788187 0.788169C0.283524 1.29282 0 1.97726 0 2.69094V18.9126C0 19.6263 0.283524 20.3108 0.788187 20.8154C1.29285 21.3201 1.97733 21.6036 2.69103 21.6036H13.7511C14.4648 21.6036 15.1492 21.3201 15.6539 20.8154C16.1586 20.3108 16.4421 19.6263 16.4421 18.9126V4.92491H13.9671Z"
          fill="#00C650"
        />
      </g>
      <path
        d="M16.4433 4.92491H13.9683C13.4216 4.92438 12.8974 4.70696 12.5108 4.32038C12.1242 3.9338 11.9068 3.40964 11.9062 2.86294V0L16.4433 4.92491Z"
        fill="#00C650"
      />
      <path
        d="M18.3913 17.0586H5.88726C5.00029 17.0586 4.28125 17.7776 4.28125 18.6646V22.3925C4.28125 23.2795 5.00029 23.9985 5.88726 23.9985H18.3913C19.2783 23.9985 19.9973 23.2795 19.9973 22.3925V18.6646C19.9973 17.7776 19.2783 17.0586 18.3913 17.0586Z"
        fill="#00C650"
      />
      <path
        d="M7.04988 21.3711V22.0981H6.29688V21.3711H7.04988Z"
        fill="white"
      />
      <path
        d="M9.39256 22.0968L8.75958 21.1608L8.21458 22.0968H7.38157L8.34256 20.5488L7.35156 19.0898H8.21458L8.83157 19.9958L9.36358 19.0898H10.1926L9.24456 20.6038L10.2566 22.0968H9.39256Z"
        fill="white"
      />
      <path
        d="M11.2807 21.5398H12.2547V22.0968H10.5547V19.0898H11.2827L11.2807 21.5398Z"
        fill="white"
      />
      <path
        d="M14.6846 21.6574C14.5983 21.7955 14.4744 21.9061 14.3275 21.9764C14.1488 22.0601 13.9529 22.1008 13.7556 22.0953C13.4578 22.1078 13.1645 22.0209 12.9216 21.8484C12.8141 21.7677 12.7262 21.6639 12.6644 21.5446C12.6026 21.4253 12.5685 21.2937 12.5646 21.1594H13.3386C13.3441 21.2607 13.3872 21.3562 13.4596 21.4274C13.5316 21.4945 13.6272 21.5304 13.7255 21.5274C13.7656 21.5297 13.8058 21.5241 13.8437 21.5109C13.8817 21.4977 13.9166 21.4771 13.9466 21.4504C13.9732 21.4245 13.9942 21.3933 14.0082 21.3589C14.0221 21.3245 14.0287 21.2875 14.0275 21.2504C14.0285 21.2151 14.0224 21.18 14.0095 21.1472C13.9966 21.1144 13.9773 21.0845 13.9525 21.0594C13.8996 21.0058 13.8368 20.963 13.7676 20.9334C13.6949 20.9014 13.5929 20.8624 13.4616 20.8164C13.3022 20.7638 13.1465 20.7007 12.9956 20.6274C12.8715 20.5635 12.7651 20.4701 12.6856 20.3554C12.5933 20.2166 12.5477 20.0519 12.5555 19.8854C12.5519 19.7204 12.5994 19.5583 12.6916 19.4214C12.7862 19.2876 12.9169 19.1835 13.0686 19.1214C13.2433 19.0503 13.431 19.0163 13.6196 19.0214C13.9059 19.0056 14.1888 19.0901 14.4196 19.2604C14.5178 19.3403 14.5982 19.4398 14.6557 19.5526C14.7133 19.6654 14.7466 19.789 14.7536 19.9154H13.9666C13.9578 19.8269 13.9198 19.7439 13.8586 19.6794C13.8273 19.6494 13.7902 19.6262 13.7496 19.611C13.7091 19.5959 13.6658 19.5892 13.6226 19.5914C13.5476 19.588 13.4739 19.6122 13.4156 19.6594C13.3886 19.6849 13.3677 19.7161 13.3542 19.7507C13.3407 19.7852 13.335 19.8224 13.3376 19.8594C13.336 19.9266 13.3612 19.9917 13.4076 20.0404C13.4573 20.0923 13.5172 20.1335 13.5836 20.1614C13.6546 20.1924 13.7566 20.2324 13.8896 20.2804C14.0518 20.3332 14.2099 20.3981 14.3626 20.4744C14.4872 20.5416 14.5947 20.6368 14.6765 20.7524C14.7699 20.8934 14.8161 21.0604 14.8086 21.2294C14.8072 21.3807 14.7643 21.5288 14.6846 21.6574Z"
        fill="white"
      />
      <path
        d="M17.1504 22.0968L16.5174 21.1608L15.9724 22.0968H15.1394L16.1004 20.5488L15.1094 19.0898H15.9724L16.5894 19.9958L17.1214 19.0898H17.9504L17.0024 20.6038L18.0144 22.0968H17.1504Z"
        fill="white"
      />
      <path
        d="M11.8903 15.0832H4.55826C3.92325 15.0832 3.40625 14.4512 3.40625 13.6732V8.3543C3.40625 7.57731 3.92325 6.94531 4.55826 6.94531H11.8933C12.5273 6.94531 13.0443 7.57731 13.0443 8.3543V13.6732C13.0413 14.4512 12.5243 15.0832 11.8903 15.0832ZM4.55826 7.66529C4.24726 7.66529 3.99527 7.9743 3.99527 8.3543V13.6732C3.99527 14.0532 4.24726 14.3622 4.55826 14.3622H11.8933C12.2033 14.3622 12.4553 14.0532 12.4553 13.6732V8.3543C12.4553 7.9743 12.2033 7.66529 11.8933 7.66529H4.55826Z"
        fill="#00C650"
      />
      <path
        d="M12.7443 11.8789H3.69531V12.5999H12.7443V11.8789Z"
        fill="#00C650"
      />
      <path
        d="M12.7443 9.40625H3.69531V10.1273H12.7443V9.40625Z"
        fill="#00C650"
      />
      <path
        d="M10.4241 7.30469H9.70312V14.7216H10.4241V7.30469Z"
        fill="#00C650"
      />
      <path
        d="M6.73662 7.30469H6.01562V14.7216H6.73662V7.30469Z"
        fill="#00C650"
      />
    </svg>
  )
}
