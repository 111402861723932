import IconPropsInterface from './IconPropsInterface'

export const Time = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 20 20"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M10.0013 16.6667C13.223 16.6667 15.8346 14.055 15.8346 10.8333C15.8346 7.61167 13.223 5 10.0013 5C6.77964 5 4.16797 7.61167 4.16797 10.8333C4.16797 14.055 6.77964 16.6667 10.0013 16.6667Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 8.33398V10.834H11.6667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83464 3.33398L3.54297 5.00065"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.168 3.33398L16.4596 5.00065"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
