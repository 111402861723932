import styled from 'styled-components'

interface IBox {
  flex?: boolean
  alignItems?: string
  justifyContent?: string
  flexDirection?: string
  m?: number | string
  mx?: number | string
  my?: number | string
  mt?: number | string
  mr?: number | string
  mb?: number | string
  ml?: number | string
  p?: number
  px?: number
  py?: number
  pt?: number
  pr?: number
  pb?: number
  pl?: number
  rg?: number
  cg?: number
}

const getResult = (
  paramAll?: number | string,
  paramOneDirection?: number | string,
  paramOneSide?: number | string
) => {
  const result = paramAll || paramOneDirection || paramOneSide
  if (!result) {
    return '0'
  }
  if (typeof result === 'string') {
    return result
  }
  return `${result}px`
}

export const Box = styled.div<IBox>`
  margin-top: ${({ m, my, mt }) => getResult(m, my, mt)};
  margin-right: ${({ m, mx, mr }) => getResult(m, mx, mr)};
  margin-bottom: ${({ m, my, mb }) => getResult(m, my, mb)};
  margin-left: ${({ m, mx, ml }) => getResult(m, mx, ml)};

  padding-top: ${({ p, py, pt }) => getResult(p, py, pt)};
  padding-right: ${({ p, px, pr }) => getResult(p, px, pr)};
  padding-bottom: ${({ p, py, pb }) => getResult(p, py, pb)};
  padding-left: ${({ p, px, pl }) => getResult(p, px, pl)};

  column-gap: ${({ cg }) => getResult(cg)};
  row-gap: ${({ rg }) => getResult(rg)};

  ${({ flex, alignItems, justifyContent, flexDirection }) => {
    if (!flex) {
      return 'display: block;'
    }
    return `display: flex; 
    align-items: ${alignItems || 'center'};
    justify-content: ${justifyContent || 'flex-start'};
    flex-direction: ${flexDirection || 'row'};
    `
  }}
`
