import IconPropsInterface from '@icons/IconPropsInterface'

export const SocialOk = ({ size = 20, color, style }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style, verticalAlign: 'middle' }}
    >
      <path
        d="M1.4 10C1.4 5.25036 5.25036 1.4 10 1.4C14.7496 1.4 18.6 5.25036 18.6 10C18.6 14.7496 14.7496 18.6 10 18.6C5.25036 18.6 1.4 14.7496 1.4 10Z"
        stroke={color}
        strokeWidth="0.8"
      />
      <path
        d="M7.42384 15.7204L7.81043 15.4033C7.65585 15.2148 7.65576 14.8757 7.8101 14.6871C7.81011 14.6871 7.81013 14.6871 7.81014 14.6871C7.81018 14.687 7.81022 14.687 7.81026 14.6869L9.30472 12.8649L9.79147 12.2715L9.05194 12.066C8.57263 11.9329 8.10526 11.7001 7.67011 11.3673C7.52003 11.2516 7.43109 10.9357 7.56483 10.6767L7.5652 10.6759C7.62404 10.5615 7.69742 10.5185 7.74321 10.5057C7.78401 10.4944 7.83462 10.4966 7.89584 10.5436L7.89597 10.5437C9.16989 11.5207 10.8292 11.5209 12.1037 10.5437L12.1039 10.5436C12.1651 10.4966 12.2157 10.4944 12.2566 10.5057C12.3024 10.5185 12.376 10.5616 12.4351 10.6763L12.4354 10.6768C12.5689 10.9352 12.4799 11.2519 12.3293 11.3676L12.634 11.764L12.33 11.367C11.895 11.7001 11.4275 11.9328 10.9479 12.066L10.2084 12.2715L10.6951 12.8649L12.1895 14.6871L12.1898 14.6875C12.3443 14.8754 12.3445 15.2145 12.1897 15.4031L12.1895 15.4033C12.1233 15.484 12.0605 15.5 12.0225 15.5C11.9844 15.5 11.9218 15.484 11.8558 15.4034L11.8556 15.4032L10.3862 13.6125L9.99954 13.1413L9.61305 13.6127L8.1454 15.4029C8.14534 15.4029 8.14528 15.403 8.14522 15.4031C8.07899 15.4836 8.01596 15.4998 7.97742 15.4998C7.93955 15.4998 7.87665 15.4838 7.8101 15.4029L7.42384 15.7204ZM7.42384 15.7204C7.57692 15.9066 7.77717 15.9998 7.97742 15.9998C8.178 15.9998 8.37859 15.9066 8.53167 15.7204L7.12054 10.4473C6.89009 10.8937 6.99996 11.4828 7.36575 11.764C7.69812 12.0183 8.05005 12.222 8.41438 12.3735L8.53154 12.2307L8.91813 12.5478L8.78432 13.0296C8.71416 13.0101 8.64436 12.989 8.57496 12.9662L7.4235 14.37C7.118 14.7429 7.118 15.3475 7.42384 15.7204Z"
        stroke={color}
      />
      <path
        d="M10 9.5C8.62162 9.5 7.5 8.37831 7.5 7.00046C7.5 5.6219 8.62176 4.5 10 4.5C11.3786 4.5 12.5 5.62186 12.5 7.00046C12.5 8.37835 11.3787 9.5 10 9.5ZM11.7421 7.00046C11.7421 6.0392 10.961 5.25839 10 5.25839C9.0396 5.25839 8.25785 6.03907 8.25785 7.00046C8.25785 7.96122 9.03955 8.74234 10 8.74234C10.9611 8.74234 11.7421 7.96109 11.7421 7.00046Z"
        stroke={color}
      />
    </svg>
  )
}
