import IconPropsInterface from './IconPropsInterface'

export const Pay = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 14" fill="none">
      <path
        d="M13.832 4.5H5.4987C4.57822 4.5 3.83203 5.24619 3.83203 6.16667V11.1667C3.83203 12.0871 4.57822 12.8333 5.4987 12.8333H13.832C14.7525 12.8333 15.4987 12.0871 15.4987 11.1667V6.16667C15.4987 5.24619 14.7525 4.5 13.832 4.5Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.66667 10.3333C10.5871 10.3333 11.3333 9.58714 11.3333 8.66667C11.3333 7.74619 10.5871 7 9.66667 7C8.74619 7 8 7.74619 8 8.66667C8 9.58714 8.74619 10.3333 9.66667 10.3333Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1667 4.49984V2.83317C12.1667 2.39114 11.9911 1.96722 11.6785 1.65466C11.366 1.3421 10.942 1.1665 10.5 1.1665H2.16667C1.72464 1.1665 1.30072 1.3421 0.988155 1.65466C0.675595 1.96722 0.5 2.39114 0.5 2.83317V7.83317C0.5 8.2752 0.675595 8.69912 0.988155 9.01168C1.30072 9.32424 1.72464 9.49984 2.16667 9.49984H3.83333"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
