import IconPropsInterface from './IconPropsInterface'

export const BarLog = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 14 14"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M7 4.33203V6.9987L8.33333 8.33203"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.03516 6.33338C1.18455 4.86675 1.86867 3.50645 2.95698 2.51202C4.04529 1.51759 5.46162 0.958635 6.93574 0.941798C8.40986 0.924961 9.83859 1.45142 10.9493 2.42074C12.0601 3.39005 12.7751 4.73436 12.9579 6.1972C13.1408 7.66003 12.7787 9.13899 11.9407 10.3519C11.1028 11.5648 9.84759 12.4267 8.41468 12.7733C6.98177 13.1198 5.47143 12.9267 4.17182 12.2308C2.87221 11.5348 1.8743 10.3848 1.36849 9.00005M1.03516 12.3334V9.00005H4.36849"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
