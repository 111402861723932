import moment from 'moment-timezone'
import { ReactNode, useEffect, useState } from 'react'
import { Value } from 'react-calendar/dist/cjs/shared/types'
import 'react-clock/dist/Clock.css'
import { TimePicker } from 'react-time-picker'
import 'react-time-picker/dist/TimePicker.css'
import { Value as TimePickerValue } from 'react-time-picker/src/shared/types'
import styled from 'styled-components'

import { Box } from '@ui/Box'
import {
  BaseCalendar,
  IBaseCalendarProps,
  OmitCalendarProps,
  formatValue,
} from '@ui/CalendarComponent/BaseCalendar'
import { RegularText } from '@ui/Typography'

const DEFAULT_TIME = '12:00'

export interface ICalendarWithTimeProps {
  value: Date | null
  onChange: (value: Date | null) => void
  minTime?: string
  children?: (handleClose?: () => void) => ReactNode
}

export const CalendarWithTime = ({
  value,
  onChange,
  formatString,
  minTime,
  children,
  ...rest
}: OmitCalendarProps & IBaseCalendarProps & ICalendarWithTimeProps) => {
  const [time, setTime] = useState(minTime ?? DEFAULT_TIME)

  useEffect(() => {
    if (minTime && value) {
      const [h, m] = minTime.split(':')

      const valueH = value.getHours()
      const valueM = value.getMinutes()

      if (valueH < +h || (valueH === +h && valueM < +m)) {
        handleTimeChange(minTime)
      }
    }
  }, [minTime])

  const handleDateChange = (value: Value) => {
    if (Array.isArray(value)) return

    if (value) {
      const m = moment(value)

      const [hours, minutes] = time.split(':')
      m.set('hour', Number(hours))
      m.set('minute', Number(minutes))

      onChange(m.toDate())
    } else {
      onChange(value)
      setTime(minTime ?? DEFAULT_TIME)
    }
  }

  const handleTimeChange = (timeValue: TimePickerValue) => {
    if (value) {
      const t = timeValue ?? DEFAULT_TIME
      const [hours, minutes] = t.split(':')

      const m = moment(value)

      m.set('hour', Number(hours))
      m.set('minute', Number(minutes))

      onChange(m.toDate())
    }

    setTime(timeValue ? timeValue : minTime ?? DEFAULT_TIME)
  }

  return (
    <BaseCalendar
      {...rest}
      value={value}
      onChange={handleDateChange}
      formatString={formatString ?? 'DD.MM.YYYY HH:mm'}
      selectRange={false}
    >
      {(handleClose) => (
        <>
          <Box mb={20}>
            <RegularText style={{ marginBottom: '5px' }}>
              Выберите время
            </RegularText>
            <StyledTimePicker
              onChange={handleTimeChange}
              value={
                !value ? time : formatValue({ value, formatString: 'HH:mm' })
              }
              disableClock={true}
              locale={'ru'}
              format={'HH:mm'}
              minTime={minTime}
              clearIcon={null}
            />
            {children?.(handleClose)}
          </Box>
        </>
      )}
    </BaseCalendar>
  )
}

const StyledTimePicker = styled(TimePicker)`
  input,
  span {
    outline: none;
    background: none !important;
    font-size: ${({ theme }) => theme.fontSizes.s};
    color: ${({ theme }) => theme.colors.text20};
  }

  .react-time-picker__wrapper {
    border: none;
  }

  .react-time-picker__inputGroup {
    min-width: 75px;
    text-align: center;
    box-sizing: border-box;
    padding: 5px 15px;
    border-radius: 7px;
    border: 1px solid ${({ theme }) => theme.colors.gray77};
  }
`
