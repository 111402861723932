import { useState } from 'react'
import styled from 'styled-components'

import { FIELD_IS_REQUIRED_TEXT } from '@pages/TasksBoard/ExecutorOffer/constants'
import {
  setOfferFieldErrorMessage,
  setOfferFieldValue,
} from '@pages/TasksBoard/ExecutorOffer/executorOfferSlice'
import { InputWrapper } from '@pages/TasksBoard/ExecutorOffer/inputs/InputWrapper'
import { useAppDispatch, useAppSelector } from '@src/hooks/store'
import { debounce } from '@src/utils/helpers/debounce'
import { getBgColor, getColor } from '@theme/helpers'
import { Box } from '@ui/Box'
import { CloseButton } from '@ui/Buttons/CloseButton'
import { BaseInput } from '@ui/Inputs/Base/BaseInput'
import { Spinner } from '@ui/Loader/Spinner'
import { RegularText } from '@ui/Typography'

interface IProps {
  taskId: number
  isPreviewMode: boolean
  isFirstValidation: boolean
}

const StyledSuggestionContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray77};
  border-radius: 7px;
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 1;
  width: 100%;
  ${getBgColor('light')}
  overflow: hidden;
`
export const StyledSuggestion = styled.div`
  cursor: pointer;
  ${getColor('text20')}
  padding: 7px 12px;
  &:hover {
    ${getBgColor('primaryLight')}
  }
`
export const DadataCityInput = ({
  taskId,
  isPreviewMode,
  isFirstValidation,
}: IProps) => {
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [suggestions, setSuggestions] = useState<any[]>([])
  const fieldData = useAppSelector(
    (state) => state.executorOffer.selectedTasks[taskId]['contract_city_name']
  )
  const handleResetData = () => {
    setSuggestions([])
    dispatch(
      setOfferFieldValue({
        taskId,
        fieldName: 'contract_city_name',
        value: '',
      })
    )
    dispatch(
      setOfferFieldErrorMessage({
        taskId,
        fieldName: 'contract_city_name',
        value: FIELD_IS_REQUIRED_TEXT,
      })
    )
  }

  const getSuggestions = debounce((value: string) => {
    setIsLoading(true)
    fetch(
      'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Token ${import.meta.env.VITE_DADATA_API_KEY}`,
        },
        body: JSON.stringify({
          query: value,
          count: 10,
          from_bound: { value: 'city' },
          to_bound: { value: 'city' },
        }),
      }
    )
      .then((data) => {
        return data.json()
      })
      .then((result) => {
        setSuggestions(result.suggestions)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, 500)
  const handleInputChange = (e: any) => {
    getSuggestions(e.target.value)
  }

  return (
    <InputWrapper
      title={'Город заключения договора'}
      required={true}
      isDisabled={false}
      errorMessage={fieldData.errorMessage}
    >
      {fieldData.value ? (
        <Box flex cg={10} justifyContent={'space-between'}>
          <RegularText>{fieldData.value}</RegularText>
          {!isPreviewMode && <CloseButton onClick={handleResetData} />}
        </Box>
      ) : (
        <>
          <Box flex cg={10}>
            <BaseInput
              border
              onChange={handleInputChange}
              hasError={!!fieldData.errorMessage}
              style={{ width: '100%' }}
              placeholder={'Введите название города'}
            />
            {isLoading && <Spinner />}
          </Box>
          {!!suggestions?.length && (
            <div style={{ position: 'relative' }}>
              <StyledSuggestionContainer>
                {suggestions.map(({ value, data }) => {
                  return (
                    <StyledSuggestion
                      key={value}
                      style={{ display: 'block' }}
                      onClick={() => {
                        dispatch(
                          setOfferFieldValue({
                            taskId,
                            fieldName: 'contract_city_name',
                            value: data?.city || '',
                          })
                        )
                        dispatch(
                          setOfferFieldErrorMessage({
                            taskId,
                            fieldName: 'contract_city_name',
                            value: '',
                          })
                        )
                      }}
                    >
                      {value}
                    </StyledSuggestion>
                  )
                })}
              </StyledSuggestionContainer>
            </div>
          )}
        </>
      )}
    </InputWrapper>
  )
}
