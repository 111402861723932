import IconPropsInterface from '@icons/IconPropsInterface'

export const TaskBg = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.13 11.8773L40.6225 8.36984C39.645 7.39234 38.0625 7.39234 37.0875 8.36984L10 35.4548V42.4998H17.045L44.13 15.4148C45.1075 14.4373 45.1075 12.8548 44.13 11.8773Z"
        stroke={color}
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 52.5H52.5"
        stroke="#34C759"
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.675 19.8742L32.625 12.8242"
        stroke={color}
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
