import { useGetTheme } from '@theme/hooks'
import { BaseIconButton } from '@ui/Buttons/Base/BaseIconButton'

interface IProps {
  onClick?: () => void
}

export const SelectValueRemoveButton = ({ onClick }: IProps) => {
  const theme = useGetTheme()

  return (
    <BaseIconButton
      fillVariant="close"
      sizeVariant="close"
      iconName="Close"
      iconColor={theme.colors.primary}
      hoverIconColor={theme.colors.light}
      iconSize={15}
      type={'button'}
      onClick={onClick}
    />
  )
}
