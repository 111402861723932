import { createSlice } from '@reduxjs/toolkit'
import { IObject } from '@src/services/objects/objectsApi/objectsApi'

import { LIMIT_OBJECTS, LIMIT_TASKS } from './constans'
import { Task } from './types/interfaces'

interface initialStateType {
  items: IObject[]
  page: number
  lastItemsCount: number
  tasks: Task[]
  tasksPage: number
  tasksLastItemsCount: number
  name: string
  totalCount: number | null
}

const initialState: initialStateType = {
  items: [],
  page: 1,
  lastItemsCount: LIMIT_OBJECTS,
  tasks: [],
  tasksPage: 1,
  tasksLastItemsCount: LIMIT_TASKS,
  name: '',
  totalCount: null,
}

const objectSlice = createSlice({
  name: 'object',
  initialState: initialState,
  reducers: {
    totalCountChanged: (state, { payload }) => {
      state.totalCount = payload
    },
    objectsIncrementPage: (state) => {
      state.page += 1
    },
    objectsSetLastItemsCount: (state, action) => {
      state.lastItemsCount = action.payload
    },
    objectsAddPageData: (state, action) => {
      state.items = [...state.items, ...action.payload]
    },
    objectsRemove: (state) => {
      state.items = []
      state.page = 1
      state.lastItemsCount = LIMIT_OBJECTS
      state.totalCount = null
    },
    changeSearchName: (state, action) => {
      state.name = action.payload
    },
    tasksIncrementPage: (state) => {
      state.tasksPage += 1
    },
    tasksSetLastItemsCount: (state, action) => {
      state.tasksLastItemsCount = action.payload
    },
    tasksAddPageData: (state, action) => {
      state.tasks = [...state.tasks, ...action.payload]
    },
    tasksRemove: (state) => {
      state.tasks = []
      state.tasksPage = 1
      state.tasksLastItemsCount = LIMIT_TASKS
    },
  },
})

export const {
  objectsIncrementPage,
  objectsSetLastItemsCount,
  objectsAddPageData,
  objectsRemove,
  changeSearchName,
  tasksIncrementPage,
  tasksSetLastItemsCount,
  tasksAddPageData,
  tasksRemove,
  totalCountChanged,
} = objectSlice.actions

export const objectsReducer = objectSlice.reducer
