import IconPropsInterface from '@icons/IconPropsInterface'

export const Accrual = ({ size = 20, color, style }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style, flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M11 15.9H3.9C2.85 15.9 2 15.05 2 14V4.9C2 3.85 2.85 3 3.9 3H19.1C20.15 3 21 3.85 21 4.9V10"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.1793 7.97139C14.1063 8.89839 14.1063 10.4024 13.1793 11.3304C12.2523 12.2574 10.7483 12.2574 9.82025 11.3304C8.89325 10.4034 8.89325 8.89939 9.82025 7.97139C10.7483 7.04339 12.2523 7.04339 13.1793 7.97139"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 21C15.791 21 14 19.209 14 17C14 14.791 15.791 13 18 13C20.21 13 22 14.791 22 17C22 19.209 20.21 21 18 21"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 13H5"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 6H17"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 17L16 17"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 19L18 15"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
