import IconPropsInterface from '@icons/IconPropsInterface'

export const Diagram = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.875 12.5V12.5C21.875 17.6781 17.6781 21.875 12.5 21.875V21.875C7.32187 21.875 3.125 17.6781 3.125 12.5V12.5C3.125 7.32187 7.32187 3.125 12.5 3.125V3.125C17.6781 3.125 21.875 7.32187 21.875 12.5Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.1292 19.1292L12.8052 12.8052C12.6094 12.6094 12.5 12.3448 12.5 12.0688V3.125"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5732 12.4583L20.6149 7.8125"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
