import IconPropsInterface from './IconPropsInterface'

export const Warning = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 36 36"
      fill={color}
      stroke="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <rect
        height="25.4558"
        width="25.4558"
        rx="2"
        transform="rotate(-45 0 18)"
        y="18"
      />
      <path
        d="M17.2433 20.3927L16.9553 12.7287H18.7633L18.4593 20.3927H17.2433ZM17.8673 24.0247C17.5686 24.0247 17.3179 23.9234 17.1153 23.7207C16.9126 23.518 16.8113 23.278 16.8113 23.0007C16.8113 22.702 16.9126 22.4567 17.1153 22.2647C17.3179 22.0727 17.5686 21.9767 17.8673 21.9767C18.1659 21.9767 18.4113 22.0727 18.6033 22.2647C18.7953 22.4567 18.8913 22.702 18.8913 23.0007C18.8913 23.278 18.7953 23.518 18.6033 23.7207C18.4113 23.9234 18.1659 24.0247 17.8673 24.0247Z"
        fill="white"
      />
    </svg>
  )
}
