import IconPropsInterface from './IconPropsInterface'

export const Sky = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1471_27283)">
        <path
          d="M15.8336 15C16.6071 15 17.349 14.6927 17.896 14.1457C18.4429 13.5987 18.7502 12.8569 18.7502 12.0833C18.7502 11.3098 18.4429 10.5679 17.896 10.0209C17.349 9.47396 16.6071 9.16667 15.8336 9.16667H15.0002C15.1218 8.62496 15.1237 8.06701 15.0057 7.52466C14.8877 6.98232 14.6522 6.4662 14.3125 6.00577C13.9729 5.54535 13.5359 5.14964 13.0263 4.84123C12.5168 4.53283 11.9447 4.31777 11.3428 4.20833C10.7409 4.0989 10.121 4.09723 9.51838 4.20343C8.91577 4.30962 8.34231 4.5216 7.83072 4.82726C6.79754 5.44457 6.07914 6.40598 5.83357 7.5C4.9452 7.46442 4.07152 7.72533 3.36213 8.23808C2.65274 8.75083 2.15176 9.48352 1.94496 10.3107C1.73817 11.1379 1.83843 12.0081 2.22857 12.7723C2.61871 13.5366 3.27447 14.1473 4.08357 14.5"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 18.333V10.833"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.5 13.333L10 10.833L12.5 13.333"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1471_27283">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Sky
