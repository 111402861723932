import IconPropsInterface from './IconPropsInterface'

export const Ads = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4690_20037)">
        <path
          d="M12.5003 15.8333C14.3413 15.8333 15.8337 14.3409 15.8337 12.5C15.8337 10.659 14.3413 9.16663 12.5003 9.16663C10.6594 9.16663 9.16699 10.659 9.16699 12.5C9.16699 14.3409 10.6594 15.8333 12.5003 15.8333Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.417 15.4166L17.5003 17.5"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.33301 5H16.6663"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.33301 10H6.66634"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.33301 15H6.66634"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4690_20037">
          <rect width={size} height={size} fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
