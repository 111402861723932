import { CSSProperties, ReactNode } from 'react'

import { useWindowResize } from '@src/hooks/useWindowResize'
import { useGetTheme } from '@theme/hooks'
import { Box } from '@ui/Box'
import { InputTitle, RegularText, Signature } from '@ui/Typography'

interface IProps {
  required: boolean
  title: string
  description?: string
  isDisabled?: boolean
  errorMessage?: string
  children: ReactNode
  style?: CSSProperties
}
export const InputWrapper = ({
  required,
  title,
  isDisabled,
  errorMessage,
  children,
  description,
  style,
}: IProps) => {
  const theme = useGetTheme()
  const { isMobile } = useWindowResize()
  return (
    <Box
      flex
      flexDirection={isMobile ? 'column' : 'row'}
      cg={20}
      mb={20}
      rg={10}
      style={{
        ...style,
        opacity: isDisabled ? 0.2 : 1,
        pointerEvents: isDisabled ? 'none' : 'all',
      }}
    >
      <div
        style={{
          width: isMobile ? '100%' : '49%',
        }}
      >
        <InputTitle
          style={{
            fontWeight: theme.fontWeights[required ? 'bold' : 'medium'],
          }}
        >
          {`${title}${required ? ' *' : ''}`}
        </InputTitle>
        {description && <Signature>{description}</Signature>}
      </div>

      <div style={{ width: isMobile ? '100%' : '49%' }}>
        {children}
        {errorMessage && (
          <RegularText color={'accent'}>{errorMessage}</RegularText>
        )}
      </div>
    </Box>
  )
}
