import { useGetSpaceByIdQuery } from '@pages/Space/Pages/SpaceList/SpacesService'
import { useAppSelector } from '@src/hooks/store'

export const useTimezone = () => {
  const currentId = useAppSelector((state) => state.profile.currentSpaceId)

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { data, isFetching, isError } = useGetSpaceByIdQuery(
    currentId!.toString(),
    {
      skip: !currentId,
    }
  )
  const timezone = data?.data?.timezone

  return { data, isFetching, isError, timezone }
}
