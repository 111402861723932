import IconPropsInterface from './IconPropsInterface'

export const SortUp = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill={color}
      stroke="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path
        style={{ transform: 'translateY(23%)' }}
        d="M12.701.442a.632.632 0 0 0-.21.162c-.033.05-.619.651-1.301 1.337-.681.686-1.261 1.29-1.288 1.343a.472.472 0 0 0-.037.257c.015.197.126.366.337.51.12.081.17.098.291.097a.603.603 0 0 0 .245-.052c.055-.028.447-.397.871-.818l.772-.767.007 4.361.008 4.362.063.118c.09.169.225.242.475.258.234.015.371-.02.501-.129.192-.162.178.181.193-4.617l.015-4.343.75.754c.887.891.964.943 1.254.856.145-.043.369-.245.444-.401a.48.48 0 0 0-.001-.452c-.088-.174-2.661-2.762-2.83-2.846a.62.62 0 0 0-.559.01M1.105.417C.942.475.869.531.802.644.739.751.728.805.727 1.002c-.001.193.009.251.067.353a.595.595 0 0 0 .168.183l.101.063h3.173c3.165 0 3.173 0 3.286-.059.166-.088.248-.261.248-.528 0-.318-.101-.496-.327-.58C7.376.41 6.555.401 4.25.401c-1.706 0-3.122.007-3.145.016m.028 4.997c-.313.062-.47.36-.398.756.049.262.17.385.43.432.105.019 1.197.027 3.187.022 2.755-.007 3.038-.012 3.128-.055.206-.098.288-.258.288-.562 0-.304-.082-.464-.288-.562-.09-.043-.37-.048-3.159-.052-1.704-.002-3.117.007-3.188.021m-.059 5.011a.457.457 0 0 0-.281.235c-.056.1-.067.159-.066.352.001.197.012.25.075.358a.479.479 0 0 0 .182.179c.103.051.256.054 4.059.061 4.335.009 4.094.018 4.256-.157.096-.103.136-.234.136-.443 0-.228-.039-.358-.134-.449-.181-.173.084-.163-4.221-.16-2.163.001-3.965.012-4.006.024"
        fillRule="evenodd"
      />
    </svg>
  )
}
