import IconPropsInterface from '@icons/IconPropsInterface'

export const SocialVk = ({ size = 20, color, style }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style, verticalAlign: 'middle' }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0003 18.3337C14.6027 18.3337 18.3337 14.6027 18.3337 10.0003C18.3337 5.39795 14.6027 1.66699 10.0003 1.66699C5.39795 1.66699 1.66699 5.39795 1.66699 10.0003C1.66699 14.6027 5.39795 18.3337 10.0003 18.3337Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.90368 8.03711H9.91618V13.1004H9.91452C8.87202 13.1004 7.72618 12.4671 6.91368 11.3004C5.69368 9.59044 5.36035 8.29794 5.36035 8.03794"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8819 13.0996C13.8819 13.0996 13.5136 12.3229 12.7661 11.4688C12.2677 10.8846 11.5661 10.5688 10.9277 10.5688C11.5661 10.5688 12.2677 10.2521 12.7661 9.66878C13.5136 8.81378 13.8819 8.03711 13.8819 8.03711"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9274 10.57H9.91406"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
