import { startWs } from '@pages/Chat/chatWebsocket/startWs'
import { wsConnectionTryRef } from '@pages/Chat/constants'
import { setChatStateValue } from '@pages/Chat/helpers'
import { store } from '@src/store'

export const onError = (e: any) => {
  console.dir({
    wsConnectionError: e,
    tries: wsConnectionTryRef.current,
  })

  if (wsConnectionTryRef.current) {
    wsConnectionTryRef.current--
    const currentProfileId = store.getState().profile?.id
    const userId = store.getState().user?.data?.id
    if (currentProfileId && userId) {
      startWs(currentProfileId, userId)
    }
  } else {
    setChatStateValue('isSocketOnline')(false)
  }
}
