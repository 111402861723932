import IconPropsInterface from './IconPropsInterface'

export const FavoriteFilled = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6437_94561)">
        <path
          d="M11.9969 18.2519L5.82491 21.4969L7.00391 14.6239L2.00391 9.75691L8.90391 8.75691L11.9899 2.50391L15.0759 8.75691L21.9759 9.75691L16.9759 14.6239L18.1549 21.4969L11.9969 18.2519Z"
          fill={color}
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6437_94561">
          <rect
            width={24}
            height={24}
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
