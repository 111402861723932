import IconPropsInterface from './IconPropsInterface'

export const SelectItem = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 16 16"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M6.33229 15.3143C5.60696 15.1498 4.91036 14.8776 4.26562 14.5068"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.66797 0.685547C11.3248 1.06394 12.804 1.99362 13.8635 3.32239C14.923 4.65115 15.5 6.30026 15.5 7.99971C15.5 9.69917 14.923 11.3483 13.8635 12.677C12.804 14.0058 11.3248 14.9355 9.66797 15.3139"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.81547 12.244C1.36089 11.5835 1.01564 10.8542 0.792969 10.084"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.601562 6.74993C0.734896 5.95827 0.991563 5.20827 1.35156 4.52077L1.4924 4.2666"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.75391 1.81555C4.53362 1.2788 5.40834 0.895311 6.33141 0.685547"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 7.99967L7.16667 9.66634L10.5 6.33301"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
