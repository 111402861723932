import { FormikValues } from 'formik'

import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface initialStateType {
  application: {
    ownership_type_id: number
    employee_id?: number
    payment_method: string
    only_favorites: boolean
    favorite_ownership_type_ids: number[]
    enable_rostechnadsor?: boolean
    deferred_payment?: number
    start_from?: string
    auto_offer_enabled?: boolean
  }
  tasks: FormikValues[]
  isApplicationFilled: boolean
  isShowMovingTaskHeader: boolean // если у нас отображается MovingHeader, скрываем box-shadow в моб header
  applicationId: number
}

const initialState: initialStateType = {
  application: {
    ownership_type_id: 0,
    employee_id: -1,
    payment_method: 'cash',
    only_favorites: false,
    enable_rostechnadsor: false,
    deferred_payment: 0,
    favorite_ownership_type_ids: [],
    start_from: '',
    auto_offer_enabled: true,
  },
  tasks: [],
  isApplicationFilled: false,
  isShowMovingTaskHeader: false,
  applicationId: 0,
}

const applicationSlice = createSlice({
  name: 'application',
  initialState: initialState,
  reducers: {
    // application - создание заявки
    setOwnershipTypeId: (state, action) => {
      state.application.ownership_type_id = action.payload
    },
    setEmployeeId: (state, action) => {
      state.application.employee_id = action.payload
    },
    setPaymentMethod: (state, action) => {
      if (state.application.payment_method !== action.payload) {
        state.application.payment_method = action.payload
      }
    },
    setOnlyFavorites: (state, action) => {
      state.application.only_favorites = action.payload
    },
    setOwnershipFavorites: (state, action) => {
      state.application.favorite_ownership_type_ids = action.payload
    },
    setDefferedPayment: (state, action) => {
      state.application.deferred_payment = action.payload
    },
    setAutoOfferEnabled: (state, action) => {
      state.application.auto_offer_enabled = action.payload
    },
    changeApplicationFilled: (state, action) => {
      state.isApplicationFilled = action.payload
    },
    enableRostechnadsorChanged: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.application.enable_rostechnadsor = payload
    },
    startFromChanged: (state, { payload }: PayloadAction<string>) => {
      state.application.start_from = payload
    },
    applicationReset: (state) => {
      state.application = initialState.application
    },
    setIsShowMovingTaskHeader: (state, action) => {
      state.isShowMovingTaskHeader = action.payload
    },
    setNewTask: (state, action) => {
      if (state.tasks?.length) {
        state.tasks = [...state.tasks, action.payload]
      } else {
        state.tasks = [action.payload]
      }
    },
    resetTasks: (state, action) => {
      if (state.tasks?.length) {
        state.tasks = []
      }
    },
    deleteTask: (state, action: PayloadAction<{ index: number }>) => {
      const { index } = action.payload
      const updateTasks = state.tasks?.filter((item, i) => i !== index)

      if (updateTasks) {
        state.tasks = updateTasks
      }
    },
    resetAllTasks: (state) => {
      if (state.tasks?.length) {
        state.tasks = []
      }
    },

    setApplicationId: (state, action) => {
      state.applicationId = action.payload
    },
    setFieldValue: (state, action) => {
      const { index, field, value } = action.payload

      const task = state.tasks[index]
      if (task) {
        task[field] = value
      }
    },
    setFieldCoordinatesValue: (state, action) => {
      const { index, value, indexPoints, pointsField } = action.payload

      const task = state.tasks[index]
      if (task) {
        const point = task?.points[indexPoints]
        point[pointsField] = value
      }
    },
    setFieldCharacteristicValue: (state, action) => {
      const { index, value, charId } = action.payload

      const task = state.tasks[index]
      if (task) {
        task.characteristics[charId] = value
      }
    },
    setAddNewPoint: (state, action) => {
      const { index, value } = action.payload

      const task = state.tasks[index]
      if (task) {
        const insertionIndex = task.points.length - 1
        task.points.splice(insertionIndex, 0, value)
      }
    },
    deleteTaskPoint: (state, action) => {
      const { index, indexPoint } = action.payload

      const task = state.tasks[index]
      if (task) {
        const points = task.points.filter(
          (_: any, i: number) => i !== indexPoint
        )
        task.points = points
      }
    },
  },
})

export const {
  setDefferedPayment,
  setOwnershipTypeId,
  setEmployeeId,
  setOnlyFavorites,
  setPaymentMethod,
  setOwnershipFavorites,
  changeApplicationFilled,
  enableRostechnadsorChanged,
  applicationReset,
  startFromChanged,
  setIsShowMovingTaskHeader,
  setNewTask,
  deleteTask,
  resetAllTasks,
  resetTasks,
  setAutoOfferEnabled,
  setApplicationId,
  setFieldValue,
  setFieldCoordinatesValue,
  setFieldCharacteristicValue,
  setAddNewPoint,
  deleteTaskPoint,
} = applicationSlice.actions

export const applicationReducer = applicationSlice.reducer
