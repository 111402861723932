import IconPropsInterface from '@icons/IconPropsInterface'

export const Messages = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.88867 1H2.11133C1.49733 1 1 1.49733 1 2.11133V7.66667"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 13L9.11133 10.778H11.8893C12.5027 10.778 13 10.28 13 9.66667V4.11133C13 3.49733 12.5027 3 11.8887 3H4.11133C3.49733 3 3 3.49733 3 4.11133V9.66667C3 10.28 3.49733 10.778 4.11133 10.778H6.33333"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.66602 5.66732H10.3327"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.66602 8.33333H8.33268"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
