export const fontSizes = {
  xxxs: '8px',
  xxs: '10px',
  xs: '12px',
  s: '14px',
  m: '16px',
  l: '18px',
  xl: '20px',
  xxl: '24px',
  xxxl: '32px',
  '4xl': '42px',
}
