import IconPropsInterface from './IconPropsInterface'

export const Hamburger = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 16 9"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path d="M1 0.5H15M1 4.5H15M1 8.5H15" strokeLinecap="round" />
    </svg>
  )
}
