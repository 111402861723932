import IconPropsInterface from '@icons/IconPropsInterface'

export const Filter = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4568_79637)">
        <path
          d="M9.33333 5.33329C10.0697 5.33329 10.6667 4.73634 10.6667 3.99996C10.6667 3.26358 10.0697 2.66663 9.33333 2.66663C8.59695 2.66663 8 3.26358 8 3.99996C8 4.73634 8.59695 5.33329 9.33333 5.33329Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.66699 4H8.00033"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.667 4H13.3337"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.33333 9.33329C6.06971 9.33329 6.66667 8.73634 6.66667 7.99996C6.66667 7.26358 6.06971 6.66663 5.33333 6.66663C4.59695 6.66663 4 7.26358 4 7.99996C4 8.73634 4.59695 9.33329 5.33333 9.33329Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.66699 8H4.00033"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.66699 8H13.3337"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.3333 13.3333C12.0697 13.3333 12.6667 12.7363 12.6667 12C12.6667 11.2636 12.0697 10.6666 11.3333 10.6666C10.597 10.6666 10 11.2636 10 12C10 12.7363 10.597 13.3333 11.3333 13.3333Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.66699 12H10.0003"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.667 12H13.3337"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4568_79637">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
