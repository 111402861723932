import IconPropsInterface from './IconPropsInterface'

export const AddImage = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 26 26"
      fill={color}
      stroke="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path d="M25.072 20.4291H22.2863V17.6434C22.2863 17.3971 22.1884 17.161 22.0143 16.9868C21.8402 16.8127 21.604 16.7148 21.3577 16.7148C21.1114 16.7148 20.8752 16.8127 20.7011 16.9868C20.527 17.161 20.4291 17.3971 20.4291 17.6434V20.4291H17.6434C17.3971 20.4291 17.161 20.527 16.9868 20.7011C16.8127 20.8752 16.7148 21.1114 16.7148 21.3577C16.7148 21.604 16.8127 21.8402 16.9868 22.0143C17.161 22.1884 17.3971 22.2863 17.6434 22.2863H20.4291V25.072C20.4291 25.3183 20.527 25.5544 20.7011 25.7286C20.8752 25.9027 21.1114 26.0006 21.3577 26.0006C21.604 26.0006 21.8402 25.9027 22.0143 25.7286C22.1884 25.5544 22.2863 25.3183 22.2863 25.072V22.2863H25.072C25.3183 22.2863 25.5544 22.1884 25.7286 22.0143C25.9027 21.8402 26.0006 21.604 26.0006 21.3577C26.0006 21.1114 25.9027 20.8752 25.7286 20.7011C25.5544 20.527 25.3183 20.4291 25.072 20.4291Z" />
      <path d="M13.9286 20.4286H2.78571C2.53944 20.4286 2.30326 20.3307 2.12912 20.1566C1.95497 19.9825 1.85714 19.7463 1.85714 19.5V2.78571C1.85714 2.53944 1.95497 2.30326 2.12912 2.12912C2.30326 1.95497 2.53944 1.85714 2.78571 1.85714H19.5C19.7463 1.85714 19.9825 1.95497 20.1566 2.12912C20.3307 2.30326 20.4286 2.53944 20.4286 2.78571V13.9286C20.4286 14.1748 20.5264 14.411 20.7005 14.5852C20.8747 14.7593 21.1109 14.8571 21.3571 14.8571C21.6034 14.8571 21.8396 14.7593 22.0137 14.5852C22.1879 14.411 22.2857 14.1748 22.2857 13.9286V2.78571C22.2857 2.0469 21.9922 1.33834 21.4698 0.815917C20.9474 0.293494 20.2388 0 19.5 0H2.78571C2.0469 0 1.33834 0.293494 0.815917 0.815917C0.293494 1.33834 0 2.0469 0 2.78571V19.5C0 20.2388 0.293494 20.9474 0.815917 21.4698C1.33834 21.9922 2.0469 22.2857 2.78571 22.2857H13.9286C14.1748 22.2857 14.411 22.1879 14.5852 22.0137C14.7593 21.8396 14.8571 21.6034 14.8571 21.3571C14.8571 21.1109 14.7593 20.8747 14.5852 20.7005C14.411 20.5264 14.1748 20.4286 13.9286 20.4286Z" />
      <path d="M8.35658 8.3577C9.63867 8.3577 10.678 7.31836 10.678 6.03627C10.678 4.75418 9.63867 3.71484 8.35658 3.71484C7.0745 3.71484 6.03516 4.75418 6.03516 6.03627C6.03516 7.31836 7.0745 8.3577 8.35658 8.3577Z" />
      <path d="M4.9127 11.4119L3.71484 12.6191V18.5712H18.572V12.6191L14.5884 8.62623C14.5021 8.5392 14.3994 8.47012 14.2862 8.42298C14.1731 8.37583 14.0517 8.35156 13.9291 8.35156C13.8065 8.35156 13.6852 8.37583 13.572 8.42298C13.4589 8.47012 13.3562 8.5392 13.2698 8.62623L8.3577 13.5477L6.23127 11.4119C6.14495 11.3249 6.04225 11.2558 5.92909 11.2087C5.81594 11.1615 5.69457 11.1373 5.57199 11.1373C5.4494 11.1373 5.32803 11.1615 5.21488 11.2087C5.10172 11.2558 4.99902 11.3249 4.9127 11.4119Z" />
    </svg>
  )
}
