import IconPropsInterface from '@icons/IconPropsInterface'

export const DocumentPlus = ({
  size = 20,
  color,
  style,
}: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style, flexShrink: 0, transition: '300ms' }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33203 2.49996C3.33203 2.03973 3.70513 1.66663 4.16536 1.66663H10.1529C11.6319 1.66663 12.7877 2.94367 12.6404 4.41538L12.5189 5.6306C12.5084 5.73538 12.5966 5.8235 12.7014 5.81303L13.9166 5.6915C15.3883 5.54433 16.6654 6.70004 16.6654 8.17909V17.5C16.6654 17.9602 16.2923 18.3333 15.832 18.3333H4.16536C3.70513 18.3333 3.33203 17.9602 3.33203 17.5V2.49996ZM10.7279 15.83H9.26953V13.2335H6.66536V11.7751H9.26953V9.17313H10.7279V11.7751H13.332V13.2335H10.7279V15.83Z"
        fill={color}
      />
    </svg>
  )
}
