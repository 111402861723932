import IconPropsInterface from './IconPropsInterface'

export const BarAnswer = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 12 13"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M11.3359 11.9987V3.33203C11.3359 2.8016 11.1252 2.29289 10.7502 1.91782C10.3751 1.54275 9.86637 1.33203 9.33594 1.33203H2.66927C2.13884 1.33203 1.63013 1.54275 1.25506 1.91782C0.879984 2.29289 0.669271 2.8016 0.669271 3.33203V7.33203C0.669271 7.86246 0.879984 8.37117 1.25506 8.74625C1.63013 9.12132 2.13884 9.33203 2.66927 9.33203H8.66927L11.3359 11.9987Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.66406 4H3.33073"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.66406 6.66797H4.66406"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
