import IconPropsInterface from '@icons/IconPropsInterface'

interface IProps extends IconPropsInterface {
  questionColor?: string
}

export const QuestionCircle = ({
  size = 20,
  color = '#D9D9D9',
  questionColor = 'hsla(0, 0%, 52%, 1)',
}: IProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="10.0012"
        cy="10.5012"
        r="7.50312"
        fill={color}
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.07031 8.57019C8.26783 7.71889 9.0432 7.12864 9.91636 7.16488C10.8936 7.11076 11.7313 7.85542 11.7921 8.83224C11.7921 10.086 9.99972 10.4996 9.99972 11.3333"
        stroke={questionColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.105 13.6258C10.105 13.6834 10.0584 13.73 10.0008 13.73C9.94327 13.73 9.89661 13.6834 9.89661 13.6258C9.89661 13.5683 9.94327 13.5216 10.0008 13.5216"
        stroke={questionColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.9995 13.5229C10.0571 13.5229 10.1037 13.5696 10.1037 13.6271"
        stroke={questionColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
