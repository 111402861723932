import IconPropsInterface from './IconPropsInterface'

export const Doc1 = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4235_45290)">
        <path
          d="M12.7266 3.24219V6.55482C12.7266 6.77446 12.8223 6.9851 12.9928 7.14041C13.1633 7.29572 13.3945 7.38297 13.6357 7.38297H17.272"
          stroke={color}
          strokeWidth="0.833333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.54688 7.38297V4.8985C4.54688 4.45922 4.73843 4.03793 5.07941 3.72731C5.42038 3.41669 5.88284 3.24219 6.36506 3.24219H12.7287L17.2741 7.38297V16.4927C17.2741 16.932 17.0826 17.3533 16.7416 17.6639C16.4006 17.9745 15.9382 18.149 15.456 18.149H10.9105"
          stroke={color}
          strokeWidth="0.833333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.45384 14.8371C6.96007 14.8371 8.18111 13.7248 8.18111 12.3526C8.18111 10.9805 6.96007 9.86816 5.45384 9.86816C3.9476 9.86816 2.72656 10.9805 2.72656 12.3526C2.72656 13.7248 3.9476 14.8371 5.45384 14.8371Z"
          stroke={color}
          strokeWidth="0.833333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.0902 14.8369L2.72656 18.9777L5.45383 17.7355L8.18111 18.9777L6.81747 14.8369"
          stroke={color}
          strokeWidth="0.833333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4235_45290">
          <rect
            width="21.8182"
            height="19.8758"
            fill="white"
            transform="translate(0 0.757812)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
