import {
  IDriverAttachContent,
  IVehicleAttachContent,
} from '@pages/Chat/interfaces'
import {
  checkIsToday,
  checkIsYesterday,
  getDateSpaceGMT,
} from '@src/utils/helpers/dateUtils'
import { handleValueFabric } from '@src/utils/helpers/handlerFabrics'
import { Rating } from '@ui/Rating'

import { setValueInStateChat } from './chatSlice/chatSlice'

export const getDateString = (date: string) => {
  const currentDate = getDateSpaceGMT(date)
  const fullDate = currentDate.format('DD.MM.YY')
  let monthAndDay

  if (checkIsToday(currentDate)) {
    monthAndDay = 'Сегодня'
  } else if (checkIsYesterday(currentDate)) {
    monthAndDay = 'Вчера'
  } else {
    monthAndDay = currentDate.format('DD MMMM')
  }

  const time = currentDate.format('H:mm')

  return { fullDate, monthAndDay, time }
}

export const msToMinSecString = (milliseconds: number): string => {
  const seconds = Math.floor(milliseconds / 1000)
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60
  return minutes + ':' + ('0' + remainingSeconds).slice(-2)
}

export const reduceNameWithExtension = (name: string, maxLength: number) => {
  const nameArray = name.split('.')
  const fileExtension = nameArray.pop()
  const fileName = nameArray.join('.')
  const readyFileName =
    fileName.length > maxLength
      ? `${fileName.slice(0, maxLength)}...`
      : fileName
  return `${readyFileName}.${fileExtension}`
}

export const setChatStateValue = handleValueFabric(setValueInStateChat)

export const getAttachDriverContent = (data: IDriverAttachContent) => {
  const name = `${data.first_name} ${data.last_name}`
  const avatar = data.avatar || ''
  const id = data.id
  const description = data.rating ? (
    <Rating rating={data.rating} starsOnly />
  ) : null
  return { id, name, avatar, description }
}

export const getAttachVehicleContent = (data: IVehicleAttachContent) => {
  const id = data.id
  const name = `${data.brand.name} ${data.name}`
  const avatar = data?.image_gallery
    ? data.image_gallery.find((item) => item.is_primary)?.media?.public_url ||
      ''
    : ''
  const description = null

  return { id, name, avatar, description }
}

export const getMessageAuthorDescription = (
  ownershipName: string,
  workPosition: string
): string => {
  if (!ownershipName && !workPosition) {
    return ''
  } else if (!ownershipName && workPosition) {
    return workPosition
  } else if (ownershipName && !workPosition) {
    return ownershipName
  }
  return `${ownershipName}, ${workPosition}`
}
