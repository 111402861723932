import IconPropsInterface from './IconPropsInterface'

export const Driver = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 12 16"
      fill="none"
      stroke={color}
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M6 7C7.65685 7 9 5.65685 9 4C9 2.34315 7.65685 1 6 1C4.34315 1 3 2.34315 3 4C3 5.65685 4.34315 7 6 7Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 15V13.3333C1 12.4493 1.35119 11.6014 1.97631 10.9763C2.60143 10.3512 3.44928 10 4.33333 10H7.66667C8.55072 10 9.39857 10.3512 10.0237 10.9763C10.6488 11.6014 11 12.4493 11 13.3333V15"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
