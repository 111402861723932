import { CSSProperties, ChangeEvent, ReactNode, useEffect } from 'react'
import styled from 'styled-components'

import { useToggle } from '@src/hooks/useToggle'
import { getBgColor, getColor, media } from '@theme/helpers'

interface IProps {
  onChange: (e: ChangeEvent) => void
  isChecked: boolean
  isDisabled?: boolean
  children?: ReactNode
  style?: CSSProperties
  hasError?: boolean
  className?: string
  top?: number
  name?: string
}

const StyledLabel = styled.label<{ isDisabled?: boolean }>`
  ${media(
    'xlarge',
    (props) => `
  font-size: ${props.theme.fontSizes.s}
  `
  )}
  display: block;
  padding-left: 30px;
  position: relative;
  ${getColor('text20')}
  opacity: ${({ isDisabled }) => (isDisabled ? '0.7' : 1)};
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'all')};
  font-size: ${(props) => props.theme.fontSizes.xs};

  ${media(
    'small',
    () => `
     padding-left: 25px;
  `
  )}
`

const StyledHiddenCheckbox = styled.input.attrs({ type: 'checkbox' })<{
  checked: boolean
  isHovered: boolean
  hasError: boolean
  top: number
}>`
  box-sizing: border-box;
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);

  & + div {
    position: absolute;
    top: ${({ top }) => top}px;
    left: 0;
    display: block;
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    border-radius: 1px;
    transition: 300ms;
    background-color: ${({ theme, isHovered }) =>
      theme.colors[isHovered ? 'primary' : 'light']};
    border: 1px solid
      ${({ theme, isHovered, hasError }) =>
        !hasError ? theme.colors[isHovered ? 'primary' : 'gray75'] : 'red'};

    ${media(
      'small',
      () => `
     top: calc((100% - 16px) / 2);
  `
    )}
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 2px;
      left: 2px;
      border-radius: 1px;
      opacity: 0;
      ${getBgColor('primary')}
      width: 10px;
      height: 10px;
      transition: opacity 300ms;
    }
  }

  &:checked + div:after {
    opacity: 1;
  }
  &:focus + div {
    box-shadow: inset -2px -2px 3px ${({ theme }) => theme.colors.primary},
      inset 2px 2px 3px ${({ theme }) => theme.colors.primary} !important;
  }
`

export const Checkbox = ({
  onChange,
  isChecked,
  isDisabled,
  children,
  style,
  hasError,
  className,
  top = 2,
  name,
}: IProps) => {
  const { isOn: isHovered, on: hover, off: unhover } = useToggle(false)

  useEffect(() => {
    //чтобы бэкграунд не закрывал сам квадратик чека и сразу после клика было видно результат
    unhover()
  }, [isChecked])
  return (
    <StyledLabel
      onMouseEnter={hover}
      onMouseLeave={unhover}
      isDisabled={isDisabled}
      style={style}
      className={className}
    >
      <StyledHiddenCheckbox
        isHovered={isHovered}
        checked={isChecked}
        hasError={hasError ? hasError : false}
        disabled={isDisabled}
        onChange={onChange}
        top={top}
        name={name}
      />
      <div />
      {children}
    </StyledLabel>
  )
}
