import { useEffect, useState } from 'react'

import {
  StyledTitleLevel1,
  StyledTitleLevel2,
} from '@pages/TasksBoard/ExecutorOffer/StyledTitles'
import {
  setOfferFieldIsDisabled,
  setOfferFieldValue,
} from '@pages/TasksBoard/ExecutorOffer/executorOfferSlice'
import { usePrepaymentAmount } from '@pages/TasksBoard/ExecutorOffer/hooks'
import {
  DateInput,
  NumberInput,
  SelectEmployee,
  SelectPeriod,
  SelectVehicleConfiguration,
  TextInput,
} from '@pages/TasksBoard/ExecutorOffer/inputs'
import { DadataCityInput } from '@pages/TasksBoard/ExecutorOffer/inputs/DadataCityInput'
import { InfoField } from '@pages/TasksBoard/ExecutorOffer/inputs/InfoField'
import { SelectEmployeeCustomer } from '@pages/TasksBoard/ExecutorOffer/inputs/SelectEmployeeCustomer'
import { ITaskListItemViewData } from '@pages/TasksBoard/interfaces'
import { useAppDispatch, useAppSelector } from '@src/hooks/store'
import { Checkbox } from '@ui/Checkbox'
import { RegularText } from '@ui/Typography'

interface IProps {
  taskId: number
  data: ITaskListItemViewData | undefined
}
export const EditTaskRent = ({ taskId, data }: IProps) => {
  const dispatch = useAppDispatch()
  const travelExpensesInclude = useAppSelector(
    (state) =>
      state.executorOffer.selectedTasks[taskId]['travel_expanses_include'].value
  )

  //const [driverCostIsIncluded, setDriverCostIsIncluded] = useState(true)
  const [deferredPaymentIsActive, setDeferredPaymentIsActive] = useState(false)
  const [prepaymentAmountIsActive, setPrepaymentAmountIsActive] =
    useState(false)

  const withoutDriver = data?.details.without_driver

  const deferredPayment = data?.project.deferred_payment || ''

  const vehicleTypes = data?.details?.vehicle_types?.map((item) => item.id) || [
    0,
  ]

  const dateTimeStart = '' //TODO уточнить, откуда брать эти данные в модели data
  // const customerPayFuel = useAppSelector(
  //   (state) =>
  //     state.executorOffer.selectedTasks[taskId]['customer_pay_fuel'].value
  // )

  const handleDriverCostCheck = (e: any) => {
    const checked = e.target.checked
    dispatch(
      setOfferFieldIsDisabled({
        taskId,
        fieldName: 'travel_expenses_cost',
        value: checked,
      })
    )
    dispatch(
      setOfferFieldIsDisabled({
        taskId,
        fieldName: 'travel_expenses_period',
        value: checked,
      })
    )
    dispatch(
      setOfferFieldValue({
        taskId,
        fieldName: 'travel_expanses_include',
        value: checked,
      })
    )
  }

  const handlePrepaymentAmountIsActive = () => {
    if (prepaymentAmountIsActive) {
      dispatch(
        setOfferFieldValue({
          taskId,
          fieldName: 'prepayment_amount',
          value: '',
        })
      )
    }
    setPrepaymentAmountIsActive((prev) => !prev)
  }

  const handleDeferredPaymentIsActive = () => {
    dispatch(
      setOfferFieldValue({
        taskId,
        fieldName: 'deferred_payment',
        value: deferredPaymentIsActive ? '' : deferredPayment,
      })
    )

    setDeferredPaymentIsActive((prev) => !prev)
  }

  useEffect(() => {
    if (data) {
      dispatch(
        setOfferFieldValue({
          taskId,
          fieldName: 'without_driver',
          value: withoutDriver,
        })
      )
      dispatch(
        setOfferFieldValue({
          taskId,
          fieldName: 'deferred_payment',
          value: deferredPayment,
        })
      )
      // dispatch(
      //   setOfferFieldValue({
      //     taskId,
      //     fieldName: 'fuel_cost_period',
      //     value: 'hour',
      //   })
      // )
      dispatch(
        setOfferFieldValue({
          taskId,
          fieldName: 'travel_expenses_period',
          value: 'hour',
        })
      )
      dispatch(
        setOfferFieldValue({
          taskId,
          fieldName: 'date_time_start',
          value: dateTimeStart,
        })
      )
      // dispatch(
      //   setOfferFieldIsDisabled({
      //     taskId,
      //     fieldName: 'travel_expenses_cost',
      //     value: withoutDriver,
      //   })
      // )
      // dispatch(
      //   setOfferFieldIsDisabled({
      //     taskId,
      //     fieldName: 'travel_expenses_period',
      //     value: withoutDriver,
      //   })
      // )
      dispatch(
        setOfferFieldIsDisabled({
          taskId,
          fieldName: 'deny_execution_days',
          value: withoutDriver,
        })
      )
      dispatch(
        setOfferFieldIsDisabled({
          taskId,
          fieldName: 'deny_execution_percent',
          value: withoutDriver,
        })
      )
    }
  }, [data, deferredPayment, dispatch, taskId, withoutDriver])

  const { isFirstValidation, isPreviewMode } = useAppSelector(
    (state) => state.executorOffer
  )

  const { handler, showPrepaymentDays } = usePrepaymentAmount(taskId)

  return (
    <div>
      <StyledTitleLevel1>Основная информация</StyledTitleLevel1>
      <SelectVehicleConfiguration
        taskId={taskId}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
        taskVehicleTypes={vehicleTypes}
      />
      <NumberInput
        taskId={taskId}
        fieldName={'cost'}
        title={'Ставка руб./час'}
        description={''}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
      />
      <NumberInput
        taskId={taskId}
        fieldName={'cost_delivery'}
        title={'Стоимость доставки, руб.'}
        description={''}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
      />
      {!withoutDriver && (
        <>
          <InfoField
            title={'Стоимость питания и проживания'}
            style={{ marginBottom: '30px' }}
          >
            <Checkbox
              onChange={handleDriverCostCheck}
              isChecked={travelExpensesInclude}
              top={3}
            >
              <RegularText>
                {`${
                  travelExpensesInclude ? 'Включена' : 'Не включена'
                } в стоимость аренды`}
              </RegularText>
            </Checkbox>
          </InfoField>
          {!travelExpensesInclude && (
            <SelectPeriod
              taskId={taskId}
              isPreviewMode={isPreviewMode}
              isFirstValidation={isFirstValidation}
              title={'Стоимость питания и проживания, руб'}
              costFieldName={'travel_expenses_cost'}
              periodFieldName={'travel_expenses_period'}
            />
          )}
        </>
      )}
      <SelectEmployeeCustomer taskId={taskId} isPreviewMode={isPreviewMode} />
      {/*{!customerPayFuel && (*/}
      {/*  <SelectPeriod*/}
      {/*    taskId={taskId}*/}
      {/*    isPreviewMode={isPreviewMode}*/}
      {/*    isFirstValidation={isFirstValidation}*/}
      {/*    title={'Стоимость ГСМ, руб'}*/}
      {/*    costFieldName={'fuel_cost'}*/}
      {/*    periodFieldName={'fuel_cost_period'}*/}
      {/*  />*/}
      {/*)}*/}
      <DateInput
        taskId={taskId}
        fieldName={'date_time_start'}
        title={'Начало проведения работ'}
        isFirstValidation={isFirstValidation}
        isPreviewMode={isPreviewMode}
      />
      <NumberInput
        taskId={taskId}
        fieldName={'working_time'}
        title={'Предполагаемое время работы, ч'}
        description={''}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
      />
      <InfoField title={'Предоплата'} style={{ marginBottom: '30px' }}>
        <Checkbox
          onChange={handlePrepaymentAmountIsActive}
          isChecked={prepaymentAmountIsActive}
          top={3}
        >
          <RegularText>Предусмотрена</RegularText>
        </Checkbox>
        <Checkbox
          onChange={handlePrepaymentAmountIsActive}
          isChecked={!prepaymentAmountIsActive}
          top={3}
        >
          <RegularText>Не предусмотрена</RegularText>
        </Checkbox>
      </InfoField>
      {prepaymentAmountIsActive && (
        <NumberInput
          taskId={taskId}
          additionalHandler={handler}
          fieldName={'prepayment_amount'}
          title={'Сумма предоплаты, руб.'}
          description={''}
          isPreviewMode={isPreviewMode}
          isFirstValidation={isFirstValidation}
        />
      )}
      <InfoField title={'Отсрочка оплаты'} style={{ marginBottom: '25px' }}>
        <Checkbox
          onChange={handleDeferredPaymentIsActive}
          isChecked={deferredPaymentIsActive}
          top={3}
        >
          <RegularText>Предусмотрена</RegularText>
        </Checkbox>
        <Checkbox
          onChange={handleDeferredPaymentIsActive}
          isChecked={!deferredPaymentIsActive}
          top={3}
        >
          <RegularText>Не предусмотрена</RegularText>
        </Checkbox>
      </InfoField>
      {deferredPaymentIsActive && (
        <NumberInput
          taskId={taskId}
          fieldName={'deferred_payment'}
          title={'Отсрочка оплаты, дней'}
          description={''}
          isPreviewMode={isPreviewMode}
          isFirstValidation={isFirstValidation}
        />
      )}

      <SelectEmployee
        taskId={taskId}
        fieldName={'employee_id'}
        additionalFieldName={'responsible_person'}
        title={'Ответственный сотрудник по задаче'}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
      />
      <StyledTitleLevel1>Данные для договора</StyledTitleLevel1>
      <DadataCityInput
        taskId={taskId}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
      />
      <NumberInput
        taskId={taskId}
        fieldName={'contract_period'}
        title={'Срок действия договора, мес.'}
        description={''}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
      />
      <NumberInput
        taskId={taskId}
        fieldName={'working_time_min'}
        title={'Минимальный срок аренды, ч.'}
        description={''}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
      />
      {showPrepaymentDays && (
        <NumberInput
          taskId={taskId}
          fieldName={'prepayment_days'}
          title={'Срок внесения предоплаты, дней'}
          description={
            'Количество дней на внесение предоплаты с момента получения счета.'
          }
          isPreviewMode={isPreviewMode}
          isFirstValidation={isFirstValidation}
        />
      )}
      <TextInput
        taskId={taskId}
        fieldName={'responsible_person'}
        title={'Ответственное лицо по договору'}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
      />
      {!withoutDriver && (
        <>
          <StyledTitleLevel2>
            Неустойка за отказ от исполнения
          </StyledTitleLevel2>
          <NumberInput
            taskId={taskId}
            fieldName={'deny_execution_days'}
            title={'Срок оповещения об отказе от Договора без неустойки, дней'}
            description={'До времени предоставления техники'}
            isPreviewMode={isPreviewMode}
            isFirstValidation={isFirstValidation}
          />
          <NumberInput
            taskId={taskId}
            fieldName={'deny_execution_percent'}
            title={'Неустойка при нарушении сроков оповещения об отказе, %'}
            description={'От суммы минимального времени работы'}
            isPreviewMode={isPreviewMode}
            isFirstValidation={isFirstValidation}
          />
        </>
      )}

      <StyledTitleLevel2>Неустойка за простой техники</StyledTitleLevel2>
      <NumberInput
        taskId={taskId}
        fieldName={'idle_vehicle_min_hours'}
        title={'Количество часов простоя без неустойки'}
        description={''}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
      />
      <NumberInput
        taskId={taskId}
        fieldName={'idle_vehicle_percent'}
        title={'Размер неустойки, %.'}
        description={'От ставки в час'}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
      />
      <NumberInput
        taskId={taskId}
        fieldName={'idle_vehicle_max_hours'}
        title={'Максимальное количество часов простоя'}
        description={''}
        isPreviewMode={isPreviewMode}
        isFirstValidation={isFirstValidation}
      />
    </div>
  )
}
