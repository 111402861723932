import IconPropsInterface from './IconPropsInterface'

export const Check = ({ size = 20, color, style }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 16 16"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{
        flexShrink: 0,
        transition: '300ms',
        verticalAlign: 'middle',
        ...style,
      }}
    >
      <path
        d="M3 7.5L5.54823 10.1756C5.96441 10.6126 6.66991 10.5842 7.04961 10.1152L12 4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
