import { createFieldsModel } from '@pages/TasksBoard/ExecutorOffer/fields/createFieldsModel'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { TaskType } from '@src/shared/interfaces/task/TaskType'

const initialState: {
  projectId: number | null
  modalContentStep: number
  bankAccountId: number
  ownershipTypeId: number
  validUntil: Date | null
  isPreviewMode: boolean
  isFirstValidation: boolean
  allTasks: {
    id: number
    title: string
    description: string
    taskType: TaskType
    isActive: boolean
  }[]
  selectedTasks: any
} = {
  projectId: null,
  bankAccountId: 0,
  ownershipTypeId: 0,
  validUntil: null,
  isPreviewMode: false,
  isFirstValidation: true,
  modalContentStep: 0,
  allTasks: [],
  selectedTasks: {},
}

const executorOfferSlice = createSlice({
  name: 'executorOffer',
  initialState,
  reducers: {
    incrementModalContentStep: (state) => {
      state.modalContentStep += 1
    },
    decrementModalContentStep: (state) => {
      state.modalContentStep -= 1
    },
    resetExecutorOffer: (state) => {
      state.bankAccountId = 0
      state.ownershipTypeId = 0
      state.validUntil = null
      state.isPreviewMode = false
      state.isFirstValidation = true
      state.modalContentStep = 0
      state.allTasks = []
      state.selectedTasks = {}
    },
    setOfferFieldValue: (state, action) => {
      state.selectedTasks[action.payload.taskId][
        action.payload.fieldName
      ].value = action.payload.value
    },
    setOfferFieldIsDisabled: (state, action) => {
      state.selectedTasks[action.payload.taskId][
        action.payload.fieldName
      ].isDisabled = action.payload.value
    },
    setOfferFieldErrorMessage: (state, action) => {
      state.selectedTasks[action.payload.taskId][
        action.payload.fieldName
      ].errorMessage = action.payload.value
    },
    addMainTask: (state, action) => {
      if (!(action.payload.id in state.selectedTasks)) {
        state.selectedTasks[action.payload.id] = {
          task_id: action.payload.id,
          task_title: action.payload.title,
          task_type: action.payload.taskType,
          ...createFieldsModel(action.payload.taskType),
        }
      }
    },
    toggleTask: (state, action) => {
      const task = state.allTasks.find((item) => item.id === action.payload)
      if (task) {
        if (task.isActive) {
          delete state.selectedTasks[task.id]
        } else {
          //TODO в зависимости от типа задачи добавляем набор полей
          if (!(task.id in state.selectedTasks)) {
            state.selectedTasks[task.id] = {
              task_id: task.id,
              task_title: task.title,
              task_type: task.taskType,
              ...createFieldsModel(task.taskType),
            }
          }
        }
        task.isActive = !task.isActive
      }
    },
    setBankAccountId: (state, action) => {
      state.bankAccountId = action.payload
    },
    setOwnershipTypeId: (state, action) => {
      state.ownershipTypeId = action.payload
    },
    setValidUntil: (state, action: PayloadAction<Date | null>) => {
      state.validUntil = action.payload
    },
    setIsPreviewMode: (state, action) => {
      state.isPreviewMode = action.payload
    },
    setIsFirstValidation: (state, action) => {
      state.isFirstValidation = action.payload
    },
    setTasks: (state, action) => {
      state.allTasks = action.payload
    },
    setProjectId: (state, action) => {
      state.projectId = action.payload
    },
  },
})

export const {
  incrementModalContentStep,
  decrementModalContentStep,
  setOfferFieldValue,
  setOfferFieldIsDisabled,
  resetExecutorOffer,
  addMainTask,
  setIsPreviewMode,
  setTasks,
  toggleTask,
  setOwnershipTypeId,
  setBankAccountId,
  setValidUntil,
  setOfferFieldErrorMessage,
  setIsFirstValidation,
  setProjectId,
} = executorOfferSlice.actions

export const executorOfferReducer = executorOfferSlice.reducer
