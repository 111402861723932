import IconPropsInterface from '@icons/IconPropsInterface'

export const Sort = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6054_73100)">
        <path
          d="M1.75 5.24935L4.08333 2.91602M4.08333 2.91602L6.41667 5.24935M4.08333 2.91602V11.0827"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.2497 8.74935L9.91634 11.0827M9.91634 11.0827L7.58301 8.74935M9.91634 11.0827V2.91602"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6054_73100">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
