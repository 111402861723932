import { useTheme } from 'styled-components'

import { ITheme } from '@theme/interfaces'

export const useGetTheme = () => {
  const theme: ITheme = useTheme() as ITheme
  return theme
}

export const useMediaQuery = (size: keyof ITheme['mediaQueries']) => {
  const theme = useTheme() as ITheme
  return theme.mediaQueries[size]
}
