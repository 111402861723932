import IconPropsInterface from './IconPropsInterface'

export const Handshake = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 16 14"
      fill={color}
      stroke="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path d="M15.8718 3.72445C15.7739 3.62656 12.9222 0.775069 12.8204 0.673263C12.652 0.504919 12.3792 0.504746 12.2106 0.672745L11.0808 1.79883C10.9841 1.80003 9.52615 1.26928 8.3266 2.46886L5.30607 5.48939C5.13756 5.6579 5.13756 5.9311 5.30607 6.09962C6.14742 6.94099 7.51606 6.94045 8.35689 6.09962L8.80022 5.65629C9.54427 6.1094 10.4883 6.06757 11.1882 5.55857L12.9456 7.31597C13.1311 7.50152 13.2784 7.71526 13.3837 7.94683C13.9743 7.24923 14.4081 6.4334 14.6584 5.54393L15.8712 4.33543C16.0409 4.16574 16.0395 3.89274 15.8718 3.72445Z" />
      <path d="M10.1152 7.38641C9.45203 7.64875 8.71674 7.66978 8.04192 7.45127C6.45829 8.51374 5.0403 8.8345 3.68073 7.47493C3.16717 6.96137 3.16688 6.12968 3.68073 5.61582L6.49218 2.80438L5.21908 2.55987L4.04589 1.38609C3.87442 1.21461 3.59689 1.21549 3.426 1.38609L0.32739 4.4847C0.156495 4.65589 0.156495 4.93341 0.32739 5.1046C1.0916 5.86851 1.65862 6.85065 1.92446 7.8693C1.94315 7.91254 2.34921 9.93582 4.16888 11.4704L5.52027 12.6105C6.26987 13.2433 7.33292 13.2938 8.13394 12.7683L11.2296 10.7389C11.9265 10.2827 12.0287 9.30021 11.4385 8.71005L10.1152 7.38641Z" />
    </svg>
  )
}
