import IconPropsInterface from './IconPropsInterface'

export const Vehicle = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 16 14"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M3.83464 12.8333C4.75511 12.8333 5.5013 12.0871 5.5013 11.1667C5.5013 10.2462 4.75511 9.5 3.83464 9.5C2.91416 9.5 2.16797 10.2462 2.16797 11.1667C2.16797 12.0871 2.91416 12.8333 3.83464 12.8333Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1667 12.8333C13.0871 12.8333 13.8333 12.0871 13.8333 11.1667C13.8333 10.2462 13.0871 9.5 12.1667 9.5C11.2462 9.5 10.5 10.2462 10.5 11.1667C10.5 12.0871 11.2462 12.8333 12.1667 12.8333Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.16667 11.167H0.5V2.00033C0.5 1.77931 0.587797 1.56735 0.744078 1.41107C0.900358 1.25479 1.11232 1.16699 1.33333 1.16699H8.83333V11.167M5.5 11.167H10.5M13.8333 11.167H15.5V6.16699M15.5 6.16699H8.83333M15.5 6.16699L13 2.00033H8.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
