import IconPropsInterface from './IconPropsInterface'

export const Personal = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 12 12"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M10.6675 3.80586L7.11217 1.04053C6.80016 0.7978 6.41614 0.666016 6.02083 0.666016C5.62553 0.666016 5.24151 0.7978 4.9295 1.04053L1.3735 3.80586C1.1598 3.97205 0.986896 4.18488 0.868004 4.42809C0.749112 4.67131 0.687372 4.93848 0.6875 5.20919V10.0092C0.6875 10.3628 0.827976 10.702 1.07802 10.952C1.32807 11.2021 1.66721 11.3425 2.02083 11.3425H10.0208C10.3745 11.3425 10.7136 11.2021 10.9636 10.952C11.2137 10.702 11.3542 10.3628 11.3542 10.0092V5.20919C11.3542 4.66053 11.1008 4.14253 10.6675 3.80586Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
