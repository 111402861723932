import IconPropsInterface from '@icons/IconPropsInterface'

export const BonusRecipients = ({
  size = 20,
  color,
  style,
}: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ flexShrink: 0, transition: '300ms', ...style }}
    >
      <g clipPath="url(#clip0_3934_44895)">
        <path
          d="M4.16797 5.83333C4.16797 6.71739 4.51916 7.56524 5.14428 8.19036C5.7694 8.81548 6.61725 9.16667 7.5013 9.16667C8.38536 9.16667 9.2332 8.81548 9.85833 8.19036C10.4834 7.56524 10.8346 6.71739 10.8346 5.83333C10.8346 4.94928 10.4834 4.10143 9.85833 3.47631C9.2332 2.85119 8.38536 2.5 7.5013 2.5C6.61725 2.5 5.7694 2.85119 5.14428 3.47631C4.51916 4.10143 4.16797 4.94928 4.16797 5.83333Z"
          stroke={color}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.5 17.5V15.8333C2.5 14.9493 2.85119 14.1014 3.47631 13.4763C4.10143 12.8512 4.94928 12.5 5.83333 12.5H9.16667C10.0507 12.5 10.8986 12.8512 11.5237 13.4763C12.1488 14.1014 12.5 14.9493 12.5 15.8333V17.5"
          stroke={color}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.332 2.6084C14.049 2.79198 14.6846 3.20898 15.1384 3.79366C15.5922 4.37833 15.8386 5.09742 15.8386 5.83757C15.8386 6.57771 15.5922 7.2968 15.1384 7.88147C14.6846 8.46615 14.049 8.88315 13.332 9.06673"
          stroke={color}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.5 17.5V15.8333C17.4958 15.0976 17.2483 14.384 16.7961 13.8037C16.3439 13.2233 15.7124 12.8089 15 12.625"
          stroke={color}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3934_44895">
          <rect width={size} height={size} fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
