import IconPropsInterface from '@icons/IconPropsInterface'

export const SocialTg = ({ size = 20, color, style }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style, verticalAlign: 'middle' }}
    >
      <path
        d="M9.8288 14.0885L8.10797 15.7626C7.80964 16.0526 7.31047 15.9243 7.18964 15.526L6.04297 11.751"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.7534 9.10591C11.7534 9.10591 9.59594 11.0526 8.48927 12.0517C8.15844 12.3501 8.18594 12.8751 8.54427 13.1392L13.0259 16.4501C13.4668 16.7759 14.0968 16.5367 14.2109 16.0001L16.4118 5.62757C16.5184 5.12591 16.0259 4.70674 15.5476 4.89091L2.61844 9.87757C2.2376 10.0242 2.25594 10.5684 2.6451 10.6901L6.0426 11.7501"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
