import { WithOptional } from '@src/shared/interfaces/helpers/WithOptional'

import IconPropsInterface from './IconPropsInterface'

export const DocFile = ({
  size = 20,
  style,
}: WithOptional<IconPropsInterface, 'color'>) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style, flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M16.5 22H7.5C6.70435 22 5.94129 21.6839 5.37868 21.1213C4.81607 20.5587 4.5 19.7956 4.5 19V5C4.5 4.20435 4.81607 3.44129 5.37868 2.87868C5.94129 2.31607 6.70435 2 7.5 2H14.09C14.3523 2.0011 14.6037 2.10526 14.79 2.29L19.21 6.71C19.3947 6.89626 19.4989 7.14766 19.5 7.41V19C19.5 19.7956 19.1839 20.5587 18.6213 21.1213C18.0587 21.6839 17.2956 22 16.5 22Z"
        fill="#2196F3"
      />
      <path
        d="M18.7992 7.74008H15.1992C14.8014 7.74008 14.4199 7.58205 14.1386 7.30074C13.8573 7.01944 13.6992 6.63791 13.6992 6.24008V2.64008C13.6988 2.53098 13.7308 2.42422 13.7912 2.33337C13.8516 2.24252 13.9377 2.17169 14.0384 2.12988C14.1392 2.08807 14.2501 2.07718 14.3571 2.09858C14.4641 2.11998 14.5623 2.17272 14.6392 2.25008L19.1892 6.80008C19.2666 6.87701 19.3193 6.9752 19.3407 7.08218C19.3621 7.18916 19.3512 7.30009 19.3094 7.40086C19.2676 7.50163 19.1968 7.58768 19.1059 7.64809C19.0151 7.70849 18.9083 7.74052 18.7992 7.74008Z"
        fill="#82B1FF"
      />
      <path
        d="M8.30081 18.1103C8.07549 18.117 7.85309 18.0579 7.66081 17.9403C7.47215 17.8143 7.32306 17.6375 7.23081 17.4303C7.12491 17.1821 7.07031 16.9151 7.07031 16.6453C7.07031 16.3755 7.12491 16.1085 7.23081 15.8603C7.32306 15.6531 7.47215 15.4762 7.66081 15.3503C7.85225 15.2297 8.07456 15.1671 8.30081 15.1703C8.47171 15.1678 8.64041 15.2091 8.79081 15.2903C8.93068 15.3502 9.05166 15.447 9.14081 15.5703V14.4803C9.13571 14.4133 9.14566 14.3461 9.16993 14.2834C9.19421 14.2208 9.23219 14.1644 9.28109 14.1184C9.33 14.0724 9.38859 14.0379 9.45256 14.0174C9.51654 13.997 9.58427 13.9911 9.65081 14.0003C9.7196 13.9957 9.78863 14.0048 9.85387 14.0271C9.9191 14.0494 9.97925 14.0845 10.0308 14.1303C10.0776 14.1812 10.1133 14.2413 10.1356 14.3067C10.158 14.3721 10.1666 14.4414 10.1608 14.5103V17.6203C10.1705 17.6887 10.1644 17.7583 10.1428 17.8239C10.1213 17.8896 10.085 17.9493 10.0366 17.9986C9.98828 18.0479 9.92924 18.0855 9.86407 18.1083C9.7989 18.1311 9.72935 18.1386 9.66081 18.1303C9.59903 18.1388 9.53616 18.1341 9.47634 18.1164C9.41653 18.0988 9.36115 18.0687 9.31387 18.028C9.26659 17.9873 9.2285 17.9371 9.20211 17.8806C9.17572 17.8241 9.16164 17.7626 9.16081 17.7003C9.08018 17.8407 8.95786 17.9525 8.81081 18.0203C8.65074 18.0916 8.47561 18.1225 8.30081 18.1103ZM8.63081 17.3703C8.70222 17.3711 8.77284 17.3552 8.83706 17.324C8.90128 17.2927 8.95734 17.247 9.00081 17.1903C9.11665 17.0316 9.17004 16.8358 9.15081 16.6403C9.1682 16.4449 9.11498 16.2498 9.00081 16.0903C8.95537 16.0366 8.89867 15.9935 8.83473 15.9641C8.77079 15.9347 8.70117 15.9198 8.63081 15.9203C8.5574 15.9189 8.48454 15.9333 8.41724 15.9627C8.34993 15.992 8.28975 16.0355 8.24081 16.0903C8.12664 16.2498 8.07342 16.4449 8.09081 16.6403C8.07157 16.8358 8.12497 17.0316 8.24081 17.1903C8.28737 17.2485 8.34686 17.2951 8.41455 17.3263C8.48225 17.3576 8.55629 17.3726 8.63081 17.3703Z"
        fill="#E3F2FD"
      />
      <path
        d="M12.3218 18.1106C12.0387 18.1215 11.7572 18.0632 11.5018 17.9406C11.275 17.8282 11.087 17.6506 10.9618 17.4306C10.8305 17.1898 10.7617 16.9199 10.7617 16.6456C10.7617 16.3713 10.8305 16.1014 10.9618 15.8606C11.087 15.6406 11.275 15.463 11.5018 15.3506C11.7563 15.2246 12.0379 15.1628 12.3218 15.1706C12.6055 15.1651 12.8865 15.2268 13.1418 15.3506C13.3666 15.4659 13.5539 15.6427 13.6818 15.8606C13.8064 16.1035 13.8714 16.3726 13.8714 16.6456C13.8714 16.9186 13.8064 17.1877 13.6818 17.4306C13.5539 17.6484 13.3666 17.8253 13.1418 17.9406C12.8856 18.0609 12.6047 18.1192 12.3218 18.1106ZM12.3218 17.3706C12.3933 17.3722 12.4642 17.3567 12.5286 17.3253C12.5929 17.294 12.6489 17.2478 12.6918 17.1906C12.8076 17.0319 12.861 16.8361 12.8418 16.6406C12.8592 16.4452 12.806 16.2501 12.6918 16.0906C12.6468 16.0363 12.5902 15.9929 12.5262 15.9634C12.4621 15.934 12.3923 15.9194 12.3218 15.9206C12.2484 15.9192 12.1755 15.9336 12.1082 15.963C12.0409 15.9923 11.9807 16.0358 11.9318 16.0906C11.8212 16.2514 11.7715 16.4464 11.7918 16.6406C11.7706 16.8348 11.8203 17.0301 11.9318 17.1906C11.9784 17.2488 12.0378 17.2954 12.1055 17.3266C12.1732 17.3579 12.2473 17.3729 12.3218 17.3706Z"
        fill="#E3F2FD"
      />
      <path
        d="M15.9186 18.1104C15.6322 18.1214 15.3475 18.063 15.0886 17.9404C14.8607 17.8258 14.67 17.6489 14.5386 17.4304C14.4056 17.1888 14.34 16.916 14.3486 16.6404C14.339 16.3647 14.4047 16.0916 14.5386 15.8504C14.6679 15.6326 14.8595 15.4585 15.0886 15.3504C15.3467 15.2243 15.6314 15.1626 15.9186 15.1704C16.0348 15.1567 16.1523 15.1567 16.2686 15.1704C16.3964 15.1898 16.5209 15.2268 16.6386 15.2804C16.718 15.3109 16.7849 15.3673 16.8286 15.4404C16.8705 15.5093 16.8881 15.5903 16.8786 15.6704C16.8766 15.7458 16.8522 15.8189 16.8086 15.8804C16.7922 15.9118 16.7695 15.9396 16.742 15.9619C16.7145 15.9843 16.6827 16.0008 16.6486 16.0104C16.6107 16.0269 16.5699 16.0354 16.5286 16.0354C16.4873 16.0354 16.4464 16.0269 16.4086 16.0104C16.2902 15.9523 16.1604 15.9216 16.0286 15.9204C15.9419 15.9146 15.8549 15.9277 15.7738 15.9587C15.6926 15.9898 15.6192 16.0382 15.5586 16.1004C15.4402 16.2471 15.3797 16.4321 15.3886 16.6204C15.3787 16.8088 15.4393 16.9942 15.5586 17.1404C15.6169 17.2062 15.6897 17.2576 15.7713 17.2905C15.8528 17.3235 15.9409 17.3371 16.0286 17.3304H16.2086L16.4086 17.2604C16.4464 17.2439 16.4873 17.2354 16.5286 17.2354C16.5699 17.2354 16.6107 17.2439 16.6486 17.2604C16.7165 17.2818 16.7737 17.3283 16.8086 17.3904C16.8479 17.454 16.8719 17.5259 16.8786 17.6004C16.8775 17.6796 16.8605 17.7579 16.8286 17.8304C16.7807 17.9037 16.7106 17.9598 16.6286 17.9904C16.5092 18.0394 16.3853 18.0763 16.2586 18.1004L15.9186 18.1104Z"
        fill="#E3F2FD"
      />
    </svg>
  )
}
