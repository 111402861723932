import IconPropsInterface from './IconPropsInterface'

export const BarProperty = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 13 12"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M1.33203 1.66667L2.33203 2.66667L3.9987 1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.33203 5.66667L2.33203 6.66667L3.9987 5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.33203 9.66667L2.33203 10.6667L3.9987 9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.33203 2H12.332"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.33203 6H12.332"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.33203 10H12.332"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
