import { setOfferFieldIsDisabled } from '@pages/TasksBoard/ExecutorOffer/executorOfferSlice'
import { useAppDispatch, useAppSelector } from '@src/hooks/store'

export const usePrepaymentAmount = (taskId: number) => {
  const dispatch = useAppDispatch()
  const prepaymentAmount = useAppSelector(
    (state) =>
      state.executorOffer.selectedTasks[taskId]['prepayment_amount'].value
  )
  const handler = (value: string) => {
    dispatch(
      setOfferFieldIsDisabled({
        taskId,
        fieldName: 'prepayment_days',
        value: !(value !== '' && +value > 0),
      })
    )
  }

  return {
    handler,
    showPrepaymentDays: prepaymentAmount !== '' && +prepaymentAmount > 0,
  }
}
