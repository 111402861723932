import { ReactNode } from 'react'
import styled, { CSSProperties } from 'styled-components'

import { Play } from '@icons/Play'
import { useIntersectionObserver } from '@src/hooks/useIntersectionObserver'
import { useToggle } from '@src/hooks/useToggle'
import { noop } from '@src/utils/helpers/noop'
import { useGetTheme } from '@theme/hooks'
import { Box } from '@ui/Box'

import { BaseSkeleton } from './BaseSkeleton'

interface IProps {
  src?: string
  alt?: string
  boxHeight?: string | number
  boxWidth?: string | number
  size?: string | number
  fit?: string
  rounded?: boolean
  borderRadius?: number
  style?: CSSProperties
  placeholderColor?: string
  placeholderIcon?: ReactNode
  autoplay?: boolean
  onClick?: () => void
}

const StyledBox = styled.div<Omit<IProps, 'src'>>`
  position: relative;
  height: ${({ boxHeight }) => boxHeight};
  width: ${({ boxWidth }) => boxWidth};
  border-radius: ${({ rounded, borderRadius }) =>
    rounded ? '50%' : `${borderRadius}px`};
  overflow: hidden;
`
const Video = styled.video<IProps>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: ${({ fit }) => fit ?? 'content'};
  z-index: 3;
`
const SkeletonWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
`

export const VideoBox = ({
  src,
  boxHeight,
  boxWidth,
  fit,
  size,
  rounded = false,
  borderRadius = 0,
  style,
  placeholderColor,
  placeholderIcon = null,
  autoplay,
  onClick = noop,
}: IProps) => {
  const { isOn: isLoading, off: loaded } = useToggle(true)
  const { isOn: isTimeToLoad, on: startLoading } = useToggle(false)
  const { ref } = useIntersectionObserver({
    callback: startLoading,
    once: true,
  })
  const theme = useGetTheme()
  const getParam = (param?: string | number) => {
    if (size) {
      return typeof size === 'string' ? size : `${size}px`
    }
    if (param) {
      return typeof param === 'string' ? param : `${param}px`
    }
    return '100%'
  }
  return (
    <StyledBox
      ref={ref}
      boxHeight={getParam(boxHeight)}
      boxWidth={getParam(boxWidth)}
      rounded={rounded}
      borderRadius={borderRadius}
      style={src ? style : { ...style, backgroundColor: placeholderColor }}
      onClick={onClick}
    >
      {src ? (
        <>
          {isTimeToLoad && (
            <Video
              fit={fit}
              controls
              onLoadedData={loaded}
              onError={loaded}
              src={src}
              autoPlay={autoplay}
            />
          )}
          {isLoading && (
            <>
              <SkeletonWrapper>
                <BaseSkeleton />
              </SkeletonWrapper>
              <Play
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  zIndex: 3,
                  stroke: 'none',
                  margin: 'auto',
                }}
                size={80}
                color={theme.colors.gray70}
              />
            </>
          )}
        </>
      ) : (
        <Box
          flex
          justifyContent="center"
          style={{ width: '100%', height: '100%' }}
        >
          {placeholderIcon}
        </Box>
      )}
    </StyledBox>
  )
}
