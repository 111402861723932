import { myApplicationsApi } from '@pages/MyApplications/api/myApplicationsApi'
import { IApplication } from '@pages/MyApplications/types/application/IApplication'
import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'
import { IOption } from '@src/shared/interfaces/partials/IOption'
import { RootState } from '@src/store'
import { RangeCalendarValue } from '@ui/CalendarComponent/RangeCalendar'

export type TabsType = 'active' | 'drafts' | 'on_moderation' | 'ban' | 'closed'

interface IAdditionalState {
  page: number
  totalCount: null | number
  ownershipTypeId: IOption<number> | null
  dateRange: RangeCalendarValue
  sort: 'asc' | 'desc'
  activeTab: TabsType
  isWithdrawPopupOpened: boolean
  withdrawId: number | null
  isWithdraw: boolean
  steals: {
    id?: number | null
    applicationId?: number | null
    ownershipTypes?: { id: number; name: string }[] | null
  }
  isPayStealsPopupOpened: boolean
  isStealsOwnershipTypesPopupOpened: boolean
  isDeleteStealsOwnershipTypePopupOpened: boolean
}

const additionalInitialState: IAdditionalState = {
  page: 1,
  totalCount: null,
  ownershipTypeId: null,
  dateRange: null,
  sort: 'desc',
  activeTab: 'active',
  isWithdrawPopupOpened: false,
  withdrawId: null,
  isWithdraw: true,
  steals: {
    id: null,
    applicationId: null,
    ownershipTypes: null,
  },
  isPayStealsPopupOpened: false,
  isDeleteStealsOwnershipTypePopupOpened: false,
  isStealsOwnershipTypesPopupOpened: false,
}

const applicationsAdapter = createEntityAdapter<IApplication>()

const myApplicationsSlice = createSlice({
  name: 'myApplications',
  initialState: applicationsAdapter.getInitialState(additionalInitialState),
  reducers: {
    applicationsListCleared: (state) => {
      applicationsAdapter.removeAll(state)
      state.page = 1
      state.totalCount = null
    },
    ownershipTypeIdChanged: (
      state,
      { payload }: PayloadAction<IOption<number> | null>
    ) => {
      state.ownershipTypeId = payload
    },
    dateRangeChanged: (
      state,
      { payload }: PayloadAction<RangeCalendarValue>
    ) => {
      state.dateRange = payload
    },
    sortChanged: (state, { payload }: PayloadAction<'asc' | 'desc'>) => {
      state.sort = payload
    },
    tabChanged: (state, { payload }: PayloadAction<TabsType>) => {
      state.activeTab = payload
    },
    withdrawPopupToggled: (state, { payload }: PayloadAction<boolean>) => {
      state.isWithdrawPopupOpened = payload
    },
    withdrawIdChanged: (state, { payload }: PayloadAction<number | null>) => {
      state.withdrawId = payload
    },
    stealsChanged: (
      state,
      { payload }: PayloadAction<IAdditionalState['steals']>
    ) => {
      state.steals = payload
    },
    applicationsReset: () =>
      applicationsAdapter.getInitialState(additionalInitialState),
    isWithdrawChanged: (state, { payload }: PayloadAction<boolean>) => {
      state.isWithdraw = payload
    },
    payStealsPopupToggled: (state, { payload }: PayloadAction<boolean>) => {
      state.isPayStealsPopupOpened = payload
    },
    deleteStealsOwnershipTypePopupToggled: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isDeleteStealsOwnershipTypePopupOpened = payload
    },
    stealsOwnershipTypesPopupToggled: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isStealsOwnershipTypesPopupOpened = payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      myApplicationsApi.endpoints.getApplications.matchFulfilled,
      (state, { payload }) => {
        applicationsAdapter.addMany(state, payload.data.items)
        state.page = state.page + 1
        if (state.totalCount === null) {
          state.totalCount = payload.data.pagination.total_items
        }
      }
    )
  },
})

export default myApplicationsSlice.reducer

export const {
  applicationsListCleared,
  ownershipTypeIdChanged,
  dateRangeChanged,
  sortChanged,
  tabChanged,
  applicationsReset,
  withdrawPopupToggled,
  withdrawIdChanged,
  isWithdrawChanged,
  stealsChanged,
  stealsOwnershipTypesPopupToggled,
  deleteStealsOwnershipTypePopupToggled,
  payStealsPopupToggled,
} = myApplicationsSlice.actions

export const selectOwnershipTypeId = (state: RootState) =>
  state.myApplications.ownershipTypeId
export const selectDateRange = (state: RootState) =>
  state.myApplications.dateRange
export const selectSort = (state: RootState) => state.myApplications.sort
export const selectActiveTab = (state: RootState) =>
  state.myApplications.activeTab

export const {
  selectAll: selectApplications,
  selectById: selectApplicationById,
  selectIds: selectApplicationsIds,
} = applicationsAdapter.getSelectors<RootState>((state) => state.myApplications)
