export const FileDocx = () => {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3">
        <path
          opacity="0.3"
          d="M13.9669 4.92471C13.4202 4.92418 12.8961 4.70677 12.5095 4.3202C12.1229 3.93364 11.9055 3.4095 11.9049 2.86282V1.85843e-07H2.69199C2.33852 -0.00013116 1.98848 0.0693605 1.66188 0.204529C1.33528 0.339698 1.03851 0.53789 0.788521 0.787771C0.538534 1.03765 0.340228 1.33433 0.204931 1.66086C0.0696333 1.9874 -2.44059e-08 2.33738 0 2.69083V18.9119C-2.44059e-08 19.2653 0.0696333 19.6153 0.204931 19.9419C0.340228 20.2684 0.538534 20.5651 0.788521 20.815C1.03851 21.0648 1.33528 21.263 1.66188 21.3982C1.98848 21.5333 2.33852 21.6029 2.69199 21.6027H13.7519C14.4656 21.6027 15.1501 21.3192 15.6547 20.8146C16.1594 20.31 16.4429 19.6255 16.4429 18.9119V4.92471H13.9669Z"
          fill="#006CEC"
        />
      </g>
      <path
        d="M16.4442 4.92455H13.9683C13.4215 4.92403 12.8974 4.70662 12.5108 4.32007C12.1242 3.93352 11.9068 3.40939 11.9062 2.86273V0L16.4442 4.92455Z"
        fill="#006CEC"
      />
      <path
        d="M13.0573 8.37396H2.73337C2.63418 8.37396 2.53906 8.33456 2.46892 8.26442C2.39878 8.19429 2.35938 8.09917 2.35938 7.99998C2.35924 7.95079 2.36883 7.90205 2.38756 7.85656C2.4063 7.81107 2.43383 7.76972 2.46857 7.73488C2.50331 7.70005 2.54457 7.67242 2.59001 7.65356C2.63546 7.63471 2.68418 7.625 2.73337 7.625H13.0573C13.1568 7.625 13.2522 7.66451 13.3225 7.73483C13.3928 7.80516 13.4323 7.90053 13.4323 7.99998C13.4321 8.09926 13.3924 8.19439 13.3221 8.2645C13.2518 8.3346 13.1566 8.37396 13.0573 8.37396Z"
        fill="#006CEC"
      />
      <path
        d="M13.0573 10.6083H2.73337C2.63418 10.6083 2.53906 10.5689 2.46892 10.4988C2.39878 10.4287 2.35938 10.3335 2.35938 10.2344C2.35924 10.1852 2.36883 10.1364 2.38756 10.0909C2.4063 10.0454 2.43383 10.0041 2.46857 9.96926C2.50331 9.93442 2.54457 9.90679 2.59001 9.88794C2.63546 9.86908 2.68418 9.85937 2.73337 9.85938H13.0573C13.1568 9.85938 13.2522 9.89886 13.3225 9.96918C13.3928 10.0395 13.4323 10.1349 13.4323 10.2344C13.4321 10.3336 13.3924 10.4287 13.3221 10.4988C13.2518 10.569 13.1566 10.6083 13.0573 10.6083Z"
        fill="#006CEC"
      />
      <path
        d="M13.0573 12.8466H2.73337C2.68418 12.8466 2.63546 12.8369 2.59001 12.8181C2.54457 12.7992 2.50331 12.7716 2.46857 12.7367C2.43383 12.7019 2.4063 12.6606 2.38756 12.6151C2.36883 12.5696 2.35924 12.5208 2.35938 12.4716C2.35938 12.3725 2.39878 12.2773 2.46892 12.2072C2.53906 12.1371 2.63418 12.0977 2.73337 12.0977H13.0573C13.1566 12.0977 13.2518 12.137 13.3221 12.2071C13.3924 12.2772 13.4321 12.3724 13.4323 12.4716C13.4323 12.5711 13.3928 12.6665 13.3225 12.7368C13.2522 12.8071 13.1568 12.8466 13.0573 12.8466Z"
        fill="#006CEC"
      />
      <path
        d="M8.98334 15.0849H2.73337C2.68417 15.0849 2.63546 15.0752 2.59001 15.0563C2.54457 15.0375 2.50331 15.0098 2.46857 14.975C2.43383 14.9402 2.4063 14.8988 2.38756 14.8533C2.36883 14.8079 2.35924 14.7591 2.35938 14.7099C2.35938 14.6107 2.39878 14.5156 2.46892 14.4455C2.53906 14.3753 2.63418 14.3359 2.73337 14.3359H8.98334C9.08253 14.3359 9.17765 14.3753 9.24779 14.4455C9.31793 14.5156 9.35734 14.6107 9.35734 14.7099C9.35747 14.7591 9.34789 14.8079 9.32915 14.8533C9.31041 14.8988 9.28289 14.9402 9.24815 14.975C9.2134 15.0098 9.17214 15.0375 9.1267 15.0563C9.08126 15.0752 9.03254 15.0849 8.98334 15.0849Z"
        fill="#006CEC"
      />
      <path
        d="M18.3912 17.0625H5.88724C5.00028 17.0625 4.28125 17.7815 4.28125 18.6684V22.396C4.28125 23.2829 5.00028 24.0019 5.88724 24.0019H18.3912C19.2781 24.0019 19.9972 23.2829 19.9972 22.396V18.6684C19.9972 17.7815 19.2781 17.0625 18.3912 17.0625Z"
        fill="#006CEC"
      />
      <path
        d="M6.12017 21.3398V22.0668H5.36719V21.3398H6.12017Z"
        fill="white"
      />
      <path
        d="M9.01599 21.3409C8.8905 21.568 8.70143 21.7536 8.47199 21.8749C8.21353 22.007 7.9262 22.0726 7.63599 22.0658H6.5V19.0591H7.63599C7.92641 19.0521 8.21413 19.1162 8.47399 19.2461C8.70273 19.3648 8.89129 19.5485 9.01599 19.774C9.14528 20.0141 9.21039 20.2834 9.20499 20.556C9.21014 20.8295 9.14506 21.0997 9.01599 21.3409ZM8.23399 21.1899C8.31528 21.1065 8.37826 21.007 8.4189 20.8978C8.45954 20.7887 8.47694 20.6723 8.47 20.556C8.47699 20.4399 8.4596 20.3235 8.41896 20.2145C8.37832 20.1055 8.3153 20.0062 8.23399 19.923C8.0518 19.7633 7.81384 19.6821 7.57199 19.697H7.228V21.4149H7.57199C7.81364 21.4297 8.05142 21.3489 8.23399 21.1899Z"
        fill="white"
      />
      <path
        d="M11.8165 19.2079C12.0453 19.3378 12.2344 19.5276 12.3635 19.7568C12.4988 19.9989 12.5678 20.2724 12.5635 20.5497C12.5686 20.8278 12.4995 21.1021 12.3635 21.3447C12.2347 21.575 12.0451 21.7655 11.8155 21.8956C11.5817 22.0291 11.3167 22.0981 11.0475 22.0956C10.7783 22.0981 10.5133 22.0291 10.2795 21.8956C10.0499 21.7655 9.86036 21.575 9.73152 21.3447C9.59549 21.1021 9.52646 20.8278 9.53152 20.5497C9.52634 20.2723 9.59538 19.9986 9.73152 19.7568C9.86102 19.5275 10.0505 19.3378 10.2795 19.2079C10.5133 19.0744 10.7783 19.0054 11.0475 19.0079C11.317 19.0055 11.5823 19.0745 11.8165 19.2079ZM10.4765 19.9178C10.329 20.0942 10.254 20.3201 10.2665 20.5497C10.2539 20.7785 10.329 21.0034 10.4765 21.1787C10.5484 21.2568 10.6365 21.3182 10.7346 21.3586C10.8327 21.399 10.9385 21.4175 11.0445 21.4126C11.1502 21.417 11.2556 21.3984 11.3533 21.358C11.451 21.3176 11.5388 21.2564 11.6105 21.1787C11.7587 21.0037 11.8346 20.7787 11.8225 20.5497C11.8345 20.3211 11.7595 20.0964 11.6125 19.9208C11.5407 19.8426 11.4526 19.7812 11.3545 19.7408C11.2564 19.7003 11.1505 19.6819 11.0445 19.6868C10.9388 19.6819 10.8332 19.7001 10.7352 19.74C10.6371 19.7798 10.5489 19.8405 10.4765 19.9178Z"
        fill="white"
      />
      <path
        d="M15.2893 19.3311C15.5429 19.5375 15.7154 19.8268 15.7763 20.148H15.0063C14.9544 20.0149 14.8634 19.9006 14.7453 19.8201C14.6209 19.739 14.4748 19.6976 14.3263 19.7011C14.2283 19.6979 14.1308 19.7174 14.0415 19.758C13.9522 19.7986 13.8734 19.8592 13.8113 19.9351C13.6722 20.1132 13.6012 20.3352 13.6113 20.561C13.6011 20.7859 13.6721 21.007 13.8113 21.184C13.8739 21.2591 13.9529 21.3189 14.0421 21.359C14.1314 21.399 14.2286 21.4181 14.3263 21.4149C14.4748 21.4189 14.621 21.3774 14.7453 21.2959C14.8627 21.2169 14.9537 21.1044 15.0063 20.973H15.7763C15.7144 21.2933 15.5421 21.5817 15.2893 21.7879C15.0173 21.9956 14.6813 22.1017 14.3393 22.0879C14.0699 22.0936 13.8039 22.0265 13.5693 21.8939C13.3498 21.7663 13.1717 21.5781 13.0563 21.3519C12.9321 21.1039 12.8699 20.8294 12.8753 20.552C12.87 20.2746 12.9321 20.0001 13.0563 19.7521C13.1717 19.5259 13.3498 19.3377 13.5693 19.2101C13.8039 19.0775 14.0699 19.0105 14.3393 19.0161C14.683 19.007 15.0191 19.1185 15.2893 19.3311Z"
        fill="white"
      />
      <path
        d="M18.0176 22.0653L17.3835 21.1294L16.8396 22.0653H16.0056L16.9676 20.5174L15.9766 19.0586H16.8396L17.4566 19.9645L17.9876 19.0586H18.8176L17.8686 20.5724L18.8806 22.0653H18.0176Z"
        fill="white"
      />
    </svg>
  )
}
