import { CSSProperties, ReactNode } from 'react'

import { BaseSkeleton } from '@ui/BaseSkeleton'

export const FieldSkeleton = ({
  isLoading,
  children,
  style,
  className,
}: {
  isLoading?: boolean
  children: ReactNode
  style?: CSSProperties
  className?: string
}) => {
  return (
    <>
      {isLoading ? (
        <BaseSkeleton
          width={'100%'}
          height={45}
          borderRadius={7}
          style={style}
          className={className}
        />
      ) : (
        children
      )}
    </>
  )
}
