import { GroupBase, OptionProps, components } from 'react-select'

import { Check } from '@icons/Check'
import { useGetTheme } from '@theme/hooks'
import { ITheme } from '@theme/interfaces'

export const getOptionStyles = (theme: ITheme, state: any) => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: theme.colors.s,
    lineHeight: '24px',
    color: state.isSelected ? theme.colors.primary : theme.colors.text20,
    opacity: state.isDisabled && !state.isSelected ? 0.5 : 1,
    padding: '5px 15px',
    cursor: state.isDisabled ? 'default' : 'pointer',
    background: 'none',
    overflow: 'hidden',
    ':active': {
      background: 'none',
    },
    ':hover': {
      background: state.isDisabled ? 'none' : theme.colors.primaryHoverLight,
    },
  }
}

export const Option = <
  TOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<TOption> = GroupBase<TOption>
>({
  children,
  ...rest
}: OptionProps<TOption, IsMulti, Group>) => {
  const theme = useGetTheme()

  return (
    <components.Option {...rest}>
      {children}
      {rest.isSelected && <Check color={theme.colors.primary} size={12} />}
    </components.Option>
  )
}
