import { ICreatePartnerRequest } from '@pages/Partnership/types/ICreatePartnerRequest'
import { IPartnerBalance } from '@pages/Partnership/types/IPartnerBalance'
import { IPartnerPersonal } from '@pages/Partnership/types/IPartnerPersonal'
import {
  IPartnerReferralEvent,
  IPartnerReferralEventsRequest,
} from '@pages/Partnership/types/IPartnerReferralEvents'
import { IReferralSpace } from '@pages/Partnership/types/IPartnerReferrals'
import {
  IPartnerReferralsStatisticRequest,
  IPartnerReferralsStatisticResponse,
} from '@pages/Partnership/types/IPartnerReferralsStatistic'
import {
  IPartnerTransaction,
  IPartnerTransactionsRequest, Transaction
} from "@pages/Partnership/types/IPartnerTransactions";
import { IPaginatedItems } from '@src/shared/interfaces/apiResponse/IPagination'
import { ISuccessResponse } from '@src/shared/interfaces/apiResponse/ISuccessResponse'
import { IValueWithName } from '@src/shared/interfaces/partials/IValueWithName'
import { clientApiSlice } from '@src/store/slices/clientApiSlice'
import { formatUrlGetParamsFromObject } from '@src/utils/helpers/formatUrlGetParamsFromObject'

export const partnershipApi = clientApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPartnerPersonal: builder.query<ISuccessResponse<IPartnerPersonal>, void>(
      {
        query: () => ({
          url: `/partner`,
        }),
        providesTags: () => [{ type: 'Partnership', id: 'PERSONAL' }],
      }
    ),
    createPartner: builder.mutation<
      ISuccessResponse<IPartnerPersonal>,
      ICreatePartnerRequest
    >({
      query: (args) => ({
        url: `/partner/create`,
        method: 'POST',
        body: args,
      }),
    }),
    updatePartner: builder.mutation<
      ISuccessResponse<IPartnerPersonal>,
      ICreatePartnerRequest
    >({
      query: (args) => ({
        url: `/partner/update`,
        method: 'PUT',
        body: args,
      }),
      invalidatesTags: (result) =>
        result && result.success
          ? [
              { type: 'Partnership', id: 'PERSONAL' },
              { type: 'Partnership', id: 'BALANCE' },
            ]
          : [],
    }),
    getPartnerBalance: builder.query<ISuccessResponse<IPartnerBalance>, void>({
      query: () => ({
        url: `/partner/balance`,
      }),
      providesTags: () => [{ type: 'Partnership', id: 'BALANCE' }],
    }),
    getPartnerReferralKey: builder.query<
      ISuccessResponse<{
        referral_key: string
      }>,
      void
    >({
      query: () => ({
        url: `/partner/referral-key`,
      }),
    }),
    getPartnerReferralEvents: builder.query<
      ISuccessResponse<
        IPaginatedItems<IPartnerReferralEvent> & { total: string }
      >,
      IPartnerReferralEventsRequest
    >({
      query: ({ space, from, to, page, limit, event_name }) => ({
        url: `/partner/referral-events${formatUrlGetParamsFromObject({
          space,
          from,
          to,
          event_name,
          page,
          limit,
        })}`,
      }),
    }),
    getPartnerReferrals: builder.query<
      ISuccessResponse<IReferralSpace[]>,
      void
    >({
      query: () => ({
        url: `/partner/referrals`,
      }),
    }),
    getPartnerReferralsStatistic: builder.query<
      IPartnerReferralsStatisticResponse,
      IPartnerReferralsStatisticRequest
    >({
      query: ({ from, to }) => ({
        url: `/partner/referrals-statistic${formatUrlGetParamsFromObject({
          from,
          to,
        })}`,
      }),
    }),
    getPartnerTransactions: builder.query<
      ISuccessResponse<
        IPaginatedItems<IPartnerTransaction> & { total: string }
      >,
      IPartnerTransactionsRequest
    >({
      query: ({ from, to, transaction_name, page, limit }) => ({
        url: `/partner/transactions${formatUrlGetParamsFromObject({
          transaction_name,
          from,
          to,
          page,
          limit,
        })}`,
      }),
    }),
    getPartnerEventTypes: builder.query<
      ISuccessResponse<IValueWithName[]>,
      void
    >({
      query: () => ({
        url: `/partner/event-types`,
      }),
    }),
    getPartnerTransactionsTypes: builder.query<
      ISuccessResponse<IValueWithName<Transaction>[]>,
      void
    >({
      query: () => ({
        url: `/partner/transaction-types`,
      }),
    }),
    getPartnerFutureDatePayment: builder.query<
      ISuccessResponse<{
        future_day: string
      }>,
      void
    >({
      query: () => ({
        url: `/partner/future-day-payment`,
      }),
    }),
  }),
})

export const {
  useGetPartnerPersonalQuery,
  useCreatePartnerMutation,
  useUpdatePartnerMutation,
  useGetPartnerBalanceQuery,
  useGetPartnerReferralKeyQuery,
  useLazyGetPartnerReferralEventsQuery,
  useGetPartnerReferralsQuery,
  useGetPartnerReferralsStatisticQuery,
  useLazyGetPartnerTransactionsQuery,
  useGetPartnerEventTypesQuery,
  useGetPartnerTransactionsTypesQuery,
  useGetPartnerFutureDatePaymentQuery,
} = partnershipApi
