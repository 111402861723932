import { Fragment } from 'react'
import styled, { CSSProperties } from 'styled-components'

import { pointsLabels } from '@pages/MyApplications/constants'
import { convertDateToYmdAndTime } from '@pages/TasksBoard/helpers/responseTransformHelpers'
import { PointType } from '@src/shared/interfaces/task/PointTypes'
import { TaskType } from '@src/shared/interfaces/task/TaskType'
import { getBgColor, getColor, getFontWeight, media } from '@theme/helpers'
import { Box } from '@ui/Box'

import { ITaskPointItem } from '../interfaces'

const StyledWrapper = styled.ul`
  list-style: none;
  position: relative;
  padding-left: 20px;
  margin-left: 10px;

  &:before {
    content: '';
    position: absolute;
    width: 1px;
    ${getBgColor('gray70')}
    left: 0;
    top: 10px;
    bottom: 40px;
  }
`

const StyledPoint = styled.li<{ pointType: PointType }>`
  position: relative;
  display: flex;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 5px;
    left: -25px;
    width: 11px;
    height: 11px;
    ${getBgColor('light')}
    border-radius: 50%;
    border: 2px solid
      ${({ theme, pointType }) =>
        theme.colors[pointType === 'stop' ? 'gray70' : 'primary']};

    ${media(
      'small',
      () => `
      top: 2.5px;
      `
    )}
  }

  ${media(
    'small',
    () => `
    display: flex;
  `
  )}
`

const StyledTitle = styled.span`
  ${getColor('text20')}
  ${getFontWeight('semibold')}
  min-width: 100px;

  ${media(
    'small',
    ({ theme }) => `
    flex-shrink: 0;
    width: 80px;
    font-size: ${theme.fontSizes.xs};
    line-height: 16px;
  `
  )}
`

const StyledDescription = styled.span`
  ${getColor('gray20')}
  ${getFontWeight('medium')}

  ${media(
    'small',
    ({ theme }) => `
    font-size: ${theme.fontSizes.xs};
    line-height: 16px;
  `
  )}
`

export const PointsList = ({
  points,
  style,
  taskType,
}: {
  points: ITaskPointItem[]
  style?: CSSProperties
  taskType: TaskType
}) => {
  const isList = taskType === 'passenger' || taskType === 'cargo'

  const pointsContent = (
    <>
      {points.map((item) => {
        const { ymd, time } = convertDateToYmdAndTime(
          item?.arrival_time?.datetime || ''
        )

        const addressLabel = isList
          ? item.type.name
          : pointsLabels[taskType].address
        const dateLabel = isList ? 'Дата' : pointsLabels[taskType].date
        const date = `${time}, ${ymd} г`

        const grid = (
          <Grid>
            <StyledTitle>{addressLabel}</StyledTitle>
            <StyledDescription>{item.address}</StyledDescription>
            <StyledTitle>{dateLabel}</StyledTitle>
            <StyledDescription>{date}</StyledDescription>
          </Grid>
        )

        return (
          <Fragment key={item.id}>
            {isList ? (
              <StyledPoint pointType={item.type.value} key={item.id}>
                {grid}
              </StyledPoint>
            ) : (
              grid
            )}
          </Fragment>
        )
      })}
    </>
  )

  return (
    <Box style={style}>
      {isList ? <StyledWrapper>{pointsContent}</StyledWrapper> : pointsContent}
    </Box>
  )
}

const Grid = styled(Box)`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px 20px;

  ${media(
    'small',
    () => `
     align-items: flex-start;
  `
  )}
`
