import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import {
  FormOfOwnership,
  IEditPhysical,
  IIndividual,
  IJuridical,
  IPhysicalPerson,
} from './interfaces/index'

interface initialStateType {
  firstVerif: IPhysicalPerson
  items: FormOfOwnership[]
  // initialItems - для сброса в поиске
  initialItems: FormOfOwnership[]
  createOwnership: IIndividual
  createJuridical: IJuridical
  ownershipId: number
  editPhysical: IEditPhysical
  isShowFields: boolean
  disabledFields: string[]
  isOpenFirstStep: boolean
  isOpenSecondStep: boolean
}

const initialState: initialStateType = {
  firstVerif: {
    first_name: '',
    patronymic: '',
    last_name: '',
    living_address: '',
    birthdate: null,
    passport_series: '',
    passport_number: '',
    passport_date: null,
    passport_address_registration: '',
    passport_photo: '',
    inn: '',
    selfie: '',
    logo: '',
    issuing_authority: '',
    subdivision_code: '',
  },
  items: [],
  initialItems: [],
  createOwnership: {
    inn_code: '',
    last_name: '',
    first_name: '',
    patronymic: '',
    registration_address: '',
    actual_address: '',
    email: '',
    phone: '',
    ogrn_code: '',
    okpo_code: '',
    name: '',
    logo: '',
    use_vat: false,
  },
  ownershipId: 0,
  createJuridical: {
    title: '',
    inn: '',
    kpp: '',
    ogrn: '',
    legal_address: '',
    actual_address: '',
    okpo: '',
    general_dir_first_name: '',
    general_dir_second_name: '',
    general_dir_last_name: '',
    general_dir_email: '',
    general_dir_phone: '',
    logo_image: '',
    use_vat: false,
    reason_type: 'constitution',
    reason_number: '',
    reason_date: null,
  },
  editPhysical: {
    living_address: '',
    birthdate: null,
    passport_series: '',
    passport_number: '',
    passport_date: null,
    passport_address_registration: '',
    passport_photo: '',
    inn: '',
    selfie: '',
    name: '',
    first_name: '',
    last_name: '',
    patronymic: '',
    issuing_authority: '',
    subdivision_code: '',
    logo: '',
  },
  isShowFields: false,
  disabledFields: [],
  isOpenFirstStep: false,
  isOpenSecondStep: false,
}

const ownershipSlice = createSlice({
  name: 'ownership',
  initialState: initialState,
  reducers: {
    setItemsOwnership: (state, action) => {
      state.items = action.payload
    },
    setInitialsItemsOwnership: (state, action) => {
      state.initialItems = action.payload
    },
    setOwnershipId: (state, action) => {
      state.ownershipId = action.payload
    },
    setFirstVerification: (
      state,
      action: PayloadAction<{
        field: keyof IPhysicalPerson
        value: any
      }>
    ) => {
      const { field, value } = action.payload

      state.firstVerif[field] = value
    },
    resetBirthday: (state) => {
      state.firstVerif.birthdate = null
    },
    resetPassportDate: (state) => {
      state.firstVerif.passport_date = null
    },
    setEditPhysicalPerson: (
      state,
      action: PayloadAction<{
        field: keyof IEditPhysical
        value: any
      }>
    ) => {
      const { field, value } = action.payload

      state.editPhysical[field] = value
    },
    setIndividualField: (
      state,
      action: PayloadAction<{
        field: keyof IIndividual
        value: string | boolean
      }>
    ) => {
      const { field, value } = action.payload
      state.createOwnership = {
        ...state.createOwnership,
        [field]: value,
      }
    },
    setJuridicalField: (
      state,
      action: PayloadAction<{
        field: keyof IJuridical
        value: string | boolean | Date | null
      }>
    ) => {
      const { field, value } = action.payload
      state.createJuridical = {
        ...state.createJuridical,
        [field]: value,
      }
    },
    setIsShowFields: (state, action) => {
      state.isShowFields = action.payload
    },
    setDisabledFields: (state, action) => {
      state.disabledFields = action.payload
    },
    resetIndividual: (state) => {
      state.createOwnership = {
        inn_code: '',
        last_name: '',
        first_name: '',
        patronymic: '',
        registration_address: '',
        actual_address: '',
        email: '',
        phone: '',
        ogrn_code: '',
        okpo_code: '',
        name: '',
        logo: '',
        use_vat: false,
      }
    },
    resetJuridical: (state) => {
      state.createJuridical = {
        title: '',
        inn: '',
        kpp: '',
        ogrn: '',
        legal_address: '',
        actual_address: '',
        okpo: '',
        general_dir_first_name: '',
        general_dir_second_name: '',
        general_dir_last_name: '',
        general_dir_email: '',
        general_dir_phone: '',
        logo_image: '',
        use_vat: false,
        reason_type: 'constitution',
        reason_number: '',
        reason_date: null,
      }
    },
    resetPhysical: (state) => {
      state.editPhysical = {
        living_address: '',
        birthdate: null,
        passport_series: '',
        passport_number: '',
        passport_date: null,
        passport_address_registration: '',
        passport_photo: '',
        inn: '',
        selfie: '',
        name: '',
        first_name: '',
        last_name: '',
        patronymic: '',
        issuing_authority: '',
        subdivision_code: '',
      }
    },
    resetFirstVerification: (state) => {
      state.firstVerif = {
        first_name: '',
        patronymic: '',
        last_name: '',
        living_address: '',
        birthdate: null,
        passport_series: '',
        passport_number: '',
        passport_date: null,
        passport_address_registration: '',
        passport_photo: '',
        inn: '',
        selfie: '',
        issuing_authority: '',
        subdivision_code: '',
      }
    },
    setIsOpenFirstStep: (state, action) => {
      state.isOpenFirstStep = action.payload
    },
    setIsOpenSecondStep: (state, action) => {
      state.isOpenSecondStep = action.payload
    },
  },
})

export const {
  setFirstVerification,
  setIndividualField,
  setJuridicalField,
  resetIndividual,
  resetJuridical,
  setEditPhysicalPerson,
  resetPhysical,
  resetFirstVerification,
  setOwnershipId,
  setInitialsItemsOwnership,
  setItemsOwnership,
  resetBirthday,
  resetPassportDate,
  setIsShowFields,
  setDisabledFields,
  setIsOpenFirstStep,
  setIsOpenSecondStep,
} = ownershipSlice.actions

export const ownershipReducer = ownershipSlice.reducer
