import IconPropsInterface from './IconPropsInterface'

export const Eyeoff = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 20 20"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M2.5 2.5L17.5 17.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.833333"
      />
      <path
        d="M8.8207 8.82227C8.50797 9.13478 8.33219 9.55873 8.33203 10.0008C8.33188 10.443 8.50736 10.867 8.81987 11.1798C9.13238 11.4925 9.55633 11.6683 9.99845 11.6684C10.4406 11.6686 10.8646 11.4931 11.1774 11.1806"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.833333"
      />
      <path
        d="M7.8038 4.47023C8.51832 4.26583 9.25813 4.16343 10.0013 4.16607C13.3346 4.16607 16.1121 6.11023 18.3346 9.9994C17.6863 11.1336 16.9913 12.1027 16.2488 12.9061M14.4655 14.4569C13.1063 15.3736 11.6196 15.8327 10.0013 15.8327C6.66797 15.8327 3.89047 13.8886 1.66797 9.9994C2.8088 8.00357 4.09547 6.52023 5.52797 5.54856"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.833333"
      />
    </svg>
  )
}
