import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { ExecutorOfferTaskItem } from '@pages/TasksBoard/ExecutorOffer/ExecutorOfferTaskItem'
import { FinanceInfo } from '@pages/TasksBoard/ExecutorOffer/FinanceInfo'
import { ValidUntil } from '@pages/TasksBoard/ExecutorOffer/ValidUntil'
import {
  addMainTask,
  incrementModalContentStep,
  resetExecutorOffer,
  setTasks,
} from '@pages/TasksBoard/ExecutorOffer/executorOfferSlice'
import { useGetTaskByIdQuery } from '@pages/TasksBoard/taskBoardApi'
import { useAppDispatch, useAppSelector } from '@src/hooks/store'
import { usePermissions } from '@src/hooks/usePermissions'
import { Box } from '@ui/Box'
import { MainButton } from '@ui/Buttons/MainButton'
import { MediumTitle, RegularText } from '@ui/Typography'

export const SelectTasks = () => {
  const dispatch = useAppDispatch()
  const { taskId } = useParams()
  const { hasPermission: canViewOwnershipTypes } = usePermissions(
    'ownership_type_view'
  )

  const {
    selectedTasks,
    allTasks,
    ownershipTypeId,
    bankAccountId,
    validUntil,
  } = useAppSelector((state) => state.executorOffer)

  const { data: task } = useGetTaskByIdQuery(taskId)
  const { taskChain } = task || {}

  useEffect(() => {
    dispatch(
      setTasks(
        taskChain?.map((item) => {
          return {
            ...item,
            isActive: item.id in selectedTasks,
          }
        })
      )
    )
  }, [dispatch, selectedTasks, taskChain, taskId])

  const mainTask = useMemo(() => {
    return allTasks.find((item) => item.id === +taskId!)
  }, [allTasks, taskId])

  const restTasks = useMemo(() => {
    return allTasks.filter((item) => item.id !== +taskId!)
  }, [allTasks, taskId])

  useEffect(() => {
    if (mainTask) {
      dispatch(addMainTask(mainTask))
    }
  }, [dispatch, mainTask])

  return (
    <>
      <MediumTitle style={{ marginBottom: '20px' }}>
        Предложить сделку:
      </MediumTitle>
      {canViewOwnershipTypes ? (
        <FinanceInfo />
      ) : (
        <RegularText color={'accent'} style={{ marginBottom: '20px' }}>
          Обратитесь к владельцу спейса за правами на просмотр форм
          собственности
        </RegularText>
      )}

      <ExecutorOfferTaskItem
        description={mainTask?.description || ''}
        title={mainTask?.title || ''}
        taskId={mainTask?.id || 0}
        isActive={true}
        taskType={mainTask?.taskType || 'rent'}
        disabled
      />
      <MediumTitle style={{ marginTop: '20px' }}>
        Добавить задачи из цепочки:
      </MediumTitle>
      {restTasks.map(({ description, isActive, taskType, title, id }) => {
        return (
          <ExecutorOfferTaskItem
            key={id}
            description={description}
            title={title}
            taskId={id}
            isActive={isActive}
            taskType={taskType}
          />
        )
      })}
      <ValidUntil />
      <Box flex cg={10} justifyContent={'flex-end'} mt={30}>
        <MainButton
          fillType={'lightFilled'}
          onClick={() => {
            dispatch(resetExecutorOffer())
          }}
        >
          Отмена
        </MainButton>
        <MainButton
          fillType={'fullFilled'}
          onClick={() => {
            dispatch(incrementModalContentStep())
          }}
          disabled={
            ownershipTypeId < 1 || !validUntil || !canViewOwnershipTypes
          }
        >
          Продолжить
        </MainButton>
      </Box>
    </>
  )
}
