import {
  AppealType,
  IAppealItem,
  IAppealTheme,
  IAppealsListRequest,
  ICreateAppealRequest,
} from '@src/shared/appeals/interfaces'
import { IPaginatedItems } from '@src/shared/interfaces/apiResponse/IPagination'
import { ISuccessResponse } from '@src/shared/interfaces/apiResponse/ISuccessResponse'
import { clientApiSlice } from '@src/store/slices/clientApiSlice'
import { formatUrlGetParamsFromObject } from '@src/utils/helpers/formatUrlGetParamsFromObject'

export const appealsApiSlice = clientApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAppeals: builder.query<
      ISuccessResponse<IPaginatedItems<IAppealItem>>,
      IAppealsListRequest
    >({
      query: ({ page, limit, type }) => ({
        url: `/appeals${formatUrlGetParamsFromObject({ page, limit, type })}`,
        method: 'GET',
      }),
    }),
    getAppealThemes: builder.query<
      ISuccessResponse<IAppealTheme[]>,
      AppealType
    >({
      query: (type) => ({
        url: `/appeals/themes${formatUrlGetParamsFromObject({ type })}`,
        method: 'GET',
      }),
    }),
    createAppeal: builder.mutation<
      ISuccessResponse<IAppealItem>,
      ICreateAppealRequest
    >({
      query: (args) => ({
        url: `/appeals`,
        method: 'POST',
        body: args,
      }),
    }),
  }),
})

export const {
  useLazyGetAppealsQuery,
  useGetAppealThemesQuery,
  useCreateAppealMutation,
} = appealsApiSlice
