export const FileDoc = () => {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3">
        <path
          opacity="0.3"
          d="M13.9669 4.92478C13.4202 4.92425 12.8961 4.70683 12.5095 4.32026C12.1229 3.93369 11.9055 3.40955 11.9049 2.86286V1.85845e-07H2.69199C2.33852 -0.000131161 1.98848 0.0693614 1.66188 0.204532C1.33528 0.339702 1.03852 0.537897 0.788534 0.787782C0.538547 1.03767 0.34024 1.33435 0.204943 1.66089C0.0696456 1.98742 -2.44059e-08 2.33741 0 2.69087V18.9121C-2.44059e-08 19.2656 0.0696456 19.6156 0.204943 19.9421C0.34024 20.2687 0.538547 20.5654 0.788534 20.8152C1.03852 21.0651 1.33528 21.2633 1.66188 21.3985C1.98848 21.5336 2.33852 21.6031 2.69199 21.603H13.7519C14.1053 21.603 14.4552 21.5334 14.7817 21.3982C15.1082 21.263 15.4049 21.0647 15.6548 20.8149C15.9046 20.565 16.1028 20.2684 16.2381 19.9419C16.3733 19.6154 16.4429 19.2655 16.4429 18.9121V4.92478H13.9669Z"
          fill="#006CEC"
        />
      </g>
      <path
        d="M16.4442 4.92462H13.9682C13.4215 4.92409 12.8974 4.70668 12.5108 4.32013C12.1242 3.93357 11.9068 3.40944 11.9062 2.86277V0L16.4442 4.92462Z"
        fill="#006CEC"
      />
      <path
        d="M13.0573 8.37397H2.73337C2.63418 8.37397 2.53906 8.33457 2.46892 8.26443C2.39878 8.19429 2.35938 8.09917 2.35938 7.99999C2.35924 7.95079 2.36883 7.90205 2.38756 7.85656C2.4063 7.81107 2.43383 7.76972 2.46857 7.73488C2.50331 7.70005 2.54459 7.67242 2.59003 7.65356C2.63547 7.63471 2.68418 7.625 2.73337 7.625H13.0573C13.1568 7.625 13.2522 7.66451 13.3225 7.73483C13.3928 7.80516 13.4323 7.90053 13.4323 7.99999C13.4321 8.09927 13.3924 8.1944 13.3221 8.2645C13.2518 8.33461 13.1566 8.37397 13.0573 8.37397Z"
        fill="#006CEC"
      />
      <path
        d="M13.0573 10.6083H2.73337C2.63418 10.6083 2.53906 10.5689 2.46892 10.4988C2.39878 10.4287 2.35938 10.3335 2.35938 10.2344C2.35924 10.1852 2.36883 10.1364 2.38756 10.0909C2.4063 10.0454 2.43383 10.0041 2.46857 9.96926C2.50331 9.93442 2.54459 9.9068 2.59003 9.88794C2.63547 9.86908 2.68418 9.85937 2.73337 9.85938H13.0573C13.1568 9.85938 13.2522 9.89886 13.3225 9.96919C13.3928 10.0395 13.4323 10.1349 13.4323 10.2344C13.4321 10.3336 13.3924 10.4287 13.3221 10.4989C13.2518 10.569 13.1566 10.6083 13.0573 10.6083Z"
        fill="#006CEC"
      />
      <path
        d="M13.0573 12.8466H2.73337C2.68418 12.8466 2.63547 12.8369 2.59003 12.8181C2.54459 12.7992 2.50331 12.7716 2.46857 12.7367C2.43383 12.7019 2.4063 12.6606 2.38756 12.6151C2.36883 12.5696 2.35924 12.5208 2.35938 12.4716C2.35938 12.3725 2.39878 12.2773 2.46892 12.2072C2.53906 12.1371 2.63418 12.0977 2.73337 12.0977H13.0573C13.1566 12.0977 13.2518 12.137 13.3221 12.2071C13.3924 12.2772 13.4321 12.3724 13.4323 12.4716C13.4323 12.5711 13.3928 12.6665 13.3225 12.7368C13.2522 12.8071 13.1568 12.8466 13.0573 12.8466Z"
        fill="#006CEC"
      />
      <path
        d="M8.98535 15.0849H2.73337C2.68418 15.0849 2.63547 15.0752 2.59003 15.0563C2.54459 15.0375 2.50331 15.0098 2.46857 14.975C2.43383 14.9402 2.4063 14.8988 2.38756 14.8534C2.36883 14.8079 2.35924 14.7591 2.35938 14.7099C2.35938 14.6107 2.39878 14.5156 2.46892 14.4455C2.53906 14.3753 2.63418 14.3359 2.73337 14.3359H8.98535C9.08454 14.3359 9.17967 14.3753 9.24981 14.4455C9.31995 14.5156 9.35934 14.6107 9.35934 14.7099C9.35948 14.7591 9.34991 14.8079 9.33117 14.8534C9.31243 14.8988 9.28491 14.9402 9.25016 14.975C9.21542 15.0098 9.17415 15.0375 9.12871 15.0563C9.08326 15.0752 9.03454 15.0849 8.98535 15.0849Z"
        fill="#006CEC"
      />
      <path
        d="M18.3912 17.0586H5.88724C5.00027 17.0586 4.28125 17.7776 4.28125 18.6645V22.3922C4.28125 23.2791 5.00027 23.9981 5.88724 23.9981H18.3912C19.2781 23.9981 19.9972 23.2791 19.9972 22.3922V18.6645C19.9972 17.7776 19.2781 17.0586 18.3912 17.0586Z"
        fill="#006CEC"
      />
      <path
        d="M7.62019 21.3359V22.0629H6.86719V21.3359H7.62019Z"
        fill="white"
      />
      <path
        d="M10.516 21.3409C10.3908 21.5683 10.2017 21.754 9.972 21.8749C9.71355 22.0071 9.4262 22.0727 9.13599 22.0659H8V19.0591H9.13599C9.42642 19.052 9.71417 19.1161 9.97399 19.2461C10.2027 19.3649 10.3913 19.5485 10.516 19.7741C10.6453 20.0141 10.7104 20.2834 10.705 20.556C10.7101 20.8295 10.6451 21.0998 10.516 21.3409ZM9.734 21.19C9.81529 21.1065 9.87826 21.007 9.9189 20.8979C9.95954 20.7887 9.97695 20.6723 9.97 20.556C9.97699 20.4399 9.9596 20.3236 9.91896 20.2146C9.87832 20.1056 9.81531 20.0063 9.734 19.9231C9.55181 19.7633 9.31385 19.6821 9.072 19.6971H8.729V21.4149H9.07299C9.3143 21.4295 9.55169 21.3487 9.734 21.19Z"
        fill="white"
      />
      <path
        d="M13.3175 19.2079C13.5463 19.3378 13.7354 19.5276 13.8645 19.7568C13.9998 19.9989 14.0688 20.2724 14.0645 20.5498C14.0691 20.8277 14.0001 21.102 13.8645 21.3447C13.735 21.5748 13.5452 21.7653 13.3155 21.8956C13.0817 22.0291 12.8167 22.0981 12.5475 22.0956C12.2783 22.098 12.0133 22.029 11.7795 21.8956C11.5499 21.7656 11.3604 21.575 11.2315 21.3447C11.0955 21.1021 11.0265 20.8278 11.0315 20.5498C11.0263 20.2723 11.0954 19.9986 11.2315 19.7568C11.361 19.5275 11.5504 19.3378 11.7795 19.2079C12.0133 19.0745 12.2783 19.0055 12.5475 19.0079C12.8173 19.0053 13.083 19.0743 13.3175 19.2079ZM11.9775 19.9178C11.83 20.0942 11.7549 20.3201 11.7675 20.5498C11.7549 20.7785 11.83 21.0034 11.9775 21.1787C12.0494 21.2568 12.1375 21.3182 12.2356 21.3586C12.3337 21.399 12.4395 21.4175 12.5455 21.4127C12.6512 21.4172 12.7566 21.3986 12.8544 21.3582C12.9521 21.3178 13.0399 21.2565 13.1115 21.1787C13.2597 21.0037 13.3356 20.7788 13.3235 20.5498C13.3355 20.3211 13.2605 20.0964 13.1135 19.9208C13.0417 19.8427 12.9536 19.7812 12.8555 19.7408C12.7573 19.7004 12.6515 19.6819 12.5455 19.6868C12.4398 19.682 12.3342 19.7001 12.2362 19.74C12.1381 19.7799 12.0499 19.8405 11.9775 19.9178Z"
        fill="white"
      />
      <path
        d="M16.7962 19.3272C17.0498 19.5336 17.2223 19.8229 17.2832 20.1442H16.5132C16.4612 20.011 16.3702 19.8967 16.2522 19.8162C16.1277 19.7352 15.9816 19.6937 15.8332 19.6972C15.7351 19.694 15.6377 19.7135 15.5484 19.7541C15.4591 19.7947 15.3803 19.8553 15.3182 19.9312C15.1789 20.1093 15.108 20.3313 15.1182 20.5571C15.108 20.782 15.179 21.0031 15.3182 21.1801C15.3808 21.2552 15.4597 21.3151 15.549 21.3551C15.6382 21.3951 15.7354 21.4143 15.8332 21.411C15.9817 21.415 16.1279 21.3735 16.2522 21.2921C16.3696 21.213 16.4605 21.1005 16.5132 20.9691H17.2832C17.2213 21.2894 17.0489 21.5778 16.7962 21.784C16.5241 21.9917 16.1881 22.0978 15.8462 22.084C15.5767 22.0897 15.3107 22.0227 15.0762 21.89C14.8566 21.7624 14.6785 21.5743 14.5632 21.3481C14.4392 21.1 14.3774 20.8254 14.3832 20.5481C14.3775 20.2708 14.4393 19.9963 14.5632 19.7482C14.6785 19.522 14.8566 19.3338 15.0762 19.2062C15.3107 19.0736 15.5767 19.0066 15.8462 19.0122C16.1899 19.0031 16.526 19.1146 16.7962 19.3272Z"
        fill="white"
      />
    </svg>
  )
}
