export const flags = {
  //Модуль чата, подключение при загрузке страницы, ссылка на чаты в хедере, а также кнопки "написать"
  chat: true,
  //Возможность записать аудиосообщение в чате, отображение аудиосообщений в истории сообщений.
  chat_audio_messages: false,
  // Бонусы | Неправильно отображается период проведения программы
  '#8692zqq97': true,
  // Деталка заявки | Добавить поле without_driver в создание/изменение задачи, для Доставки добавили поле weight_unit_type
  '#8692yvy7v': true,
  // Автоответы характеристики
  '#8692p9ugp': true,
  //Модуль техники(подтверждение техники)
  '8692x8n2u_vehicles': true,
  // Метод attach для изображений
  '8692xu4gx_individual_enterpreneur': true,
  // Модуль Support (section_slug)
  '#8692wzh93_question_answer': true,
  // Задача. Типы упаковки для грузоперевозок
  '#8692zat78': true,
  // изменение type в задачах Аренды и Доставки
  '#86931jt0u_type': true,
  // Движение заявки и задач со стороны заказчика по статусам
  '#869319heg': true,
  // Задачи - Страница задачи для заказчика
  '#869306a42': true,
  // Заявки/Задачи - править типы точек
  '#86932nj8c': true,
  disabled_at: true,
  // Партнерка страница для непартнера: убрать блок со ссылками, скрыть пустые ссылки с незарегистрированного хэдера
  partnership_links: false,
  //Раздел автоответы
  auto_offers: true,
  // Планые ф-ции основная страница
  paid_features: true,
  // Планая ф-ция "Скрытие заявки от ФС"
  paid_steals: true,
  // Планая ф-ция "Добавить фон задаче"
  paid_tasks_bg: true,
  // Добавить поле kladr_region_id в создание задач с типом аренда и доставка материалов
  '#869366zqr': true,
  // Добавить поле region_id в технике
  region_id: true,
  eSign: true,
  eSignAuth: false,
  //Заявки - Добавить права
  '#86937e1ct': true,
  // Задача : добавить информацию об объекте в деталку задачи
  '#86937q7u0': true,
  // Планая ф-ция "Автоответы"
  paid_auto_offer: true,
  // Давать 404 ошибку на фронте когда бек присылает ее
  '#86936khtt': true,
  // Показать номера исполнителей (кроме доставки материалов)
  paid_executors_phones: true,
  // Платная функция. Разослать задачу исполнителям
  paid_task_send: true,
  // Попасть в верхний блок контактов
  paid_top_contact: true,
  // Подъем задачи в верх списка объявлений
  paid_ascent_task: true,
  //Крестики в попапах заменить на большие
  '#8693bkx1c': true,
  // Платная ф-ция сотрудники
  paid_employees: true,
  // Платная функция поднятие оффера
  paid_ascent_offer: true,
  // Cтраница Мои покупки, активация платных услуг в рамках тарифов
  '#8693d79ka': true,
  auto_search: true,
  sentry: false,
  // Поле "Доступно для автоответов" в заявке
  auto_offer_enabled_field: false,
  // валидация для поля Объем в грузоперевозках. Изменения бэка - https://app.clickup.com/t/8693rzxv3
  '8693rxy0e': true,
  // новое апи для изменения счетов и редактирование задач в сделке(Для исполнителя и Заказчика)
  '8693r5rjp': true,
  // изменения в создании задач - https://app.clickup.com/t/8693ruu0r (объем не целые числа)
  '8693ruu0r': true,
  // Заявка. Задачи доставка. Убрать объем и вес https://app.clickup.com/t/8693v3fmu
  '8693v3fmu': true,
  //   отказ от подписания доп соглашения(Сделка) https://app.clickup.com/t/8693v7xqg
  refusalToSignAdditionalAgreement: true,
  // Задача. Создание. Поменять зависимость типа техники и наименования грузов https://app.clickup.com/t/8693z4bn9
  '8693z4bn9': true,
  // Задача. Создание из деталки. Поменять зависимость типа техники и наименования грузов https://app.clickup.com/t/869429qwg
  '869429qwg': true,
  // Заявки https://app.clickup.com/t/86946rpr0
  '86946rpr0': true,
  // подписание договора без подписи(Сделка)
  isContractWithoutSignature: true,
  // Обязателен Vin (создание, редактирование техники)
  isNecessarilyVin: false,
  // Обязателен СТС (создание, редактирование техники)
  isNecessarilySts: false,
  // Обязателен Регистрационный знак (создание, редактирование техники)
  isNecessarilyNumber: false,
  // Обязателен Год выпуска (создание, редактирование техники)
  isNecessarilyYearsIssue: false,
  // Обязателен Мощность двигателя (создание, редактирование техники)
  isNecessarilyEnginePower: false,
  // Обязателен Цвет (создание, редактирование техники)
  isNecessarilyColor: false,
  // Обязательна Масса (создание, редактирование техники)
  isNecessarilyWeight: false,
  // Обязательны габариты (создание, редактирование техники)
  isNecessarilyDimensions: false,
  // Убираем валидацию для задачи https://app.clickup.com/t/8694b5qmd
  isValidationForOwnership: false,
  hints: true,
  user_actions: false,
  counter_deals: true,
  paid_auto_search: true,
  'VD-2692': true,
}
