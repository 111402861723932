import { WithOptional } from '@src/shared/interfaces/helpers/WithOptional'

import IconPropsInterface from './IconPropsInterface'

export const PdfFile = ({
  size = 20,
  style,
}: WithOptional<IconPropsInterface, 'color'>) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style, flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M16.5 22H7.5C6.70435 22 5.94129 21.6839 5.37868 21.1213C4.81607 20.5587 4.5 19.7956 4.5 19V5C4.5 4.20435 4.81607 3.44129 5.37868 2.87868C5.94129 2.31607 6.70435 2 7.5 2H14.09C14.3523 2.0011 14.6037 2.10526 14.79 2.29L19.21 6.71C19.3947 6.89626 19.4989 7.14766 19.5 7.41V19C19.5 19.7956 19.1839 20.5587 18.6213 21.1213C18.0587 21.6839 17.2956 22 16.5 22Z"
        fill="#F44336"
      />
      <path
        d="M18.8695 7.70883H15.2695C14.8717 7.70883 14.4902 7.5508 14.2089 7.26949C13.9276 6.98819 13.7695 6.60666 13.7695 6.20883V2.60883C13.7691 2.49973 13.8011 2.39297 13.8615 2.30212C13.9219 2.21127 14.008 2.14044 14.1088 2.09863C14.2095 2.05682 14.3205 2.04593 14.4274 2.06733C14.5344 2.08873 14.6555 2.14453 14.7415 2.23047L19.261 6.75781C19.3508 6.85156 19.3896 6.94395 19.411 7.05093C19.4324 7.15791 19.4215 7.26884 19.3797 7.36961C19.3379 7.47038 19.2671 7.55643 19.1762 7.61684C19.0854 7.67724 18.9786 7.70927 18.8695 7.70883Z"
        fill="#FF8A80"
      />
      <path
        d="M7.88967 19.1301C7.82036 19.1401 7.74966 19.1339 7.68319 19.1118C7.61672 19.0897 7.55633 19.0525 7.5068 19.0029C7.45728 18.9534 7.42 18.893 7.39793 18.8265C7.37586 18.7601 7.36961 18.6894 7.37967 18.6201V15.6901C7.36996 15.6217 7.37611 15.552 7.39764 15.4864C7.41918 15.4208 7.45552 15.361 7.50386 15.3117C7.5522 15.2624 7.61124 15.2249 7.67641 15.2021C7.74158 15.1792 7.81113 15.1717 7.87967 15.1801C7.94118 15.1731 8.00345 15.179 8.0626 15.1972C8.12174 15.2155 8.17649 15.2457 8.22342 15.2861C8.27034 15.3264 8.30844 15.376 8.33533 15.4318C8.36221 15.4875 8.37731 15.5482 8.37967 15.6101C8.4603 15.4697 8.58262 15.3578 8.72967 15.2901C8.8871 15.2084 9.06235 15.1672 9.23967 15.1701C9.46592 15.1669 9.68823 15.2294 9.87967 15.3501C10.0683 15.476 10.2174 15.6528 10.3097 15.8601C10.4156 16.1082 10.4702 16.3752 10.4702 16.6451C10.4702 16.9149 10.4156 17.1819 10.3097 17.4301C10.1733 17.7079 9.93491 17.9223 9.64421 18.0286C9.35352 18.1348 9.03302 18.1246 8.74967 18.0001C8.60788 17.9338 8.48686 17.8301 8.39967 17.7001V18.6101C8.40545 18.6789 8.39688 18.7483 8.3745 18.8137C8.35213 18.8791 8.31643 18.9392 8.26967 18.9901C8.21892 19.0376 8.15913 19.0745 8.09386 19.0986C8.02859 19.1226 7.95916 19.1333 7.88967 19.1301ZM8.88967 17.3701C8.96252 17.3709 9.03459 17.3551 9.10043 17.3239C9.16627 17.2927 9.22414 17.2469 9.26967 17.1901C9.38114 17.0296 9.43086 16.8343 9.40967 16.6401C9.42996 16.4459 9.38032 16.2509 9.26967 16.0901C9.22226 16.0361 9.16378 15.993 9.0982 15.9636C9.03263 15.9343 8.96151 15.9194 8.88967 15.9201C8.81626 15.9187 8.74341 15.9331 8.6761 15.9625C8.60879 15.9918 8.54861 16.0353 8.49967 16.0901C8.39423 16.2532 8.34507 16.4464 8.35967 16.6401C8.34355 16.8339 8.39284 17.0275 8.49967 17.1901C8.5492 17.2526 8.61355 17.3018 8.68688 17.3332C8.76021 17.3647 8.84022 17.3773 8.91967 17.3701H8.88967Z"
        fill="#FFEBEE"
      />
      <path
        d="M12.168 18.1095C11.9456 18.1179 11.726 18.0586 11.538 17.9395C11.3464 17.8142 11.1939 17.6374 11.098 17.4295C10.9921 17.1814 10.9375 16.9143 10.9375 16.6445C10.9375 16.3747 10.9921 16.1077 11.098 15.8595C11.1939 15.6516 11.3464 15.4749 11.538 15.3495C11.7251 15.2275 11.9447 15.1648 12.168 15.1695C12.342 15.1679 12.5137 15.2091 12.668 15.2895C12.8062 15.3522 12.9265 15.4485 13.018 15.5695V14.4795C13.0079 14.4102 13.0142 14.3395 13.0363 14.273C13.0583 14.2066 13.0956 14.1462 13.1451 14.0966C13.1947 14.0471 13.255 14.0098 13.3215 13.9878C13.388 13.9657 13.4587 13.9595 13.528 13.9695C13.5953 13.9645 13.6629 13.9735 13.7266 13.9959C13.7902 14.0183 13.8486 14.0535 13.898 14.0995C13.9448 14.1504 13.9805 14.2105 14.0028 14.2759C14.0252 14.3413 14.0338 14.4106 14.028 14.4795V17.5895C14.0386 17.7221 13.9961 17.8535 13.9098 17.9548C13.8236 18.0561 13.7006 18.1189 13.568 18.1295C13.5023 18.1348 13.4363 18.127 13.3736 18.1068C13.3109 18.0865 13.2529 18.0541 13.2027 18.0114C13.1015 17.9251 13.0386 17.8021 13.028 17.6695C12.951 17.8092 12.8321 17.9212 12.688 17.9895C12.5266 18.0701 12.3484 18.1112 12.168 18.1095ZM12.498 17.3695C12.5708 17.3703 12.6429 17.3545 12.7088 17.3233C12.7746 17.2922 12.8325 17.2464 12.878 17.1895C12.9938 17.0308 13.0472 16.835 13.028 16.6395C13.0454 16.4441 12.9922 16.249 12.878 16.0895C12.8306 16.0355 12.7721 15.9924 12.7065 15.9631C12.641 15.9338 12.5698 15.9189 12.498 15.9195C12.4261 15.9182 12.3547 15.9328 12.289 15.9622C12.2234 15.9915 12.165 16.035 12.118 16.0895C12.0038 16.249 11.9506 16.4441 11.968 16.6395C11.9488 16.835 12.0022 17.0308 12.118 17.1895C12.1625 17.2476 12.2201 17.2942 12.2862 17.3255C12.3523 17.3568 12.4249 17.3719 12.498 17.3695Z"
        fill="#FFEBEE"
      />
      <path
        d="M15.5216 18.1001C15.4527 18.1081 15.3829 18.1005 15.3174 18.0777C15.2519 18.0549 15.1923 18.0175 15.1433 17.9685C15.0942 17.9194 15.0569 17.8599 15.0341 17.7944C15.0113 17.7289 15.0036 17.6591 15.0116 17.5901V16.0001H14.8616C14.8094 16.0084 14.756 16.0043 14.7056 15.9883C14.6553 15.9722 14.6093 15.9447 14.5715 15.9078C14.5336 15.8709 14.5049 15.8257 14.4875 15.7758C14.4702 15.7258 14.4648 15.6726 14.4716 15.6201C14.4716 15.3701 14.6016 15.2501 14.8616 15.2501H15.0016C14.9973 15.0898 15.0251 14.9301 15.0835 14.7807C15.1419 14.6313 15.2297 14.4951 15.3416 14.3801C15.5997 14.1611 15.9234 14.0344 16.2616 14.0201H16.4316C16.5247 13.9829 16.6286 13.9829 16.7216 14.0201C16.7891 14.0586 16.8419 14.1184 16.8716 14.1901C16.8864 14.2627 16.8864 14.3376 16.8716 14.4101C16.8601 14.4807 16.8289 14.5465 16.7816 14.6001C16.7372 14.6559 16.6725 14.6919 16.6016 14.7001H16.5216C16.3754 14.6936 16.2312 14.7358 16.1116 14.8201C16.0729 14.8614 16.0431 14.9103 16.0242 14.9636C16.0052 15.017 15.9976 15.0737 16.0016 15.1301V15.2201H16.3216C16.5816 15.2201 16.7116 15.3401 16.7116 15.5901C16.7185 15.6426 16.713 15.6958 16.6957 15.7458C16.6784 15.7957 16.6496 15.8409 16.6118 15.8778C16.5739 15.9147 16.528 15.9422 16.4776 15.9583C16.4273 15.9743 16.3738 15.9784 16.3216 15.9701H16.0016V17.5701C16.0142 17.6382 16.0109 17.7082 15.9919 17.7747C15.973 17.8412 15.9391 17.9025 15.8926 17.9538C15.8462 18.005 15.7886 18.0449 15.7242 18.0703C15.6599 18.0957 15.5906 18.1059 15.5216 18.1001Z"
        fill="#FFEBEE"
      />
    </svg>
  )
}
