import { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'

import { TimeoutId } from '@reduxjs/toolkit/dist/query/core/buildMiddleware/types'
import { useAppDispatch, useAppSelector } from '@src/hooks/store'
import { setCheckIsOnline } from '@src/shared/CheckOnline/checkOnlineSlice'
import { Box } from '@ui/Box'
import { Spinner } from '@ui/Loader/Spinner'
import { RegularText } from '@ui/Typography'

const StyledOverlayWarning = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`
const StyledImage = styled.img`
  width: 2px;
  height: 2px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  pointer-events: none;
`
const IMAGE_URL = `${import.meta.env.VITE_CLIENT_URL}/check_online.png`
const POLLING_INTERVAL = 4000
export const CheckOnline = () => {
  const dispatch = useAppDispatch()
  const { isOnline: isOnlineGlobal } = useAppSelector(
    (state) => state.checkIsOnline
  )
  const intervalRef = useRef<TimeoutId>()
  const [srcHash, setSrcHash] = useState(Date.now())

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setSrcHash(Date.now())
    }, POLLING_INTERVAL)
    return () => {
      clearInterval(intervalRef.current)
    }
  }, [])

  const handleSuccess = () => {
    dispatch(setCheckIsOnline(true))
  }
  const handleError = () => {
    dispatch(setCheckIsOnline(false))
  }

  return (
    <>
      <StyledImage
        onLoad={handleSuccess}
        onError={handleError}
        src={`${IMAGE_URL}?${srcHash}`}
      />
      {!isOnlineGlobal &&
        createPortal(
          <StyledOverlayWarning>
            <Box flex cg={20}>
              <RegularText>Проверьте соединение с сетью</RegularText>
              <Spinner />
            </Box>
          </StyledOverlayWarning>,
          document.querySelector('#over') as Element
        )}
    </>
  )
}
