import { paidFeaturesApi } from '@pages/PaidFeatures/api/paidFeaturesApi'
import { IPaidFeatureDetailWithRate } from '@pages/PaidFeatures/types/IPaidFeatureWithRate'
import { EntityState, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { RootState } from '@src/store'
import {
  IPaginationAdditionalState,
  addItemsToAdapter,
  clearAdapterItems,
  getInitialPaginationState,
} from '@src/utils/helpers/getInitialPaginationState'

interface IPaidFeaturesState {
  history: EntityState<IPaidFeatureDetailWithRate> & IPaginationAdditionalState
}

const historyAdapter = createEntityAdapter<IPaidFeatureDetailWithRate>()

const paidFeaturesInitialState: IPaidFeaturesState = {
  history: historyAdapter.getInitialState(getInitialPaginationState()),
}

const paidFeaturesSlice = createSlice({
  name: 'paidFeatures',
  initialState: paidFeaturesInitialState,
  reducers: {
    paidFeaturesHistoryCleared: (state) => {
      clearAdapterItems(historyAdapter, state.history)
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        paidFeaturesApi.endpoints.getPaidFeaturesHistory.matchFulfilled,
        (state, { payload }) => {
          addItemsToAdapter(historyAdapter, state.history, payload)
        }
      )
      .addMatcher(
        paidFeaturesApi.endpoints.togglePaidFeatureAutoRenewal.matchFulfilled,
        (state) => {
          clearAdapterItems(historyAdapter, state.history)
        }
      )
  },
})

export const { paidFeaturesHistoryCleared } = paidFeaturesSlice.actions

export const paidFeaturesSliceReducer = paidFeaturesSlice.reducer

export const {
  selectAll: selectPaidFeaturesHistory,
  selectById: selectPaidFeaturesHistoryItemById,
  selectIds: selectPaidFeaturesHistoryItemIds,
} = historyAdapter.getSelectors<RootState>(
  (state) => state.paidFeatures.history
)
