import Cookies from 'js-cookie'

import { refreshToken } from '@src/store/slices/baseAuth'

const SIGN_UP = 'sign-up'
const SIGN_IN = 'sign-in'
const SPACE = 'space'
const FORGOT_PASSWORD = 'forgot-password'

export const ROUTES = {
  SPACE,
  SIGN_UP,
  SIGN_IN,
  FORGOT_PASSWORD,
}
export const ACCESS_TOKEN =
  localStorage.getItem('ACCESS_TOKEN') || Cookies.get('ACCESS_TOKEN')

export const resendCodeTimer = 60

export const baseURL = import.meta.env.VITE_USERS_API
export const baseClientURL = import.meta.env.VITE_CLIENT_API

export const chatUrl = import.meta.env.VITE_CHAT_API

export const websocketChatUrl = import.meta.env.VITE_CHAT_WS

export const HOMEPAGE = import.meta.env.VITE_HOME_PAGE
