import IconPropsInterface from './IconPropsInterface'

export const Attachment = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 16 18"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M10.5012 4.83241L5.08455 10.2491C4.75303 10.5806 4.56678 11.0302 4.56678 11.4991C4.56678 11.9679 4.75303 12.4176 5.08455 12.7491C5.41607 13.0806 5.86571 13.2668 6.33455 13.2668C6.80339 13.2668 7.25303 13.0806 7.58455 12.7491L13.0012 7.33241C13.6643 6.66937 14.0368 5.77009 14.0368 4.83241C14.0368 3.89473 13.6643 2.99545 13.0012 2.33241C12.3382 1.66937 11.4389 1.29688 10.5012 1.29688C9.56354 1.29687 8.66426 1.66937 8.00122 2.33241L2.58455 7.74908C1.58999 8.74364 1.03125 10.0926 1.03125 11.4991C1.03125 12.9056 1.58999 14.2545 2.58455 15.2491C3.57911 16.2436 4.92803 16.8024 6.33455 16.8024C7.74107 16.8024 9.08999 16.2436 10.0846 15.2491L15.5012 9.83241"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
