import IconPropsInterface from '@icons/IconPropsInterface'

export const Extension = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9883 17L13.9883 19L15.9883 21"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4137 7.58575C14.1947 8.36675 14.1947 9.63275 13.4137 10.4137C12.6327 11.1947 11.3668 11.1947 10.5857 10.4137C9.80475 9.63275 9.80475 8.36675 10.5857 7.58575C11.3668 6.80475 12.6327 6.80475 13.4137 7.58575"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 7.5V10.5"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 7.5V10.5"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 15H5C3.895 15 3 14.105 3 13V5C3 3.895 3.895 3 5 3H19C20.105 3 21 3.895 21 5V11"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 15H19C20.105 15 21 15.895 21 17V17C21 18.105 20.105 19 19 19H14"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
