import IconPropsInterface from './IconPropsInterface'

export const IndividualEntrepreneur = ({
  size = 20,
  color,
}: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3 9.5C3 8.96957 3.21071 8.46086 3.58579 8.08579C3.96086 7.71071 4.46957 7.5 5 7.5H19C19.5304 7.5 20.0391 7.71071 20.4142 8.08579C20.7893 8.46086 21 8.96957 21 9.5V18.5C21 19.0304 20.7893 19.5391 20.4142 19.9142C20.0391 20.2893 19.5304 20.5 19 20.5H5C4.46957 20.5 3.96086 20.2893 3.58579 19.9142C3.21071 19.5391 3 19.0304 3 18.5V9.5ZM8 7.5V5.5C8 4.96957 8.21071 4.46086 8.58579 4.08579C8.96086 3.71071 9.46957 3.5 10 3.5H14C14.5304 3.5 15.0391 3.71071 15.4142 4.08579C15.7893 4.46086 16 4.96957 16 5.5V7.5" />
      <path
        d="M8 7.5V5.5C8 4.96957 8.21071 4.46086 8.58579 4.08579C8.96086 3.71071 9.46957 3.5 10 3.5H14C14.5304 3.5 15.0391 3.71071 15.4142 4.08579C15.7893 4.46086 16 4.96957 16 5.5V7.5M3 9.5C3 8.96957 3.21071 8.46086 3.58579 8.08579C3.96086 7.71071 4.46957 7.5 5 7.5H19C19.5304 7.5 20.0391 7.71071 20.4142 8.08579C20.7893 8.46086 21 8.96957 21 9.5V18.5C21 19.0304 20.7893 19.5391 20.4142 19.9142C20.0391 20.2893 19.5304 20.5 19 20.5H5C4.46957 20.5 3.96086 20.2893 3.58579 19.9142C3.21071 19.5391 3 19.0304 3 18.5V9.5Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 17L10.6667 13.3333L13.3333 14.5556L16 11.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
