import IconPropsInterface from '@icons/IconPropsInterface'

export const Phone = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.16667 1.3335H5.5L7.16667 5.50016L5.08333 6.75016C5.9758 8.55977 7.44039 10.0244 9.25 10.9168L10.5 8.8335L14.6667 10.5002V13.8335C14.6667 14.2755 14.4911 14.6994 14.1785 15.012C13.866 15.3246 13.442 15.5002 13 15.5002C9.74939 15.3026 6.68346 13.9222 4.38069 11.6195C2.07792 9.3167 0.697541 6.25078 0.5 3.00016C0.5 2.55814 0.675595 2.13421 0.988155 1.82165C1.30072 1.50909 1.72464 1.3335 2.16667 1.3335Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
