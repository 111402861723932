import IconPropsInterface from '@icons/IconPropsInterface'

export const Sun = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="sun-svg"
    >
      <g clipPath="url(#clip0_8791_68122)">
        <path
          d="M12.8327 9.99935C12.8327 11.5642 11.5642 12.8327 9.99935 12.8327C8.43454 12.8327 7.16602 11.5642 7.16602 9.99935C7.16602 8.43454 8.43454 7.16602 9.99935 7.16602C11.5642 7.16602 12.8327 8.43454 12.8327 9.99935Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 18L10 16M2 10L4 10M10 4L10 2M16 10L18 10M4 16L6 14M4 4L6 6M14 6L16 4M14 14L16 16"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_8791_68122">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
