import { createSlice } from '@reduxjs/toolkit'

export interface IMediaViewerState {
  type: 'video' | 'image' | 'mixed'
  items: {
    id?: number
    path: string
    description?: string
    type?: 'image' | 'video' //если общий type===mixed, то поле type нужно в каждом item
  }[]
  currentIndex: number | null
  infinite: boolean
}

const initialState: IMediaViewerState = {
  type: 'image',
  items: [],
  currentIndex: null,
  infinite: true,
}

const mediaViewerSlice = createSlice({
  name: 'mediaViewer',
  initialState,
  reducers: {
    openViewer: (state, action) => {
      state.items = action.payload.items
      state.currentIndex = action.payload.index
      state.type = action.payload.type
      state.infinite = action.payload.infinite || false
    },
    closeViewer: (state) => {
      state.currentIndex = null
      state.items = []
      state.type = 'image'
      state.infinite = true
    },
    nextMedia: (state) => {
      if (state.currentIndex === state.items.length - 1) {
        if (state.items[0].type) {
          state.type = state.items[0].type
        }
        state.currentIndex = 0
      } else {
        if (state.items[state.currentIndex! + 1].type) {
          state.type = state.items[state.currentIndex! + 1].type!
        }
        state.currentIndex! += 1
      }
    },
    prevMedia: (state) => {
      if (state.currentIndex === 0) {
        if (state.items[state.items.length - 1].type) {
          state.type = state.items[state.items.length - 1].type!
        }
        state.currentIndex = state.items.length - 1
      } else {
        if (state.items[state.currentIndex! - 1].type) {
          state.type = state.items[state.currentIndex! - 1].type!
        }
        state.currentIndex! -= 1
      }
    },
  },
})

export const { openViewer, closeViewer, nextMedia, prevMedia } =
  mediaViewerSlice.actions

export const mediaViewerReducer = mediaViewerSlice.reducer
