import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from '@src/store'

interface ITaskState {
  isIncreaseViewsPopupOpened: boolean
  isFindExecutorsPopupOpened: boolean
  isExecutorsPhonesPopupOpened: boolean
  isSendTaskPopupOpened: boolean
}

const initialState: ITaskState = {
  isIncreaseViewsPopupOpened: false,
  isFindExecutorsPopupOpened: false,
  isExecutorsPhonesPopupOpened: false,
  isSendTaskPopupOpened: false,
}

const taskSlice = createSlice({
  name: 'customerTaskDetail',
  initialState,
  reducers: {
    increaseViewsPopupToggled: (state, { payload }: PayloadAction<boolean>) => {
      state.isIncreaseViewsPopupOpened = payload
    },
    findExecutorsPopupToggled: (state, { payload }: PayloadAction<boolean>) => {
      state.isFindExecutorsPopupOpened = payload
    },
    executorsPhonesPopupToggled: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isExecutorsPhonesPopupOpened = payload
    },
    sendTaskPopupToggled: (state, { payload }: PayloadAction<boolean>) => {
      state.isSendTaskPopupOpened = payload
    },
  },
})

export const customerTaskDetailReducer = taskSlice.reducer

export const selectIsIncreaseViewsPopupOpened = (state: RootState) =>
  state.customerTaskDetail.isIncreaseViewsPopupOpened

export const {
  increaseViewsPopupToggled,
  findExecutorsPopupToggled,
  executorsPhonesPopupToggled,
  sendTaskPopupToggled,
} = taskSlice.actions
