import styled from 'styled-components'

import { getColor, getFontSize } from '@theme/helpers'

export const StyledTitleLevel1 = styled.div`
  ${getColor('text20')}
  ${getFontSize('xl')}
  padding-left: 30px;
  margin-bottom: 25px;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: ${({ theme }) => theme.colors.primary};
    height: 15px;
    width: 15px;
    border-radius: 50%;
  }
`

export const StyledTitleLevel2 = styled.div`
  ${getColor('text20')}
  ${getFontSize('l')}
  padding-left: 20px;
  margin-bottom: 20px;
  margin-left: 10px;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: ${({ theme }) => theme.colors.primary};
    height: 5px;
    width: 5px;
    border-radius: 50%;
  }
`
