import { memo } from 'react'

import {
  EMPLOYEE_CUSTOMER_VALUES,
  selectEmployeeCustomerOptions,
} from '@pages/TasksBoard/ExecutorOffer/constants'
import { setOfferFieldValue } from '@pages/TasksBoard/ExecutorOffer/executorOfferSlice'
import { InputWrapper } from '@pages/TasksBoard/ExecutorOffer/inputs/InputWrapper'
import { useAppDispatch, useAppSelector } from '@src/hooks/store'
import DropDown from '@ui/DropDown'
import { RegularText } from '@ui/Typography'

interface IProps {
  taskId: number
  isPreviewMode: boolean
}

// eslint-disable-next-line react/display-name
export const SelectEmployeeCustomer = memo(
  ({ taskId, isPreviewMode }: IProps) => {
    const dispatch = useAppDispatch()
    const fieldData = useAppSelector(
      (state) => state.executorOffer.selectedTasks[taskId]['customer_pay_fuel']
    )

    const handleChange = (value: any) => {
      // dispatch(
      //   setOfferFieldIsDisabled({
      //     taskId,
      //     fieldName: 'fuel_cost',
      //     value: value,
      //   })
      // )
      // dispatch(
      //   setOfferFieldIsDisabled({
      //     taskId,
      //     fieldName: 'fuel_cost_period',
      //     value: value,
      //   })
      // )
      dispatch(
        setOfferFieldValue({
          taskId,
          fieldName: 'customer_pay_fuel',
          value: value,
        })
      )
    }

    return (
      <InputWrapper
        title={'Плательщик ГСМ'}
        required={true}
        isDisabled={false}
        errorMessage={fieldData.errorMessage}
      >
        {isPreviewMode ? (
          <RegularText>
            {selectEmployeeCustomerOptions.find(
              (item) =>
                item.value === EMPLOYEE_CUSTOMER_VALUES.indexOf(fieldData.value)
            )?.name || 'Не задано'}
          </RegularText>
        ) : (
          <DropDown
            disabled={false}
            hasError={false}
            width={'100%'}
            height={45}
            items={selectEmployeeCustomerOptions}
            title={'Выберите плательщика'}
            activeValue={EMPLOYEE_CUSTOMER_VALUES.indexOf(fieldData.value)}
            handleChange={(value) => {
              handleChange(EMPLOYEE_CUSTOMER_VALUES[value || 0])
            }}
          />
        )}
      </InputWrapper>
    )
  }
)
