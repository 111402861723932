import { CSSProperties, ReactNode, RefObject, forwardRef } from 'react'
import styled from 'styled-components'

import { noop } from '@src/utils/helpers/noop'
import { useGetTheme } from '@theme/hooks'
import { BaseButton } from '@ui/Buttons/Base/BaseButton'
import { BorderVariant } from '@ui/Buttons/Base/interfaces'
import { Spinner } from '@ui/Loader/Spinner'

export interface IBaseButtonProps {
  onClick?: () => void
  fillType:
    | 'fullFilled'
    | 'lightFilled'
    | 'inverted'
    | 'paid'
    | 'paidLight'
    | 'withBorder'
  children: ReactNode
  disabled?: boolean
  fluid?: boolean
  style?: CSSProperties
  type?: 'button' | 'submit' | 'reset'
  isLoading?: boolean
  className?: string
  as?: 'button' | 'a'
  href?: string
  id?: string
  borderVariant?: BorderVariant
}

const StyledLoader = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%);
`

// eslint-disable-next-line react/display-name
export const MainButton = forwardRef(
  (
    {
      onClick,
      fillType,
      disabled,
      children,
      style,
      fluid,
      type,
      isLoading,
      className,
      as = 'button',
      href,
      id,
      borderVariant,
    }: IBaseButtonProps,
    ref
  ) => {
    const theme = useGetTheme()
    return (
      <BaseButton
        ref={ref as RefObject<HTMLButtonElement>}
        fillVariant={fillType}
        sizeVariant="regular"
        disabled={disabled || isLoading}
        onClick={isLoading ? noop : onClick}
        borderVariant={borderVariant}
        style={
          isLoading
            ? { position: 'relative', color: 'transparent', ...style }
            : style
        }
        fluid={fluid}
        type={type}
        className={className}
        as={as}
        href={href}
        id={id}
      >
        {isLoading && (
          <StyledLoader>
            <Spinner
              color={
                theme.colors[fillType === 'lightFilled' ? 'primary' : 'light']
              }
            />
          </StyledLoader>
        )}
        {children}
      </BaseButton>
    )
  }
)
