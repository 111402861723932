import IconPropsInterface from './IconPropsInterface'

export const Microphone = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 10 14"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M7.16068 3.23355C7.16068 2.14885 6.19343 1.26953 5.00026 1.26953C3.8071 1.26953 2.83984 2.14885 2.83984 3.23355V6.5069C2.83984 7.5916 3.8071 8.47092 5.00026 8.47092C6.19343 8.47092 7.16068 7.5916 7.16068 6.5069V3.23355Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.679688 6.22266C0.679688 7.55961 1.13492 8.8418 1.94523 9.78716C2.75554 10.7325 3.85456 11.2636 5.00052 11.2636C6.14648 11.2636 7.2455 10.7325 8.05581 9.78716C8.86612 8.8418 9.32135 7.55961 9.32135 6.22266"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 11.2656V13.426"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
