import IconPropsInterface from './IconPropsInterface'

export const Video = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 16 16"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M12.9987 1.33301H2.9987C2.07822 1.33301 1.33203 2.0792 1.33203 2.99967V12.9997C1.33203 13.9201 2.07822 14.6663 2.9987 14.6663H12.9987C13.9192 14.6663 14.6654 13.9201 14.6654 12.9997V2.99967C14.6654 2.0792 13.9192 1.33301 12.9987 1.33301Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.66797 1.33301V14.6663"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.332 1.33301V14.6663"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.33203 4.66699H4.66536"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.33203 11.333H4.66536"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.33203 8H14.6654"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.332 4.66699H14.6654"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.332 11.333H14.6654"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
