import IconPropsInterface from './IconPropsInterface'

export const Document = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1931_34019)">
        <path
          d="M6.66536 4.1665H4.9987C4.55667 4.1665 4.13275 4.3421 3.82019 4.65466C3.50763 4.96722 3.33203 5.39114 3.33203 5.83317V15.8332C3.33203 16.2752 3.50763 16.6991 3.82019 17.0117C4.13275 17.3242 4.55667 17.4998 4.9987 17.4998H9.7462"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15 11.6667V15.0001H18.3333"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.0013 9.1665V5.83317C15.0013 5.39114 14.8257 4.96722 14.5131 4.65466C14.2006 4.3421 13.7767 4.1665 13.3346 4.1665H11.668"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.0013 2.5H8.33464C7.41416 2.5 6.66797 3.24619 6.66797 4.16667C6.66797 5.08714 7.41416 5.83333 8.33464 5.83333H10.0013C10.9218 5.83333 11.668 5.08714 11.668 4.16667C11.668 3.24619 10.9218 2.5 10.0013 2.5Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.0013 18.3332C16.8423 18.3332 18.3346 16.8408 18.3346 14.9998C18.3346 13.1589 16.8423 11.6665 15.0013 11.6665C13.1604 11.6665 11.668 13.1589 11.668 14.9998C11.668 16.8408 13.1604 18.3332 15.0013 18.3332Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.66797 9.1665H10.0013"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.66797 12.5H9.16797"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1931_34019">
          <rect width={size} height={size} fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
