import IconPropsInterface from '@icons/IconPropsInterface'

export const Steals = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.6586 24.3448C38.7805 27.4659 38.7805 32.5327 35.6586 35.6592C32.5367 38.7803 27.4686 38.7803 24.3414 35.6592C21.2195 32.5381 21.2195 27.4713 24.3414 24.3448C27.4686 21.2184 32.534 21.2184 35.6586 24.3448"
        stroke={color}
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.993 42.4904C40.681 45.761 35.4135 47.5238 30.0015 47.5073C21.0338 47.6669 12.7479 42.7384 8.60761 34.782C7.12038 31.7674 7.12038 28.2325 8.60761 25.218C10.6782 21.0946 13.979 17.7164 18.0533 15.5508"
        stroke={color}
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.0679 35.3375C51.1683 35.1475 51.2988 34.977 51.3936 34.7833C52.8809 31.7687 52.8809 28.2338 51.3936 25.2193C47.2534 17.2628 38.9675 12.3343 29.9997 12.494C29.4375 12.494 28.891 12.569 28.3359 12.6056"
        stroke={color}
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.75475 6.25475C9.43817 5.57133 10.5462 5.57133 11.2296 6.25475L53.7473 48.7725C54.4308 49.4559 54.4308 50.5639 53.7473 51.2473C53.0639 51.9308 51.9559 51.9308 51.2725 51.2473L8.75475 8.72962C8.07133 8.04621 8.07133 6.93817 8.75475 6.25475Z"
        fill="#34C759"
      />
    </svg>
  )
}
