import moment, { Moment } from 'moment-timezone'

import { store } from '@src/store'

export const getDateSpaceGMT = (date: string) => {
  const timeZone = store.getState()?.profile?.profileInfo?.timezone || ''
  const readyDate = moment(date)
  readyDate.tz(timeZone)
  readyDate.locale('ru')
  return readyDate
}
export const checkIsToday = (date: Moment) => {
  const timeZone = store.getState()?.profile?.profileInfo?.timezone || ''
  const checkDate = moment()
  checkDate.tz(timeZone)

  return date.format('DD.MM.YYYY') === checkDate.format('DD.MM.YYYY')
}
export const checkIsYesterday = (date: Moment) => {
  const timeZone = store.getState()?.profile?.profileInfo?.timezone || ''
  const checkDate = moment().subtract(1, 'days')
  checkDate.tz(timeZone)

  return date.format('DD.MM.YYYY') === checkDate.format('DD.MM.YYYY')
}
export const formatDateWithoutYearAtTime = (date: string) => {
  const checkDate = getDateSpaceGMT(date)
  const isToday = checkIsToday(checkDate)
  const isYesterday = checkIsYesterday(checkDate)
  const stringTime = checkDate.format('H:mm')
  let stringDate
  if (isToday) {
    stringDate = 'Сегодня'
  } else if (isYesterday) {
    stringDate = 'Вчера'
  } else {
    stringDate = checkDate.format('DD MMMM')
  }

  return `${stringDate}, в ${stringTime}`
}
