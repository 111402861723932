import {
  IPaidFeature,
  IPaidFeatureDetailResponse,
  IPaidFeaturesListRequest,
} from '@pages/PaidFeatures/types/IPaidFeature'
import { IPaidFeatureDetailWithRate } from '@pages/PaidFeatures/types/IPaidFeatureWithRate'
import {
  IPayAscentOfferBtRateRequest,
  IPayAscentOfferRequest,
} from '@pages/PaidFeatures/types/ascentOffer'
import { IPayAscentTaskRequest } from '@pages/PaidFeatures/types/ascentTask'
import {
  IActivateAutoOfferRequest,
  IAutoOfferRequest,
} from '@pages/PaidFeatures/types/autoOffer'
import {
  ActivateAutoSearchMutation,
  PayAutoSearchMutation,
} from '@pages/PaidFeatures/types/autoSearch'
import { IPayEmployeesRequest } from '@pages/PaidFeatures/types/employees'
import {
  IFindPhonesExecutorsRequest,
  IPayPhonesExecutorsByRateRequest,
  IPayPhonesExecutorsRequest,
} from '@pages/PaidFeatures/types/findPhonesExecutors'
import {
  IStealsByRateRequest,
  IStealsRequest,
  IUpdateStealsRequest,
} from '@pages/PaidFeatures/types/steals'
import { IPayTaskSendRequest } from '@pages/PaidFeatures/types/taskSend'
import {
  ITasksBgByTariffRequest,
  ITasksBgRequest,
} from '@pages/PaidFeatures/types/tasksBg'
import { IPayTopContactsRequest } from '@pages/PaidFeatures/types/topContacts'
import {
  IPaginatedItems,
  IPaginatedRequest,
} from '@src/shared/interfaces/apiResponse/IPagination'
import { ISuccessResponse } from '@src/shared/interfaces/apiResponse/ISuccessResponse'
import { clientApiSlice } from '@src/store/slices/clientApiSlice'
import { formatUrlGetParamsFromObject } from '@src/utils/helpers/formatUrlGetParamsFromObject'

export const paidFeaturesApi = clientApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPaidFeaturesList: builder.query<
      ISuccessResponse<IPaidFeature[]>,
      IPaidFeaturesListRequest
    >({
      query: ({ profile_type }) => ({
        url: `/paid-features${formatUrlGetParamsFromObject({
          profile_type,
        })}`,
      }),
    }),

    paySteals: builder.mutation<IPaidFeatureDetailResponse, IStealsRequest>({
      query: (args) => ({
        url: `/paid-features/steals`,
        method: 'POST',
        body: args,
      }),
      invalidatesTags: (result, error, arg) => {
        if (result?.success) {
          return arg.balance_type === 'partner'
            ? [
                { type: 'Application' as const, id: arg.project_id },
                { type: 'Partnership', id: 'BALANCE' },
              ]
            : [{ type: 'Application' as const, id: arg.project_id }, 'Balance']
        } else return []
      },
    }),

    payStealsByRate: builder.mutation<
      IPaidFeatureDetailResponse,
      IStealsByRateRequest
    >({
      query: (args) => ({
        url: `/paid-features/steals/by-tariff`,
        method: 'POST',
        body: args,
      }),
      invalidatesTags: (result, error, arg) => {
        if (result?.success) {
          return [
            { type: 'Application', id: arg.project_id },
            { type: 'Space', id: 'LIST' },
          ]
        } else return []
      },
    }),

    updateSteals: builder.mutation<
      IPaidFeatureDetailResponse,
      IUpdateStealsRequest
    >({
      query: ({ project_id, steals_id, ...rest }) => ({
        url: `/paid-features/steals/${steals_id}`,
        method: 'PUT',
        body: rest,
      }),
      invalidatesTags: (result, error, arg) => {
        if (result?.success) {
          return [{ type: 'Application', id: arg.project_id }]
        } else return []
      },
    }),

    payTasksBg: builder.mutation<IPaidFeatureDetailResponse, ITasksBgRequest>({
      query: ({ taskId, project_id, ...rest }) => ({
        url: `/paid-features/tasks/tasks-background/${taskId}`,
        method: 'POST',
        body: rest,
      }),
      invalidatesTags: (result, error, arg) => {
        if (result?.success) {
          return arg.payment_source === 'partner'
            ? [
                { type: 'Application' as const, id: arg.project_id },
                { type: 'Partnership', id: 'BALANCE' },
              ]
            : [{ type: 'Application' as const, id: arg.project_id }, 'Balance']
        } else return []
      },
    }),

    payTasksBgByTariff: builder.mutation<
      IPaidFeatureDetailResponse,
      ITasksBgByTariffRequest
    >({
      query: ({ taskId }) => ({
        url: `/paid-features/tasks/tasks-background/${taskId}/by-tariff`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, arg) => {
        if (result?.success) {
          return [
            { type: 'Application', id: arg.project_id },
            { type: 'Space', id: 'LIST' },
          ]
        } else return []
      },
    }),

    payAutoOffer: builder.mutation<
      IPaidFeatureDetailResponse,
      IAutoOfferRequest
    >({
      query: ({ noInvalidate, ...rest }) => ({
        url: `/paid-features/auto-offer`,
        method: 'POST',
        body: rest,
      }),
      invalidatesTags: (result, error, arg) => {
        if (result?.success && !arg.noInvalidate) {
          return arg.balance_type === 'partner'
            ? [
                { type: 'PaidFeatures', id: 'AUTO_OFFERS' },
                { type: 'Partnership', id: 'BALANCE' },
              ]
            : [{ type: 'PaidFeatures', id: 'AUTO_OFFERS' }, 'Balance']
        } else return []
      },
    }),

    getAvailableAutoOffers: builder.query<
      ISuccessResponse<{ available: number; limit: number }>,
      number
    >({
      query: () => ({
        url: `/paid-features/auto-offer/available`,
      }),
      providesTags: () => [{ type: 'PaidFeatures', id: 'AUTO_OFFERS' }],
    }),

    activateAutoOfferById: builder.mutation<
      IPaidFeatureDetailResponse,
      IActivateAutoOfferRequest
    >({
      query: (args) => ({
        url: `/paid-features/auto-offer/activateById`,
        method: 'POST',
        body: {
          auto_offer_id: args.auto_offer_id,
        },
      }),
      invalidatesTags: (result, error, arg) => {
        if (result?.success) {
          const common = [
            { type: 'PaidFeatures' as const, id: 'AUTO_OFFERS' },
            { type: 'Autoanswer' as const, id: `LIST_${arg.task_type}` },
            { type: 'Autoanswer' as const, id: arg.auto_offer_id },
          ]

          if (arg?.balance_type) {
            return arg.balance_type === 'partner'
              ? [...common, { type: 'Partnership', id: 'BALANCE' }]
              : [...common, 'Balance']
          } else return common
        } else return []
      },
    }),

    payAutoSearch: builder.mutation<
      IPaidFeatureDetailResponse,
      PayAutoSearchMutation
    >({
      query: ({ noInvalidate, ...rest }) => ({
        url: `/paid-features/auto-search`,
        method: 'POST',
        body: rest,
      }),
      invalidatesTags: (result, error, arg) => {
        if (result?.success && !arg.noInvalidate) {
          return arg.balance_type === 'partner'
            ? [
                { type: 'PaidFeatures', id: 'AUTO_SEARCH' },
                { type: 'Partnership', id: 'BALANCE' },
              ]
            : [{ type: 'PaidFeatures', id: 'AUTO_SEARCH' }, 'Balance']
        } else return []
      },
    }),

    getAvailableAutoSearch: builder.query<
      ISuccessResponse<{ available: number; limit: number }>,
      number
    >({
      query: () => ({
        url: `/paid-features/auto-search/available`,
      }),
      providesTags: () => [{ type: 'PaidFeatures', id: 'AUTO_SEARCH' }],
    }),

    activateAutoSearchById: builder.mutation<
      IPaidFeatureDetailResponse,
      ActivateAutoSearchMutation
    >({
      query: (args) => ({
        url: `/paid-features/auto-search/activateById`,
        method: 'POST',
        body: { auto_search_id: args.auto_search_id },
      }),
      invalidatesTags: (result, error, arg) => {
        if (result?.success) {
          const common = [
            { type: 'PaidFeatures' as const, id: 'AUTO_SEARCH' },
            { type: 'AutoSearch' as const, id: `LIST_${arg.task_type}` },
            { type: 'AutoSearch' as const, id: arg.auto_search_id },
          ]

          if (arg?.balance_type) {
            return arg.balance_type === 'partner'
              ? [...common, { type: 'Partnership', id: 'BALANCE' }]
              : [...common, 'Balance']
          } else return common
        } else return []
      },
    }),

    findPhonesExecutors: builder.mutation<
      ISuccessResponse,
      IFindPhonesExecutorsRequest
    >({
      query: ({ spaceId, projectId, taskId }) => ({
        url: `/spaces/${spaceId}/projects/${projectId}/tasks/${taskId}/find-phones-executors`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, arg) => {
        if (result?.success) {
          return [{ type: 'Application' as const, id: arg.projectId }]
        } else return []
      },
    }),

    payPhonesExecutors: builder.mutation<
      IPaidFeatureDetailResponse,
      IPayPhonesExecutorsRequest
    >({
      query: ({ projectId, resultId, ...rest }) => ({
        url: `/paid-features/tasks/executor-phones/${resultId}`,
        method: 'POST',
        body: rest,
      }),
      invalidatesTags: (result, error, arg) => {
        if (result?.success) {
          return arg.payment_source === 'partner'
            ? [
                { type: 'Application' as const, id: arg.projectId },
                { type: 'Partnership', id: 'BALANCE' },
              ]
            : [{ type: 'Application' as const, id: arg.projectId }, 'Balance']
        } else return []
      },
    }),

    payPhonesExecutorsByTariff: builder.mutation<
      IPaidFeatureDetailResponse,
      IPayPhonesExecutorsByRateRequest
    >({
      query: ({ resultId }) => ({
        url: `/paid-features/tasks/executor-phones/${resultId}/by-tariff`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, arg) => {
        if (result?.success) {
          return [
            { type: 'Application', id: arg.projectId },
            { type: 'Space', id: 'LIST' },
          ]
        } else return []
      },
    }),

    findSpacesExecutors: builder.mutation<
      ISuccessResponse,
      IFindPhonesExecutorsRequest
    >({
      query: ({ spaceId, projectId, taskId }) => ({
        url: `/spaces/${spaceId}/projects/${projectId}/tasks/${taskId}/find-spaces-executors`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, arg) => {
        if (result?.success) {
          return [{ type: 'Application' as const, id: arg.projectId }]
        } else return []
      },
    }),

    payTaskSend: builder.mutation<
      IPaidFeatureDetailResponse,
      IPayTaskSendRequest
    >({
      query: ({ projectId, resultId, ...rest }) => ({
        url: `/paid-features/tasks/tasks-send/${resultId}`,
        method: 'POST',
        body: rest,
      }),
      invalidatesTags: (result, error, arg) => {
        if (result?.success) {
          return arg.payment_source === 'partner'
            ? [
                { type: 'Application' as const, id: arg.projectId },
                { type: 'Partnership', id: 'BALANCE' },
              ]
            : [{ type: 'Application' as const, id: arg.projectId }, 'Balance']
        } else return []
      },
    }),

    payTaskSendByTariff: builder.mutation<
      IPaidFeatureDetailResponse,
      IPayPhonesExecutorsByRateRequest
    >({
      query: ({ resultId }) => ({
        url: `/paid-features/tasks/tasks-send/${resultId}/by-tariff`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, arg) => {
        if (result?.success) {
          return [
            { type: 'Application', id: arg.projectId },
            { type: 'Space', id: 'LIST' },
          ]
        } else return []
      },
    }),

    payTopContacts: builder.mutation<
      IPaidFeatureDetailResponse,
      IPayTopContactsRequest
    >({
      query: (args) => ({
        url: `/paid-features/spaces/top-contact`,
        method: 'POST',
        body: args,
      }),
      invalidatesTags: (result, error, arg) => {
        if (result?.success) {
          return arg.payment_source === 'partner'
            ? [
                { type: 'Partnership', id: 'BALANCE' },
                { type: 'Space', id: 'LIST' },
              ]
            : ['Balance', { type: 'Space', id: 'LIST' }]
        } else return []
      },
    }),

    payTopContactsByTariff: builder.mutation<IPaidFeatureDetailResponse, void>({
      query: () => ({
        url: `/paid-features/spaces/top-contact/by-tariff`,
        method: 'POST',
      }),
      invalidatesTags: (result) =>
        result?.success ? [{ type: 'Space', id: 'LIST' }] : [],
    }),

    payAscentTask: builder.mutation<
      IPaidFeatureDetailResponse,
      IPayAscentTaskRequest
    >({
      query: ({ taskId, projectId, ...rest }) => ({
        url: `/paid-features/tasks/tasks-ascent/${taskId}`,
        method: 'POST',
        body: rest,
      }),
      invalidatesTags: (result, error, arg) => {
        if (result?.success) {
          return arg.payment_source === 'partner'
            ? [
                { type: 'Partnership', id: 'BALANCE' },
                { type: 'Application', id: arg.projectId },
              ]
            : ['Balance', { type: 'Application', id: arg.projectId }]
        } else return []
      },
    }),

    payEmployees: builder.mutation<
      IPaidFeatureDetailResponse,
      IPayEmployeesRequest
    >({
      query: (args) => ({
        url: `/paid-features/employees`,
        method: 'POST',
        body: args,
      }),
      invalidatesTags: (result, error, arg) => {
        if (result?.success) {
          return arg.balance_type === 'partner'
            ? [{ type: 'Partnership', id: 'BALANCE' }]
            : ['Balance']
        } else return []
      },
    }),

    payAscentOffer: builder.mutation<
      IPaidFeatureDetailResponse,
      IPayAscentOfferRequest
    >({
      query: ({ offerId, ...rest }) => ({
        url: `/paid-features/offers/offers-ascent/${offerId}`,
        method: 'POST',
        body: rest,
      }),
      invalidatesTags: (result, error, arg) => {
        if (result?.success) {
          return arg.payment_source === 'partner'
            ? [{ type: 'Partnership', id: 'BALANCE' }]
            : ['Balance']
        } else return []
      },
    }),

    payAscentOfferByTariff: builder.mutation<
      IPaidFeatureDetailResponse,
      IPayAscentOfferBtRateRequest
    >({
      query: ({ offerId }) => ({
        url: `/paid-features/offers/offers-ascent/${offerId}/by-tariff`,
        method: 'POST',
      }),
      invalidatesTags: (result) =>
        result?.success ? [{ type: 'Space', id: 'LIST' }] : [],
    }),

    payAscentTaskByTariff: builder.mutation<
      IPaidFeatureDetailResponse,
      ITasksBgByTariffRequest
    >({
      query: ({ taskId }) => ({
        url: `/paid-features/tasks/tasks-ascent/${taskId}/by-tariff`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, arg) => {
        if (result?.success) {
          return [
            { type: 'Application', id: arg.project_id },
            { type: 'Space', id: 'LIST' },
          ]
        } else return []
      },
    }),

    getPaidFeaturesHistory: builder.query<
      ISuccessResponse<IPaginatedItems<IPaidFeatureDetailWithRate>>,
      IPaginatedRequest
    >({
      query: (args) => ({
        url: `/paid-features/my${formatUrlGetParamsFromObject(args)}`,
      }),
    }),

    togglePaidFeatureAutoRenewal: builder.mutation<
      ISuccessResponse<IPaidFeatureDetailWithRate>,
      number
    >({
      query: (id) => ({
        url: `/paid-features/${id}/toggle-auto-renewal`,
        method: 'PATCH',
      }),
    }),
  }),
})

export const {
  useGetPaidFeaturesListQuery,
  usePayStealsMutation,
  useUpdateStealsMutation,
  usePayTasksBgMutation,
  usePayAutoOfferMutation,
  useGetAvailableAutoOffersQuery,
  useActivateAutoOfferByIdMutation,
  useFindPhonesExecutorsMutation,
  usePayPhonesExecutorsMutation,
  useFindSpacesExecutorsMutation,
  usePayTaskSendMutation,
  usePayTopContactsMutation,
  usePayAscentTaskMutation,
  usePayEmployeesMutation,
  usePayAscentOfferMutation,
  usePayTasksBgByTariffMutation,
  usePayAscentTaskByTariffMutation,
  usePayPhonesExecutorsByTariffMutation,
  usePayTaskSendByTariffMutation,
  usePayStealsByRateMutation,
  usePayTopContactsByTariffMutation,
  usePayAscentOfferByTariffMutation,
  useLazyGetPaidFeaturesHistoryQuery,
  useTogglePaidFeatureAutoRenewalMutation,
  useGetAvailableAutoSearchQuery,
  useActivateAutoSearchByIdMutation,
  usePayAutoSearchMutation,
} = paidFeaturesApi
