import { ChangeEvent } from 'react'

import { store } from '@src/store'

// ===== ИСПОЛЬЗОВАНИЕ =====
// Хелперы используются для установки в слайсе одноуровневых значений
// Т.е. если слайс имеет вид
// {
//   field1: value1,
//   field2: value2,
// }
// Чтобы не писать для каждого поля отдельный редюсер, следует сделать универсальный для текущего слайса:
// setValueInState[название слайса]: (state, action) => {
//   const field = action.payload.field as keyof [интерфейс стейта слайса]
//   state[field] = action.payload.data as never
// }
//В action.payload используются поля field (куда устанавливаем) и data (что устанавливаем)

//По аналогии можно при необходимости сделать универсальные редюсеры и для более глубоко вложенных объектов

// Затем в файле с хелперами текущего модуля создаем фабрики хендлеров для этого модуля,
// которые принимают в замыкание наш универсальный редюсер из слайса модуля setValueInState[название слайса]:
// export const handle[Название слайса]Value = handleValue(setValueInState[название слайса]) - для установки значения
// export const handle[Название слайса]Input = handleInput(setValueInState[название слайса]) - для обработчика инпута
// export const handle[Название слайса]Check = handleCheck(setValueInState[название слайса]) - для обработчика чекбокса

// Затем в компоненте мы можем создать хендлер:
// const handleField1 = handle[Название слайса]Value('field1') - в замыкание кладется название поля и возвращается функция, работающая с этим полем.
// ее можно класть в onClick(или другой обработчик, в зависимости от типа элемента)

// Также мы можем просто устанавливать значение, вызвав
// handle[Название слайса]Value('field1')('value1')

//Таким образом уменьшается количество бойлерплейта как в слайсе, так и в компоненте

export const handleCheckFabric = (action: any) => {
  return (field: string) => {
    return (e: ChangeEvent) => {
      const target = e.target as HTMLInputElement
      store.dispatch(
        action({
          field,
          data: target.checked,
        })
      )
    }
  }
}

export const handleValueFabric = (action: any) => {
  return (field: string) => {
    return (value: any) => {
      store.dispatch(
        action({
          field,
          data: value,
        })
      )
    }
  }
}

export const handleInputFabric = (action: any) => {
  return (field: string) => {
    return (e: ChangeEvent) => {
      const target = e.target as HTMLInputElement
      store.dispatch(
        action({
          field,
          data: target.value,
        })
      )
    }
  }
}
