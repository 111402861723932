import { PayloadAction, createSlice } from '@reduxjs/toolkit'

const initialState = {
  isShown: false,
}

const errorScreenSlice = createSlice({
  name: 'errorScreen',
  initialState,
  reducers: {
    toggleErrorScreen: (state, { payload }: PayloadAction<boolean>) => {
      state.isShown = payload
    },
  },
})

export const { toggleErrorScreen } = errorScreenSlice.actions
export const errorScreenSliceReducer = errorScreenSlice.reducer
