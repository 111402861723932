import { TaskType } from '@src/shared/interfaces/task/TaskType'
import { clientApiSlice } from '@src/store/slices/clientApiSlice'

export interface ITaskTypeItem {
  id: number
  name: string
  slug: TaskType
  sort: number
}

export interface ITaskTypesResponse {
  success: boolean
  data: ITaskTypeItem[]
}

interface ResponseProps {
  vehicle_type_ids?: number[] | null
}

const dirsSlice = clientApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTaskTypes: builder.query<ITaskTypesResponse, ResponseProps>({
      query: ({ vehicle_type_ids }) => ({
        url: `/dirs/task-types/list?${
          vehicle_type_ids && vehicle_type_ids.length
            ? vehicle_type_ids
                .map((item) => `vehicle_type_ids[]=${item}&`)
                .join('')
            : ''
        }`,
        method: 'GET',
      }),
    }),
  }),
})

export const { useGetTaskTypesQuery, useLazyGetTaskTypesQuery } = dirsSlice
