import { LIMIT_CONTRACTS } from '@pages/Space/Contracts/constants'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { IFileState } from '@src/shared/Upload/interfaces'
import { toggleValueInArray } from '@src/utils/helpers/toggleValueInArray'

import { IContractItem } from './interfaces'

export interface IContractsState {
  list: {
    items: IContractItem[]
    page: number
    lastItemsCount: number
  }
  documentsToAdd: IFileState['gotFileData'][]
  name: string
  description: string
  fileToDeleteIds: (number | string)[]
  contractToDeleteIds: number[]
  documentToReplaceId: number | null
  isContractDeleteModalOpened: boolean
  isFileDeleteModalOpened: boolean
}

const initialState: IContractsState = {
  list: {
    items: [],
    page: 1,
    lastItemsCount: LIMIT_CONTRACTS,
  },
  documentsToAdd: [],
  name: '',
  description: '',
  fileToDeleteIds: [],
  contractToDeleteIds: [],
  documentToReplaceId: null,
  isContractDeleteModalOpened: false,
  isFileDeleteModalOpened: false,
}

const contractsSlice = createSlice({
  name: 'contracts',
  initialState,
  reducers: {
    addPageDataContracts: (state, action) => {
      state.list.items = [...state.list.items, ...action.payload]
    },
    incrementPageContracts: (state) => {
      state.list.page += 1
    },
    clearContracts: (state) => {
      state.list.items = []
      state.list.page = 1
      state.list.lastItemsCount = LIMIT_CONTRACTS
    },
    setLastItemsCountContracts: (state, action) => {
      state.list.lastItemsCount = action.payload
    },
    deleteDocumentContracts: (state, action) => {
      state.documentsToAdd = state.documentsToAdd.filter(
        (item) => item.bind_key !== action.payload
      )
    },
    setValueInStateContracts: (state, action) => {
      const field = action.payload.field as keyof IContractsState
      state[field] = action.payload.data as never
    },
    contractDeletedIdsToggled: (state, { payload }: PayloadAction<number>) => {
      state.contractToDeleteIds = toggleValueInArray(
        state.contractToDeleteIds,
        payload
      )
    },
    fileDeletedIdsToggled: (
      state,
      { payload }: PayloadAction<number | string>
    ) => {
      state.fileToDeleteIds = toggleValueInArray(state.fileToDeleteIds, payload)
    },
  },
})

export const {
  addPageDataContracts,
  incrementPageContracts,
  setLastItemsCountContracts,
  deleteDocumentContracts,
  clearContracts,
  setValueInStateContracts,
  contractDeletedIdsToggled,
  fileDeletedIdsToggled,
} = contractsSlice.actions
export const contractsReducer = contractsSlice.reducer
