import IconPropsInterface from './IconPropsInterface'

export const AddToChat = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 14 13"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M3.38478 3.14844C2.28596 3.92799 1.54301 5.03571 1.29405 6.26564C1.0451 7.49557 1.30709 8.76401 2.03131 9.83513L1.19336 12.349L4.22288 11.7044C5.45674 12.3034 6.89333 12.4838 8.26555 12.2119C9.63777 11.9401 10.8522 11.2345 11.6832 10.2265C12.5141 9.21841 12.9049 7.97644 12.7829 6.73147"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.73957 1.04297V5.25649M6.63281 3.14973H10.8463"
        strokeLinecap="round"
      />
    </svg>
  )
}
