import { FIELD_IS_REQUIRED_TEXT } from '@pages/TasksBoard/ExecutorOffer/constants'
import {
  checkCyrillicCharsOnly,
  checkIsRequired,
  noValidate,
} from '@pages/TasksBoard/ExecutorOffer/validationHelpers'
import { TaskType } from '@src/shared/interfaces/task/TaskType'

const mapCreators = {
  rent: () => {
    //из задачи: without_driver, deferred_payment, date_time_start
    return {
      //h1 Основная информация

      // Добавить поле выбора техники, название Техника - обязательное
      vehicle_configuration_id: {
        //title: 'Конфигурация техники',
        required: true,
        value: -1,
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: number) => {
          if (value < 1) {
            return FIELD_IS_REQUIRED_TEXT
          }
          return ''
        },
      },
      cost: {
        //title: 'Ставка руб./час',
        required: true,
        value: '',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: string) => {
          if (value === '') {
            return FIELD_IS_REQUIRED_TEXT
          }
          return ''
        },
      },
      cost_delivery: {
        //title: 'Стоимость доставки, руб.',
        required: false,
        value: '',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: () => {
          return ''
        },
      },
      travel_expanses_include: {
        required: true,
        value: true,
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: string) => {
          return ''
        },
      },
      //Берем это значение из задачи, если с экипажем, добавляем пункт ниже (из 2 полей делаем 1 строку).
      travel_expenses_cost: {
        //title: 'Стоимость питания и проживания, руб',
        required: true,
        value: '',
        errorMessage: '',
        isDisabled: true,
        getErrorMessage: (value: string) => {
          if (value === '') {
            return FIELD_IS_REQUIRED_TEXT
          }
          return ''
        },
      },
      //просто период, без названия, по умолчанию час
      travel_expenses_period: {
        //title: 'Оплата питания и проживания - период',
        required: true,
        value: 'hour',
        errorMessage: '',
        isDisabled: true,
        getErrorMessage: () => {
          return ''
        },
      },
      without_driver: {
        //title: 'Техника без экипажа',
        required: true,
        value: true,
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: () => {
          return ''
        },
      },

      //Это поле переделать в селект - Исполнитель|заказчик, по умолч. Заказчик
      customer_pay_fuel: {
        //title: 'Плтельщик ГСМ',
        required: true, // обязательное
        value: true,
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: checkIsRequired,
      },
      //следующие 2 объединяем в одно по принципу предыдущих. Если выбран исполнитель, то отобразить эти поля, изначально не отображаются, пока выбран заказчик.
      // fuel_cost: {
      //   //title: 'Стоимость ГСМ',
      //   required: false,
      //   value: '',
      //   errorMessage: '',
      //   isDisabled: true,
      //   getErrorMessage: (value: string) => {
      //     return ''
      //   },
      // },
      // //По умолчанию час
      // fuel_cost_period: {
      //   //title: 'Стоимость ГСМ период - если плательщиком является Исполнитель',
      //   required: false,
      //   value: 'hour',
      //   errorMessage: '',
      //   isDisabled: true,
      //   getErrorMessage: (value: string) => {
      //     return ''
      //   },
      // },

      //Берем из задачи, но можно менять
      date_time_start: {
        //title: 'Начало проведения работ',
        required: true,
        value: '',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: Date | null) => {
          if (!value) {
            return FIELD_IS_REQUIRED_TEXT
          }
          return ''
        },
      },

      working_time: {
        //title: 'Предполагаемое время работы, ч',
        required: true, //проверить
        value: '',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: string) => {
          if (value === '') {
            return FIELD_IS_REQUIRED_TEXT
          }
          return ''
        },
      },

      prepayment_amount: {
        //title: 'Сумма предоплаты, руб.',
        required: false,
        value: '',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: string) => {
          return ''
        },
      },

      deferred_payment: {
        //title: 'Отсрочка оплаты, дней',
        required: false,
        value: '', // значение из задачи (если есть)
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: string) => {
          if (value === '') {
            return ''
          }
          const number = +value
          if (number < 1) {
            return 'Число должно быть больше 1'
          }
          return ''
        },
      },

      employee_id: {
        //title: 'Ответственный сотрудник по задаче',
        required: false,
        value: null,
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: string) => {
          return ''
        },
      },
      //h1 Данные для договора

      //Подключить dadata - по городам и населенным пунктам. - От 2 символов поиск
      contract_city_name: {
        //title: 'Город заключения договора',
        required: true,
        value: '',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: string) => {
          if (value === '') {
            return 'Введите корректное название города'
          }
          return ''
        },
        //не даем ввести текст.
        // Сделать как в фильтрах в карте - с кнопкой сбросить.
      },

      contract_period: {
        //title: 'Срок действия договора, мес.',
        required: true,
        value: '10',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: string) => {
          if (value === '') {
            return FIELD_IS_REQUIRED_TEXT
          }
          const number = +value
          if (number < 1 || number > 120) {
            return 'Число должно быть от 1 до 120'
          }
          return ''
        },
      },

      working_time_min: {
        //title: 'Минимальный срок аренды, ч.',
        required: true,
        value: '',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: string) => {
          if (value === '') {
            return FIELD_IS_REQUIRED_TEXT
          }
          return ''
        },
      },

      //Добавить тултип или подсказку "Количество дней на внесение предоплаты с момента получения счета."
      //Поле появляется если заполнено prepayment_amount, и становится обязательным
      prepayment_days: {
        //title: 'Срок внесения предоплаты, дней',
        required: true,
        value: '3',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: string) => {
          if (value === '') {
            return FIELD_IS_REQUIRED_TEXT
          }
          const number = +value
          if (number < 1 || number > 365) {
            return 'Число должно быть от 1 до 365'
          }
          return ''
        },
      },

      //Значение берет из Ответственного сотрудника, но можно редактировать. Обязательное.
      responsible_person: {
        //title: 'Ответственное лицо по договору',
        required: true,
        value: '',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: checkCyrillicCharsOnly,
      },
      //h2: Неустойки. //Один подзаголовок в структуре
      //h3 Отказ от исполнения
      deny_execution_days: {
        //title: 'Срок оповещения об отказе от Договора без неустойки, дней', //Добавить подсказку "До времени предоставления техники". Подсказка серым текстом внизу
        required: true,
        value: '2',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: string) => {
          if (value === '') {
            return FIELD_IS_REQUIRED_TEXT
          }
          const number = +value
          if (number < 1 || number > 365) {
            return 'Число должно быть от 1 до 365'
          }
          return ''
        },
      },
      deny_execution_percent: {
        //title: 'Неустойка при нарушении сроков оповещения об отказе, %', //Подсказка: от суммы минимального времени работы
        required: true,
        value: '0.1',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: string) => {
          if (value === '') {
            return FIELD_IS_REQUIRED_TEXT
          }
          const number = +value
          if (number < 0 || number > 300) {
            return 'Число должно быть от 0 до 300'
          }
          return ''
        },
      },
      //h3 Простой техники

      idle_vehicle_min_hours: {
        //title: 'Количество часов простоя без неустойки',
        required: true,
        value: '2',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: string) => {
          if (value === '') {
            return FIELD_IS_REQUIRED_TEXT
          }
          const number = +value
          if (number < 1 || number > 720) {
            return 'Число должно быть от 1 до 720'
          }
          return ''
        },
      },
      idle_vehicle_percent: {
        //title: 'Размер неустойки, %.', //Подсказка: от ставки в час
        required: true,
        value: '0.1',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: string) => {
          if (value === '') {
            return FIELD_IS_REQUIRED_TEXT
          }
          const number = +value
          if (number < 0 || number > 300) {
            return 'Число должно быть от 0 до 300'
          }
          return ''
        },
      },

      idle_vehicle_max_hours: {
        //title: 'Максимальное количество часов простоя',
        required: true,
        value: '5',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: string) => {
          if (value === '') {
            return FIELD_IS_REQUIRED_TEXT
          }
          const number = +value
          if (number < 1 || number > 720) {
            return 'Число должно быть от 1 до 720'
          }
          return ''
        },
      },
    }
  },

  passenger: () => {
    // Из задачи deferred_payment, delivery_datetime_to
    return {
      //h1 Основная информация

      //сделать как в аренде (2 поля)
      vehicle_configuration_id: {
        //title: 'Конфигурация техники',
        required: true,
        value: '',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: number) => {
          if (value < 1) {
            return FIELD_IS_REQUIRED_TEXT
          }
          return ''
        },
      },
      number_of_passengers: {
        //title: 'Вместимость, чел.',
        required: true,
        value: '',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: string) => {
          if (value === '') {
            return FIELD_IS_REQUIRED_TEXT
          }
          return ''
        },
      },
      cost: {
        //title: 'Стоимость, руб.',
        required: true,
        value: '',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: string) => {
          if (value === '') {
            return FIELD_IS_REQUIRED_TEXT
          }
          return ''
        },
      },

      //это поле техническое, на секунду меньше чем следующее, на фронте не отображается.
      delivery_datetime_from: {
        //title: 'Время прибытия в конечную точку - от',
        required: true,
        value: '',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: Date | null) => {
          //Не валидируется, тк связано с dalivery_datetime_to
          return ''
        },
      },
      //Взять из задачи
      delivery_datetime_to: {
        //title: 'Время начала посадки',
        required: true,
        value: '',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: Date | null) => {
          if (!value) {
            return FIELD_IS_REQUIRED_TEXT
          }
          return ''
        },
      },

      deferred_payment: {
        //title: 'Отсрочка оплаты, дней',
        required: false,
        value: '', //предзаполнено отсрочкой из задачи
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: string) => {
          if (value === '') {
            return ''
          }
          const number = +value
          if (number < 1) {
            return 'Число должно быть больше 1'
          }
          return ''
        },
      },
      prepayment_amount: {
        //title: 'Сумма предоплаты, руб.',
        required: false,
        value: '',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: string) => {
          return ''
        },
      },
      employee_id: {
        //title: 'Ответственный сотрудник по задаче',
        required: false,
        value: null,
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: string) => {
          return ''
        },
      },

      //h1 Данные для договора
      contract_city_name: {
        //title: 'Город заключения договора', //Dadata как в аренде
        required: true,
        value: '',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: string) => {
          if (value === '') {
            return FIELD_IS_REQUIRED_TEXT
          }
          return ''
        },
      },
      contract_period: {
        //title: 'Срок действия договора, мес.',
        required: true,
        value: '10',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: string) => {
          if (value === '') {
            return FIELD_IS_REQUIRED_TEXT
          }
          const number = +value
          if (number < 1 || number > 120) {
            return 'Число должно быть от 1 до 120'
          }
          return ''
        },
      },
      cost_stop: {
        //title: 'Стоимость остановки, руб.',
        required: true,
        value: '',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: string) => {
          if (value === '') {
            return FIELD_IS_REQUIRED_TEXT
          }
          return ''
        },
      },

      cost_city: {
        //title: 'Стоимость изменения маршрута, руб./час',
        required: true,
        value: '',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: string) => {
          if (value === '') {
            return FIELD_IS_REQUIRED_TEXT
          }
          return ''
        },
      },

      waiting_minutes: {
        //title: 'Бесплатное ожидание, мин.',
        required: true,
        value: '15',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: string) => {
          if (value === '') {
            return FIELD_IS_REQUIRED_TEXT
          }
          const number = +value
          if (number < 0 || number > 1440) {
            return 'Число должно быть от 0 до 1440'
          }
          return ''
        },
      },

      baggage_allowance: {
        //title: 'Норма багажа на одного пассажира, кг',
        required: true,
        value: '',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: string) => {
          if (value === '') {
            return FIELD_IS_REQUIRED_TEXT
          }
          const number = +value
          if (number < 0 || number > 1000) {
            return 'Число должно быть от 0 до 1000'
          }
          return ''
        },
      },
      carry_on_allowance: {
        //title: 'Норма ручной клади на одного пассажира, кг',
        required: true,
        value: '',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: string) => {
          if (value === '') {
            return FIELD_IS_REQUIRED_TEXT
          }
          const number = +value
          if (number < 0 || number > 1000) {
            return 'Число должно быть от 0 до 1000'
          }
          return ''
        },
      },

      //Добавить тултип или подсказку "Количество дней на внесение предоплаты с момента получения счета."
      //Поле появляется если заполнено prepayment_amount, и становится обязательным
      prepayment_days: {
        //title: 'Срок внесения предоплаты, дней',
        required: true,
        value: '3',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: string) => {
          if (value === '') {
            return FIELD_IS_REQUIRED_TEXT
          }
          const number = +value
          if (number < 1 || number > 365) {
            return 'Число должно быть от 1 до 365'
          }
          return ''
        },
      },
      term_of_signing: {
        //title: 'Срок принятия акта, дней',
        required: true,
        value: '5',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: string) => {
          if (value === '') {
            return FIELD_IS_REQUIRED_TEXT
          }
          const number = +value
          if (number < 1 || number > 365) {
            return 'Число должно быть от 1 до 365'
          }
          return ''
        },
      },
      responsible_person: {
        //title: 'Ответственное лицо по договору',
        required: true,
        value: '',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: checkCyrillicCharsOnly,
      },
      //h2 Неустойки
      idle_use_vehicle_hours: {
        //title: 'Срок оповещения об отказе от Договора без неустойки, часов', //Добавить подсказку "До времени предоставления транспорта".
        required: true,
        value: '24',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: string) => {
          if (value === '') {
            return FIELD_IS_REQUIRED_TEXT
          }
          const number = +value
          if (number < 1 || number > 720) {
            return 'Число должно быть от 1 до 720'
          }
          return ''
        },
      },

      idle_pay_percent: {
        //title: 'Неустойка за просрочку оплаты, %', //ПОдсказка: за каждый день просрочки
        required: true,
        value: '0.1',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: string) => {
          if (value === '') {
            return FIELD_IS_REQUIRED_TEXT
          }
          const number = +value
          if (number < 0 || number > 300) {
            return 'Число должно быть от 0 до 300'
          }
          return ''
        },
      },
    }
  },
  cargo: () => {
    //Из задачи: габариты, вес(значение и единица), deferred_payment
    return {
      //h1 Основная информация

      //Добавить информацию, чтоб выглядело как поле. "Габариты груза" - ВШГ, взять из задачи

      volume: {
        //title: 'Объем, куб.м.',
        required: true,
        value: '', //Вычислить из ВШГ в метрах
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: string) => {
          if (value === '') {
            return FIELD_IS_REQUIRED_TEXT
          }
          return ''
        },
      },

      weight: {
        //title: 'Вес груза',
        required: true,
        value: '',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: string) => {
          if (value === '') {
            return FIELD_IS_REQUIRED_TEXT
          }
          return ''
        },
      },
      //В строку, добавить 2 поля - для веса и для единиц измерения, значения взять из задачи.
      //!Уточнить, как называется поле для единиц измерения.

      cost: {
        //title: 'Стоимость грузоперевозки, руб.',
        required: true,
        value: '',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: string) => {
          if (value === '') {
            return FIELD_IS_REQUIRED_TEXT
          }
          return ''
        },
      },

      //Техническое поле, на секунду меньше.
      delivery_datetime_from: {
        //title: 'Время прибытия в конечную точку - от',
        required: true,
        value: '',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: noValidate,
      },
      delivery_datetime_to: {
        //title: 'Дата и время загрузки',
        required: true,
        value: '',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: Date | null) => {
          if (!value) {
            return FIELD_IS_REQUIRED_TEXT
          }
          return ''
        },
      },

      employee_id: {
        //title: 'Ответственный сотрудник',
        required: false,
        value: '',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: noValidate,
      },
      deferred_payment: {
        //title: 'Отсрочка оплаты, дней',
        required: false,
        value: '', //взять из задачи
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: string) => {
          if (value === '') {
            return ''
          }
          const number = +value
          if (number < 1) {
            return 'Число должно быть больше 1'
          }
          return ''
        },
      },
      prepayment_amount: {
        //title: 'Сумма предоплаты, руб.',
        required: false,
        value: '',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: noValidate,
      },
      //h1 Данные для договора
      contract_city_name: {
        //title: 'Город заключения договора', //Dadata, как в аренде
        required: true,
        value: '',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: string) => {
          if (value === '') {
            return FIELD_IS_REQUIRED_TEXT
          }
          return ''
        },
      },
      contract_period: {
        //title: 'Срок действия договора, мес.',
        required: true,
        value: '10',
        errorMessage: '',
        isDisabled: false,

        getErrorMessage: (value: string) => {
          if (value === '') {
            return FIELD_IS_REQUIRED_TEXT
          }
          const number = +value
          if (number < 1 || number > 120) {
            return 'Число должно быть от 1 до 120'
          }
          return ''
        },
      },
      //Добавить тултип или подсказку "Количество дней на внесение предоплаты с момента получения счета."
      //Поле появляется если заполнено prepayment_amount, и становится обязательным
      prepayment_days: {
        //title: 'Срок внесения предоплаты, дней',
        required: true,
        value: '3',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: string) => {
          if (value === '') {
            return FIELD_IS_REQUIRED_TEXT
          }
          const number = +value
          if (number < 1 || number > 365) {
            return 'Число должно быть от 1 до 365'
          }
          return ''
        },
      },
      cost_stop: {
        //title: 'Дополнительная остановка, руб.',
        required: true,
        value: '',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: string) => {
          if (value === '') {
            return FIELD_IS_REQUIRED_TEXT
          }
          return ''
        },
      },
      cost_city: {
        //title: 'Дополнительный город, руб.',
        required: true,
        value: '',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: string) => {
          if (value === '') {
            return FIELD_IS_REQUIRED_TEXT
          }
          return ''
        },
      },
      responsible_person: {
        //title: 'Ответственное лицо по договору',
        required: true,
        value: '',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: checkCyrillicCharsOnly,
      },
      //h2 Неустойки
      idle_cargo_hours: {
        //title: 'Ожидание предоставления груза, часов',
        required: false,
        value: '',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: string) => {
          if (value === '') {
            return ''
          }
          const number = +value
          if (number < 1 || number > 720) {
            return 'Число должно быть от 1 до 720'
          }
          return ''
        },
      },
      idle_cargo_cost: {
        //title: 'Сумма неустойки за ожидание, руб./час',
        required: false,
        value: '',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: noValidate,
      },
    }
  },
  delivery: () => {
    //Из задачи: материал, характеристики, deferred_payment
    return {
      //h1 Основная информация

      //Добавить справочное поле - Материал
      //Добавить справочное поле - Характеристики материала, отображение из задачи TODO
      //!Бэку - сделать характеристики в виде ассоциативного массива.

      cost: {
        //title: 'Цена, руб.',
        required: true,
        value: '',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: string) => {
          if (value === '') {
            return FIELD_IS_REQUIRED_TEXT
          }
          return ''
        },
      },
      material_type_unit_id: {
        //title: 'Единица измерения',
        required: true,
        value: '',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: number) => {
          if (value < 1) {
            return FIELD_IS_REQUIRED_TEXT
          }
          return ''
        },
      },
      //Эти два поля - в одну строку.

      quantity: {
        //title: 'Количество',
        required: true,
        value: '',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: string) => {
          if (value === '') {
            return FIELD_IS_REQUIRED_TEXT
          }
          const number = +value
          if (number < 1) {
            return 'Число должно быть больше 1'
          }
          return ''
        },
      },
      //сбоку добавляем выбранную сверху единицу.

      //Новое справочное поле - Сумма. cost * quantity

      cost_delivery: {
        //title: 'Стоимость доставки, руб.',
        required: false,
        value: '',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: noValidate,
      },

      //Техническое поле
      delivery_datetime_from: {
        //title: 'Время прибытия в конечную точку - от',
        required: true,
        value: '',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: noValidate,
      },
      delivery_datetime_to: {
        //title: 'Дата доставки',
        required: true,
        value: '',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: Date | null) => {
          if (!value) {
            return FIELD_IS_REQUIRED_TEXT
          }
          return ''
        },
      },
      deferred_payment: {
        //title: 'Отсрочка оплаты, дней',
        required: false,
        value: '', //Из задачи
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: string) => {
          if (value === '') {
            return ''
          }
          const number = +value
          if (number < 1) {
            return 'Число должно быть больше 1'
          }
          return ''
        },
      },
      prepayment_amount: {
        //title: 'Предоплата, руб.',
        required: false,
        value: '',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: noValidate,
      },

      employee_id: {
        //title: 'Ответственный сотрудник',
        required: false,
        value: '',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: noValidate,
      },

      //h1 Данные для договора

      contract_city_name: {
        //title: 'Город заключения договора', //Dadata, как в аренде
        required: true,
        value: '',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: string) => {
          if (value === '') {
            return FIELD_IS_REQUIRED_TEXT
          }
          return ''
        },
      },

      contract_period: {
        //title: 'Срок действия договора, мес.',
        required: true,
        value: '10',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: string) => {
          if (value === '') {
            return FIELD_IS_REQUIRED_TEXT
          }
          const number = +value
          if (number < 1 || number > 120) {
            return 'Число должно быть от 1 до 120'
          }
          return ''
        },
      },

      //Добавить тултип или подсказку "Количество дней на внесение предоплаты с момента получения счета."
      //Поле появляется если заполнено prepayment_amount, и становится обязательным
      prepayment_days: {
        //title: 'Срок внесения предоплаты, дней',
        required: true,
        value: '3',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: string) => {
          if (value === '') {
            return FIELD_IS_REQUIRED_TEXT
          }
          const number = +value
          if (number < 1 || number > 365) {
            return 'Число должно быть от 1 до 365'
          }
          return ''
        },
      },
      //Как в аренде
      responsible_person: {
        //title: 'Ответственное лицо по договору',
        required: true,
        value: '',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: checkCyrillicCharsOnly,
      },

      //h2 Неустойки

      idle_payment_days: {
        //title: 'Срок оплаты, дней', //Подсказка: С момента получения счета на оплату
        required: true,
        value: '5',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: string) => {
          if (value === '') {
            return FIELD_IS_REQUIRED_TEXT
          }
          const number = +value
          if (number < 1 || number > 365) {
            return 'Число должно быть от 1 до 365'
          }
          return ''
        },
      },
      idle_payment_percent: {
        //title: 'Неустойка за просрочку оплаты, %', //Подсказка: за каждый день просрочки
        required: true,
        value: '0.2',
        errorMessage: '',
        isDisabled: false,
        getErrorMessage: (value: string) => {
          if (value === '') {
            return FIELD_IS_REQUIRED_TEXT
          }
          const number = +value
          if (number < 0 || number > 100) {
            return 'Число должно быть от 0 до 100'
          }
          return ''
        },
      },
    }
  },
}
export const createFieldsModel = (taskType: TaskType) => {
  return mapCreators[taskType]()
}
