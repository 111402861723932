import IconPropsInterface from './IconPropsInterface'

export const Favorite = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg width={size} height={size} viewBox="0 0 12 16" fill="none">
      <path
        d="M1.25 0.5H10.75C11.1642 0.5 11.5 0.835787 11.5 1.25V14.4857C11.5 14.6926 11.2631 14.8099 11.0985 14.6845L7.06058 11.608C6.43408 11.1307 5.56591 11.1307 4.93942 11.608L0.901511 14.6845C0.736948 14.8099 0.5 14.6926 0.5 14.4857V1.25C0.5 0.835786 0.835786 0.5 1.25 0.5Z"
        stroke={color}
        strokeLinejoin="round"
      />
    </svg>
  )
}
