import IconPropsInterface from './IconPropsInterface'

export const Doc3 = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4235_45314)">
        <path
          d="M16.364 3.64722H5.4549C4.45075 3.64722 3.63672 4.38877 3.63672 5.30353V15.2414C3.63672 16.1562 4.45075 16.8977 5.4549 16.8977H16.364C17.3681 16.8977 18.1822 16.1562 18.1822 15.2414V5.30353C18.1822 4.38877 17.3681 3.64722 16.364 3.64722Z"
          stroke={color}
          strokeWidth="0.833333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.72798 7.78909C7.97902 7.78909 8.18253 7.60371 8.18253 7.37502C8.18253 7.14633 7.97902 6.96094 7.72798 6.96094C7.47694 6.96094 7.27344 7.14633 7.27344 7.37502C7.27344 7.60371 7.47694 7.78909 7.72798 7.78909Z"
          fill="black"
          stroke={color}
          strokeWidth="0.833333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.0913 7.78909C14.3423 7.78909 14.5458 7.60371 14.5458 7.37502C14.5458 7.14633 14.3423 6.96094 14.0913 6.96094C13.8402 6.96094 13.6367 7.14633 13.6367 7.37502C13.6367 7.60371 13.8402 7.78909 14.0913 7.78909Z"
          fill="black"
          stroke={color}
          strokeWidth="0.833333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.0913 13.585C14.3423 13.585 14.5458 13.3996 14.5458 13.1709C14.5458 12.9422 14.3423 12.7568 14.0913 12.7568C13.8402 12.7568 13.6367 12.9422 13.6367 13.1709C13.6367 13.3996 13.8402 13.585 14.0913 13.585Z"
          fill="black"
          stroke="#B9B9B9"
          strokeWidth="0.833333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.72798 13.585C7.97902 13.585 8.18253 13.3996 8.18253 13.1709C8.18253 12.9422 7.97902 12.7568 7.72798 12.7568C7.47694 12.7568 7.27344 12.9422 7.27344 13.1709C7.27344 13.3996 7.47694 13.585 7.72798 13.585Z"
          fill="black"
          stroke={color}
          strokeWidth="0.833333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4235_45314">
          <rect
            width="21.8182"
            height="19.8758"
            fill="white"
            transform="translate(0 0.334961)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
