import { createSlice } from '@reduxjs/toolkit'
import { IOption } from '@src/shared/interfaces/partials/IOption'

export interface ICargoState {
  vehicleShippingNames: IOption[]
  vehicleLoadingTypes: IOption[]
  isCombined: boolean
  isDanger: boolean
  needKoniki: boolean
  weightDiapason: [string, string]
  lengthDiapason: [string, string]
  widthDiapason: [string, string]
  heightDiapason: [string, string]
  palletCountDiapason: [string, string]
  radiusFrom: number
  coordsFrom: [number, number] | null
  radiusWhere: number
  coordsWhere: [number, number] | null
}

const initialState: ICargoState = {
  vehicleShippingNames: [],
  vehicleLoadingTypes: [],
  isCombined: false,
  isDanger: false,
  needKoniki: false,
  weightDiapason: ['', ''],
  lengthDiapason: ['', ''],
  widthDiapason: ['', ''],
  heightDiapason: ['', ''],
  palletCountDiapason: ['', ''],
  radiusFrom: 1,
  coordsFrom: null,
  radiusWhere: 1,
  coordsWhere: null,
}

const filterSliceCargo = createSlice({
  name: 'filterCargo',
  initialState,
  reducers: {
    setValueInStateCargo: (state, action) => {
      const field = action.payload.field as keyof ICargoState
      state[field] = action.payload.data as never
    },
    resetCargoFilter: () => initialState,
  },
})

export const { setValueInStateCargo, resetCargoFilter } =
  filterSliceCargo.actions

export const filterSliceCargoReducer = filterSliceCargo.reducer
