import { Suspense, lazy, useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { YMInitializer } from 'react-yandex-metrika'

import { useChat } from '@pages/Chat'
import { useAppDispatch } from '@src/hooks/store'
import { CheckOnline } from '@src/shared/CheckOnline'
import { MutationResultNotifications } from '@src/shared/MutationResultNotifications'
import { updateWindowSize } from '@src/store/slices/windowSizeSlice'
import { debounce } from '@src/utils/helpers/debounce'
import { GlobalStyle } from '@theme/GlobalStyle'
import { CustomThemeProvider } from '@theme/index'
import Loader from '@ui/Loader/Loader'
import { MediaViewer } from '@ui/MediaViewer'

const AppRoutes = lazy(() => import('@src/app/AppRoutes'))
const LIBRARY_OF_COMPONENTS = lazy(() =>
  import('@ui/LIBRARY_OF_COMPONENTS/LIBRARY_OF_COMPONENTS').then((module) => ({
    default: module.LIBRARY_OF_COMPONENTS,
  }))
)

function App() {
  const dispatch = useAppDispatch()
  useChat()
  useEffect(() => {
    const handleResize = debounce(() => {
      dispatch(
        updateWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        })
      )
    })

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [dispatch])

  return (
    <CustomThemeProvider>
      <GlobalStyle />
      <CheckOnline />
      <Router basename={'app'}>
        <Suspense fallback={<Loader />}>
          <AppRoutes />
          <LIBRARY_OF_COMPONENTS />
        </Suspense>
      </Router>
      <MutationResultNotifications />
      <MediaViewer />
      {/*<button*/}
      {/*  type="button"*/}
      {/*  onClick={() => {*/}
      {/*    throw new Error('Sentry Test Error')*/}
      {/*  }}*/}
      {/*>*/}
      {/*  Break the world*/}
      {/*</button>*/}
      <YMInitializer accounts={[97122192]} />
    </CustomThemeProvider>
  )
}

export default App
