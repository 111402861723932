import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { NotificationContentType } from '@src/shared/MutationResultNotifications/types'

const initialState: {
  notifications: NotificationContentType[]
} = {
  notifications: [],
}

export const shiftNotificationAsync = createAsyncThunk(
  'shiftNotification',
  async () => {
    await new Promise((resolve) => {
      setTimeout(() => {
        resolve(true)
      }, 10000)
    })
  }
)

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    pushNotification: (state, action) => {
      state.notifications.push(action.payload)
    },

    shiftNotification: (state) => {
      state.notifications.shift()
    },

    closeNotification: (state, action) => {
      state.notifications = state.notifications.filter(
        (el, index) => index !== action.payload
      )
    },
  },
  extraReducers: (builder) => {
    builder.addCase(shiftNotificationAsync.fulfilled, (state) => {
      state.notifications.shift()
    })
  },
})

export const { pushNotification, closeNotification } =
  notificationsSlice.actions

export default notificationsSlice.reducer
