class ScrollLocker {
  //для корректной работы необходимы следующие стили:
  // html {
  //   height: 100%;
  // }
  // body {
  //   margin: 0;
  //   height: 100%;
  // }
  public scrollWidth = 0
  public getScrollWidth() {
    const inner = document.createElement('p')
    inner.style.width = '100%'
    inner.style.height = '200px'

    const outer = document.createElement('div')
    outer.style.position = 'absolute'
    outer.style.top = '0px'
    outer.style.left = '0px'
    outer.style.visibility = 'hidden'
    outer.style.width = '200px'
    outer.style.height = '150px'
    outer.style.overflow = 'hidden'
    outer.classList.add('calculated-scrollbar')
    outer.appendChild(inner)

    document.body.appendChild(outer)
    const w1 = inner.offsetWidth
    outer.style.overflow = 'scroll'
    let w2 = inner.offsetWidth
    if (w1 == w2) w2 = outer.clientWidth

    document.body.removeChild(outer)
    this.scrollWidth = w1 - w2

    return this.scrollWidth
  }
  public bodyHasScroll() {
    //чтобы отступ не появлялся, если изначально скролла не было
    return document.body.scrollHeight !== window.innerHeight
  }
  public lock() {
    document.body.style.overflow = 'hidden'
    //не вычисляем this.scrollWidth изначально, чтобы не замедлять загрузку страницы
    const scrollWidth = this.scrollWidth || this.getScrollWidth()

    document.body.style.width = `calc(100vw - ${scrollWidth}px)`

    const header = document.querySelector('header')
    if (header !== null) {
      header.style.paddingRight = `${scrollWidth}px`
    }
  }

  public unlock() {
    document.body.style.overflow = ''
    document.body.style.width = ''

    const header = document.querySelector('header')
    if (header !== null) {
      header.style.paddingRight = ''
    }
  }
}

export const scrollLocker = new ScrollLocker()
