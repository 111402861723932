import IconPropsInterface from '@icons/IconPropsInterface'

export const Stop = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 12 12"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path d="M9.53516 2.46484L2.46409 9.53591" strokeLinecap="round" />
      <circle cx="6" cy="6" r="5.5" />
    </svg>
  )
}
