import { css } from 'styled-components'

import { media } from '@theme/helpers'
import {
  IconLayoutVariant,
  VertPaddingVariant,
} from '@ui/Inputs/Base/interfaces'

export const iconLayoutVariants: Record<
  NonNullable<IconLayoutVariant>,
  ReturnType<typeof css>
> = {
  left: css`
    padding-left: 45px;
    padding-right: 15px;
  `,
  right: css`
    padding-left: 15px;
    padding-right: 45px;
  `,
  rightDouble: css`
    padding-left: 15px;
    padding-right: 73px;
  `,
  both: css`
    padding-left: 45px;
    padding-right: 45px;

    ${media(
      'small',
      () => `
    padding-left: 40px;
    padding-right: 40px;
  `
    )}
  `,
  none: css`
    padding-left: 15px;
    padding-right: 15px;
  `,
}

export const vertPaddingVariants: Record<
  NonNullable<VertPaddingVariant>,
  ReturnType<typeof css>
> = {
  small: css`
    padding-top: 8px;
    padding-bottom: 8px;
  `,
  regular: css`
    padding-top: 12px;
    padding-bottom: 12px;
  `,
  search: css`
    padding-top: 19px;
    padding-bottom: 19px;
    ${media(
      'small',
      () => `
    padding-top: 14px;
    padding-bottom: 14px;
  `
    )}
  `,
}
