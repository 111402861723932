import IconPropsInterface from './IconPropsInterface'

export const Reply = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 20 20"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M15.8333 15V10C15.8333 9.33696 15.5699 8.70107 15.1011 8.23223C14.6323 7.76339 13.9964 7.5 13.3333 7.5H7.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.832 10.8327L7.4987 7.49935L10.832 4.16602M6.66536 10.8327L3.33203 7.49935L6.66536 4.16602"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
