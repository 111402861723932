import { ICargoState } from '@pages/TasksBoard/filterSliceCargo'
import { IDeliveryState } from '@pages/TasksBoard/filterSliceDelivery'
import { IPassengerState } from '@pages/TasksBoard/filterSlicePassenger'
import { IRentState } from '@pages/TasksBoard/filterSliceRent'
import { getGlobalFilterPayload } from '@pages/TasksBoard/helpers/sendPayloadTransformHelpers'
import {
  addPageDataTasks,
  setCursorTasks,
  setLastItemsCountTasks,
  setTasksTabsAreDisabled,
} from '@pages/TasksBoard/taskSlice'
import { store } from '@src/store'
import { clientApiSlice } from '@src/store/slices/clientApiSlice'
import { formatUrlGetParamsFromObject } from '@src/utils/helpers/formatUrlGetParamsFromObject'

import { LIMIT_TASKS, filtersPayloadHelpersMap } from './constants'
import {
  getMediaData,
  getWhenData,
  transformTaskResponse,
} from './helpers/responseTransformHelpers'
import {
  ITaskChainResponseItem,
  ITaskItem,
  ITaskListItemViewData,
} from './interfaces'

export const taskBoardApi = clientApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTasks: builder.query<ITaskListItemViewData[], any>({
      query: ({ forMe, spaceId }) => {
        const state = store.getState()
        const currentFilterType = state.tasks.filter.type! //запрос должен вызываться после того, как загрузятся dirs/task-types
        const currentCursor = state.tasks.list.cursor
        const currentFilterGlobalVariant = state.tasks.filter.global
        const currentPaymentMethods = state.tasks.filter.payment_methods
        const currentRostechnadsor = state.tasks.filter.enable_rostechnadsor
        const filterIsOn = state.tasks.filter.filterIsOn
        const filtersDataMap = {
          cargo: state.filterCargo,
          delivery: state.filterDelivery,
          rent: state.filterRent,
          passenger: state.filterPassenger,
        }

        if (forMe) {
          return {
            url: `/spaces/${spaceId}/task-for-me/list${formatUrlGetParamsFromObject(
              { cursor: currentCursor, limit: LIMIT_TASKS }
            )}`,
            method: 'get',
          }
        }

        return {
          url: `/tasks`,
          method: 'POST',
          body: {
            order_by: 'created_at',
            sort: 'desc',
            for_me: forMe,
            cursor: currentCursor,
            limit: LIMIT_TASKS,
            filter: filterIsOn
              ? {
                  task_types: [currentFilterType.value],
                  payment_methods: currentPaymentMethods,
                  enable_rostechnadsor: currentRostechnadsor,
                  for_me: false,
                  ...getGlobalFilterPayload(currentFilterGlobalVariant),
                  [currentFilterType.value]: filtersPayloadHelpersMap[
                    currentFilterType.value
                  ](
                    filtersDataMap[currentFilterType.value] as IRentState &
                      IPassengerState &
                      ICargoState &
                      IDeliveryState
                  ),
                }
              : undefined,
          },
        }
      },
      transformResponse: (response: any): any => {
        store.dispatch(setCursorTasks(response?.data?.pagination.next_cursor))
        store.dispatch(
          addPageDataTasks(
            response?.data?.items?.map((item: ITaskItem) =>
              transformTaskResponse(item)
            )
          )
        )
        store.dispatch(
          setLastItemsCountTasks(response?.data?.items?.length || 0)
        )
        store.dispatch(setTasksTabsAreDisabled(false))
      },
    }),
    getTaskById: builder.query<ITaskListItemViewData, any>({
      query: (taskId) => ({
        url: `/tasks/${taskId}`,
        method: 'GET',
      }),
      //@ts-ignore
      transformResponse: ({ data }) => {
        return {
          ...transformTaskResponse(data),
          project: data.project,
          taskChain: data?.project?.tasks_chain?.map(
            (item: ITaskChainResponseItem) => ({
              id: item.id,
              title: item.title,
              description: getWhenData(item.when_to_start),
              taskType: item.type?.value,
            })
          ),
          media: getMediaData(data),
          // Пока функционал голосовых не реализован
          //voices: [],
        }
      },
    }),
    actions: builder.mutation({
      query: ({ taskId, action, type }) => ({
        url: `/tasks/${type}`,
        method: action === 'add' ? 'POST' : 'DELETE',
        body: {
          task_ids: [taskId],
        },
      }),
    }),
    getPhone: builder.mutation({
      query: ({ taskSpaceId, profilePhoneId }) => ({
        url: `/spaces/${taskSpaceId}/profile-phones/${profilePhoneId}`,
        method: 'POST',
      }),
    }),
  }),
})

export const {
  useLazyGetTasksQuery,
  useGetTaskByIdQuery,
  useActionsMutation,
  useGetPhoneMutation,
} = taskBoardApi
