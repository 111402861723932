import { createSlice } from '@reduxjs/toolkit'

export interface IContextMenuState {
  contextKey: string | null
  data: any
  coordinates: [number, number]
}

const initialState: IContextMenuState = {
  contextKey: null,
  data: null,
  coordinates: [0, 0],
}

const contextMenuSlice = createSlice({
  name: 'contextMenu',
  initialState,
  reducers: {
    openContextMenu: (state, action) => {
      state.contextKey = action.payload.contextKey
      state.data = action.payload.data
      state.coordinates = action.payload.coordinates
    },
    closeContextMenu: (state) => {
      state.contextKey = null
      state.data = null
      state.coordinates = [0, 0]
    },
  },
})

export const { openContextMenu, closeContextMenu } = contextMenuSlice.actions
export const contextMenuReducer = contextMenuSlice.reducer
