import IconPropsInterface from './IconPropsInterface'

export const BarBlacklist = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 14 14"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M4.9987 6.33333C6.47146 6.33333 7.66536 5.13943 7.66536 3.66667C7.66536 2.19391 6.47146 1 4.9987 1C3.52594 1 2.33203 2.19391 2.33203 3.66667C2.33203 5.13943 3.52594 6.33333 4.9987 6.33333Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 13V11.6667C1 10.9594 1.28095 10.2811 1.78105 9.78105C2.28115 9.28095 2.95942 9 3.66667 9H6.33333C7.04058 9 7.71885 9.28095 8.21895 9.78105C8.71905 10.2811 9 10.9594 9 11.6667V13"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.332 5L12.9987 7.66667M12.9987 5L10.332 7.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
