import { useParams } from 'react-router-dom'

import { useLazyGetBankAccountQuery } from '@pages/Offers/api'
import { useGetOwnersipDataQuery } from '@pages/Space/OwnershipTypes/api/OwnershipApi'
import {
  setBankAccountId,
  setOwnershipTypeId,
} from '@pages/TasksBoard/ExecutorOffer/executorOfferSlice'
import { useGetTaskByIdQuery } from '@pages/TasksBoard/taskBoardApi'
import { useAppDispatch, useAppSelector } from '@src/hooks/store'
import { usePermissions } from '@src/hooks/usePermissions'
import { useWindowResize } from '@src/hooks/useWindowResize'
import { Box } from '@ui/Box'
import DropDown from '@ui/DropDown'
import { RegularText } from '@ui/Typography'

export const FinanceInfo = () => {
  const { id: spaceId, taskId } = useParams()
  const { isDesktop } = useWindowResize()
  const dispatch = useAppDispatch()
  const { data, isLoading, isError } = useGetTaskByIdQuery(taskId)
  const { hasPermission: canViewBankAccounts } = usePermissions(
    'ownership_bank_account_view'
  )
  const { ownershipTypeId, bankAccountId } = useAppSelector(
    (state) => state.executorOffer
  )
  const [getBankAccount, { data: bankAccounts }] = useLazyGetBankAccountQuery() //spaceId, ownershipTypeId
  const { data: ownershipTypes } = useGetOwnersipDataQuery({ spaceId })
  const paymentMethod = data?.project?.payment_method?.value || 'bank'
  const readyOwnershipTypes =
    ownershipTypes?.data
      ?.filter((item: any) => item.model.state === 'approved')
      ?.filter((item: any) =>
        paymentMethod === 'bank_nds' ? item?.type.value !== 'individual' : true
      )
      .map((item: any) => {
        return {
          name: item.name,
          value: item.id,
        }
      }) || []

  const readyBankAccounts =
    bankAccounts?.data?.map((item: any) => {
      return {
        name: item.bank,
        value: item.id,
      }
    }) || []

  if (isLoading) {
    return null
  }

  return (
    <>
      {paymentMethod === 'bank_nds' && (
        <RegularText style={{ marginBottom: '20px' }}>
          Вы можете выбрать реквизиты только для юридического лица, поскольку в
          задаче указан безналичный расчет с НДС
        </RegularText>
      )}

      <Box
        flex
        flexDirection={isDesktop ? 'row' : 'column'}
        rg={10}
        justifyContent={isDesktop ? 'space-between' : 'center'}
        mb={20}
      >
        <DropDown
          width={isDesktop ? '49%' : '100%'}
          height={45}
          items={readyOwnershipTypes}
          title={'Выбрать форму собственности'}
          activeValue={ownershipTypeId || -1}
          handleChange={(value) => {
            dispatch(setOwnershipTypeId(value))
            if (value >= 1 && canViewBankAccounts) {
              getBankAccount({ spaceId, ownershipTypeId: value })
            }
          }}
        />
        {canViewBankAccounts && (
          <DropDown
            disabled={ownershipTypeId < 1}
            width={isDesktop ? '49%' : '100%'}
            height={45}
            items={readyBankAccounts}
            activeValue={bankAccountId || -1}
            title={'Выбрать банковский счет'}
            handleChange={(value) => {
              dispatch(setBankAccountId(value))
            }}
          />
        )}
      </Box>
    </>
  )
}
