import { useAppSelector } from '@src/hooks/store'
import { TypeRole } from '@src/shared/interfaces/profile/TypeRole'
import { useGetTheme } from '@theme/hooks'

import IconPropsInterface from './IconPropsInterface'

export const VereskCurrency = ({
  size = 20,
  color,
  style,
}: IconPropsInterface) => {
  const myRole = useAppSelector((state) => state.profile.myRole)
  const theme = useGetTheme()
  const colorsMap: Record<TypeRole, { color2: string; color3: string }> = {
    executor: {
      color2: '#BA5C06',
      color3: '#F38E30',
    },
    customer: {
      color2: '#0052B4',
      color3: '#12B8FF',
    },
    partner: {
      color2: '#0052B4',
      color3: '#12B8FF',
    },
  }

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M6.2098 16L0 0H4.27422L9.88107 16H6.2098Z"
        fill={color ?? 'url(#paint0_linear_2892_62554)'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3083 0H5.8488L7.55035 4.79038L8.06066 6.44794C8.19555 6.02595 8.28331 5.74913 8.31581 5.6141C8.40032 5.33896 8.48646 5.06383 8.58397 4.78869L10.3083 0Z"
        fill={color ?? 'url(#paint0_linear_2892_62554)'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7258 0L6.20993 16H9.88121L16 0H11.7258Z"
        fill={color ?? theme.colors.primary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.27422 0H0L1.24976 3.22397C2.21512 2.13356 3.23735 1.08028 4.27422 0Z"
        fill={color ?? 'url(#paint0_linear_2892_62554)'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7255 0H15.9997L14.75 3.22397C13.7862 2.13356 12.7624 1.08028 11.7255 0Z"
        fill={color ?? 'url(#paint0_linear_2892_62554)'}
      />
      <defs>
        <linearGradient
          id="paint0_linear_2892_62554"
          x1="3.81579"
          y1="-3.19807e-08"
          x2="7.89188"
          y2="17.2488"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.651712" stopColor={color ?? theme.colors.primary} />
          <stop
            offset="1"
            stopColor={color ?? colorsMap[myRole || 'executor'].color2}
          />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2892_62554"
          x1="8.1701"
          y1="15.9162"
          x2="8.1701"
          y2="1.03694e-08"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color ?? colorsMap[myRole || 'executor'].color3} />
          <stop offset="1" stopColor={color ?? theme.colors.primary} />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2892_62554"
          x1="-3.18534"
          y1="8.00142"
          x2="20.534"
          y2="8.00142"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color ?? theme.colors.primary} />
          <stop
            offset="1"
            stopColor={color ?? colorsMap[myRole || 'executor'].color3}
          />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2892_62554"
          x1="10.8745"
          y1="1.61227"
          x2="17.2109"
          y2="1.61227"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color ?? colorsMap[myRole || 'executor'].color3} />
          <stop offset="1" stopColor={color ?? theme.colors.primary} />
        </linearGradient>
      </defs>
    </svg>
  )
}
