import { startWs } from '@pages/Chat/chatWebsocket/startWs'
import { ws } from '@pages/Chat/chatWebsocket/ws'
import { wsConnectionTryRef } from '@pages/Chat/constants'
import { setChatStateValue } from '@pages/Chat/helpers'
import { store } from '@src/store'

export const onClose = () => {
  console.log('connection closed')
  if (wsConnectionTryRef.current) {
    ws.current = null
    const currentProfileId = store.getState().profile.id
    const userId = store.getState().user?.data?.id
    startWs(currentProfileId || 0, userId || 0)
  } else {
    setChatStateValue('isSocketOnline')(false)
  }
}
