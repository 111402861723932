import { Suspense, lazy } from 'react'

import { RegularText } from '@ui/Typography'

export { useChat } from './hooks/useChat'
export { PostMessage } from './components/external/PostMessage'

const ChatPage = lazy(() =>
  import('./ChatPage').then((res) => ({
    default: res.ChatPage,
  }))
)

export const ChatPageLazy = () => {
  return (
    <Suspense fallback={<RegularText>Loading...</RegularText>}>
      <ChatPage />
    </Suspense>
  )
}
