import { ApplicationState } from '@pages/MyApplications/types/application/IApplication'
import { TaskStateType } from '@pages/MyApplications/types/tasks/IApplicationTask'
import { PointType } from '@src/shared/interfaces/task/PointTypes'
import { TooltipPosition } from '@ui/Tooltip/BaseTooltip'
import { Tooltip } from "@src/store/slices/hintsSlice";

export const APPLICATIONS_LIMIT = 10

export const DEFAULT_SORT = 10

export const NO_SELECTED_ITEM_ID = -1

export const MAX_TITLE_LENGTH = 250
export const MIN_TITLE_LENGTH = 5
export const MAX_DESC_LENGTH = 2000

export const MAX_MEDIAS_COUNT = 5

export const INITIAL_DIMENSION_VALUE = {
  value: 'meter',
  label: 'м.',
  multiplier: 1,
}

export const INITIAL_WEIGHT_VALUE = {
  value: 'kilogram',
  label: 'кг.',
}

export const INITIAL_LOADING_KIND = {
  value: 'unloading',
  label: 'Paзгрузка',
}

export const tabTypes: { [key: string]: ApplicationState[] } = {
  active: ['published', 'in_process'],
  drafts: ['draft'],
  on_moderation: ['on_moderation', 'new', 'rejected'],
  ban: ['blocked'],
  closed: ['closed'],
}

export const momentOptions = {
  sameDay: 'LL [в] LT',
  nextDay: 'LL [в] LT',
  nextWeek: 'LL [в] LT',
  lastDay: 'LL [в] LT',
  lastWeek: 'LL [в] LT',
  sameElse: 'LL [в] LT',
}

export const cargoLoadingTypes = [
  { id: 1, name: 'Порузка', slug: 'loading' },
  { id: 2, name: 'Paзгрузка', slug: 'unloading' },
  { id: 3, name: 'Транзит', slug: 'transit' },
]

export const pointNames: Record<PointType, string> = {
  where: 'Куда',
  from: 'Откуда',
  stop: 'Остановка',
}

export const cargoPointLabels = {
  loading: {
    start: 'Начало загрузки',
    end: 'Конец загрузки',
  },
  unloading: {
    start: 'Начало разгрузки',
    end: 'Конец разгрузки',
  },
  transit: {
    start: 'Начало остановки',
    end: 'Конец остановки',
  },
}

export const passengerPointLabels = {
  from: {
    start: 'Начало посадки',
    end: 'Конец посадки',
  },
  where: {
    start: 'Начало высадки',
    end: 'Конец высадки',
  },
  stop: {
    start: 'Начало остановки',
    end: 'Конец остановки',
  },
}

export const applicationStatusesNames: { [key in ApplicationState]: string } = {
  draft: 'Черновик',
  new: 'Отправлено на модерацию',
  on_moderation: 'На модерации',
  rejected: 'Отклонена модератором',
  blocked: 'Заблокирована',
  published: 'Опубликована',
  in_process: 'В работе',
  closed: 'Завершена',
}

interface IColor {
  text: string
  bg: string
}

export const taskStateColors: { [key in TaskStateType]: IColor } = {
  opened: {
    text: 'primary',
    bg: 'primaryLight',
  },
  canceled: {
    text: 'error',
    bg: 'errorLight',
  },
  published: {
    text: 'primary',
    bg: 'primaryLight',
  },
  coordination: {
    text: 'primary',
    bg: 'primaryLight',
  },
  selected: {
    text: 'primary',
    bg: 'primaryLight',
  },
  in_process: {
    text: 'primary',
    bg: 'primaryLight',
  },
  completed: {
    text: 'primary',
    bg: 'primaryLight',
  },
}

export const radioButtonsValues = [
  {
    label: 'Да',
    value: true,
  },
  {
    label: 'Нет',
    value: false,
  },
]

export const pointsLabels = {
  rent: {
    address: 'Адрес работ',
    date: 'Время начала работ',
  },
  delivery: {
    address: 'Адрес доставки',
    date: 'Время доставки',
  },
}

export const APPLICATIONS_HINTS_TABS = [
  {
    position: 'bottom-left' as TooltipPosition,
    gap: 30,
    title: 'Активные',
    description: 'Список ваших активных заявок.',
    targetElementId: 'active', // id элемента, возле которого должна отобразиться подсказка
  },
  {
    position: 'bottom-left' as TooltipPosition,
    gap: 30,
    title: 'Черновики',
    description: 'Список созданных, но не опубликованных заявок.',
    targetElementId: 'drafts', // id элемента, возле которого должна отобразиться подсказка
  },
  {
    position: 'bottom' as TooltipPosition,
    gap: 30,
    title: 'На модерации',
    description: 'Список заявок, находящихся на модерации (проверке).',
    targetElementId: 'on_moderation', // id элемента, возле которого должна отобразиться подсказка
  },
  {
    position: 'bottom' as TooltipPosition,
    gap: 30,
    title: 'Бан',
    description:
      'Список ваших заявок, которые не прошли модерацию с указанием причины отказа в публикации.',
    targetElementId: 'ban', // id элемента, возле которого должна отобразиться подсказка
  },
  {
    position: 'bottom' as TooltipPosition,
    gap: 30,
    title: 'Завершенные',
    description: 'Список ваших завершенных заявок.',
    targetElementId: 'closed', // id элемента, возле которого должна отобразиться подсказка
  },
]

export const APPLICATIONS_HINTS_ACTIONS: Tooltip[] = [
  {
    position: 'bottom-left',
    gap: 30,
    title: 'Снять с публикации',
    description:
      'Ваша заявка становится неактивной и невидимой для исполнителей.',
    targetElementId: 'application-withdraw', // id элемента, возле которого должна отобразиться подсказка
  },
  {
    position: 'bottom',
    gap: 30,
    title: 'Редактировать',
    description:
      'Внесение изменений в текущую заявку. После любых изменений проводится модерация.',
    targetElementId: 'application-edit', // id элемента, возле которого должна отобразиться подсказка
  },
  {
    position: 'bottom',
    gap: 30,
    title: 'Перейти к предложениям',
    description: 'Список откликов исполнителей на вашу активную заявку.',
    targetElementId: 'application-offers', // id элемента, возле которого должна отобразиться подсказка
  },
  {
    position: 'bottom',
    gap: 30,
    title: 'Скрыть заявку',
    description:
      'Платная функция, позволяющая скрыть заявку от выбранных исполнителей, компаний.',
    targetElementId: 'application-steals', // id элемента, возле которого должна отобразиться подсказка
  },
]

export const APPLICATIONS_HINTS_FILTER = [
  {
    position: 'bottom-right' as TooltipPosition,
    gap: 30,
    title: 'Реквизиты/диапазон дат',
    description:
      'Фильтры для выборки нужных заявок среди действующих по реквизитам или дате публикации.',
    targetElementId: 'application-filter', // id элемента, возле которого должна отобразиться подсказка
  },
]

export const APPLICATION_HINTS = [
  ...APPLICATIONS_HINTS_TABS,
  ...APPLICATIONS_HINTS_ACTIONS,
  ...APPLICATIONS_HINTS_FILTER,
]

export const MOBILE_HINTS = [
  {
    position: 'bottom-left' as TooltipPosition,
    gap: 30,
    title: 'Активные',
    description: 'Список ваших активных заявок.',
    targetElementId: 'active', // id элемента, возле которого должна отобразиться подсказка
  },
  {
    position: 'bottom-left' as TooltipPosition,
    gap: 30,
    title: 'Черновики',
    description: 'Список созданных, но не опубликованных заявок.',
    targetElementId: 'drafts', // id элемента, возле которого должна отобразиться подсказка
  },
  {
    position: 'bottom-right' as TooltipPosition,
    gap: 30,
    title: 'На модерации',
    description: 'Список заявок, находящихся на модерации (проверке).',
    targetElementId: 'on_moderation', // id элемента, возле которого должна отобразиться подсказка
  },
  {
    position: 'bottom-right' as TooltipPosition,
    gap: 30,
    title: 'Реквизиты/диапазон дат',
    description:
      'Фильтры для выборки нужных заявок среди действующих по реквизитам или дате публикации.',
    targetElementId: 'application-filter-mob', // id элемента, возле которого должна отобразиться подсказка
  },
]
