import IconPropsInterface from './IconPropsInterface'

export const Search = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 14 14"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M6.33333 12.1667C9.55499 12.1667 12.1667 9.55499 12.1667 6.33333C12.1667 3.11167 9.55499 0.5 6.33333 0.5C3.11167 0.5 0.5 3.11167 0.5 6.33333C0.5 9.55499 3.11167 12.1667 6.33333 12.1667Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 13.5L10.5 10.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
