import { ITheme } from '@theme/interfaces'
import { getMenuListStyles } from '@ui/Selects/BaseSelect/getMenuListStyles'

export const getCommonStyles = (theme: ITheme, props: any) => {
  return {
    indicatorSeparator: () => ({ display: 'none' }),
    clearIndicator: () => (props.isClearable ? {} : { display: 'none' }),
    menu: (styles: any) => ({
      ...styles,
      overflow: 'hidden',
      zIndex: '10',
    }),
    menuList: (styles: any) => ({
      ...styles,
      ...getMenuListStyles(theme, props?.menuListHeight),
    }),
    placeholder: (defaultStyles: any) => {
      return {
        ...defaultStyles,
        color: theme.colors.gray50,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      }
    },
    singleValue: (styles: any) => ({
      ...styles,
      color:
        props.hasError && props.singleValueErrorColor
          ? `${theme.colors[props.singleValueErrorColor]}`
          : `${theme.colors.text20}`,
    }),
    input: (styles: any) => ({
      ...styles,
      color: `${theme.colors.text20}`,
    }),
    multiValue: (styles: any) => ({
      ...styles,
      maxWidth: '95%',
      backgroundColor: 'transparent',
      border: `1px solid ${theme.colors.gray77}`,
      borderRadius: '3px',
      padding: '2px 6px !important',
      lineHeight: '24px',
    }),
    multiValueLabel: (styles: any) => ({
      ...styles,
      color: theme.colors.text20,
      fontSize: theme.fontSizes.s,
    }),
    multiValueRemove: (styles: any) => ({
      ...styles,
      marginLeft: '6px !important',
      ':hover': {
        background: 'none',
      },
    }),
    valueContainer: (styles: any) => ({
      ...styles,
      gap: '6px',
      padding: '6px 0 !important',
    }),
  }
}
