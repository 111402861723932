import { FIELD_IS_REQUIRED_TEXT } from '@pages/TasksBoard/ExecutorOffer/constants'

export const checkCyrillicCharsOnly = (value: string) => {
  const regex = /^[А-Яа-яЁё\s]+$/
  if (regex.test(value)) {
    return ''
  } else {
    return 'Допустимы только буквы русского алфавита'
  }
}

export const noValidate = () => ''

export const checkIsRequired = (value: string) => {
  if (value === '') {
    return FIELD_IS_REQUIRED_TEXT
  }
  return ''
}
export const checkNumberInDiapason =
  (min: number, max: number, required?: boolean) => (value: string) => {}

export const checkNumberIsGreater =
  (from: number, required?: boolean) => (value: string) => {}
