import {
  LIMIT_EMPLOYEES_LIST,
  LIMIT_INVITATIONS,
  LIMIT_WORK_OBJECTS_LIST,
} from '@pages/Space/Employee/constants'
import {
  IEmployeeItem,
  IWorkObjectItem,
  InvitationItemState,
} from '@pages/Space/Employee/interfaces'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

const initialState: {
  employees: IEmployeeItem[]
  page: number
  lastItemsCount: number
  workObjects: IWorkObjectItem[]
  workObjectsPage: number
  workObjectsLastItemsCount: number
  invitations: InvitationItemState[]
  invitationsPage: number
  invitationsLastItemsCount: number
  fio: string
  currentPermissionsIds: number[]
  disabledPermissionsIds: number[]
  templateName: string
  activePresetId: number | null
  workPosition: string
  phone: string
  isPayEmployeesPopupOpened: boolean
} = {
  employees: [],
  page: 1,
  lastItemsCount: LIMIT_EMPLOYEES_LIST,
  fio: '',
  workObjects: [],
  workObjectsPage: 1,
  workObjectsLastItemsCount: LIMIT_WORK_OBJECTS_LIST,
  invitations: [],
  invitationsPage: 1,
  invitationsLastItemsCount: LIMIT_INVITATIONS,
  currentPermissionsIds: [],
  disabledPermissionsIds: [],
  templateName: '',
  activePresetId: null,
  workPosition: '',
  phone: '',
  isPayEmployeesPopupOpened: false,
}

const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    //Получение и пагинация списка сотрудников
    addPageDataEmployees: (state, action) => {
      state.employees = [...state.employees, ...action.payload]
    },
    incrementPage: (state) => {
      state.page += 1
    },
    setLastItemsCount: (state, action) => {
      state.lastItemsCount = action.payload
    },
    // сброс при мутации
    clearEmployeesList: (state) => {
      state.employees = []
      state.page = 1
      state.lastItemsCount = LIMIT_EMPLOYEES_LIST
    },
    //Получение и пагинация списка закрепленных объектов
    addPageDataWorkObjects: (state, action) => {
      state.workObjects = [...state.workObjects, ...action.payload]
    },
    incrementWorkObjectsPage: (state) => {
      state.workObjectsPage += 1
    },
    setWorkObjectsLastItemsCount: (state, action) => {
      state.workObjectsLastItemsCount = action.payload
    },
    clearWorkObjectsList: (state) => {
      state.workObjects = []
      state.workObjectsPage = 1
      state.workObjectsLastItemsCount = LIMIT_WORK_OBJECTS_LIST
    },
    //Получение и пагинация списка приглашений
    addPageDataInvitations: (state, action) => {
      state.invitations = [...state.invitations, ...action.payload]
    },
    incrementInvitationsPage: (state) => {
      state.invitationsPage += 1
    },
    setInvitationsLastItemsCount: (state, action) => {
      state.invitationsLastItemsCount = action.payload
    },
    clearInvitationsList: (state) => {
      state.invitations = []
      state.invitationsPage = 1
      state.invitationsLastItemsCount = LIMIT_WORK_OBJECTS_LIST
    },
    //Инпуты
    changeFio: (state, action) => {
      state.fio = action.payload
    },
    changePhone: (state, action) => {
      state.phone = action.payload
    },
    changeWorkPosition: (state, action) => {
      state.workPosition = action.payload
    },
    changeTemplateName: (state, action) => {
      state.templateName = action.payload
    },
    //Табы
    setActivePresetId: (state, action) => {
      state.activePresetId = action.payload
    },
    // из запроса или из табы пресетов
    setPermissions: (state, action) => {
      state.currentPermissionsIds = action.payload
    },
    //из чекбоксов
    addPermission: (state, action) => {
      state.currentPermissionsIds = [
        ...state.currentPermissionsIds,
        action.payload,
      ]
    },
    deletePermission: (state, action) => {
      state.currentPermissionsIds = state.currentPermissionsIds.filter(
        (item) => item !== action.payload
      )
    },
    //disabled permissions
    addDisabledPermission: (state, action) => {
      state.disabledPermissionsIds = [
        ...state.disabledPermissionsIds,
        action.payload,
      ]
    },
    deleteDisabledPermission: (state, action) => {
      state.disabledPermissionsIds = state.disabledPermissionsIds.filter(
        (item) => item !== action.payload
      )
    },
    clearDisabledPermissions: (state) => {
      state.disabledPermissionsIds = []
    },
    payEmployeesPopupToggled: (state, { payload }: PayloadAction<boolean>) => {
      state.isPayEmployeesPopupOpened = payload
    },
  },
})

export const {
  addPageDataEmployees,
  clearEmployeesList,
  incrementPage,
  changeFio,
  setLastItemsCount,
  setPermissions,
  addPermission,
  deletePermission,
  changeTemplateName,
  setActivePresetId,
  changePhone,
  changeWorkPosition,
  addPageDataWorkObjects,
  incrementWorkObjectsPage,
  setWorkObjectsLastItemsCount,
  clearWorkObjectsList,
  addPageDataInvitations,
  incrementInvitationsPage,
  setInvitationsLastItemsCount,
  clearInvitationsList,
  addDisabledPermission,
  deleteDisabledPermission,
  clearDisabledPermissions,
  payEmployeesPopupToggled,
} = employeeSlice.actions

export default employeeSlice.reducer
