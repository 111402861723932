import { autoAnswerApiSlice } from '@pages/Space/Autoanswers/api'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from '@src/store'

interface IAnswerState {
  deletedAnswerId: number | null
  currentAnswerCoords: [number, number] | null
  expandedAnswerId: number | null
  delivery: {
    activeTab: 'answers' | 'materials'
  }
  isPaymentPopupOpened: boolean

  activatedAnswerId: number | null
}

const answerInitialState: IAnswerState = {
  deletedAnswerId: null,
  currentAnswerCoords: null,
  expandedAnswerId: null,
  delivery: {
    activeTab: 'answers',
  },
  isPaymentPopupOpened: false,

  activatedAnswerId: null,
}

const autoanswersSlice = createSlice({
  name: 'autoanswers',
  initialState: answerInitialState,
  reducers: {
    deletedAnswerIdChanged: (
      state,
      { payload }: PayloadAction<number | null>
    ) => {
      state.deletedAnswerId = payload
    },
    currentAnswerCoordsChanged: (
      state,
      { payload }: PayloadAction<[number, number] | null>
    ) => {
      state.currentAnswerCoords = payload
    },
    expandedAnswerIdChanged: (
      state,
      { payload }: PayloadAction<number | null>
    ) => {
      state.expandedAnswerId = payload
    },
    deliveryActiveTabChanged: (
      state,
      { payload }: PayloadAction<'answers' | 'materials'>
    ) => {
      state.delivery.activeTab = payload
    },
    isPaymentPopupOpenedChanged: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isPaymentPopupOpened = payload
    },

    activatedAnswerIdChanged: (
      state,
      { payload }: PayloadAction<number | null>
    ) => {
      state.activatedAnswerId = payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      autoAnswerApiSlice.endpoints.deleteAutoAnswers.matchFulfilled,
      (state) => {
        state.deletedAnswerId = null
      }
    )
  },
})

export const {
  deletedAnswerIdChanged,
  currentAnswerCoordsChanged,
  expandedAnswerIdChanged,
  deliveryActiveTabChanged,
  isPaymentPopupOpenedChanged,
  activatedAnswerIdChanged,
} = autoanswersSlice.actions

export const selectDeletedAnswerId = (state: RootState) =>
  state.autoanswers.deletedAnswerId

export const deliveryActiveTab = (state: RootState) =>
  state.autoanswers.delivery.activeTab

export const selectIsPaymentPopupOpened = (state: RootState) =>
  state.autoanswers.isPaymentPopupOpened

export default autoanswersSlice.reducer
