import { GroupBase, Props } from 'react-select'
import { AsyncPaginate } from 'react-select-async-paginate'

import { useGetTheme } from '@theme/hooks'
import { getCommonSelectStyles } from '@ui/Selects/BaseSelect/BaseSelect'
import {
  LOADING_MESSAGE,
  NO_OPTIONS_MESSAGE,
} from '@ui/Selects/BaseSelect/constants'
import { ClearIndicator } from '@ui/Selects/partials/ClearIndicator'
import { DropdownIndicator } from '@ui/Selects/partials/DropdownIndicator'
import { MultiValueRemove } from '@ui/Selects/partials/MultiValueRemove'
import { Option } from '@ui/Selects/partials/Option'

export interface IInfiniteScrollSelectProps {
  loadOptions: any
  additional?: any
  defaultOptions?: boolean
}

export const InfiniteScrollSelect = <
  TOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<TOption> = GroupBase<TOption>
>({
  loadOptions,
  additional,
  hasError,
  noOptionsMessage = () => NO_OPTIONS_MESSAGE,
  loadingMessage = () => LOADING_MESSAGE,
  ...rest
}: Props<TOption, IsMulti, Group> & IInfiniteScrollSelectProps) => {
  const theme = useGetTheme()

  return (
    <AsyncPaginate
      loadOptions={loadOptions}
      additional={additional}
      // @ts-ignore
      isOptionDisabled={(option) => !!option?.disabled}
      styles={getCommonSelectStyles(theme, { hasError })}
      components={{
        DropdownIndicator,
        ClearIndicator,
        MultiValueRemove,
        Option,
      }}
      loadingMessage={loadingMessage}
      noOptionsMessage={noOptionsMessage}
      {...rest}
    />
  )
}
