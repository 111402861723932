export const formatUrlFromParamsArray = (
  paramName: string,
  array: number[]
) => {
  let result = ''
  array.forEach((item) => {
    result += `${paramName}[]=${item}&`
  })
  return result
}
