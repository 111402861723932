import IconPropsInterface from './IconPropsInterface'

export const Pin = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 16 17"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M8 9.66602C9.38071 9.66602 10.5 8.54673 10.5 7.16602C10.5 5.7853 9.38071 4.66602 8 4.66602C6.61929 4.66602 5.5 5.7853 5.5 7.16602C5.5 8.54673 6.61929 9.66602 8 9.66602Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.7129 11.8806L9.17703 15.4164C8.86452 15.7286 8.44085 15.904 7.99912 15.904C7.55738 15.904 7.13371 15.7286 6.8212 15.4164L3.28453 11.8806C2.35222 10.9482 1.71731 9.76034 1.46011 8.46714C1.2029 7.17394 1.33494 5.83352 1.83954 4.61536C2.34413 3.39721 3.19861 2.35604 4.29494 1.62351C5.39126 0.890984 6.68017 0.5 7.9987 0.5C9.31723 0.5 10.6061 0.890984 11.7025 1.62351C12.7988 2.35604 13.6533 3.39721 14.1579 4.61536C14.6625 5.83352 14.7945 7.17394 14.5373 8.46714C14.2801 9.76034 13.6452 10.9482 12.7129 11.8806V11.8806Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
