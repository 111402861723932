import { RefObject, forwardRef } from 'react'
import styled from 'styled-components'

import * as Icons from '@icons/index'
import { useToggle } from '@src/hooks/useToggle'
import { useGetTheme } from '@theme/hooks'
import { BaseButton } from '@ui/Buttons/Base/BaseButton'
import {
  IBaseButtonProps,
  IBaseIconButtonProps,
} from '@ui/Buttons/Base/interfaces'
import { Spinner } from '@ui/Loader/Spinner'

const BaseIconButtonInner = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`
const TextMarginContainer = styled.span<{
  iconPosition: 'left' | 'right'
  color?: string
  margin?: number
}>`
  margin-left: ${({ iconPosition, margin }) =>
    iconPosition === 'left' ? `${margin ?? 10}px` : '0'};
  margin-right: ${({ iconPosition, margin }) =>
    iconPosition === 'right' ? `${margin ?? 10}px` : '0'};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ color }) => color || 'inherit'};
  transition: color 300ms;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
`

//Три варианта отображения кнопки: иконка слева, справа от текста, и просто иконка.

export const BaseIconButton = forwardRef(
  (
    {
      sizeVariant,
      fillVariant,
      borderVariant,
      onClick,
      fluid = false,
      children,
      iconName,
      iconColor,
      iconSize = 20,
      hoverIconName = iconName,
      hoverIconColor = iconColor,
      hoverIconSize = iconSize,
      iconPosition = 'left',
      disabled = false,
      style,
      className,
      margin,
      disabledIconColor,
      type,
      isLoading,
      withLoading = true,
      ...restProps
    }: IBaseIconButtonProps & IBaseButtonProps,
    ref
  ) => {
    const theme = useGetTheme()
    const { isOn: isHovered, on: hover, off: unhover } = useToggle(false)
    const CurrentIcon = isHovered
      ? Icons[hoverIconName as keyof typeof Icons]
      : Icons[iconName as keyof typeof Icons]
    const currentSize = isHovered ? hoverIconSize : iconSize
    let currentColor = ''
    if (disabled) {
      currentColor = disabledIconColor ?? iconColor
    } else {
      currentColor = isHovered ? hoverIconColor : iconColor
    }

    return (
      <BaseButton
        onClick={onClick}
        onMouseEnter={hover}
        onMouseLeave={unhover}
        sizeVariant={sizeVariant}
        fillVariant={fillVariant}
        borderVariant={borderVariant}
        fluid={fluid}
        disabled={disabled}
        style={style}
        ref={ref as RefObject<HTMLButtonElement>}
        className={className}
        type={type}
        {...restProps}
      >
        {isLoading && withLoading ? (
          <BaseIconButtonInner>
            <Spinner size={20} color={theme.colors.light} />
          </BaseIconButtonInner>
        ) : (
          <BaseIconButtonInner>
            {iconPosition === 'left' && (
              <CurrentIcon size={currentSize} color={currentColor} />
            )}
            {children && (
              <TextMarginContainer
                iconPosition={iconPosition}
                color={currentColor}
                margin={margin}
              >
                {children}
              </TextMarginContainer>
            )}
            {iconPosition === 'right' && (
              <CurrentIcon size={currentSize} color={currentColor} />
            )}
          </BaseIconButtonInner>
        )}
      </BaseButton>
    )
  }
)
