import IconPropsInterface from './IconPropsInterface'

export const BarTreaty = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 12 14"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M7.33203 1V3.66667C7.33203 3.84348 7.40227 4.01305 7.52729 4.13807C7.65232 4.2631 7.82189 4.33333 7.9987 4.33333H10.6654"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33203 13H2.66536C2.31174 13 1.9726 12.8595 1.72256 12.6095C1.47251 12.3594 1.33203 12.0203 1.33203 11.6667V2.33333C1.33203 1.97971 1.47251 1.64057 1.72256 1.39052C1.9726 1.14048 2.31174 1 2.66536 1H7.33203L10.6654 4.33333V11.6667C10.6654 12.0203 10.5249 12.3594 10.2748 12.6095C10.0248 12.8595 9.68565 13 9.33203 13Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 3.66797H4.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M4 7.66797H8" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M6.66406 10.332H7.9974"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
