import styled from 'styled-components'

export const ItemsList = styled.ul`
  width: 100%;
  overflow-y: auto;
  //padding: 0 15px !important;
  list-style: none;
  display: flex;
  flex-direction: column;

  li {
    padding: 5px 0;
    max-width: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &:first-child {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }

  p {
    text-align: start;
    max-width: 88%;
    color: ${(props) => props.theme.colors.gray10};
    word-wrap: break-word;
    white-space: normal !important;
    transition: ${({ theme }) => theme.transitions.base};
    margin-left: 15px;
  }

  p:hover {
    color: ${(props) => props.theme.colors.primaryHover};
  }
`

export const ItemWrapper = styled.div<{ isSelected: boolean }>`
  li {
    &:hover {
      background: ${({ theme, isSelected }) =>
        isSelected ? theme.colors.delivery20 : theme.colors.gray80};
    }
  }
`
