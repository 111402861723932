import IconPropsInterface from './IconPropsInterface'

export const Notification = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill={'none'}
      stroke={color}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M5.48867 9.03366C5.40325 10.7277 3.56659 11.3832 3.56641 12.9284C3.56631 13.7335 4.07085 14.4681 4.93734 14.4681H15.0427C16.0928 14.4681 16.4322 13.7384 16.4322 12.9284C16.4322 11.3987 14.4355 10.7429 14.4355 9.03366C14.4355 8.1236 14.4355 7.58697 14.4355 6.71496C14.4355 4.44757 12.2589 2.98178 9.99153 2.97852C7.72025 2.97525 5.6556 4.44691 5.53455 6.71496C5.48867 7.57478 5.53455 8.1236 5.48867 9.03366Z"
        stroke={color}
      />
      <path
        d="M12.499 14.4681H7.49902C7.49902 14.4681 7.48403 17.0223 9.99153 17.0223C12.499 17.0223 12.499 14.4681 12.499 14.4681Z"
        stroke={color}
      />
    </svg>
  )
}
