export const customerColors = {
  primary: 'hsla(213, 100%, 46%, 1)',
  primaryHover: 'hsla(213, 96%, 53%, 1)',
  primaryLight: 'hsla(213, 92%, 95%, 1)',
  primaryHoverLight: 'hsla(213, 85%, 92%, 1)',
  primaryExtraLight: 'hsla(213, 100%, 46%, 0.02)',
  primaryLightOpacity: 'hsla(213, 92%, 95%, 0.15)',
}

export const executorColors = {
  primary: 'hsla(29, 100%, 46%, 1)',
  primaryHover: 'hsla(29, 96%, 53%, 1)',
  primaryLight: 'hsla(29, 92%, 95%, 1)',
  primaryHoverLight: 'hsla(29, 85%, 92%, 1)',
  primaryExtraLight: 'hsla(29, 100%, 46%, 0.02)',
}

export const commonColors = {
  delivery: 'hsla(213, 100%, 46%, 1)',
  blue: 'hsla(213, 86%, 73%, 1)',
  delivery10: 'hsla(213, 100%, 46%, 0.1)',
  delivery20: 'hsla(213, 100%, 46%, 0.05)',
  delivery30: 'hsla(241, 81%, 51%, 1)',
  rent: 'hsla(101, 61%, 47%, 1)',
  green: 'hsla(135, 59%, 49%, 1)',
  green10: `hsla(136, 60%, 95%, 1)`,
  green20: `hsla(135, 59%, 49%, 0.1)`,
  green30: `hsla(135, 59%, 49%, 0.2)`,
  green40: `hsla(135, 59%, 49%, 0.05)`,
  lightGreen: 'hsla(158, 82%, 47%, 1)',
  lightGreenBg: 'hsla(158, 82%, 47%, 0.5)',
  rent10: 'hsla(101, 60%, 47%, 0.1)',
  rent20: 'hsla(101, 60%, 47%, 0.05)',
  rent30: 'hsla(158, 82%, 47%, 0.1)',
  rent40: `hsla(101, 60%, 47%, 1)`,
  cargo: 'hsla(253, 80%, 60%, 1)',
  cargo10: 'hsla(253, 80%, 60%, 0.1)',
  cargo20: 'hsla(253, 80%, 60%, 0.05)',
  passenger: 'hsla(21, 100%, 59%, 1)',
  passenger12: 'hsla(21, 100%, 59%, 0.5)',
  passenger13: 'hsla(31, 100%, 56%, 1)',
  passenger11: 'hsla(31, 100%, 56%, 0.1)',
  passenger10: 'hsla(21, 100%, 59%, 0.1)',
  passenger20: 'hsla(21, 100%, 59%, 0.05)',
  passenger30: 'hsla(36, 100%, 52%, 0.1)',
  accent: 'hsla(0, 100%, 58%, 1)',
  accent10: 'hsla(0, 100%, 58%, 0.5)',
  accent100: 'hsla(0, 100%, 58%, 0.05)',
  accent15: 'hsla(0, 100%, 58%, 0.1)',
  accent20: 'hsla(0, 100%, 58%, 0.05)',
  text: 'hsla(0, 0%, 21%, 1)',
  text10: 'hsla(0, 0%, 28%, 0.5)',
  text20: 'hsla(0, 0%, 28%, 1)',
  body: 'hsla(0, 0%, 98%,1)',
  light: 'hsla(0, 0%, 100%, 1)',
  lightBg: 'hsla(0, 100%, 98%, 1)',
  lightTransparent: 'hsla(0, 0%, 100%, 0.2)',
  online: 'hsla(137, 100%, 40%, 1)',
  tabBorder: 'hsla(213, 10%, 94%, 1)',
  gray10: 'hsla(0, 0%, 42%, 1)',
  gray11: 'hsla(0, 0%, 43%, 1)',
  gray20: 'hsla(0, 0%, 52%, 1)',
  gray21: 'hsla(0, 0%, 53%, 1)',
  gray30: 'hsla(0, 0%, 54%, 1)',
  gray31: 'hsla(0, 0%, 68%, 1)',
  gray40: 'hsla(0, 0%, 65%, 1)',
  gray50: 'hsla(0, 0%, 73%, 1)',
  gray51: 'hsla(0, 0%, 73%, 0.05)',
  gray52: 'hsla(0, 0%, 28%, 0.1)',
  gray53: 'hsla(0, 0%, 73%, 0.4)',
  gray54: 'hsla(0, 0%, 73%, 0.2)',
  gray60: 'hsla(0, 0%, 75%, 1)',
  gray68: 'hsla(0, 0%, 83%, 1)',
  gray69: 'hsla(0, 0%, 84%, 1)',
  gray70: 'hsla(0, 0%, 85%, 1)',
  gray75: 'hsla(0, 0%, 88%, 1)',
  gray76: 'hsla(0, 0%, 89%, 0.5)',
  gray77: 'hsla(0, 0%, 91%, 1)',
  gray78: 'hsla(213, 7%, 47%, 1)',
  gray79: 'hsla(0, 0%, 75%, 0.1)',
  gray80: 'hsla(0, 0%, 94%, 1)',
  gray81: 'hsla(0, 0%, 0%, 0.1)',
  gray82: 'hsla(0, 0%, 0%, 0.05)',
  gray83: 'hsla(225, 5%, 15%, 1)',
  gray84: 'hsla(221, 13%, 68%, 1)',
  gray85: 'hsla(210, 4%, 10%, 1)',
  gray86: 'hsla(0, 0%, 13%, 0.3)',
  gray90: 'hsla(0, 0%, 96%, 1)',
  gray92: 'hsla(0, 0%, 98%, 1)',
  gray93: 'hsla(0, 0%, 97%, 1)',
  gray94: 'hsla(210, 100%, 97%, 1)',
  gray95: 'hsla(0, 0%, 89%, 1)',
  gray96: 'hsla(0, 0%, 36%, 1)',
  success: 'hsla(101, 61%, 47%, 1)',
  error: 'hsla(0, 100%, 58%, 1)',
  errorLight: 'hsla(0, 100%, 98%, 1)',
  successBg: 'hsla(101, 61%, 95%, 1)',
  errorBg: 'hsla(0, 100%, 97%, 1)',
  pink: 'hsla(298, 53%, 58%, 1)',
  pink10: 'hsla(298, 53%, 58%, 0.1)',
  yellow: 'hsla(36, 100%, 52%, 1)',
  yellow2: 'hsla(45, 88%, 57%, 1)',
  yellowLight: 'hsla(36, 100%, 52%, 0.05)',
  orange: 'hsla(32, 78%, 56%, 1)',
  orange10: 'hsla(29, 89%, 57%, 1)',
  yellow10: 'hsla(36, 100%, 52%, 0.05)',
  yellow11: 'hsla(31, 100%, 56%, 0.1)',
  yellow20: 'hsla(44, 90%, 68%, 1)',
  transparent: 'transparent',
  refill: 'hsla(135, 59%, 49%, 1)',
  refill10: 'hsla(135, 59%, 49%, 0.7)',
  refill20: 'hsla(135, 59%, 49%, 0.05)',
  refund: 'hsla(29, 89%, 57%, 1)',
  withdraw: 'hsla(0, 100%, 58%, 1)',
  tagColor: 'hsla(0, 0%, 100%, 0.1)',
}
