import { useEffect } from 'react'

import { resetChatState } from '@pages/Chat/chatSlice/chatSlice'
import { startWs } from '@pages/Chat/chatWebsocket/startWs'
import { setChatStateValue } from '@pages/Chat/helpers'
import { useAppDispatch, useAppSelector } from '@src/hooks/store'
import { checkFeature } from '@src/shared/featureToggle/checkFeature'

export const useChat = () => {
  const dispatch = useAppDispatch()
  const currentProfileId = useAppSelector((state) => state.profile.id)
  const userId = useAppSelector((state) => state.user?.data?.id)
  const myRole = useAppSelector((state) => state.profile.myRole)
  useEffect(() => {
    if (
      currentProfileId &&
      userId &&
      myRole !== 'partner' &&
      checkFeature('chat')
    ) {
      startWs(currentProfileId || 0, userId || 0)
      dispatch(resetChatState())
      setChatStateValue('commonNewMessagesCount')(0)
    }
  }, [currentProfileId, dispatch, userId, myRole])
}
