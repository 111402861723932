import { noop } from '@src/utils/helpers/noop'

import IconPropsInterface from './IconPropsInterface'

export const Date = ({
  size = 20,
  color,
  style,
  onClick,
}: IconPropsInterface & { onClick?: () => void }) => {
  const baseStyle = { flexShrink: 0, transition: '300ms' }
  const combinedStyle = style
    ? {
        ...baseStyle,
        ...style,
      }
    : baseStyle

  return (
    <svg
      viewBox="0 0 15 14"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={combinedStyle}
      onClick={onClick || noop}
    >
      <path
        d="M6.86333 12.125H2.33333C1.97971 12.125 1.64057 11.9933 1.39052 11.7589C1.14048 11.5245 1 11.2065 1 10.875V3.375C1 3.04348 1.14048 2.72554 1.39052 2.49112C1.64057 2.2567 1.97971 2.125 2.33333 2.125H10.3333C10.687 2.125 11.0261 2.2567 11.2761 2.49112C11.5262 2.72554 11.6667 3.04348 11.6667 3.375V5.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9987 12.75C12.4715 12.75 13.6654 11.6307 13.6654 10.25C13.6654 8.86929 12.4715 7.75 10.9987 7.75C9.52594 7.75 8.33203 8.86929 8.33203 10.25C8.33203 11.6307 9.52594 12.75 10.9987 12.75Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9 0.875V3.375" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M3.66797 0.875V3.375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M1 5.875H11.6667" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M11 9.30859V10.2486L11.6667 10.8736"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
