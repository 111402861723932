import IconPropsInterface from '@icons/IconPropsInterface'

export const Keys = ({ size = 20, color, style }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        ...style,
        flexShrink: 0,
        transition: '300ms',
      }}
    >
      <path
        d="M15.8631 5.934C16.291 7.5308 15.6791 9.16125 14.4425 10.1066C14.4356 10.1443 14.426 10.1816 14.4136 10.2182L14.2021 10.8434C14.0997 11.1463 14.1894 11.481 14.4295 11.6921L14.7088 11.9375C14.9489 12.1486 15.0386 12.4833 14.9362 12.7862L14.817 13.1384C14.7146 13.4412 14.8043 13.776 15.0444 13.987L15.0919 14.0287C15.4333 14.3287 15.4527 14.8538 15.1345 15.1782L13.1629 17.1882L12.6604 15.4009L11.4478 10.8755C9.93876 10.6472 8.63353 9.55222 8.21327 7.98377C7.64724 5.87132 8.90086 3.69999 11.0133 3.13396C13.1258 2.56793 15.2971 3.82155 15.8631 5.934Z"
        fill={color}
      />
      <path
        d="M8.95687 11.2662C8.9863 11.264 9.01567 11.2615 9.04498 11.2586C8.06457 10.5769 7.31072 9.55814 6.97745 8.31438C6.49212 6.50309 7.01786 4.65908 8.20872 3.38159C6.72251 3.55467 5.39866 4.56693 4.90175 6.08205C4.39568 7.62507 4.88957 9.25577 6.02818 10.2722L4.56774 14.7252L3.99902 16.3321L6.71559 15.8617C7.17726 15.7818 7.46899 15.3207 7.34353 14.8693L7.33692 14.8455C7.25131 14.5375 7.35931 14.2082 7.61073 14.0107L7.90309 13.781C8.15451 13.5835 8.26252 13.2542 8.1769 12.9461L8.07735 12.5879C7.99173 12.2799 8.09973 11.9506 8.35115 11.7531L8.87014 11.3454C8.9011 11.3211 8.93007 11.2946 8.95687 11.2662Z"
        fill={color}
      />
    </svg>
  )
}
