import IconPropsInterface from '@icons/IconPropsInterface'

export const Deal = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 21H4.5C3.948 21 3.5 20.552 3.5 20V14C3.5 13.448 3.948 13 4.5 13H6.5C7.052 13 7.5 13.448 7.5 14V20C7.5 20.552 7.052 21 6.5 21Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 17.0001H15.833C16.266 17.0001 16.687 16.8601 17.033 16.6001L19.449 14.7881C20.052 14.3361 20.896 14.3961 21.429 14.9291V14.9291C22.021 15.5211 22.021 16.4801 21.429 17.0711L19.356 19.1441C18.798 19.7021 18.086 20.0831 17.312 20.2381L14.382 20.8241C13.801 20.9401 13.202 20.9261 12.627 20.7821L9.977 20.1201C9.66 20.0401 9.335 20.0001 9.008 20.0001H7.5"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 17H14.985C15.822 17 16.5 16.322 16.5 15.485V15.182C16.5 14.487 16.027 13.881 15.353 13.713L13.061 13.14C12.688 13.047 12.306 13 11.922 13V13C10.995 13 10.088 13.274 9.317 13.789L7.5 15"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.669 5.15545C18.7773 6.26376 18.7773 8.06068 17.669 9.16899C16.5607 10.2773 14.7638 10.2773 13.6555 9.16899C12.5471 8.06068 12.5471 6.26376 13.6555 5.15545C14.7638 4.04714 16.5607 4.04714 17.669 5.15545"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.131 4.375C15.038 3.81 14.78 3.267 14.344 2.831C13.236 1.723 11.439 1.723 10.331 2.831C9.223 3.939 9.223 5.736 10.331 6.844C11.021 7.534 11.977 7.793 12.868 7.624"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
