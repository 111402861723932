import IconPropsInterface from './IconPropsInterface'

export const SendMessage = ({ size = 20, color }: IconPropsInterface) => {
  return (
    <svg
      viewBox="0 0 14 14"
      stroke={color}
      fill="none"
      width={size}
      height={size}
      style={{ flexShrink: 0, transition: '300ms' }}
    >
      <path
        d="M2.62766 7H13M2.62766 7L0.936862 2.49577C0.612172 1.63081 1.53371 0.825843 2.34717 1.26386L13 7M2.62766 7L0.936862 11.5042C0.612172 12.3692 1.53371 13.1742 2.34717 12.7361L13 7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
